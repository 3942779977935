/* 29 Nov 2022 - PAR-1045 - Moved utilization to manual entry from users.  Left in former code for poss. future changes */

/* Monthly goals assessement, 
Modal design
Questions to assess monthly goal, with follow up if not met to see why the user didn't meet them. (Skippable).
Displays the 1st of every month (or logged in later), must complete the assessment.
Only showes the last months assessment, and missed before that are automatic failures (via backend)
*/

import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import CardContainer from '../CardContainer';
import api from '../../utils/api';
import { preMonthName, prevMonthDate, parseIdFromLink } from '../../utils/helpers';
import Start from './Start';
import AssessmentQuestion from './AssessmentQuestion';
import WellnessSurvey from './WellnessSurvey';
import SeeResults from './SeeResults';
import Results from './Results';
import TellMore from './TellMore';
import useErrorHandling from '../../hooks/useErrorHandling';

import calendarIcon from '../../assets/icons/calendarIcon.svg';
import healthIcon from '../../assets/icons/healthIcon.svg';
import utilizationIcon from '../../assets/icons/utilizatinIcon.svg';

import cookies from '../../utils/cookies';
import './Index.scss';
import SetAPlan from './SetAPlan';

//-------------------------------------------------------------------------------------------

export default function GoalsAssessmentModal({ goalAssessmentData, userIDLink, firstTime, setRefreshUserData }) {
  /* firstTime, is based on the users onboardingComplete flag */
  const prevMonth = preMonthName(cookies.getDate());
  window.history.pushState(
    { page: 'monthlyAssessment' },
    'Monthly Assessment',
    `/client/monthlyAssessment?month=${prevMonth}`
  );
  const [status, setError, setLoading, resetErrorHandler] = useErrorHandling();
  const [showAssessmentModal, setshowAssessmentModal] = useState(true);

  const [paidParachuteOnTime, setPaidParachuteOnTime] = useState(false);
  const [assessmentData, setAssessmentData] = useState({
    /* Had issues with refreshing data, data update from a useEffect...
    Initial state is now null */
    loanOnTime: {
      goalMet: null,
      missedAndLatePayments: [],
    },
    // lowUtilization: {
    //   goalMet: null,
    //   threshold: null,
    //   lastMonthsUtilization: null,
    // },
    underUtilization: null,
    billsOnTime: null,
    noNewDebt: null,
    paidParachuteOnTime: null
  });

  const [surveyData, setSurveyData] = useState(null);
  /* TODO DRY CODE, goalStatus is redundant to assessment state.  partly created to use with a .every() */

  console.log(assessmentData)
  let local_paidParachuteOnTime = localStorage.getItem('paidParachuteOnTime') ? ((localStorage.getItem('paidParachuteOnTime') === true || localStorage.getItem('paidParachuteOnTime') === 'true') ? true : false) : false;
  let local_goal_met = false;
  if (assessmentData.underUtilization && assessmentData.billsOnTime && assessmentData.noNewDebt && local_paidParachuteOnTime) {
    local_goal_met = true;
  }

  const goalsStatus = {
    loanOnTime: local_goal_met,//assessmentData.loanOnTime.goalMet,
    lowUtilization: assessmentData.underUtilization, //.lowUtilization.goalMet,
    billsOnTime: assessmentData.billsOnTime,
    noNewDebt: assessmentData.noNewDebt,
    paidParachuteOnTime: local_paidParachuteOnTime
  };
  const [dataToUpload, setdataToUpload] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [reasonPatched, setReasonPatched] = useState(false);
  const [skip, setSkip] = useState(false);
  const [assessmentID, setAssessmentID] = useState(null);

  const updateAssessment = (key, value) => {
    const prev = assessmentData;
    const newData = {
      ...prev,
      [key]: value,
    };
    setAssessmentData(newData);
  };

  //============================================= Initizlize (fetch data)

  useEffect(() => {
    //Fetch data for prev Month
    //Last finData > for utilization and last payment
    //First finData > for payment Date > may not need because the date should be fixed...
    // const determineLoanGoalMet = () => {
    //   if (firstTime) return null;
    //   if (goalAssessmentData?.missedLastMonthsPayment === true) return false;
    //   const isOnTime = isPaidOnTime({
    //     due: goalAssessmentData?.prevMonthDueDate,
    //     paid: goalAssessmentData?.prevMonthPayment,
    //   });
    //   return isOnTime;
    // };
    goalAssessmentData &&
      setAssessmentData({
        loanOnTime: {
          ...goalAssessmentData.parachuteLoan,
        },
        // lowUtilization: {
        //   ...goalAssessmentData.utilization,
        // },
        underUtilization: null,
        billsOnTime: null,
        noNewDebt: null,
      });
    // eslint-disable-next-line
  }, [goalAssessmentData]);

  //=============================================

  const closeAssessmentModal = () => {
    setshowAssessmentModal(false);
    setRefreshUserData(Math.random()); //[] change to increment
    window.location.reload();
  };
  const triggerReasonPatch = async (reason) => {
    /* Trigger useEffect to patch, will set reasonPatched when complete */
    setdataToUpload({ reason: reason });
  };

  const triggerAssessmentUpload = () => {
    setPaidParachuteOnTime(localStorage.getItem('paidParachuteOnTime'));

    // setdataToUpload({
    //   assessment: {
    //     user: userIDLink,
    //     assessmentMonth: prevMonthDate(cookies.getDate(), { startOfMonth: true }),
    //     underUtilization: goalsStatus.lowUtilization,
    //     paidBillsOnTime: goalsStatus.billsOnTime,
    //     noNewDebt: goalsStatus.noNewDebt,
    //     paidParachuteOnTime: goalsStatus?.loanOnTime ? goalsStatus?.loanOnTime : false
    //   },
    //   survey: surveyData,
    // });
    let assessmentMonth = localStorage.getItem('assessmentMonth');

    setdataToUpload({
      assessment: {
        user: userIDLink,
        assessmentMonth: (assessmentMonth) ? (assessmentMonth) : prevMonthDate(cookies.getDate(), { startOfMonth: true }),
        underUtilization: goalsStatus.lowUtilization,
        paidBillsOnTime: goalsStatus.billsOnTime,
        noNewDebt: goalsStatus.noNewDebt,
        paidParachuteOnTime: localStorage.getItem('paidParachuteOnTime') ? ((localStorage.getItem('paidParachuteOnTime') === true || localStorage.getItem('paidParachuteOnTime') === 'true') ? true : false) : false
      },
      survey: surveyData,
    });


  };

  useEffect(() => {
    //Handles uploading data to API
    const uploadDataToAPI = async (data) => {
      console.log(assessmentData)
      console.log(goalsStatus)
      // console.log(goalMet)
      console.log(data)
      
      if (!data) return;
      setLoading(true);
      setError(null);
      //API CALL
      try {
        if (data.survey) {
          const surveyResponse = {
            a0: data.survey?.q0?.answer,
            a1: data.survey?.q1?.answer,
            a2: data.survey?.q2?.answer,
            a3: data.survey?.q3?.answer,
            a4: data.survey?.q4?.answer,
            user: userIDLink,
            scoreDate: cookies.getDate(),
          };
          await api.saveWellnessSurvey(surveyResponse);
        }
        if (data.assessment) {
          const res = await api.postMonthlyAssessment(data.assessment);
          const aID = res.data._links?.self.href;
          setAssessmentID(parseIdFromLink(aID));
          incrementIndex();
        }

        if (data.reason) {
          await api.patchMonthlyAssessment(assessmentID, { reason: data.reason });
          setReasonPatched(true);
        }

        //Clear upload state
        setdataToUpload(null);
        resetErrorHandler();
      } catch (e) {

        console.log(e);
        console.log(e?.response?.data?.status);
        console.log(e?.response?.data?.error);
        console.log(e?.response?.data?.message);

        const errorMsg = `Error: ${e?.response?.data?.status} - ${e?.response?.data?.error} - ${e?.response?.data?.message}`;
        setError(`${errorMsg || e}`);
        setdataToUpload(null);
        setLoading(false);
      }
    };
    uploadDataToAPI(dataToUpload);
    // eslint-disable-next-line
  }, [dataToUpload]);
  //================================================

  const removeFocus = () => {
    document.activeElement.blur();
  };
  const handleAssessmentUserAnswer = (key, value) => {
    removeFocus();
    updateAssessment(key, value);
    incrementIndex();
  };

  const [index, setIndex] = useState(0); //Active Slide
  const incrementIndex = () => {
    setIndex((p) => p + 1);
  };
  const openTellMore = () => {
    setIndex(7);
  };
  const openSetAPlan = () => {
    setIndex(8);
  };
  const backToSummary = () => {
    setIndex(6);
  };
  const skipReason = () => {
    setSkip(true);
    setIndex(7);
  };
  const SLIDES = {
    0: {
      component: <Start incrementIndex={incrementIndex} prevMonth={prevMonth} />,
    },
    1: {
      component: (
        <WellnessSurvey incrementIndex={incrementIndex} setSurveyData={setSurveyData} surveyData={surveyData} />
      ),
    },
    2: {
      component: (
        <AssessmentQuestion
          question={{
            title: 'Did you pay all your bills on time?',
            body: `${`${firstTime ? 'Since receiving your Parachute loan' : `In the month of ${prevMonth}`
              }`}, did you pay all your bills on time according to their payment dates?`,
            icon: calendarIcon,
            onYes: function () {
              handleAssessmentUserAnswer('billsOnTime', true);
            },
            onNo: function () {
              handleAssessmentUserAnswer('billsOnTime', false);
            },
          }}
        />
      ),
    },
    3: {
      component: (
        <AssessmentQuestion
          question={{
            title: 'Have you avoided any new debt?',
            body: `${firstTime ? 'Since receiving your Parachute loan' : `In the month of ${prevMonth}`
              }, have you avoided accruing additional debt outside of your Parachute loan?`,
            icon: healthIcon,
            onYes: function () {
              handleAssessmentUserAnswer('noNewDebt', true);
            },
            onNo: function () {
              handleAssessmentUserAnswer('noNewDebt', false);
            },
          }}
        />
      ),
    },
    4: {
      component: (
        <AssessmentQuestion
          question={{
            title: 'Did you maintain credit utilization?',
            body: `${firstTime ? 'Since receiving your Parachute loan' : `In the month of ${prevMonth}`
              }, did you maintain credit utilization of 50% or less?`,
            icon: utilizationIcon,
            onYes: function () {
              handleAssessmentUserAnswer('underUtilization', true);
            },
            onNo: function () {
              handleAssessmentUserAnswer('underUtilization', false);
            },
          }}
        />
      ),
    },
    5: {
      //Assessment done, pre-showing results
      //Handle POST of results & passes back post ID incase there is a reason later
      component: <SeeResults uploadAssessment={triggerAssessmentUpload} status={status} />,
    },
    6: {
      //Assessment Results
      component: (
        <Results
          goalsStatus={goalsStatus}
          assessmentData={assessmentData}
          openTellMore={openTellMore}
          openSetAPlan={openSetAPlan}
          closeAssessmentModal={closeAssessmentModal}
          skipReason={skipReason}
          prevMonth={prevMonth}
          rewardAmount={goalAssessmentData?.rewardAmount}
          paidParachuteOnTime={localStorage.getItem('paidParachuteOnTime')}
        />
      ),
    },
    7: {
      //Tell More (Optional)
      //Uses PostID that See Results got, and will patch with reason
      component: (
        <TellMore
          submitReason={triggerReasonPatch}
          closeAssessment={closeAssessmentModal}
          backToSummary={backToSummary}
          skipReason={skip}
          reasonPatched={reasonPatched}
          status={status}
        />
      ),
    },
    8: {
        component: (
          <SetAPlan
          goalsStatus={goalsStatus}
          submitReason={triggerReasonPatch}
          closeAssessment={closeAssessmentModal}
          backToSummary={backToSummary}
          skipReason={skip}
          reasonPatched={reasonPatched}
          status={status}
        />
      ),
    },
  };
  return (
    <>
      <Modal
        id="goalsAssessment--modal"
        show={showAssessmentModal}
        animation={false}
        // onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CardContainer id="goalsAssessment--container">
          {/* Slide is determined by the index and the content of SLIDES */}
          {SLIDES[index]?.component ? SLIDES[index].component : 'No Slide Found'}
        </CardContainer>
      </Modal>
    </>
  );
}
