/* 
InteractiveCard,
Initially 2 modes, SurveryCard (questin & follow up), and InpsireCard (image and one question)
Both display community response when complete

====================Questions======================
When both answers are given, upload the answer and get the latest community results
[] Can Answers Change? > Assume no for now but follow up w. Gary
- [] Disable Hover animation after selection has been made > how?
[] How to indicate selected response
[] Community Response - Should it come with the question or after the response
- First maybe iffy (Chicken  vs Egg) > If no response just use their data
*/
import React, { useState, useEffect } from 'react';
import api from '../../utils/api';
import useAuth from '../../hooks/useAuth';
import CardContainer from '../CardContainer';
import './SurveyCard.scss';
import QuestionTile from '../QuestionTile';
import CommunityTile from './CommunityTile';
import parseISO from 'date-fns/parseISO';
import { findDaysFromToday } from '../../utils/helpers';
import ErrorNotification from '../ErrorNotification';

import cookies from '../../utils/cookies';
function SurveryCard({ questionData, inspire, mood, id, disabled, overrideUserLink }) {
  const showInspireError = inspire && !questionData?.main?.image;
  /* Mood is handled at community tile level */
  const auth = useAuth();
  const userIDLink = overrideUserLink || auth?.user?.link;
  const main = questionData?.main;
  const followUp = questionData?.followUp;

  const [mainAnswer, setMainAnswer] = useState(null);
  const [followUpAnswer, setFollowUpAnswer] = useState(null);
  const [surveyComplete, setSurveyComplete] = useState(false);
  const [communityResponse, setCommunityResponse] = useState(null);
  const [dataFromAPI, setDataFromAPI] = useState(false); //Remembers if answer is from API and stops duplicate api submissions
  const [daysToNextInspiration, setDaysToNextInspiration] = useState(null);

  const [dataToUpload, setDataToUpload] = useState(null); //Used to trigger upload

  useEffect(() => {
    //Init
    //[]DRY Code, b/c get functions are similar, can make 1 get with alternate call within
    const getMoodAnswer = async () => {
      if (!userIDLink) return;
      const resM = await api.getUsersMoodAnswerForToday(userIDLink); //Has the userAnswered Today's mood survey?
      const userMoodAns = resM.data._embedded.moodAnswers[0]; //Should only have 1 answer per day
      if (userMoodAns?.feeling) setDataFromAPI(true);
      setMainAnswer(userMoodAns?.feeling);
      setFollowUpAnswer(userMoodAns?.why);
      setSurveyComplete(userMoodAns?.feeling && userMoodAns?.why ? true : false);
    };
    const getInspireAnswer = async () => {
      if (!userIDLink || !main) return;
      // const dateRange = {
      //   startDate: new Date(main?.availableDate).toISOString(),
      //   endDate: cookies.getDate().toISOString(),
      // };
      const dateRange = cookies.getDateRange();
      const resI = await api.getUsersInspirationAnswerForToday(userIDLink, dateRange);

      const userInspireAnswer = resI.data._embedded.inspirationAnswers[resI.data._embedded.inspirationAnswers.length - 1]; //Should only have 1 answer per day

      const regex = /\/(\d+)\/inspiration/;
      const match = userInspireAnswer?._links?.inspiration?.href.match(regex);
      let inspireId = "";
      let userId = "";
      if (match && match[1]) {
        inspireId = match[1];
        // userId = userIDLink.split("/")[userIDLink.split("/").length - 1];


        // Extract user ID
        let parts = userIDLink.split("/");
        let userId = parts[parts.length - 1];

        // Check if the userId is a number
        if (!isNaN(userId)) {
          // Convert userId to a number
          userId = Number(userId);
          console.log(userId); // Output: 12345
        } else {
          console.error("Invalid user ID: Not a number");
        }

        console.log("ID:", inspireId); // Output: ID: 1069731
      } else {
        console.log("ID not found");
      }
      // if (userId && inspireId) {
      //   const res2 = await api.getInspireAnswerNewLogic(userId, inspireId);

      //   if (res2.data) setDataFromAPI(true);
      //   setMainAnswer(res2.data);
      //   setFollowUpAnswer(null);
      //   setSurveyComplete(res2.data);
      // }
    }


    userIDLink && inspire ? getInspireAnswer() : getMoodAnswer();
  }, [inspire, userIDLink, main]);

  useEffect(() => {
    if (!surveyComplete || dataFromAPI) return;

    if (inspire) {
      const inspirationIDLink = questionData?.main?.inspID;
      setDataToUpload({
        inspiration: inspirationIDLink,
        user: userIDLink,
        answer: mainAnswer,
      });
    } else {
      setDataToUpload({
        user: userIDLink,
        feeling: mainAnswer,
        why: followUpAnswer,
      });
    }
    // eslint-disable-next-line
  }, [surveyComplete]);

  const handleMainClick = (e) => {
    if (mainAnswer) return; //Ignore Click
    const userResponse = e.target.textContent || e.target.alt;
    if (!followUp) setSurveyComplete(true);
    setMainAnswer(userResponse);
  };

  const handleFollowUpClick = (e) => {
    if (followUpAnswer) return; //Ignore Click
    const userResponse = e.target.textContent;
    // setDataToUpload({    //   user: userIDLink,    //   feeling: mainAnswer,    //   why: userResponse,    // });
    setFollowUpAnswer(userResponse);
    setSurveyComplete(true);
  };

  useEffect(() => {
    //Triggers Upload of user data
    if (!dataToUpload) return; //Exit if not complete

    const postMood = async () => {
      const moodPostRes = await api.postMood(dataToUpload);
      return moodPostRes;
    };
    const postInspire = async () => {
      const postInspire = await api.postInspiration(dataToUpload);
      return postInspire;
    };
    const postSurvey = async () => {
      try {
        inspire ? await postInspire() : await postMood();
        setSurveyComplete(true);
        setDataToUpload(null);
      } catch (e) { }
    };
    postSurvey();
    // eslint-disable-next-line
  }, [dataToUpload]);

  useEffect(() => {
    /* Get Comminity Responses (for month) */
    let mounted = true;
    const getCommunityReponses = async () => {
      const res = inspire ? await api.getInspirationCommunityResponses() : await api.getMoodCommunityResponses();
      /* Or options are for the very 1st user edge case, where no answer exists on the server yet */
      const communityAnswers = inspire
        ? { feeling: res.data.answer || mainAnswer }
        : { feeling: res.data.feeling || mainAnswer, why: res.data.why || followUpAnswer };
      if (mounted) {
        setCommunityResponse(communityAnswers);
        console.log(res.data)
        if (inspire) setDaysToNextInspiration(findDaysFromToday(parseISO(res.data.next)));
      }
    };
    if (surveyComplete) getCommunityReponses();
    return () => (mounted = false);
    //eslint-disable-next-line
  }, [surveyComplete, inspire]);

  const showCommunityResponse = () => {
    if (!communityResponse) return false;
    if (!mainAnswer) return false;
    if (followUp) {
      if (!followUpAnswer) return false;
    }
    return true;
  };
  console.log(inspire)
  console.log(mainAnswer)
  console.log(daysToNextInspiration)
  const showNextInspireDate = inspire === true && mainAnswer && daysToNextInspiration >= 0 ? true : false;
  return (
    <CardContainer className="surveyCard divided" id={id ? id : null} style={disabled ? { opacity: 0.7 } : null}>
      <h2 data-testid="surveyTitle" className="titleLine">
        {inspire ? 'Inspire me' : 'Mood Tracker'}
      </h2>
      {!showInspireError && (
        <>
          <QuestionTile
            key="mainQuestion"
            question={main?.question}
            answerOptions={main?.options}
            onClick={handleMainClick}
            selected={mainAnswer}
            inspireImage={main?.image}
            mood={mood}
            disabled={disabled}
          />
          {followUp && mainAnswer && (
            <QuestionTile
              key="followUpQuestion"
              labelButtons
              question={followUp.question}
              answerOptions={followUp.options}
              onClick={handleFollowUpClick}
              selected={followUpAnswer}
              disabled={disabled}
            />
          )}
          {/* Question Tile w. small setting */}
          {showCommunityResponse() && <CommunityTile data={communityResponse} mood={mood} inspire={inspire} />}
          {/* <pre>{JSON.stringify(topics, null, 2)}</pre> */}
          {showNextInspireDate ? (
            <div className="nextDayCount">
              {daysToNextInspiration === 1 || daysToNextInspiration === 0
                ? 'Next inspiration is available in 1 day'
                : `Next inspiration is available in ${daysToNextInspiration} days`}
            </div>
          ) : (
            inspire && mainAnswer && <ErrorNotification noBorder>Next inspiration date unavailable.</ErrorNotification>
          )}
        </>
      )}
      {showInspireError && <ErrorNotification>Inspiration data unavailable.</ErrorNotification>}
    </CardContainer>
  );
}

export default SurveryCard;
