
import { LINKS } from '../utils/constants';
import './ApplyView.scss';
import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import api from "../utils/api";
import axios from "../utils/axios";
import CardContainer from "../components/CardContainer";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Dismiss_Icon_Blk from "../assets/icons/Dismiss_Icon_Blk.svg";
import { Browser } from '@capacitor/browser';
import useAuth from '../hooks/useAuth';
import { Capacitor } from '@capacitor/core';
import { useLocation } from 'react-router-dom';

import cookies from '../utils/cookies';
export default function MxConnectView() {
  const auth = useAuth();

  const insSelRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [linkComplete] = useState(false);
  const [connectWidgetUrl, setConnectWidgetUrl] = useState("");
  const [isNativePlatformOrNot, setIsNativePlatformOrNot] = useState(false);
  const [showMxModal, setShowMxModal] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let token = searchParams.get('token');
  useEffect(() => {
    // Extracting all query parameters
    // const queryParams = {};
    // for (const [key, value] of searchParams) {
    //   queryParams[key] = value;
    // }
    // token=queryParams?.token;
    // console.log(queryParams)
    // console.log("token",token);
    if (Capacitor.isNativePlatform()) {
      setIsNativePlatformOrNot(false);
    } else {
      setIsNativePlatformOrNot(false);
    }
    try {
      Browser.addListener('browserPageLoaded', (e) => {
        console.log('browserPageLoaded', e);
      });

      Browser.addListener('browserPageLoaded', onPostMessage, false);
  
      Browser.addListener('browserFinished', (info) => {
        console.log('browserFinished', info);
        setShowMxModal(false);
        window.location.reload();
      });

    } catch (error) {

    }
    window.onmessage = function (e) {
      console.log('onmessage', e);
      onPostMessage(e);
    };
    // Add the post message listener//not working in ios app
    window.addEventListener("message", onPostMessage, false);

    document.onmessage = function (e) {
      console.log('onmessage', e);
      onPostMessage(e);
    };
    // Add the post message listener//not working in ios app
    document.addEventListener("message", onPostMessage, false);


    return function cleanup() {
      // Make sure to remove the post message listener to avoid multiple messages
      window.removeEventListener("message", onPostMessage, false);
      try {
        Browser.removeAllListeners();
      } catch (error) {

      }
    };




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onPostMessage = (event) => {

    console.log("event from isn ----", event.data);
    if (event.data && event.data.mx === true) {
      onEvent(event.data);
    }
  };


  const openModal = () => {
    setShowMxModal(true);
  };

  const closeModal = () => {
    setShowMxModal(false);
    window.location.reload();
  };
  useEffect(() => {
  
    if (token) {
    
      cookies.setJWT(token);
      setTimeout(() => {
       loadMxWidget();
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openCapacitorSite = async () => {
    let options = {
      presentationStyle: 'popover',
      url: connectWidgetUrl,
      windowName: '_blank',
      toolbarColor: '#ffffff'
    }
    await Browser.open(options);


    // Add an event listener to the InAppBrowser for receiving messages
    Browser.on('message').subscribe((event) => {
      // Handle the received message from the InAppBrowser
      console.log('Received message from InAppBrowser:', event.data);

      // Perform actions based on the received data
      // Update the UI or trigger specific functions in your app
    });

  };
  const onEvent = (event) => {


    console.log("MX PostMessage: ", event);



    if (event.type === "mx/connect/selectedInstitution") {
      console.log("event from isn ----", event.metadata);
      if (!_.isEmpty(event?.metadata)) {
        insSelRef.current = {
          institutionCode: event?.metadata?.code,
          institutionGuid: event?.metadata?.guid,
        };
      }
    }
    if (event.type === "mx/connect/memberConnected") {

      console.log('-START-')
      console.log(JSON.stringify(event))
      console.log(JSON.stringify(event?.metadata))
      console.log('-END-')

      const params = {
        memberGuid: event?.metadata?.member_guid,
        ...insSelRef.current,
      };
      api.saveMXBankAccntApi(params);

      /*if freemium user only*/
      const params1 = {
        "memberGuid": event?.metadata?.member_guid,
        "userGuid": event?.metadata?.user_guid
      }
      const user_id = auth.user.id;
      setIsLoading(true);
      api.memberConnectionStatus(params1, user_id)
        .then(resp => resp?.data)
        .then(res => {
          setIsLoading(false);
          window.location.reload();
        }).catch(err => {

          setIsLoading(false);
          console.log(err);

        });

      /*if freemium user only*/
    } else if (event.type === "mx/connect/loaded") {
      setIsLoading(false);
    }
  };

  const loadMxWidget = async () => {
    try {
      setIsLoading(true);
      openModal();
      const endPoint = "/users/mxConnectWidget?device=APP";
      const res = await axios.post(endPoint);
      if (res?.data) {
        setConnectWidgetUrl(res?.data?.widgetUrl?.url);
        setIsLoading(false);
      }
    } catch (err) {
      closeModal();
      setIsLoading(false);
    }
  };

  return (
    <>

      <Modal
        show={showMxModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={closeModal}
      >
        <CardContainer id="flinksConnection">
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "25px",
              }}
            >
              <h3>Connect your account</h3>
              {!linkComplete && (
                <img
                  width={"30px"}
                  style={{
                    display: "flex",
                    marginTop: "-70px",
                    marginRight: "-50px",
                  }}
                  src={Dismiss_Icon_Blk}
                  alt="cancel"
                  onClick={closeModal}
                />
              )}
            </div>
            {connectWidgetUrl && (
              <>

                {isNativePlatformOrNot ? (

                  <button onClick={openCapacitorSite} className="loginBtn" variant="outline-secondary"
                    type="button">
                    Start MX Connection
                  </button>

                ) : (
                  <iframe
                    className="flinksIframe"
                    title="Connect Accounts"
                    width="380"
                    height="660"
                    src={connectWidgetUrl}
                  />
                )
                }
              </>
            )}
            {isLoading && (
              <>
                <p>Finalizing connection please wait...</p>
                <Spinner
                  className="flinksSpinner"
                  animation="border"
                  size="md"
                />
              </>
            )}
          </div>
        </CardContainer>
      </Modal>
    </>
  );
}
