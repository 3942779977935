/* 
Get all users
Get all quizzes for month
GET Each users quiz answers (lazyload?)
*/
import React, { useState, useEffect, Fragment } from 'react';
// import Table from 'react-bootstrap/Table';
import api from '../../../utils/api';
import { Timeline, Event } from "react-timeline-scribble";

export default function Index() {
  const [urlEmail, setUrlEmail] = useState('');

  const [loadingText, setLoadingText] = useState('Fetching timeline data...');
  const [timelineData, setTimelineData] = useState(null);
  useEffect(() => {
    const urlParams = new URL(window.location);
    setUrlEmail(urlParams?.searchParams.get('username'));
    
    const getTimeline = async () => {
      try {
        const rawTimeline = await api.getTimeline(urlEmail);
        setTimelineData(rawTimeline?.data);
      } catch (e) {
        setLoadingText("No record(s) to display!");
        console.error(e);
      }
    };
    if(urlEmail){
      getTimeline();
    }
  }, [urlEmail]);



  const dateFilter = (timestamp) => {
    var options = { weekday:"long", year:"numeric", month:"short", day:"numeric"};
    return new Date(timestamp.split("T")[0]+'Z').toLocaleDateString("en-US", options);
  }

  return (
    <div id="timeline">
      <div style={{ "height": "100%", "width": "800px" }}>
        <div>
          <h1>{`Timeline`}</h1>
        </div>

        <hr />

        {timelineData ? (<Fragment>
          {/* <h1>React Timeline Scribble</h1> */}
          <Timeline>

            {timelineData.map((object, i) => (

              (<Event interval={dateFilter(object?.timestamp)} title={object?.event} subtitle={"Action made by: " + object?.actionTakenBy}>
                {object?.description}
              </Event>)
            ))}



          </Timeline>
        </Fragment>

        ) : (<p>{loadingText}</p>)}

      </div>

    </div>
  );
}
