import React, { useState, useEffect, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import WideBtn from '../WideBtn';
import api from '../../utils/api';
import useAuth from '../../hooks/useAuth';
import { isValidPassword } from '../../utils/helpers';
import './Index.scss';

export default function ChangePasswordForm({ setRootMode }) {
  let auth = useAuth();
  const username = auth.user.name;
  const userID = auth.user.id;

  const initialErrorState = {
    currPass: null,
    newPass: null,
    cfmPass: null,
  };

  const [form, setForm] = useState({
    currPass: '',
    newPass: '',
    cfmPass: '',
  });
  const [errors, setErrors] = useState(initialErrorState);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const allFieldsPresent = Object.values(form).every((x) => x);

  const handleFormChange = (e) => {
    const newValue = e.target.value;
    const formKey = e.target.id;

    setForm((p) => {
      return {
        ...p,
        [formKey]: newValue,
      };
    });
  };

  const resetErrors = useCallback(() => {
    setErrors(initialErrorState);
    //eslint-disable-next-line
  }, []);

  const updateError = (key, value) => {
    setErrors((p) => {
      return { ...p, [key]: value };
    });
  };

  useEffect(() => {
    if (!allFieldsPresent) {
      resetErrors();
    }
  }, [resetErrors, allFieldsPresent]);

  const validate = (input) => {
    resetErrors();
    let isValid = true;
    if (input.currPass === input.newPass) {
      updateError('newPass', 'New Password must be different from your current password.');
      isValid = false;
    }
    if (input.newPass !== input.cfmPass) {
      updateError('newPass', 'New password must match confirm password');
      isValid = false;
    }
    Object.entries(input).forEach(([k, v]) => {
      /* Indv password checks */
      if (!isValidPassword(v)) {
        updateError(k, "Password doesn't meet requirements");
        isValid = false;
      }
    });
    return isValid;
  };

  const handleSubmit = async (e) => {
    setChangeSuccess(false);
    e.preventDefault();
    resetErrors();
    const currPass = e.target['0'].value;
    const newPass = e.target['1'].value;
    const cfmPass = e.target['2'].value;
    const validForm = validate({ currPass, newPass, cfmPass });

    const noErrors = Object.values(errors).every((x) => x === null);
    if (validForm && noErrors) {
      // Confirm curr password is correct
      try {
        /* API calls should be in useEffect, should set "Initiate upload..." */
        await api.authenticate(username, currPass).then(async () => {
          // Then Update with new password (patch)
          await api.updatePass(userID, newPass);
          //On success Show changed
          setChangeSuccess(true);
        });
      } catch (e) {
        console.log('Change password error:', e);
        /* Possible Errors is API failure (ie 400/ 500)*/
      }
    }
  };

  const backToSettings = <WideBtn onClick={setRootMode}>Back to settings</WideBtn>;
  return (
    <div id="settings-pwd-reset" className="pwdResetCard">
      <h3>Password Reset</h3>
      {!changeSuccess && (
        <p className="muted">
          Password must be 8-50 characters, 1 uppercase, 1 lowercase, 1 digit, 1 special character.
        </p>
      )}
      <hr className="divider" />
      {!changeSuccess && (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="currPass">
            <Form.Label>Current password</Form.Label>
            <Form.Control
              type="password"
              placeholder="password"
              required
              isInvalid={errors.currPass}
              value={form.currPass}
              onChange={handleFormChange}
            />
            <Form.Control.Feedback type="invalid">{errors.currPass}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="newPass">
            <Form.Label>New password</Form.Label>
            <Form.Control
              type="password"
              placeholder="password"
              required
              isInvalid={errors.newPass}
              value={form.newPass}
              onChange={handleFormChange}
            />
            <Form.Control.Feedback type="invalid">{errors.newPass}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="cfmPass">
            <Form.Label>Confirm new password</Form.Label>
            <Form.Control
              type="password"
              placeholder="password"
              required
              isInvalid={errors.cfmPass}
              value={form.cfmPass}
              onChange={handleFormChange}
            />
            <Form.Control.Feedback type="invalid">{errors.cfmPass}</Form.Control.Feedback>
          </Form.Group>

          <WideBtn
            type="submit"
            disabled={allFieldsPresent ? false : true}
            className={allFieldsPresent ? null : 'disabled'}
          >
            Submit
          </WideBtn>
        </Form>
      )}
      {changeSuccess && backToSettings}
    </div>
  );
}
