/* 
Get all users
Get all quizzes for month
GET Each users quiz answers (lazyload?)
*/
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
// import Table from 'react-bootstrap/Table';
import api from '../../../utils/api';
import $ from 'jquery';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useCallback } from 'react'
// import DonutChart from 'react-donut-chart';
import Table from 'react-bootstrap/Table';
// import ApplicantGraph from '../../GraphComponet/ApplicantGraph';
// import { format } from 'date-fns';

import cookies from '../../../utils/cookies';

export default function Index() {
  let cstart = cookies.getToday();
  cstart.set({date:cstart.date() - 14});
  let cstartDate = (cstart.month() + 1) + "/" + (cstart.date()) + "/" + cstart.year();
  const cend = cookies.getToday();
  let cendDate = (cend.month() + 1) + "/" + (cend.date()) + "/" + cend.year();
  // let totalLeadStatusCount = 0;
  // let totalUtmSourceCount = 0;
  const [isLoading, setIsLoading] = useState(false);
  const [utmSources, setUtmSources] = useState([]);
  // const [grandTotal, setGrandTotal] = useState(0);

  const [leadStatuses, setLeadStatuses] = useState([]);
  const [utmSource, setUtmSource] = useState(null);

  const [gridApi, setGridApi] = useState(null);
  const perPage = 10;

  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const weeklyAnalyticsCall = useCallback(async () => {
    try {
      let fromDate = new Date($('#fromToDate').val()?.split(' - ')[0]);
      fromDate.setUTCHours(0, 0, 0, 0);
      fromDate.setDate(fromDate.getDate() + 1).toLocaleString('en-US');

      let toDate = new Date($('#fromToDate').val()?.split(' - ')[1]);
      toDate.setUTCHours(23, 59, 59, 999);
      toDate.setDate(toDate.getDate() + 1).toLocaleString('en-US');

      let data = {
        "email": $('#email').val(),
        "firstName": $('#firstName').val(),
        "fromDate": isValidDate(fromDate) ? fromDate : "1991-02-15T00:00:00.000Z",
        "lastName": $('#lastName').val(),
        "leadStatus": "",
        "toDate": isValidDate(toDate) ? toDate : "2050-02-15T23:59:59.999Z",
        "utmSource": ($('#utmSource').val() && $('#utmSource').val() !== 'select') ? $('#utmSource').val() : '',
      }
      const weeklyAnalyticsRes = await api.fetchSummaryByLeadFilter(data);
      console.table(weeklyAnalyticsRes?.data);

      setWeeklyAnalytics(weeklyAnalyticsRes?.data ? weeklyAnalyticsRes?.data : {});

    } catch (e) {
      console.error(e);
    }
  }, []);
  useEffect(() => {
    const fetchAllUtmSources = async () => {
      try {
        const rawData = await api.fetchAllUtmSources();

        const utmSources = rawData.data.filter((x) => x);
        setUtmSources(utmSources);
      } catch (e) {
        console.error(e);
      }
    };
    const fetchLeadStatuses = async () => {
      try {
        const rawData = await api.fetchLeadStatuses();

        const leadStatuses = rawData.data.filter((x) => x);

        setLeadStatuses(leadStatuses);

        // setLeadStatuses(["BH to follow up","Attempted to contact","New","In progress","Unqualified","Referred Credit Counselling","Connected","Bad timing","Old Lead - No Phone #","Test Account","Meeting Booked","Move To Credit Team","Missed Appointment","Not interested","Unable to Contact","Text Discussion Ongoing","They will Follow Up","Parachute Internal Contact","Follow Up","Unassigned","Others","Knocked Out"]);
      } catch (e) {
        console.error(e);
      }
    };
    fetchAllUtmSources();
    fetchLeadStatuses();
    weeklyAnalyticsCall();
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          console.log("hi");
          console.log(params);
          console.log("hi");

          gridApi.showLoadingOverlay();
          // Use startRow and endRow for sending pagination to Backend
          // params.startRow : Start Page
          // params.endRow : End Page
          const page = params.endRow / perPage;
          let fromDate = new Date($('#fromToDate').val()?.split(' - ')[0]);
          fromDate.setUTCHours(0, 0, 0, 0);
          fromDate.setDate(fromDate.getDate() + 1).toLocaleString('en-US');

          let toDate = new Date($('#fromToDate').val()?.split(' - ')[1]);
          toDate.setUTCHours(23, 59, 59, 999);
          toDate.setDate(toDate.getDate() + 1).toLocaleString('en-US');
          let data = {
            "email": $('#email').val(),
            "firstName": $('#firstName').val(),
            "fromDate": isValidDate(fromDate) ? fromDate : "1991-02-15T00:00:00.000Z",
            "lastName": $('#lastName').val(),
            "leadStatus": "",
            "toDate": isValidDate(toDate) ? toDate : "2050-02-15T23:59:59.999Z",
            "utmSource": ($('#utmSource').val() && $('#utmSource').val() !== 'select') ? $('#utmSource').val() : '',
          }
          api.fetchByLeadFilter(perPage, page - 1, data, params?.sortModel[0]?.colId, params?.sortModel[0]?.sort)
            .then(resp => resp?.data)
            .then(res => {
              gridApi.hideOverlay();
              const initUsers = res.content.filter((x) => x.email);
              params.successCallback(initUsers, res.totalElements);
            }).catch(err => {
              gridApi.hideOverlay();
              console.log(err);
              params.successCallback([], 0);
            });
        }
      }

      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, weeklyAnalyticsCall]);


  // const avatarFormatter = ({ value }) => {
  //   return <img src={value} width="50px" height="50px" />
  // }
  const dateFormatter = ({ value }) => {
    if (!value) {
      return;
    }
    // let d = format(new Date(value), 'dd-MMM-yyyy');
    return value?.split('T')[0];
  }

  const statusFormatter = ({ value }) => {
    if (!value) {
      return 'N/A';
    }
    return value;
  }
  const refreshLeadStatus = async (applicantId, event) => {

    console.log(isLoading);
    let compId = parseInt($(event.target).parent().parent().attr('comp-id')) - 1;
    let oldValue = $("div[comp-id=" + compId + "]").children('div').html();

    $("div[comp-id=" + compId + "]").children('div').html('Refreshing...');
    try {
      const applicantDetails = await api.refreshLeadStatus(applicantId);
      if (applicantDetails?.status === 200) {
        if (applicantDetails?.data) {
          $("div[comp-id=" + compId + "]").children('div').html(applicantDetails.data);
        } else {
          $("div[comp-id=" + compId + "]").children('div').html(oldValue);
        }
      } else {
        $("div[comp-id=" + compId + "]").children('div').html(oldValue);
      }
    } catch (e) {
      $("div[comp-id=" + compId + "]").children('div').html(oldValue);
      console.error(e);
    }
  };
  const actionsFormatter = ({ value }) => {
    if (!value) {
      return;
    }
    return <>

      <Button
        variant="outline-primary"
        size="sm"
        // disabled={1}
        onClick={(e) => {
          refreshLeadStatus(value, e);
        }}
      >
        Refresh
      </Button>


    </>;
  }

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }
  const filterApplicants = async () => {
    try {
      weeklyAnalyticsCall();

    } catch (error) {

    }

    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params) => {

            gridApi.showLoadingOverlay();
            // Use startRow and endRow for sending pagination to Backend
            // params.startRow : Start Page
            // params.endRow : End Page
            const page = params.endRow / perPage;
            let fromDate = new Date($('#fromToDate').val()?.split(' - ')[0]);
            fromDate.setUTCHours(0, 0, 0, 0);
            fromDate.setDate(fromDate.getDate() + 1).toLocaleString('en-US');

            let toDate = new Date($('#fromToDate').val()?.split(' - ')[1]);
            toDate.setUTCHours(23, 59, 59, 999);
            toDate.setDate(toDate.getDate() + 1).toLocaleString('en-US');

            let data = {
              "email": $('#email').val(),
              "firstName": $('#firstName').val(),
              "fromDate": isValidDate(fromDate) ? fromDate : "1991-02-15T00:00:00.000Z",
              "lastName": $('#lastName').val(),
              "leadStatus": "",
              "toDate": isValidDate(toDate) ? toDate : "2050-02-15T23:59:59.999Z",
              "utmSource": ($('#utmSource').val() && $('#utmSource').val() !== 'select') ? $('#utmSource').val() : '',
            }
            console.table(data);
            api.fetchByLeadFilter(perPage, page - 1, data, params?.sortModel[0]?.colId, params?.sortModel[0]?.sort)
              .then(resp => resp?.data)
              .then(res => {
                gridApi.hideOverlay();
                const initUsers = res.content.filter((x) => x.email);
                params.successCallback(initUsers, res.totalElements);
              }).catch(err => {
                gridApi.hideOverlay();
                console.log(err);
                params.successCallback([], 0);
              });
          }
        }

        gridApi.setDatasource(dataSource);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const redirectToPage = () => {
    // window.location.href = '/admin/treeViewer'
    try {
      weeklyAnalyticsCall();

    } catch (error) {

    }

    try {
      if (gridApi) {
        const dataSource = {
          getRows: (params) => {

            gridApi.showLoadingOverlay();
            // Use startRow and endRow for sending pagination to Backend
            // params.startRow : Start Page
            // params.endRow : End Page
            const page = params.endRow / perPage;
            let fromDate = new Date($('#fromToDate').val()?.split(' - ')[0]);
            fromDate.setUTCHours(0, 0, 0, 0);
            fromDate.setDate(fromDate.getDate() + 1).toLocaleString('en-US');

            let toDate = new Date($('#fromToDate').val()?.split(' - ')[1]);
            toDate.setUTCHours(23, 59, 59, 999);
            toDate.setDate(toDate.getDate() + 1).toLocaleString('en-US');

            let data = {
              "email": $('#email').val(),
              "firstName": $('#firstName').val(),
              "fromDate": isValidDate(fromDate) ? fromDate : "1991-02-15T00:00:00.000Z",
              "lastName": $('#lastName').val(),
              "leadStatus": "",
              "toDate": isValidDate(toDate) ? toDate : "2050-02-15T23:59:59.999Z",
              "utmSource": ($('#utmSource').val() && $('#utmSource').val() !== 'select') ? $('#utmSource').val() : '',
            }
            console.table(data);
            api.fetchByLeadFilter(perPage, page - 1, data, params?.sortModel[0]?.colId, params?.sortModel[0]?.sort)
              .then(resp => resp?.data)
              .then(res => {
                gridApi.hideOverlay();
                const initUsers = res.content.filter((x) => x.email);
                
                params.successCallback(initUsers, res.totalElements);
              }).catch(err => {
                gridApi.hideOverlay();
                console.log(err);
                params.successCallback([], 0);
              });
          }
        }
        localStorage.setItem('treeChartData', JSON.stringify(weeklyAnalytics));
        window.open('/admin/treeViewer', '_blank', 'noopener,noreferrer');
        gridApi.setDatasource(dataSource);
     
      }
    } catch (err) {
      console.log(err);
    }

  }

  const downloadAllUsers = async () => {
    try {
      let fromDate = new Date($('#fromToDate').val()?.split(' - ')[0]);
      fromDate.setUTCHours(0, 0, 0, 0);
      fromDate.setDate(fromDate.getDate() + 1).toLocaleString('en-US');

      let toDate = new Date($('#fromToDate').val()?.split(' - ')[1]);
      toDate.setUTCHours(23, 59, 59, 999);
      toDate.setDate(toDate.getDate() + 1).toLocaleString('en-US');

      let data = {
        "email": $('#email').val(),
        "firstName": $('#firstName').val(),
        "fromDate": isValidDate(fromDate) ? fromDate : "1991-02-15T00:00:00.000Z",
        "lastName": $('#lastName').val(),
        "leadStatus": "",
        "toDate": isValidDate(toDate) ? toDate : "2050-02-15T23:59:59.999Z",
        "utmSource": ($('#utmSource').val() && $('#utmSource').val() !== 'select' && $('#utmSource').val() !== 'All') ? $('#utmSource').val() : '',
      }
      console.table(data);
      setIsLoading(true);
      const rawUsersRes = await api.exportCSVByFilter(data);
      if (rawUsersRes.status === 200) {
        setIsLoading(false);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = 'data:text/plain;base64,' + window.btoa(unescape(encodeURIComponent(rawUsersRes.data)));
        a.download = "export.csv";
        a.click();
      }
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  const clearForm = () => {
    // $('#mainForm').reset()
    document.getElementById("mainForm").reset();
  };

  const handleChange = (e) => {
    const key = e.target.id;
    let value = e.target.value;
    console.log(key);
    setUtmSource(value);
  };


  const [weeklyAnalytics, setWeeklyAnalytics] = useState(null);
  // const [dataTableList, setDataTableList] = useState(null);

  // const keys = ["createDateTime", "leadStatusCount", "utmSourceCount"];

  // useEffect(() => {

  //   weeklyAnalyticsCall();
  // }, [weeklyAnalyticsCall]);

  // function currencyFormat(num) {
  //   return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  // }

  // const getSundayFromWeekNum = (weekNum, year) => {
  //   const sunday = new Date(year, 0, (1 + (weekNum - 1) * 7));
  //   while (sunday.getDay() !== 1) {
  //     sunday.setDate(sunday.getDate() - 1);
  //   }
  //   var options = { month: 'short', day: 'numeric' };

  //   return sunday.toLocaleDateString("en-US", options);
  // }

  const getDate = (cDate) => {
    const fDate = new Date(cDate);

    var options = { month: 'short', day: 'numeric', year: 'numeric' };

    return fDate.toLocaleDateString("en-US", options);
  }

  return (
    <div id="applicanttable">
      <div>
        <h1>{`Applicant(s)`}</h1>
        <hr />

        <Accordion>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Filters & Downloads</Accordion.Header>
            <Accordion.Body>
              <Form id="mainForm">


                <Form.Group className="mb-3" >
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter first name" id="firstName" name="firstName" />
                </Form.Group>

                <Form.Group className="mb-3" >
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter last name" id="lastName" name="lastName" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" id="email" name="email" />
                </Form.Group>
                <DateRangePicker
                  initialSettings={{ startDate: cstartDate, endDate: cendDate }}

                >
                  <input type="text" className="form-control" placeholder="Choose from & to date" id="fromToDate" name="fromToDate" />
                </DateRangePicker>
                <Form.Group className="mb-3">
                  <Form.Group className="mb-3" controlId="utmSource" >
                    <Form.Label>UTM Source</Form.Label>

                    {/* <Form.Label>Choose UTM Source:</Form.Label> */}
                    <Form.Select
                      aria-label="UTM Source"
                      onChange={handleChange}
                      value={utmSource}
                      id="utmSource" name="utmSource"
                    >

                      <option>
                        All
                      </option>
                      {utmSources.map((i) => (
                        <option key={`utm-source-${i}`} value={i}>
                          {i}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Form.Group>
              </Form>



              <Button variant="primary" onClick={filterApplicants} className="mr-1  btn-space">Apply Filter</Button>
              &nbsp;&nbsp;
              <Button variant="dark" onClick={downloadAllUsers}>Download Filtered</Button>

              &nbsp;&nbsp;

              <Button variant="success" onClick={redirectToPage}>View Tree View</Button>

              &nbsp;&nbsp;
              <Button variant="warning" onClick={clearForm}>Clear</Button>

            </Accordion.Body>
          </Accordion.Item>

          {/* <Accordion.Item eventKey="1">
            <Accordion.Header>Summary</Accordion.Header>
            <Accordion.Body>
              

            </Accordion.Body>
          </Accordion.Item> */}



        </Accordion>
        <hr />
        {/* <pre>{JSON.stringify(dataTableList, null, 2)}</pre> */}


        <div >

          {



            weeklyAnalytics && Object.keys(weeklyAnalytics).length > 0 ? (




              <>
                <h2>Summary:</h2>
                {

                  Object.entries(weeklyAnalytics).map(([key, val]) => {
                    let total = 0;
                    return (
                      <div class="leadSummaryComponent">

                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th colSpan={30} ><span style={{ 'margin-left': '0%' }}>{getDate(key)}</span></th>
                            </tr>
                            <tr>
                              {/* for loop of utm sources list - j */}
                              <th>
                                {/* <span style={{ 'margin-left': '20%' }}>(### total applicants)</span> */}
                                <span style={{ 'margin-left': '0%', 'fontSize': '10px' }}>UTM SOURCE ↓</span>
                              </th>
                              {leadStatuses.map((j, n1) => (
                                <th style={{ 'fontSize': '10px' }}>
                                  {j.toUpperCase()}
                                </th>
                              ))}
                              <th style={{ 'margin-left': '0%', 'fontSize': '10px' }}>TOTAL</th>
                              {/* for loop of utm sources list */}
                            </tr>
                          </thead>
                          <tbody>
                            {/* <ApplicantGraph data={weeklyAnalytics}/> */}
                            {/* for loop of lead status list - k */}

                            {Object.entries(val).map(([key1, val1], index2) => {
                              total = total + (val1?.Total ? val1?.Total : 0);
                              return (<tr className={'utm-' + index2}>
                                <td class="headcol">
                                  {key1}
                                </td>
                                {/* val{val?.utmSource[key]}  */}
                                {
                                  leadStatuses.map((o, n2) => (
                                    <th >
                                      {/* {o}
<pre>{JSON.stringify(val1, null, 2)}</pre> */}

                                      {val1[o] ? val1[o] : 0}
                                    </th>
                                  ))
                                }
                                <td class="headcol"><strong>{val1?.Total}</strong></td>{/* sum of kn-jn++ */}
                              </tr>)
                            })}

                            {/* for loop of lead status list */}
                            <tr>
                              <th colSpan={30} ><span style={{ 'margin-left': '0%' }}>{total + ' total applicants'}</span></th>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    )
                  })
                }



              </>


            ) : (<p style={{ 'margin-left': '70px' }}>No record(s) to display!</p>)}

        </div>
        <hr />

      </div>
      {/* <hr /> */}
      {/* <div id="summaryComponent">
        <h2 style={{ 'margin-left': '70px' }}>Summary:</h2>

        {weeklyAnalytics?.length > 1 ? (<><Table responsive striped  >

          <tbody>
            {weeklyAnalytics &&
              Object.values(keys)
                .map((q, i) => {

                  return (
                    <tr idval={i}>
                      {
                        q &&
                        Object.values(weeklyAnalytics)
                          .map((q1, i1) => {
                            let rowVal = null;
                            if (i1 === 0) {
                              if (q === 'createDateTime') {
                                rowVal = (<td class="headcol"><strong>{'Date'}</strong></td>)
                              } else if (q === 'leadStatusCount') {
                                rowVal = (<td class="headcol"><strong>{'Lead Status Count'}</strong></td>)
                              } else if (q === 'utmSourceCount') {
                                rowVal = (<td class="headcol"><strong>{'UTM Source Count'}</strong></td>)
                              } else {
                                rowVal = (<td class="headcol">{q}</td>)
                              }

                            } else {

                              if (q === ' ') {
                                rowVal = (<td class="long"></td>)
                              } else if (q === 'createDateTime') {
                                rowVal = (<td class="long">{getDate(q1?.createDateTime)}</td>)
                              } else if (q === 'leadStatusCount') {
                                totalLeadStatusCount = totalLeadStatusCount + q1?.leadStatusCount;
                                rowVal = (<td class="long">{q1?.leadStatusCount}</td>)
                              } else if (q === 'utmSourceCount') {
                                totalUtmSourceCount = totalUtmSourceCount + q1?.utmSourceCount;
                                rowVal = (<td class="long">{q1?.utmSourceCount}</td>)
                              }
                            }
                            return rowVal;


                          })}
                    </tr>
                  );
                })}
          </tbody>
        </Table>
          <hr />
          <span style={{ 'margin-left': '35%' }}>
            <DonutChart

              height={230}
              width={350}
              data={[
                {
                  label: '#LeadStatus',
                  value: totalLeadStatusCount,
                },
                {
                  label: '#UTMSource',
                  value: totalUtmSourceCount
                },
              ]}
            />
          </span>


        </>


        ) : (<p style={{ 'margin-left': '70px' }}>No record(s) to display!</p>)}
        <hr />




      </div> */}

      <>

      <div id="applicanttable1">
            {/* <div className="App"> */}

              <div className='container d-flex flex-column flex-grow-1 py-4'>
                <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                  <div style={{ flexGrow: '1' }}>


                    <div className="ag-theme-alpine ag-style" style={{ height: '100%', width: '100%' }}>
            <AgGridReact
              pagination={true}
              rowModelType={'infinite'}
              paginationPageSize={perPage}
              cacheBlockSize={perPage}
              onGridReady={onGridReady}
              rowHeight={60}
              sortable={true}
              unSortIcon={true}
              defaultColDef={{ flex: 1, sortable: true, unSortIcon: true, sortingOrder: ['asc', 'desc'] }}
              overlayLoadingTemplate={
                '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
              }
              overlayNoRowsTemplate={
                '<span className="ag-overlay-loading-center">No data found to display.</span>'
              }
            >
              <AgGridColumn  field="firstName" headerName="First Name" cellClass="vertical-middle" />
              <AgGridColumn  field="lastName" headerName="Last Name" cellClass="vertical-middle" />
              <AgGridColumn  field="email" headerName="Email" cellClass="vertical-middle" />

              <AgGridColumn  field="utmSource" headerName="UTM Source" cellRendererFramework={statusFormatter} cellClass="vertical-middle" />

              <AgGridColumn  field="createDateTime" headerName="Created" cellRendererFramework={dateFormatter} cellClass="vertical-middle" />

              {/* <AgGridColumn  field="updateDateTime" headerName="Updated" cellRendererFramework={dateFormatter} cellClass="vertical-middle" /> */}
              <AgGridColumn  field="currentLeadStatus" headerName="Status" cellRendererFramework={statusFormatter} cellClass="vertical-middle" />

              <AgGridColumn  field="applicantId" headerName="Actions" cellRendererFramework={actionsFormatter} cellClass="vertical-middle" />
              {/* <AgGridColumn  cellRendererParams="applicantId" field="mxTransactionFlag" headerName="FREEMIUM" cellRendererFramework={actionsFormatter1} cellClass="vertical-middle" /> */}
              {/* <AgGridColumn  field="applicantId" headerName="FREEMIUM" cellRendererFramework={actionsFormatter2} cellClass="vertical-middle" /> */}

            </AgGridReact>


            {/* </div> */}
            </div>
            </div>
            </div>
          </div>
        </div>









      </>






    </div>
  );
}
