/* Results of monthly assessment, whether its met or not 
- Content will vary based on assessemnt results, some common elements between the 2 states
    -Titleblock
    -summary card
    -action
    -discalimer


*/
import React, { useState, useEffect } from 'react';
import ModalTitleBlock from '../ModalTitleBlock';
import Disclaimer from './Disclaimer';
import WideBtn from '../WideBtn';
import TextButton from '../TextButton';
import ResultSummary from './ResultSummary/Index';
import missedGoalIcon from '../../assets/icons/missedGoal.svg';
import metGoalIcon from '../../assets/icons/metGoal.svg';
import Form from 'react-bootstrap/Form';
import Tooltip from 'react-bootstrap/Tooltip';
// import Button from 'react-bootstrap/Button';

import infoIcon from '../../assets/icons/infoIcon.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import './Results.scss';
import { color } from '@amcharts/amcharts5';

export default function Results({
  assessmentData,
  openTellMore,
  openSetAPlan,
  closeAssessmentModal,
  skipReason,
  prevMonth,
  goalsStatus,
  rewardAmount,
  paidParachuteOnTime
}) {

  const handleChange = (e) => {
    const key = e.target.id;
    let value = e.target.value;
    const questionIndex = key.slice(1);
    console.log('change', key, questionIndex, value);
    if (value.length > 1000) {
      return;
    }

    // setFormData((p) => {
    //   p.freeFormQuestion = value;
    //   return { ...p };
    // });
  };

  const goal = {
    met: {
      title: (
        <ModalTitleBlock
          title={`Congrats! You’ve earned $${rewardAmount}!`}
          body={`Great job! You completed all the required goals for ${prevMonth}, and you have earned your monthly cash-back.`}
          icon={metGoalIcon}
        />
      ),
      actionComponent: (
        <>
          <WideBtn onClick={closeAssessmentModal}>Stay on track next month</WideBtn>



        </>
      ),
    },
    missed: {
      title: (
        <ModalTitleBlock
          title="Goal not met."
          body={`Unfortunately you did not meet the monthly goals for cash-back in ${prevMonth}. Set a plan for next month so you can achieve your goals and earn cash-back!`}
          icon={missedGoalIcon}
        />
      ),
      actionComponent: (
        <>
          {/* <p className="muted">
            Tell us why you couldn’t meet your monthly goals, and we’ll see what we can do to help you out.
          </p>
          <WideBtn className="tellMoreBtn" onClick={openTellMore}>
            Tell Us More
          </WideBtn>
          <TextButton onClick={skipReason}>Skip</TextButton> */}


         
          <WideBtn onClick={openSetAPlan}>Set a Plan</WideBtn>
          <div className="blueBubble toastbox-grey">
              <p>
                Setting a plan will help you reach your goal, create better financial habits and earn cash-back.
              </p>
            </div>



        </>
      ),
    },
  };

  const [goalStatus, setgoalStatus] = useState(null);
  const [goalMetCount, setGoalMetCount] = useState(0);
  useEffect(() => {
    //Evaluates Answers and changes status
console.log(goalsStatus)
    const isMet = Object.values(goalsStatus).every((x) => x === true || x === null);
    setgoalStatus(isMet ? 'met' : 'missed'); //met or missed
    setGoalMetCount(Object.values(goalsStatus).filter((x) => x === true).length);
    console.log(goalMetCount)
  }, [goalsStatus]);

  return (
    <div id="assessment-results">
      {goalStatus && goal[goalStatus].title}
      {
        <ResultSummary
          assessmentData={assessmentData}
          goalMet={goalStatus}
          prevMonthName={prevMonth}
          rewardAmount={rewardAmount}
          paidParachuteOnTime={paidParachuteOnTime}
        />
      }
      <div className="result-actions">{goalStatus && goal[goalStatus].actionComponent}</div>
      <Disclaimer />
    </div>
  );
}
