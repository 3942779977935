import React from 'react';
import Button from 'react-bootstrap/Button';
import TitleBlock from '../ModalTitleBlock';
import Disclaimer from './Disclaimer';
import './AssessmentQuestion.scss';

export default function AssessmentQuestion({ question }) {
  const { title, body, icon, onYes, onNo } = question;
  return (
    <div className="assessmentQuestion">
      <TitleBlock title={title} body={body} icon={icon} />
      <div className="assessmentButtons">
        <Button variant="outline-danger" onClick={onNo}>
          No
        </Button>
        <Button variant="success" onClick={onYes}>
          Yes
        </Button>
      </div>
      <Disclaimer />
    </div>
  );
}
