import React, { useState, useEffect } from 'react';
import CardContainer from '../CardContainer';
import WideBtn from '../WideBtn';
import icon from '../../assets/Logo_Icon.svg';
import { LINKS } from '../../utils/constants';
import TextButton from '../TextButton';
import Spinner from 'react-bootstrap/Spinner';

import { useLocation } from 'react-router-dom';
export default function ApplicationTerms({ navForm, updatePreQualState, submitToAPI, stateData, status }) {

  const location = useLocation();
  const searchParams1 = new URLSearchParams(location.search);
  const id = searchParams1.get("applicantKey");
  const [customerReferenceId] = useState(id);

  if (stateData === undefined) stateData = null;
  const [termsAccepted, setTermsAccempted] = useState(stateData);

  useEffect(() => {
    stateData && setTermsAccempted(stateData);
  }, [stateData]);
  console.log('termsAccepted', termsAccepted);

  // const handleChange = (e) => {
  //   let value = e.target.checked;

  //   setTermsAccempted(value);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    updatePreQualState('termsAccepted', termsAccepted);
    // submitToAPI();
  };
  console.log('termsAccepted', termsAccepted);
  return (
    <>
      <CardContainer>
        <div>
          <div className="titleBlock">
            {icon && <img className="titleBlock--icon" src={icon} alt={icon} />}
            <h3 className="titleBlock--title">
              {/* What the lawyers want you to know.<br /> */}
              Please check the box below to agree to our Terms & Conditions and Privacy Policy.<br /><br />
              <p class="font-weight-light"><i>
                {!customerReferenceId ? (<strong><h2 style={{color: "#039855"}}>
                  This will not impact your credit score</h2></strong>
                ) : (<strong><h2 style={{color: "#039855"}}>
                This will not impact your credit score</h2></strong>)}
              </i></p>
            </h3>
          </div>
          <hr />
          <div className="column">
            <a href={LINKS.terms} target="_blank" rel="noreferrer">
              Terms and Conditions
            </a>
            <a href={LINKS.privacy} target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </div>
          <div
            className="termsCheckBox"
            onClick={() => {
              setTermsAccempted((p) => !p);
            }}
          >
            <input
              className="acceptTerms"
              type="checkbox"
              name="acceptTerms"
              id="acceptTerms"
              // onChange={handleChange}
              checked={!!termsAccepted}
              readOnly={status.loading}
            />
            <p className="muted" style={{ width: '100%' }}>
              By submitting the application, I represent that I have read and agree to the Terms of Use. You acknowledge
              that the Terms of Use include the following consents and certifications:
            </p>
          </div>
          <ul id="termsList">
            <li className="termsItem">I certify that I am at least 18 years of age.</li>
            <li className="termsItem">
              I certify that the information I provided in this loan application is true and correct.
            </li>
            <li className="termsItem">
              I consent to receive communications via emails, text messages, phone calls or other means of electronic
              communication.
            </li>
           
            {!customerReferenceId ? (
                   <li className="termsItem">
                   I authorize Parachute to conduct a credit check on me through credit bureaus and third-party services to
                   verify my eligibility for the loan.
                 </li>
                ) : (``)}


          
            <li className="termsItem">
              I acknowledge and agree that Parachute may use the provided information to verify my eligibility for the
              loan, Parachute’s services, and promotions for as long as I receive Parachute’s services. We only use this
              information within the duration of the loan and to quantify you and other specific promotions in the
              future.
            </li>
            <li className="termsItem">
              I agree to the collection and use of my personal information in accordance with the{' '}
              <a href={LINKS.privacy} target="_blank" rel="noreferrer">
                Privacy Policy.
              </a>
            </li>
          </ul>

          <WideBtn onClick={handleSubmit} disabled={!termsAccepted || status.loading} id="submitAcceptTerms">
            {status.loading ? <Spinner animation="border" role="status" size="sm" /> : `Submit`}
          </WideBtn>
          {status.error && <p className="error">{status.error}</p>}
          <TextButton
            onClick={(e) => {
              e.preventDefault();
              navForm('back');
            }}
          >
            Back
          </TextButton>
        </div>
      </CardContainer>
    </>
  );
}
