// import { getYear } from 'date-fns/esm';
import { removeCommas } from '../../utils/helpers';
import cookies from '../../utils/cookies';
const formatDollar = (input) => {
  const rmvCommas = removeCommas(input);
  const strippedNumber = Number(rmvCommas);
  if (!isNaN(strippedNumber) && strippedNumber < 2147483647) {
    return strippedNumber === 0 ? '' : strippedNumber.toLocaleString();
  }
};

export const validateAndFormat = (form, key, value) => {
  // console.log('validating...');
  if (form === 'client') {
    switch (key) {
      case 'debtToConsolidate':
        return formatDollar(value);
      default:
        return value;
    }
  }

  if (form === 'income') {
    switch (key) {
      case 'primaryGrossIncome':
      case 'secondaryGrossIncome':
        return formatDollar(value);
      case 'primaryWorkPhone':
      case 'secondaryWorkPhone':
        if (!isNaN(value) && value.length <= 10) return value;
        break;
      case 'primaryHiredDay':
      case 'secondaryHiredDay':
      case 'dobDay':
        if (value.length > 2) return;

        if (value === '' || (!isNaN(value) && value >= 0 && value <= 31)) return value;

        break;
      case 'primaryHiredYear':
      case 'secondaryHiredYear':
        if (value === '' || (!isNaN(value) && value > 0 && value <= (cookies.getToday().year()) && value.length <= 4))
          return value;
        break;
      case 'dobYear':
        if (value === '' || (!isNaN(value) && value > 0 && value.length <= 4)) return value;
        break;
      default:
        return value;
    }
  }

  if (form === 'residence') {
    switch (key) {
      case 'primaryResidenceCost':
      case 'previousResidenceCost':
        return formatDollar(value);
      case 'primaryYearsAtResidence':
      case 'previousYearsAtResidence':
        if (!isNaN(value)) return value;
        break;
      case 'previousMonthsAtResidence':
      case 'primaryMonthsAtResidence':
        if (!isNaN(value) && value < 12) return value;
        break;
      case 'primaryPostalCode':
      case 'previousPostalCode':
        if (!value) return value;
        const postalLength = value.length;
        const isEven = postalLength % 2 === 0;
        const lastInput = value[postalLength - 1];
        if (isEven && isNaN(lastInput)) return;
        if (!isEven && !isNaN(lastInput)) return;
        return value
          .replace(/[^a-z0-9]/gi, '')
          .slice(0, 6)
          .toUpperCase();

      // case 'primaryAptNo':
      // case 'previousAptNo':

      default:
        return value;
    }
  }

  if (form === 'debt') {
    switch (key) {
      case 'amount':
      case 'monthlyPayments':
        return formatDollar(value);
      case 'interestRate':
        if (!isNaN(value) && value < 100) return value;
        break;
      default:
        return value;
    }
  }
};
