import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import api from '../../../../utils/api';
import { formatDateForDisplay, parseIdFromLink } from '../../../../utils/helpers';

export default function UsersUtilization({ userToView, index }) {
  const { link: userLink } = userToView;

  const [userData, setUserData] = useState(null);
  const [status, setStatus] = useState({ loading: false, error: null });

  useEffect(() => {
    const getUserActivity = async () => {
      const rawRes = await api.getUsersFinDataByRange(userLink, { all: true, size: 10 });
      /* Should show all fin data but this would be HUGE, as a record is created every day...
      API only limits by date ranges... */
      //Fin Data search allows pagination, should show latest 20 then add "Show more" / "Show All"
      const userData = rawRes.data._embedded.finDatas;
      // const totalPages = rawRes.data.page.totalPages;
      // const totalRecords = rawRes.data.page.totalElements;
      setUserData(userData);
      // setUserData([]);
    };
    getUserActivity();
    //eslint-disable-next-line
  }, []);

  const validateScore = (inputScore) => {
    if (!inputScore) return 'Must enter a value';
    if (isNaN(inputScore)) return 'Score must be a valid number above 0';
    if (inputScore < 0) return 'Number must be greater or equal to 0';
    return true;
  };
  const submitFinData = async (e) => {
    e.preventDefault();
    const totalCredit = e.target[0].value;
    const creditUtilized = e.target[1].value;
    //Upload wellness core
    try {
      setStatus({ loading: true, error: null });
      if (validateScore(totalCredit) !== true || validateScore(creditUtilized) !== true) {
        throw new Error(validateScore(totalCredit));
      }

      const res = await api.saveFinData({
        user: userLink,
        utilizedCredit: creditUtilized,
        totalCreditAvailable: totalCredit,
      });
      setUserData([res.data]);
      setStatus({ loading: false, error: null });
    } catch (e) {
      setStatus({ loading: false, error: `${e}` });
    }
  };
  return (
    <>
      <Accordion.Item eventKey={index}>
        <Accordion.Header>Credit Utilization Lastest Records - {userData?.length ?? '0'}</Accordion.Header>
        <Accordion.Body>
          {userData?.length > 0 && (
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Record ID</th>
                  <th>Created</th>
                  <th>Credit Utilized ($)</th>
                  <th>Total Credit ($)</th>
                  <th>Utilized (%)</th>
                  <th>Flags</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(userData)
                  // .sort((x, y) => y.pulledDate.localeCompare(x.pulledDate))
                  .map((q, i) => {
                    return (
                      <tr key={`creditUtil-${i}`}>
                        <td>{parseIdFromLink(q?._links.self.href)}</td>
                        <td>{formatDateForDisplay(q?.createDateTime)}</td>
                        <td style={{ textAlign: 'right' }}>${Number(q?.utilizedCredit)?.toFixed(2)}</td>
                        <td style={{ textAlign: 'right' }}>${q?.totalCreditAvailable?.toFixed(2)}</td>
                        <td style={{ textAlign: 'right' }}>{(q?.creditUtilization * 100)?.toFixed(2)}%</td>
                        <td>{q?.utilizationFailureReason}</td>
                      </tr>
                    );
                  })}
              </tbody>
              <caption>
                *Calculated Daily by server. Only the latest 10 are shown, more may exist. Feature to see all to follow.
                <br />
                Requires user to have a Credit limit set, and accounts linked.
              </caption>
            </Table>
          )}
          {userData?.length === 0 && (
            <Form onSubmit={submitFinData}>
              <Form.Text className="text-muted">No Utilization results for this user</Form.Text>

              <Form.Group className="mb-3" controlId="initialWellnessScore">
                <Form.Label>Total Credit ($) Available</Form.Label>
                <Form.Control disabled={status.loading} placeholder="Enter $" />
                <Form.Text className="text-muted">How much credit ($) is available to the user</Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="initialWellnessScore">
                <Form.Label>Credit Utilized ($)</Form.Label>
                <Form.Control disabled={status.loading} placeholder="Enter $" />
                <Form.Text className="text-muted">How much credit ($) is the user currently using</Form.Text>
              </Form.Group>
              <Button type="submit" disabled={status.loading}>
                {status.loading ? 'Saving...' : 'Add Credit Utilization'}
              </Button>
              {status.error && <p className="error">{status.error}</p>}
            </Form>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
}
