import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import EditUserLoanInfo from './EditUserLoanInfo';
import api from '../../../utils/api';
import { parseIdFromLink } from '../../../utils/helpers';

export default function Index() {
  const [emailToActivate, setEmailToActivate] = useState(''); //Search Form
  const [userToActivate, setUserToActivate] = useState(null); //API Trigger

  const [userToFind, setUserToFind] = useState(null); //API Trigger
  const [userDetails, setUserDetails] = useState(null); //User Loan info to edit

  const [status, setStatus] = useState({ loading: false, error: null });
  const [mode, setMode] = useState('ready'); //ready, edit, success

  const handleChange = (e) => {
    const value = e.target.value;
    setEmailToActivate(value);
  };

  const handleFindUser = (e) => {
    e.preventDefault();
    setUserToFind(emailToActivate);
  };

  useEffect(() => {
    const findUser = async () => {
      setStatus({ loading: true, error: null });
      try {
        await api.getUserByEmail(userToFind).then(async (res) => {
          const firstResult = res.data;
          if (!firstResult) {
            throw new Error('User not found');
          }
          const userLink = parseIdFromLink(firstResult?.url);
          const rawFullUserDetails = await api.getUserData(userLink);
          const fullUserDetails = rawFullUserDetails.data;
          setUserDetails(fullUserDetails);
          setUserToFind(null);
          setMode('edit');
          setStatus({ loading: false, error: null });
        });
      } catch (e) {
        setUserToFind(null);
        setUserDetails(null);
        setStatus({ loading: false, error: `${e.message || e}` });
        setMode('ready');
      }
    };
    userToFind && findUser();
  }, [userToFind]);
  useEffect(() => {
    /* Send User Activate Email */
    const activateUser = async () => {
      try {
        setStatus({ loading: true, error: null });
        await api.activateUser(userToActivate);
        setUserToActivate(null);
        setStatus({ loading: false, error: null });
        setMode('success');
      } catch (e) {
        console.log('e', e);
        const errorResponse = `${e?.response.data.status} - ${e?.response.data.error} - ${e?.response.data.message}`;
        console.log('errorResponse', errorResponse);
        setUserToActivate(null);
        setStatus({ loading: false, error: errorResponse || e });
      }
    };
    userToActivate && activateUser();
  }, [userToActivate]);

  const resetActivation = () => {
    setEmailToActivate('');
    setUserDetails(null);
    setStatus({ loading: false, error: null });
    setMode('ready');
  };
  return (
    <div>
      {mode === 'ready' && (
        <Form onSubmit={handleFindUser}>
          <Form.Group className="mb-3" controlId="emailToActivate">
            <Form.Label>User's Email</Form.Label>
            <Form.Control type="email" placeholder="Enter email" onChange={handleChange} disabled={status.loading} />
            <Form.Text className="text-muted">Users Parachute account must be setup first.</Form.Text>
            {status.error && <p className="error">{status.error}</p>}
          </Form.Group>
          <Button variant="primary" type="submit" disabled={!emailToActivate}>
            {status.loading ? 'Searching...' : 'Find User'}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setMode('newUser');
              setStatus((p) => {
                return { ...p, error: null };
              });
            }}
          >
            New User
          </Button>
        </Form>
      )}
      {mode === 'edit' && (
        <EditUserLoanInfo userDetails={userDetails} setUserToActivate={setUserToActivate} parentError={status.error} />
      )}
      {mode === 'newUser' && (
        <EditUserLoanInfo
          newUser
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          setUserToActivate={setUserToActivate}
          setMode={setMode}
          parentError={status.error}
          setEmailToActivate={setEmailToActivate}
        />
      )}
      {mode === 'success' && (
        <div>
          {`Success: Email will will be sent to ${emailToActivate}`}
          <br />
          <Button onClick={resetActivation}>Activate another</Button>
        </div>
      )}
    </div>
  );
}
