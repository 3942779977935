import React from 'react';
import CardContainer from '../CardContainer';
import CardModal from '../CardModal';
import Notification from '../Notification/Index';
import LoanInfo from '../LoanInfo';
import payIcon from '../../assets/icons/payIcon.svg';
import ErrorNotification from '../ErrorNotification';
import { formatToDollar, objectHasMissingData } from '../../utils/helpers';
// formatDateForDisplay
export default function TileLoan({ PAYMENT }) {
  const requiredFields = ['nextPaymentAmount', 'nextDueDate'];
  const showError = !PAYMENT || objectHasMissingData(PAYMENT, requiredFields);
  const formattedNextDueDate = PAYMENT.nextDueDate;// formatDateForDisplay(PAYMENT.nextDueDate)

  const notificationColor = (daysToPayment, paid) => {
    if (paid) return 'success'; //Needs to check 1st, as days to payment maybe NaN
    if (isNaN(daysToPayment)) return 'warning';
    /*
    Assigns classname for notifications 
    Based on days left to payment 
    overdue = red, paid = green, 0-5days = yellow, +5days = grey
    */
    if (daysToPayment >= 0 && daysToPayment <= 5) return 'warning';
    if (daysToPayment < 0) return 'error';
    return 'info';
  };

  return (
    <CardContainer className="goalTile">
      <div className="tileContent">
        <img className="tileIcon" src={payIcon} alt="Coin Icon" />
        <div className="tileInfo">
          <h3>Pay Parachute loan on time.</h3>
          {PAYMENT.dueInXDays !== null && PAYMENT.dueInXDays !== undefined && !showError && (
            <Notification
              data={{
                type: `${notificationColor(PAYMENT.dueInXDays, PAYMENT.isCurrMonthPaid)}`,
                body: PAYMENT.thisDueMsg() || 'N/A',
              }}
            />
          )}

          <CardModal id="monthly-loanDetails">
            <img className="tileIcon" src={payIcon} alt="Coin Icon" />
            <h3>Pay Parachute loan on time.</h3>
            <p className="muted">
              Paying your debt on time is one of the biggest contributing factors to improving your credit score. If you
              don’t think you’ll be able to pay us on time, please{' '}
              <a className="sptLink" href={'/client/support'} >
                get in touch
              </a>{' '}
              and let us know.
            </p>
            {!showError && (
              <div className="divided-Info">
                <LoanInfo title={'Next Payment Date'} body={formattedNextDueDate ? `${formattedNextDueDate}` : 'N/A'} />
                <LoanInfo
                  title={'Next Payment Amount'}
                  body={PAYMENT.nextPaymentAmount ? `$${formatToDollar(PAYMENT.nextPaymentAmount)}` : 'N/A'}
                />
              </div>
            )}
            {showError && (
              <>
                <hr
                  style={{
                    marginTop: '24px',
                    marginBottom: '24px',
                    width: '100%',
                    background: 'rgba(0, 0, 0, 0.12)',
                    opacity: '100%',
                  }}
                />
                <ErrorNotification>We cannot determine loan details.</ErrorNotification>
              </>
            )}
          </CardModal>
        </div>
      </div>
      {showError && <ErrorNotification>We cannot determine loan details.</ErrorNotification>}
    </CardContainer>
  );
}
