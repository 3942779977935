/* Simple layout, intent is to link quick calls to reset aspects of the app for faster manual testing */

import React from 'react';
import useAuth from '../hooks/useAuth';
import { Link } from 'react-router-dom';

import api from '../utils/api';

export default function DebugView() {
  const auth = useAuth();
  const UID = auth?.user?.id;
  //   const userLink = auth?.users?.link

  return (
    <div id="debuggingView">
      <h1>Dev Debugging</h1>
      <hr />
      <li>
        <Link to="/client"> Back to Client view</Link>
      </li>
      <br />
      <ul>
        {/* <li>Reset Users Quiz Answers</li> */}
        <li
          onClick={async () => {
            await api.makeAxiosCall({
              method: 'patch',
              url: `/users/${UID}`,
              data: {
                completeOnBoard: null,
              },
            });
          }}
        >
          Reset users onboarding flag{' '}
        </li>
      </ul>
    </div>
  );
}
