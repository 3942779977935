import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import api from '../../../../utils/api';
import { formatDateForDisplay, parseIdFromLink } from '../../../../utils/helpers';
import { FLINKS_INSITUTION_IDS } from '../../../../utils/constants/FLINKS_INST_IDS';

export default function UserFlinks({ userToView, index }) {
  const { id } = userToView;

  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const getUserFlinksInfo = async () => {
      const accountsByInstituteAndAccount = {};
      const rawFlinksLogins = await api.getUsersFlinksLoginData(id);
      const flinksLogins = rawFlinksLogins.data._embedded.flinksLogins;
      const accountDataToFetch = [];
      flinksLogins.forEach(async (institute) => {
        //Update Inst Name
        let instName = institute.institution || 'Pending Account(s)';
        if (!isNaN(instName)) {
          /* Some flinks insitutions use a number, as the inst name.  Following searches a constant table for the appropriate name and reassigns the name */
          const instDetails = FLINKS_INSITUTION_IDS.find((x) => x.Flinks_Insitution_ID === Number(instName));
          if (instDetails) instName = instDetails.Institution_Name;
        }
        const instituteExist = !!accountsByInstituteAndAccount[institute];
        if (!instituteExist) {
          accountsByInstituteAndAccount[instName] = {};
        }
        const accountLink = institute._links.self.href;
        accountDataToFetch.push(accountLink);
      });

      //Fetch account Info
      const allAccountInfo = await Promise.all(accountDataToFetch.map((x) => api.axiosGet(x)));
      allAccountInfo.forEach((institute) => {
        const instData = institute.data;
        const instAccounts = instData._embedded.flinksAccounts; //array of accounts
        let instName = instData.institution || 'Pending Account(s)';
        if (!isNaN(instName)) {
          /* Some flinks insitutions use a number, as the inst name.  Following searches a constant table for the appropriate name and reassigns the name */
          const instDetails = FLINKS_INSITUTION_IDS.find((x) => x.Flinks_Insitution_ID === Number(instName));
          if (instDetails) instName = instDetails.Institution_Name;
        }
        const accountLink = instData._links.self.href;
        //Parse Account Info
        instAccounts?.forEach((acctDetails) => {
          const acctNo = acctDetails.accountNumber;
          accountsByInstituteAndAccount[instName][acctNo] = acctDetails;
          accountsByInstituteAndAccount[instName][acctNo]['flinksLoginID'] = parseIdFromLink(accountLink);
        });
      });

      setUserData(accountsByInstituteAndAccount);
    };
    getUserFlinksInfo();
    //eslint-disable-next-line
  }, []);
  const parsedAccounts =
    userData &&
    Object.entries(userData)
      .sort()
      .map(([inst, acctInfo]) => {
        return (
          <Accordion key={`flinkInfo-${inst}`}>
            <Accordion.Header>{`${inst} - As of ${formatDateForDisplay(
              Object.values(acctInfo)?.[0]?.createDateTime
            )}`}</Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Account #</th>
                    <th>Acct Title</th>
                    <th>Type</th>
                    <th>Balance</th>
                    <th>Currency</th>
                    {/* <th>Record Created</th> */}
                  </tr>
                </thead>
                <tbody>
                  {acctInfo &&
                    Object.values(acctInfo)
                      .sort((x, y) => x.accountNumber.localeCompare(y.accountNumber))
                      .map((a, i) => {
                        return (
                          <tr key={`account-${inst}-${i}`}>
                            <td>{a?.accountNumber}</td>
                            <td>{a?.title}</td>
                            <td>{a?.type}</td>
                            <td style={{ textAlign: 'right' }}>{a?.balance?.toFixed(2)}</td>
                            <td>{a?.currency}</td>
                            {/* <td>{formatDateForDisplay(a?.createDateTime)}</td> */}
                          </tr>
                        );
                      })}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion>
        );
      });
  return (
    <>
      <Accordion.Item eventKey={index}>
        <Accordion.Header>{`Account Info (Flinks) - ${parsedAccounts?.length}`}</Accordion.Header>
        <Accordion.Body>{parsedAccounts}</Accordion.Body>
      </Accordion.Item>
    </>
  );
}
