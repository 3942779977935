import React from 'react';
import './Index.scss';

function NotificationBubble({ data, disabled }) {
    if (!data) return;
    const { type, body, dismissAction } = data;
    const DismissIcon = (props) => {
    /* Custom SVG that takes in CSS for stroke color */
    return (
      <span className={props.className} onClick={props.onClick}>
        <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10 6.00016L6.00004 10.0002M6.00004 6.00016L10 10.0002M14.6667 8.00016C14.6667 11.6821 11.6819 14.6668 8.00004 14.6668C4.31814 14.6668 1.33337 11.6821 1.33337 8.00016C1.33337 4.31826 4.31814 1.3335 8.00004 1.3335C11.6819 1.3335 14.6667 4.31826 14.6667 8.00016Z"
            stroke={props.stroke}
            strokeWidth="1.3333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    );
  };

  const classes = {
    creditUp: 'success',
    creditDown: 'error',
    pay: 'info',
    quiz: 'success',
    //below act as override
    success: 'success',
    error: 'error',
    info: 'info',
    warning: 'warning',
  };
  const classToUse = ['notifyContainer'];
  classToUse.push(classes[type]);
  return (
    // <div className={classToUse.join(' ')}>
    //   <p className="xSmall">{body}</p>
    //   {dismissAction && <DismissIcon className="notifyBtn" onClick={() => !disabled && dismissAction(data)} />}
    // </div>
    <></>
  );
}
export default NotificationBubble;
