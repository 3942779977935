/* 
Get all users
Get all quizzes for month
GET Each users quiz answers (lazyload?)
*/
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
// import Table from 'react-bootstrap/Table';
import api from '../../../utils/api';

import { AgGridColumn, AgGridReact } from 'ag-grid-react';


export default function Index() {


  const [gridApi, setGridApi] = useState(null);
  const perPage =10;

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          // Use startRow and endRow for sending pagination to Backend
          // params.startRow : Start Page
          // params.endRow : End Page
          const page = params.endRow / perPage;
          api.getAllUsers(perPage, page - 1)
            .then(resp => resp?.data)
            .then(res => {
              const initUsers = res._embedded.users.filter((x) => x.role.name === 'ROLE_USER');
              params.successCallback(initUsers, res.page.totalElements);
            }).catch(err => {
              console.log(err);
              params.successCallback([], 0);
            });
        }
      }

      gridApi.setDatasource(dataSource);
    }
  }, [gridApi]);


  // const avatarFormatter = ({ value }) => {
  //   return <img src={value} width="50px" height="50px" />
  // }
  const dateFormatter = ({ value }) => {
    if (!value) {
      return;
    }
    let d = new Date(value).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" });
    return d;
  }


  const downloadAllUsers = async () => {
    try {
      const rawUsersRes = await api.exportCSVByFilter();
   if(rawUsersRes.status===200){
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href = 'data:text/plain;base64,'+window.btoa(unescape(encodeURIComponent(rawUsersRes.data)));
    a.download = "export.csv";
    a.click();
   }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div id="ciftable">
      <div>
        <h1>{`CIF`}</h1>

        <Button variant="primary" onClick={downloadAllUsers}>Download All Users</Button>
      </div>
      <hr />

      <>


        <div className="App">
          <div className="ag-theme-alpine ag-style">
            <AgGridReact
              pagination={true}
              rowModelType={'infinite'}
              paginationPageSize={perPage}
              cacheBlockSize={perPage}
              onGridReady={onGridReady}
              rowHeight={60}
              defaultColDef={{ flex: 1 }}
            >
              <AgGridColumn  field="firstName" headerName="First Name" cellClass="vertical-middle" />
              <AgGridColumn  field="lastName" headerName="Last Name" cellClass="vertical-middle" />
              <AgGridColumn  field="username" headerName="Username" cellClass="vertical-middle" />
              <AgGridColumn  field="createDateTime" headerName="Created" cellRendererFramework={dateFormatter} cellClass="vertical-middle" />
              <AgGridColumn  field="createDateTime" headerName="Created" cellRendererFramework={dateFormatter} cellClass="vertical-middle" />
            
            </AgGridReact>
          </div>
        </div>









      </>






    </div>
  );
}
