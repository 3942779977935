/* 
Get all users
Get all quizzes for month
GET Each users quiz answers (lazyload?)
*/
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
// import Table from 'react-bootstrap/Table';
import api from '../../../utils/api';
import $ from 'jquery';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useCallback } from 'react'
// import DonutChart from 'react-donut-chart';
import Table from 'react-bootstrap/Table';
// import ApplicantGraph from '../../GraphComponet/ApplicantGraph';
// import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import { useParams } from 'react-router-dom';
import cookies from '../../../utils/cookies';

export default function Index() {
  const { id } = useParams()
  let cstart = cookies.getToday();
  cstart.set({ date: cstart.date() - 14 });
  let cstartDate = (cstart.month() + 1) + "/" + (cstart.date()) + "/" + cstart.year();
  const cend = cookies.getToday();
  let cendDate = (cend.month() + 1) + "/" + (cend.date()) + "/" + cend.year();
  // let totalLeadStatusCount = 0;
  // let totalUtmSourceCount = 0;
  const [isLoading, setIsLoading] = useState(false);
  const [utmSources, setUtmSources] = useState([]);
  // const [grandTotal, setGrandTotal] = useState(0);

  const [leadStatuses, setLeadStatuses] = useState([]);
  const [utmSource, setUtmSource] = useState(null);

  const [gridApi, setGridApi] = useState(null);
  const perPage = 10;


  const reportCall = useCallback(async (date) => {
    try {

      console.log(date);
      // "userId": 14679624 | id,
      // "date": 202403 | monthyear,
      let data = {
        "userId": id,
        "date": date,

      }
      const reportRes = await api.fetchReportTrade(data);
      // console.table(reportRes?.data?.trade);

      setReport(reportRes?.data?.trade ? reportRes?.data?.trade : {});

    } catch (e) {
      console.error(e);
    }
  }, []);
  useEffect(() => {
    // Get the current date
    const currentDate = new Date();

    // Get the current year and month in the format YYYY-MM (required by the month input type)
    const currentYearMonth = currentDate.getFullYear() + ('0' + (currentDate.getMonth() + 0)).slice(-2);


    reportCall(currentYearMonth);

  }, [reportCall]);







  const clearForm = () => {
    // $('#mainForm').reset()
    document.getElementById("mainForm").reset();
  };



  const [report, setReport] = useState(null);

  const getDate = (cDate) => {
    const fDate = new Date(cDate);

    var options = { month: 'short', day: 'numeric', year: 'numeric' };

    return fDate.toLocaleDateString("en-US", options);
  }
  const [monthyear, setMonthYear] = useState('');
  // Get the current date
  const currentDate = new Date();
  // Calculate the maximum allowable month
  const maxMonth = currentDate.getMonth();
  const maxYear = currentDate.getFullYear();
  // If the current month is January, subtract 1 year
  const maxYearAdjusted = maxMonth === 0 ? maxYear - 1 : maxYear;
  // Set the max date to the last month in YYYY-MM format
  const maxDate = `${maxYearAdjusted}-${(maxMonth < 10 ? '0' : '') + maxMonth}`;

  //  const [maxDate, setMaxDate] = useState(maxDate);
  // Handler function to update the birthdayMonth state
  const handleMonthYearChange = (event) => {
    console.log(event.target.value);
    let date = event.target.value.split('-');
    console.log(date[0] + "" + date[1]);
    reportCall(date[0] + "" + date[1]);
  };

  // Function to convert data to xlsx format
  const downloadExcel = () => {

    let data = report;
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'trade_data.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Function to convert data to CSV format
  const convertToCSV = () => {
    const header = Object.keys(report[0]).join(',');
    const rows = report.map(obj => Object.values(obj).join(',')).join('\n');
    return `${header}\n${rows}`;
  };

  // Function to trigger download
  const downloadCSV = () => {
    const csvContent = convertToCSV();
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'trade_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div id="applicanttable">
      <div>
        <h1>{`Monthly Trades`}</h1>
        <hr />

        <Accordion>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Choose the month</Accordion.Header>
            <Accordion.Body>
              <Form id="mainForm">

                <input max={maxDate} style={{ width: '150px' }} type="month" className="form-control" placeholder="Choose month & year" id="monthyear" name="monthyear"


                  onChange={handleMonthYearChange}
                />


                {/* <DateRangePicker
                  initialSettings={{ startDate: cstartDate, endDate: cendDate }}

                >
                  <input type="text" className="form-control" placeholder="Choose from & to date" id="fromToDate" name="fromToDate" />
                </DateRangePicker> */}

              </Form>


              <br></br>
              {/* <Button variant="primary" onClick={handleMonthYearChange} className="mr-1  btn-space">Apply</Button>
              &nbsp;&nbsp; */}

              <Button variant="warning" onClick={clearForm}>Clear</Button>

            </Accordion.Body>
          </Accordion.Item>

          {/* <Accordion.Item eventKey="1">
            <Accordion.Header>Summary</Accordion.Header>
            <Accordion.Body>
              

            </Accordion.Body>
          </Accordion.Item> */}



        </Accordion>
        <br></br>
        <Button variant="outline-primary" onClick={downloadCSV}>Download CSV</Button>
        &nbsp; &nbsp;
        <Button variant="secondary" onClick={downloadExcel}>Download xlsx</Button>
        <br></br>
        <hr />
        {/* <pre>{JSON.stringify(dataTableList, null, 2)}</pre> */}


        <div >

          {



            report && Object.keys(report).length > 0 ? (




              <>
                <div class="leadSummaryComponent">

                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>member Code</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>member Name</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>phone</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>type</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>account</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>joint</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>date Opened</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>date Revised</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>date Last Activity</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>mop</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>balance</span></th>



                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>high Credit</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>credit Limit</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>frequency</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>payment</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>narrative1</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>narrative2</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>past Due</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>payment Pattern Start Date</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>payment Pattern</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>months Reviewed</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>plus 30</span></th>



                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>plus 60</span></th>
                        <th colSpan={30} ><span style={{ 'margin-left': '0%', 'text-transform': 'uppercase' }}>plus 90</span></th>



                      </tr>

                    </thead>
                    <tbody>
                      {/* <h2>Trade Report  (Monthly):</h2> */}
                      {

                        Object.entries(report).map(([key, val]) => {

                          return (


                            <tr>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.memberCode}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.memberName}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.phone}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.type}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.account}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.joint}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.dateOpened}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.dateRevised}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.dateLastActivity}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.mop}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.balance}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.highCredit}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.creditLimit}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.frequency}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.payment}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.narrative1}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.narrative2}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.pastDue}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.paymentPatternStartDate}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.paymentPattern}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.monthsReviewed}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.plus30}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.plus60}</span></td>
                              <td colSpan={30} ><span style={{ 'margin-left': '0%' }}>{val.plus90}</span></td>

                            </tr>

                          )
                        })
                      }


                    </tbody>
                  </Table>
                </div>
              </>


            ) : (<p style={{ 'margin-left': '70px' }}>No record(s) to display!</p>)}

        </div>
        <hr />

      </div>







    </div>
  );
}
