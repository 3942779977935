const entity = {
  role: '', //REQUIRED
  entity_name: '',
  dba: '',
  entity_type: '',
  entity_number: '',
  naics_code: '',
  established: '',
  addresses: [],
  links: [],
  emails: [],
  phones: [],
};

const defaultParaEntity = {
  formID: 'p001',
  role: 'merchant-partner', //REQUIRED
  entity_name: 'Parachute Cash',
  dba: 'Parachute',
  entity_type: 'corporation',
  entity_number: '1234569',
  // naics_code: '',
  // established: '',
  addresses: [
    {
      address: '123 Street, ON, L3L 4L3',
      type: 'office',
    },
  ],
  links: [{ url: 'https://develop.app.myparachute.co/client', type: 'website' }],
  emails: [
    {
      email: 'support@app.myparachute.co',
      type: 'support',
    },
  ],
  phones: [
    {
      phone: '123-456-7894',
      type: 'office',
    },
  ],
};

const individual = {
  role: '', //REQUIRED
  legal_name: '',
  dob: '', //DATE String YYYY-MM-DD
  addresses: [],
  links: [],
  emails: [],
  phones: [],
  sin: '',
  drivers_license: {
    //Optional
    number: '', //Required is object exists
    expired: '', //DATE String YYYY-MM-DD
    province: '',
    country: '',
  },
  omvic_registration_certificate: {
    //Optional
    registration_number: '', //Required is object exists
    name: '',
    expired: '', //DATE String YYYY-MM-DD
    dealer_registration_number: '',
    dealer_name: '',
  },
  passport: {
    //Optional
    number: '', //Required if object exists
    issued: '', //DATE String YYYY-MM-DD
    expired: '', //DATE String YYYY-MM-DD
    country: '',
  },
};

const exports = { individual, entity, defaultParaEntity };
export default exports;
