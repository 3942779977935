import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import api from '../../../../utils/api';
import { formatDateForDisplay, parseIdFromLink } from '../../../../utils/helpers';
import FICO_REASONS from '../../../../utils/constants/FICO_REASONS';

export default function UsersFICOScores({ userToView, index }) {
  const { id: userID, link: userLink } = userToView;

  const [userData, setUserData] = useState([]);
  const [status, setStatus] = useState({ loading: false, error: null });

  const [recordToDelete, setRecordToDelete] = useState(null);

  useEffect(() => {
    const getUserActivity = async () => {
      const rawRes = await api.getUsersFICOScores(userID);
      console.log('rawRes', rawRes);
      const userScores = rawRes.data._embedded.fICOScores;
      setUserData(userScores);
    };
    getUserActivity();
    //eslint-disable-next-line
  }, []);

  const validateScore = (inputScore) => {
    if (!inputScore) return 'Must enter a score';
    if (isNaN(inputScore)) return 'Score must be a valid number between 0 to 900';
    if (inputScore < 0 || inputScore > 900) return 'Score Range';
    return true;
  };
  const submitFicoScore = async (e) => {
    e.preventDefault();
    const score = e.target[0].value;
    const pulledDate =
      e.target[1].value && new Date(e.target[1].value).toISOString().replace('00:00:00.000Z', '12:00:00.000Z');
    const reasonCode1 = e.target[2].value;
    const reasonCode2 = e.target[3].value;
    //Upload wellness core
    try {
      setStatus({ loading: true, error: null });
      //Validate Input #'s only max 100
      if (validateScore(score) !== true) {
        throw new Error(validateScore(score));
      }
      if (!pulledDate) throw new Error('Date is invalid');
      const res = await api.saveFICOScore({
        user: userLink,
        ficoScore: score,
        pulledDate,
        reasonCode1,
        reasonCode2,
      });
      //Re-render component /update state (scores)
      setUserData((p) => {
        return [...p, res.data];
      });
      setStatus({ loading: false, error: null });
    } catch (e) {
      setStatus({ loading: false, error: `${e}` });
    }
  };

  const addFICOForm = (
    <Form onSubmit={submitFicoScore}>
      {/* <Form.Text className="text-muted">No FICO score results for this user</Form.Text> */}

      <Form.Group className="mb-3" controlId="initialWellnessScore">
        <Form.Label>FICO Score:</Form.Label>
        <Form.Control disabled={status.loading} placeholder="Enter score" type="number" min="300" max="850" />
        <Form.Text className="text-muted">Users FICO Credit score: 300 to 850</Form.Text>
      </Form.Group>
      <Form.Group className="mb-3" controlId="scoreDate">
        <Form.Label>Date pulled:</Form.Label>
        <Form.Control disabled={status.loading} type="date" />
        <Form.Text className="text-muted">Date the FICO Score was pulled</Form.Text>
      </Form.Group>
      <Form.Label>Top 2 FICO Reason codes</Form.Label>
      <Form.Group className="mb-3" controlId="reasonCode1">
        <Form.Select aria-label="FICO Reason Codes #1" disabled={status.loading}>
          <option value={null || undefined}></option>
          {Object.values(FICO_REASONS)
            .sort((x, y) => x.code.localeCompare(y.code))
            .map((r, i) => {
              return <option key={`reason1${i}`} value={r.code}>{`${r.code} - ${r.statement}`}</option>;
            })}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3" controlId="reasonCode2">
        <Form.Select aria-label="FICO Reason Codes #2" disabled={status.loading}>
          <option value={null || undefined}></option>
          {Object.values(FICO_REASONS)
            .sort((x, y) => x.code.localeCompare(y.code))
            .map((r, i) => {
              return <option key={`reason2${i}`} value={r.code}>{`${r.code} - ${r.statement}`}</option>;
            })}
        </Form.Select>
      </Form.Group>
      <Button type="submit" disabled={status.loading}>
        {status.loading ? 'Saving...' : 'Add FICO Score'}
      </Button>
      {status.error && <p className="error">{status.error}</p>}
    </Form>
  );

  return (
    <>
      <Accordion.Item eventKey={index}>
        <Accordion.Header>FICO Scores - {userData?.length ?? '0'}</Accordion.Header>
        <Accordion.Body>
          <div>{status.loading === false && addFICOForm}</div>
          <hr />
          {userData?.length > 0 && (
            <Table striped bordered hover size="sm">
              <caption>*As of Nov 2022, all FICO scores must by manually entered</caption>
              <thead>
                <tr>
                  <th>Record ID</th>
                  <th>Pulled Date</th>
                  <th>FICO Score</th>
                  <th>Score Reason 1</th>
                  <th>Score Reason 2</th>
                  <th>Delete Record</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(userData)
                  .sort((x, y) => y.pulledDate.localeCompare(x.pulledDate))
                  .map((q, i) => {
                    const recordID = parseIdFromLink(q?._links.self.href);
                    return (
                      <tr key={`userWellness-${i}`}>
                        <td>{recordID}</td>
                        <td>{formatDateForDisplay(q?.pulledDate)}</td>
                        <td>{q?.ficoScore}</td>
                        <td>{q?.reasonCode1 ? `${q?.reasonCode1} - ${FICO_REASONS[q.reasonCode1]?.statement?FICO_REASONS[q.reasonCode1].statement:'N/A'}` : '-'}</td>
                        <td>{q?.reasonCode2 ? `${q?.reasonCode2} - ${FICO_REASONS[q.reasonCode2]?.statement?FICO_REASONS[q.reasonCode2].statement:'N/A'}` : '-'}</td>
                        <td>
                          {recordToDelete === recordID ? (
                            <span style={{ display: 'flex', gap: '8px' }}>
                              Are you sure?{' '}
                              <Button
                                onClick={async () => {
                                  await api.deleteFICOScore(recordID);
                                  setUserData((p) => {
                                    return p.filter((x) => x._links.self.href !== q._links.self.href);
                                  });
                                }}
                                variant="danger"
                              >
                                Yes
                              </Button>
                              <Button onClick={() => setRecordToDelete(null)} variant="secondary">
                                Cancel
                              </Button>
                            </span>
                          ) : (
                            <Button
                              variant="outline-danger"
                              onClick={() => {
                                setRecordToDelete(recordID);
                              }}
                            >
                              DELETE
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}
          {/* {userData?.length === 0 && addFICOForm} */}
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
}
