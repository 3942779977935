
import React, { useEffect, useState } from "react";
import { auth, db } from "./firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

import api from '../../../utils/api';

import cookies from "../../../utils/cookies";
const SendMessage = ({ scroll, customerReferenceId, inputMessage ,initchatmessages}) => {
 
  const [message, setMessage] = useState("");
  useEffect(() => {
    setMessage(inputMessage);
    setTimeout(() => {
      document.getElementById("sendMessageForm").click();

    }, 1000);
  }, [inputMessage]);
  const sendMessage = async (event) => {
    try {
      event.preventDefault();

    } catch (error) {

    }

    if (!message) {
      return;
    }

    if (message.trim() === "") {
      return;
    }

    const savedRole = cookies.getUserRole();

    let user = {
      "uid": cookies.getUserID(),
      "email": cookies.getUsername(),
      "role": cookies.getUserRole(),
      "emailVerified": true,
      "displayName": cookies.getUserDisplayName(),
      "isAnonymous": false,
      "photoURL": "https://lh3.googleusercontent.com/a/AAcHTtfM0vJ00b9jMVMnRrEvCGJmb1hGGTmlDN5eW-BFPy0t=s96-c",
      "providerData": [
        {
          "providerId": "google.com",
          "uid": cookies.getUserID(),
          "displayName": cookies.getUserDisplayName(),
          "email": cookies.getUsername(),
          "phoneNumber": null,
          "photoURL": "https://lh3.googleusercontent.com/a/AAcHTtfM0vJ00b9jMVMnRrEvCGJmb1hGGTmlDN5eW-BFPy0t=s96-c"
        }
      ],
      "stsTokenManager": {
        "refreshToken": "AMf-vBxJdwOtJEFSDPmkgj3eagT2Ky-pX560MEClfO5acL5i379U9CZewAk4VaDuKgZnEGrfUJs5I376m-C03l8RNkZbd1QKYSmGH5kAZapYn9vhHRirtlyjxJx47fzfiByRK6J1rEwduFFZcQ91O3uaaIYdJNqCplj3jcsBRcfvD7ZSradpGIs0vM7rM6YvjFYP3v5sLjfCWdUXOKMBKzsgcxJVqRgf8-oW0dkOz0XTRV9HjOs-EW7I6r1c4v1SwSATkK8-ACOoqIAIhBovTgq0qt4i7i-GbqG_pTU07OWiasKji6MNtChNlhztqPRi7Y3IkArGnxaa56BDvSq_RIu4QTLy-h3PRihXZbwukGXsJ2XL69_QfmGCIh3UXCeQr6BLuHxGZe_riAEzKcqqV-AkUQI4Yzi93jgs2Fz59qJvpDcSJr8cR8-dyj8MXH7jMBltJOUDmI7w",
        "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjE5MGFkMTE4YTk0MGFkYzlmMmY1Mzc2YjM1MjkyZmVkZThjMmQwZWUiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoibmFuZGFuIGQiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUFjSFR0Zk0wdkowMGI5ak1WTW5SckV2Q0dKbWIxaEdHVG1sRE41ZVctQkZQeTB0PXM5Ni1jIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL215cGFyYWNodXRlLW5vdGlmaWNhdGlvbnMiLCJhdWQiOiJteXBhcmFjaHV0ZS1ub3RpZmljYXRpb25zIiwiYXV0aF90aW1lIjoxNjk0MTcxMDIyLCJ1c2VyX2lkIjoiVURsV0JFeG5EN1k3OFA3WVU5TXlVeEFYcFN2MSIsInN1YiI6IlVEbFdCRXhuRDdZNzhQN1lVOU15VXhBWHBTdjEiLCJpYXQiOjE2OTQxNzEwMjIsImV4cCI6MTY5NDE3NDYyMiwiZW1haWwiOiJuYW5kYW5kLmNvbUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJnb29nbGUuY29tIjpbIjEwODAwNTA5NTQ0MTE4ODgzMjgzMCJdLCJlbWFpbCI6WyJuYW5kYW5kLmNvbUBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.BPYsTubcOzPYfh164Ko3pPMRR65tIKIfEcowXWTUudClwLDz-JGn2GCuUNAs5Oqn5tDN1b4hCcSkMun5-qbRT_sQzq8B9LqVaUQnrb-28X6QpWmeQazjG_MPGs3RdWfx529MvnM3dkNituG6kHxXnIEVhQ2gqpNN6AS8MiEGKNYuQfb-gDIRirm5tOlJeLCjs8J_6019u2kE8YrbO5iAjxnrgw7NNJwBlZjEn6fQ3fxEUMVyIrJzomLcbxrY_wVBo0Zujw4qLgJJHds4tekdI8MOnQCjFrT1AlPPwiFgAPInUNTjz_l_qckLLLzlchSaHX3nP9BKYXqrKZsEFPEfSg",
        "expirationTime": 1694174622656
      },
      "createdAt": "1693995393108",
      "lastLoginAt": "1694171022347",
      "apiKey": JSON.parse(localStorage.getItem('firebaseConfig')).apiKey,
      "appName": "[DEFAULT]"
    };
    const { uid, displayName, photoURL } = user;

    let chatroomname = "";
    if (customerReferenceId) {
      if (customerReferenceId !== undefined && customerReferenceId !== null) {
        chatroomname = "messages" + "-" + customerReferenceId;
      }
    } else {
      if (cookies.getUserID() !== undefined && cookies.getUserID() !== null) {
        chatroomname = "messages" + "-" + cookies.getUserID();
      }
    }
    if (chatroomname === "messages-undefined" || chatroomname === "messages-null") {
      return;
    }
    await addDoc(collection(db, chatroomname), {
      text: message,
      name: displayName,
      avatar: photoURL,
      createdAt: serverTimestamp(),//servertime
      messagedAt: new Date(),//localtime
      uid,
      status: "sent",
      role: savedRole
    });
    setMessage("");
    scroll.current.scrollIntoView({ behavior: "smooth" });
  };


  const sendMessageNew = async (event) => {
    try {
      event.preventDefault();

    } catch (error) {

    }

    if (!message) {
      return;
    }

    if (message.trim() === "") {
      return;
    }

    const savedRole = cookies.getUserRole();

    let senderID;
    let chatroomname = "";
    if (customerReferenceId) {
      if (customerReferenceId !== undefined && customerReferenceId !== null) {
        chatroomname = "messages" + "-" + customerReferenceId;
        senderID = customerReferenceId;
      }
    } else {
      if (cookies.getUserID() !== undefined && cookies.getUserID() !== null) {
        chatroomname = "messages" + "-" + cookies.getUserID();
        senderID = cookies.getUserID();
      }
    }
    if (chatroomname === "messages-undefined" || chatroomname === "messages-null") {
      return;
    }

    let data = {
      "senderId": cookies.getUserRole() === 'ROLE_SUPER_ADMIN' ? +cookies.adminID : +senderID,
      "text": message.trim(),
      "read": false,
      "chatRoom": {
        "chatRoomKey": {
          "userId": +senderID,
          "adminId": +cookies.adminID
        }
      }
    };
    await api.sendMessageNew(data)
      .then(resp => resp?.data)
      .then(res => {
        console.log(res)
        setMessage("");
        initchatmessages(); 

        scroll.current.scrollIntoView({ behavior: "smooth" });
      }).catch(err => {
        // gridApi.hideOverlay();
        console.log(err);

      });



  };
  return (
    <form onSubmit={(event) => sendMessageNew(event)} className="send-message fixed-bottom" >
      <label htmlFor="messageInput" hidden>
        Enter Message
      </label>
      <input
        id="messageInput"
        name="messageInput"
        type="text"
        className="form-control form-control-lg"
        placeholder="type message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <button type="submit" id="sendMessageForm">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
        </svg>
      </button>
    </form>
  );
};

export default SendMessage;
