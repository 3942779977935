/* 
Streaks will always be 8 (for now); Streaks will reset monthly
Quiz API can be searched by date range
Resuming - User logs back in, the card appear how it was left off, ie Last Answer with see Next quiz;

Known Issue: No backsies - If the user clicks the wrong answer thats it..., no time to reconsider
Also answers will exist in fetch and state, clever pers could cheat.
*/

import React, { useState, useEffect } from 'react';
// import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
// import formatISO from 'date-fns/formatISO';
import cookies from '../../utils/cookies';

import api from '../../utils/api';
import { FREEMIUM_STREAK_REWARD, STREAK_REWARD } from '../../utils/constants';

import CardContainer from '../CardContainer';
import StreakTile from './StreakTile/Index';
import QuizTile from './QuizTile/Index';
import NextQuizTile from './NextQuizTile/Index';
import OnboardingModal from '../OnboardingModal/Index';

import './QuizCard.scss';
import isPast from 'date-fns/isPast';
import parseISO from 'date-fns/parseISO';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import ErrorNotification from '../ErrorNotification';

function QuizCard({
  buildQuizNotification,
  setNotifications,
  showOnboardingModal,
  UID,
  setOnboardingComplete,
  disabled,
  overrideUserLink,
}) {
  /* Data
- All Quizzes (set of quizzes for a set time (week or month), to be completed in order)
- User Completed Quizes /users/:id/quiz/:id
- STATE: 
> Current Quiz - to populate components
> QuizList - holding for all quizzes in the month
> userStreak - Displays how many quizzes the user has completed (out of the month total)
 
  //onInit - Fetch List of Quizes and What the user has completed
  //Update Streak
  //Determine where user left off (maybe new UE based on list change)
  //Display last quiz Finished or not
  //Show next avail if possible
*/

  const savedUserID = cookies.getUserID(); //[]TODO pull from auth context instead

  const UIDLink =
    overrideUserLink || `https://${cookies.processenv.REACT_APP_ENV}.${cookies.processenv.REACT_APP_API}/users/${savedUserID}`;
  const [quizList, setQuizList] = useState([]);
  const [userStreak, setUserStreak] = useState({ total: 0, completed: 0 }); //[]Add if 0 total dont display
  const [currQuiz, setCurrQuiz] = useState({
    question: '',
    answerOptions: [],
    correctAnswer: null, //String that is matched against an answerOption
    userAnswer: null, // Also be a string (only saved to state after saved to API << potential expoit is to interrupt network (would get the ans w/o submitting))
  });
  //API Triggers
  const [answerToPost, setAnswerToPost] = useState(null); //Triggers Use Effect that calls API

  const buildQuizObject = (quizData, userResponse) => {
    const q = quizData;
    const options = [];
    Object.entries(q).forEach(([key, value]) => {
      if (key.includes('choice')) {
        if (value) return options.push(value);
      }
      return;
    });
    const questionObject = {
      quizID: q._links.self.href,
      question: q.question,
      answerOptions: options,
      correctAnswer: q.correctAnswer,
      explanation: q.explanation,
      userAnswer: userResponse ? userResponse : null,
    };
    //[] TODO Add validation for object (must have a valid answer and options)
    return questionObject;
  };

  useEffect(() => {
    /* Init Load - GET ALL APPLICABLE QUIZZES */
    const getQuizzes = async () => {
      // moment.tz.setDefault('America/New_York');
      // let today = moment().utc().format('ddd MMM DD YYYY hh:mm:ss [GMT]ZZ (zz)');
      // const start = moment(today).startOf('month').toISOString();
      // const end = moment(today).endOf('month').toISOString();
      // const dateRange = {
      //   startDate: start,
      //   endDate: end,
      // };

      const dateRange = cookies.getDateRange();

      console.log(dateRange)
      try {
        const res = await api.getQuizzesByDates(dateRange);
        const thisMonthsQuizzes = res.data._embedded.quizzes.sort((x, y) =>
          x.availableDate.localeCompare(y.availableDate)
        );
        setQuizList(thisMonthsQuizzes); //Give full list to populate streaks
      } catch (e) {
        console.log('ERROR getting Quizzes', e);
      }
    };
    getQuizzes();
  }, []);

  useEffect(() => {
    /* Once List changes - Get user Answers & Update Streak and currQuiz (when QuizList changes)*/
    if (quizList.length <= 0) return;
    const updateStreak = (userResponses) => {
      if (quizList.length > 0) {
        /* []TODO Verify business logic, as init talks has quizzes 1 per week, but then it'd be impossible to get 8 / month
        - Should total (for streak) be hard Coded? */
        const totalQuizzes = quizList.length;
        const completeQuizzes = userResponses.filter((quizRes) => quizRes.quizAnswer).length;
        setUserStreak({ total: totalQuizzes, completed: completeQuizzes });
      }
    };

    const updateCurrQuiz = (question, userAnswer) => {
      if (!question) return;
      //Determine what part of the array to dispaly, else start w/ 0
      const questionObject = buildQuizObject(question, userAnswer);
      setCurrQuiz(questionObject);
    };

    const getAndSetUserResponses = async () => {
      const userID = UIDLink;
      const quizIDs = quizList.map((quiz) => {
        return quiz._links.self.href;
      });
      if (quizIDs.length <= 0) return;
      //[] Get user Responses for quizzes.
      try {
        const res = await Promise.all(
          quizIDs.map((quizID) => {
            return api.getUsersQuizAnswers(userID, quizID);
          })
        );
        //Build matching indexes from ID's to answers
        const quizResponses = res.map((x, i) => {
          const answer = x.data._embedded.quizAnswers[0]; //Should only have the 1 response per quiz but will map later as some maybe empty
          return {
            quizID: quizIDs[i],
            quizQuestion: quizList[i],
            quizAnswer: answer,
          };
        });
        updateStreak(quizResponses);
        // Determine where the user left off, or show the last in the list
        const nextQuiz =
          quizResponses.find((res) => res.quizAnswer === undefined) || quizResponses[quizResponses.length - 1];
        const lastAnsweredQuiz = quizResponses?.reverse().find((res) => res.quizAnswer);
        const dateofNext = nextQuiz?.quizQuestion?.availableDate && parseISO(nextQuiz.quizQuestion.availableDate);
        const showNext = isPast(dateofNext);
        if (showNext) {
          updateCurrQuiz(nextQuiz.quizQuestion, nextQuiz.quizAnswer?.answer);
        } else {
          updateCurrQuiz(lastAnsweredQuiz?.quizQuestion, lastAnsweredQuiz.quizAnswer?.answer);
        }
      } catch (e) {
        console.log('e', e);
      }
    };
    getAndSetUserResponses();
  }, [quizList, UIDLink]);

  useEffect(() => {
    /* Save the users answer to the API (when answerToPost is present)
    Will update streak and quizState on success 
    Then clear answerToPost
    */
    if (!answerToPost) return;
    let mounted = true;
    const postAnswer = async (answerData) => {
      // {quiz: quizIDLink, user: userIDLink, answer} = answerData
      try {
        await api.postQuizAnswer(answerData);
        // res.data.answer
        if (mounted) {
          //Update currQuiz to remember userAnswer
          setCurrQuiz((prev) => {
            return {
              ...prev,
              userAnswer: answerData.answer,
            };
          });
          // Update Streak
          setUserStreak((prev) => {
            return { ...prev, completed: prev.completed + 1 };
          });
          setAnswerToPost(null);
        }
      } catch (e) {
        console.log('!!Error while saving response to API', e);
      }
    };
    if (Object.values(answerToPost).every((x) => x)) {
      postAnswer(answerToPost);
    }
    return () => {
      mounted = false;
    };
  }, [answerToPost]);

  useEffect(() => {
    if (userStreak.total && userStreak.total === userStreak.completed) {
      //Build and Update quiz notification
      const quizNotification = buildQuizNotification();
      quizNotification &&
        setNotifications((prev) => {
          if (!prev) return;
          const isUpdated = prev && prev.find((x) => x.type === 'quiz');
          return isUpdated ? [...prev] : [...prev, quizNotification];
        });
    }
  }, [userStreak.completed, userStreak.total, buildQuizNotification, setNotifications]);

  const findNextQuiz = () => {
    //[]TODO make a pure fucntion that takes in args
    const currQuizID = currQuiz.quizID;
    const currIndex = quizList.findIndex((x) => {
      return x._links.self.href === currQuizID;
    });
    const nextQuiz = quizList[currIndex + 1];
    return nextQuiz ? nextQuiz : null;
    // if (isPast(parseISO(nextQuiz?.availableDate))) return nextQuiz;
    // return null;
  };

  const showNextQuiz = () => {
    const nextQuiz = findNextQuiz();
    const questionObject = buildQuizObject(nextQuiz);
    setCurrQuiz(questionObject);
  };

  const submitAnswerToAPI = (userAnswer) => {
    const answerToSave = {
      quiz: currQuiz.quizID,
      user: UIDLink,
      answer: userAnswer,
    };
    setAnswerToPost(answerToSave); //Triggers API Req in useEffect
  };

  const isNextQuizAvailable = () => {
    const nextQuiz = findNextQuiz();
    if (isPast(parseISO(nextQuiz?.availableDate))) return true;
    return false;
  };
  const showNextQuizOption = !!currQuiz.userAnswer && isNextQuizAvailable() ? true : false;

  const nextAvailDaysMsg = () => {
    /* Next Quiz Date, 
    - get nextAvailable Quiz date 
    - if none, roll to the end of the month
    */
    const today = cookies.getDate();
    const nextQuiz = findNextQuiz();
    const availDate = nextQuiz?.availableDate && parseISO(nextQuiz?.availableDate);
    let daysToNextQuiz = null;
    if (!availDate) {
      //Calc and rtn to end of Month
      const daysToEndOfMonth = differenceInCalendarDays(endOfMonth(today), today);
      daysToNextQuiz = daysToEndOfMonth;
      return 'Check back next month for new quizzes';
    } else if (isPast(parseISO(nextQuiz?.availableDate))) {
      return 0; //[] Add comment explaining this scenario
    } else {
      //calc days to next quiz
      daysToNextQuiz = differenceInCalendarDays(availDate, today);
    }

    /* can refactor with differnce being >0 anything else would be past... */
    /* Due to postman entries with availableDate of 00:00Z, its possible to get 0 days to release (<24hr) 
    Code will force 0 days to release to be shown as 1 day to release*/
    const outputString =
      daysToNextQuiz === 1 || daysToNextQuiz === 0
        ? `Next quiz is available in 1 day`
        : `Next quiz is available in ${daysToNextQuiz} days`;
    return outputString;
  };

  const nextMsg = nextAvailDaysMsg();
  const showNextQuizDays = currQuiz.userAnswer && nextMsg;

  const NextQuizArea = () => {
    if (showNextQuizDays) return <div className="nextQuizDayCount">{nextMsg}</div>;
    if (!currQuiz.question) {
      return (
        <ErrorNotification noBorder quizCardStyle>
          Next quiz data unavailable.
        </ErrorNotification>
      );
    }
    return null;
  };

  return (
    <CardContainer id="quizCard" className="quizCard divided" style={disabled ? { opacity: 0.7 } : null}>
      {!disabled && showOnboardingModal && (
        <OnboardingModal
          UID={UID}
          quiz={{ current: currQuiz, submitAnswer: submitAnswerToAPI }}
          setOnboardingComplete={setOnboardingComplete}
        />
      )}
      <h2 className="quizCardTitle">Parachute Pop-Quiz</h2>
      <div>
        <StreakTile quizStats={userStreak} reward={STREAK_REWARD} freemium_reward={FREEMIUM_STREAK_REWARD} />
        {currQuiz.question && <QuizTile questionData={currQuiz} submitAnswer={submitAnswerToAPI} disabled={disabled} />}
        {!currQuiz.question && <ErrorNotification>No quiz available.</ErrorNotification>}
        {showNextQuizOption ? (
          <NextQuizTile availQuizCount={quizList.length - userStreak.completed} goToNextQuiz={showNextQuiz} />
        ) : (
          <NextQuizArea />
        )}
      </div>
    </CardContainer>
  );
}

export default QuizCard;
