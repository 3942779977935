import format from "date-fns/format";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import differenceInCalendarMonths from "date-fns/differenceInCalendarMonths";
import differenceInCalendarYears from "date-fns/differenceInCalendarYears";
import isValid from "date-fns/isValid";
// import isPast from 'date-fns/isPast'; //Relative to Today
import isAfter from "date-fns/isAfter"; //Compares 2 dates
import isSameDay from "date-fns/isSameDay";
import isSameMonth from "date-fns/isSameMonth";
import subMonths from "date-fns/subMonths";
import addMonths from "date-fns/addMonths";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import isBefore from "date-fns/isBefore"; //Compares 2 dates
import addDays from "date-fns/addDays";

// import isEqual from 'date-fns/isEqual';

export const parseIdFromLink = (link, offset, strip) => {
  if (!link) return link;
  let id;
  if (strip) {
    id = link
      .split("/")
    [link.split("/").length - 1 + (offset ? offset : 0)].replace(strip, "");
  } else {
    id = link.split("/")[link.split("/").length - 1 + (offset ? offset : 0)];
  }
  if (id.includes("{?projection}")) id = id.replace("{?projection}", "");
  return id;
};

export const formatDateForDisplay = (inputDate, options) => {
  let dateFormat = "MMMM dd, yyyy";
  if (options?.truncated) dateFormat = "MMMM do";
  if (options?.monthYear) dateFormat = "MMMM yyyy";
  if (options?.shortMonth) dateFormat = "dd MMM yyyy";
  if (options?.dateTime) dateFormat = "dd MMM kk:mm";
  if (options?.numeric) dateFormat = "MM/dd/yy";
  if (options?.custom) dateFormat = options?.custom;
  if (!inputDate) return null;
  const dateObject = new Date(inputDate);
  let formatted = null;
  try {
    formatted = format(dateObject, dateFormat);
  } catch (error) {
    //do nothing
    console.error(inputDate)
  }
  return formatted ? formatted : inputDate;
};

export const displayMonth = (date, options) => {
  const daysToAdd = options?.addDays;
  const dateObj = daysToAdd
    ? addDays(new Date(date), daysToAdd)
    : new Date(date);
  const formatted = options?.withYear
    ? format(dateObj, "LLLL yyyy")
    : format(dateObj, "LLLL");
  return formatted ? formatted : null;
};

export const findDaysFromToday = (date) => {
  const today = new Date();
  const daysToPayment = differenceInCalendarDays(date, today);
  return daysToPayment;
};

export const createDueDateMsg = (daysToPayment, isCurrMonthPaid) => {
  let message = "";
  if (isCurrMonthPaid) return "Payment made on time";

  if (daysToPayment >= 0) {
    switch (daysToPayment) {
      /* Upcoming Payment */
      case 0:
        message = `Your next payment is due today`;
        break;
      case 1:
        message = `Your next payment is due in ${daysToPayment} day`;
        break;
      default:
        message = `Your next payment is due in ${daysToPayment} days`;
        break;
    }
  }
  if (daysToPayment < 0) {
    /* OVERDUE Payment */
    switch (daysToPayment) {
      case -1:
        message = `Payment missed by  ${daysToPayment / -1} day`;
        break;
      default:
        message = `Payment missed by  ${daysToPayment / -1} days`;
        break;
    }
  }
  return message;
};

export const formatDecimals = (amount) => {
  //If a #, Return with 2 decimal places
  return isNaN(amount) ? amount : Number.parseFloat(amount).toFixed(2);
};

export const formatNumToCommaString = (num) => {
  if (num === null) return "N/A";
  //[]Test and assign
  //Alt Try num.toLocaleString("en-US");
  const commas = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //String?
  return commas;
};

export const formatToDollar = (num, options) => {
  const noDecimal = options?.noDecimal;
  if (isNaN(num)) return num;
  const withDecimals = noDecimal ? num : formatDecimals(num);
  const formattedString = formatNumToCommaString(withDecimals);
  return formattedString;
};

export const calcCreditChange = (currMonth, lastMonth) => {
  // Rtn delta b/w curr and last if present
  const delta = currMonth?.creditScore - lastMonth?.creditScore;
  return delta ? delta : null;
};

export const monthPaymentStatus = (
  nextDueDate,
  options = { compareDate: new Date() }
) => {
  /* Prefferably takes in date objects, but will convert to date object 
  TODO should add date validation then...*/

  //Today defaults to NOW, but can be overridden for testing purposes
  //ASSUMES DUE DATE is oldest owed,
  // !! Key NOTE: nextDate doesn't increment months until the months payment is satified
  const { compareDate } = options;
  if (!nextDueDate) return null;
  const dueDate = new Date(nextDueDate);

  if (isBefore(dueDate, compareDate)) {
    return "overdue";
  } //'OVERDUE'; //nextPayment due is before today/paid === overdue (any past, day minute year, its overdue)
  if (differenceInCalendarMonths(dueDate, compareDate) > 0) return "paid"; //' Due next month thus: PAID';
  return "unpaid"; //'UNPAID';
};

export const isPaidOnTime = ({ due, paid }) => {
  /* To test if paid on time
  Need to make sure payment on day of is okay 
  
  ie due 01 April, and paid 01 april, that is on time

  */
  if (!due || !paid) return null;
  let result = false;
  if (isAfter(due, paid)) result = true;
  if (isSameDay(due, paid)) result = true;
  return result;
};

export const prevMonthDate = (inputDate, options) => {
  //Todo  Test end of month as input, ie mar 31 > 28 Feb?
  let date = subMonths(inputDate, 1);
  if (options?.endOfMonth) date = endOfMonth(date);
  if (options?.startOfMonth) date = startOfMonth(date);
  return date;
};
export const monthDates = (inputDate, options) => {
  //Todo  Test end of month as input, ie mar 31 > 28 Feb?
  let date = inputDate;
  if (options?.endOfMonth) date = endOfMonth(date);
  if (options?.startOfMonth) date = startOfMonth(date);
  return date;
};
export const preMonthName = (date) => {
  const monthName = displayMonth(prevMonthDate(date));
  return monthName;
};
export const startOfNextMonthFormatted = () => {
  const today = new Date();
  const date = startOfMonth(addMonths(today, 1));
  return formatDateForDisplay(date);
};

export const startOfMonthWithAddDays = (noOfDays) => {
  const today = new Date();
  const date = startOfMonth(addDays(today, noOfDays));
  return date;
};

export const displayPercent = (decimal) => {
  const percent = Number.parseFloat(decimal * 100).toFixed(0);
  return `${percent}%`;
};

export const isValidPassword = (password) => {
  //Length 8-50, 1 Uppercase, 1 Lowercase, 1 Digit, 1 Special Character
  const containsUpperCase = password.match(/[A-Z]/);
  const containsLowerCase = password.match(/[a-z]/);
  const containsDigit = password.match(/\d/);
  const containsSpecial = password.match(/[^a-zA-Z\d]/);

  return (
    password.length >= 8 &&
    password.length <= 50 &&
    containsDigit &&
    containsSpecial &&
    containsUpperCase &&
    containsLowerCase
  );
};

export const objectHasMissingData = (inputObj, requiredFields) => {
  /* 
  Is some if the input object null or undefined
  
  Required fields is an array of strings to check against the input objects keys
  if the key isn't required it doesn't check if its null or undefined. 
  */
  return (
    inputObj &&
    Object.entries(inputObj).some(([k, v]) => {
      //If key isn't required then false (skip the check)
      if (requiredFields && !requiredFields.includes(k)) {
        return false;
      }
      if (v === null || v === undefined) return true;
      return false;
    })
  );
};

export const findLastMonthsPayments = (paymentSchedule) => {
  if (!paymentSchedule) return null;
  const lastMonth = subMonths(new Date(), 1);
  const lastMonthPayments = [];
  paymentSchedule.forEach((payment) => {
    const dueDate = new Date(payment.dueDate);
    if (isSameMonth(lastMonth, dueDate)) {
      lastMonthPayments.push(payment);
    }
  });
  return lastMonthPayments;
};

export const removeCommas = (input) => {
  if (input === null || input === undefined) return input;
  return input
    .split("")
    .filter((x) => x !== ",")
    .join("");
};

export const compareNames = (a, b) =>
  a.lastName > b.lastName
    ? 1
    : a.lastName === b.lastName
      ? a.firstName > b.firstName
        ? 1
        : -1
      : -1;

export const limitDecimals = (inputNum, allowedDecimalsPlaces = 2) => {
  //Num needs to be a string
  const decimalIndex = inputNum.indexOf(".");
  const cutOffIndex = allowedDecimalsPlaces + 1 + decimalIndex;
  const wholeNum = inputNum.substring(0, decimalIndex);
  const decimals = inputNum.substring(decimalIndex, cutOffIndex);
  return decimalIndex > 0 ? wholeNum + decimals : inputNum;
};

export const calculateAge = (dobObject) => {
  if (isValid(!dobObject)) return null;
  let age = null;
  const today = new Date();
  age = differenceInCalendarYears(today, dobObject);
  return age;
};

export const isDST = (dstDate) => {
  // Get the offset of Jan 1
  const jan1 = new Date(dstDate.getFullYear(), 0, 1).getTimezoneOffset();

  // Get the offset of Jul 1
  const jul1 = new Date(dstDate.getFullYear(), 6, 1).getTimezoneOffset();

  if (jan1 !== jul1) {
    return Math.max(jan1, jul1) !== dstDate.getTimezoneOffset(); //True if not equal
  } else {
    return jan1 !== jul1;
  }
};
