import React from 'react';
import ToolTipIcon from './ToolTipIcon';

export default function CardTitleWTip({ tipObject, title, children }) {
  return (
    <span className="cardTitleWToolTip" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <h2 className="cardTitle" style={{ marginBottom: 0 }} data-testid="cardTitle">
        {children || title}
      </h2>
      {tipObject && <ToolTipIcon data={tipObject} />}
    </span>
  );
}
