import React from 'react';
// import ProgressGradient from './ProgressGradient';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import './ProgressCircle.scss';
export default function WellnessProgressCircle({ currentScore, bracketDescription }) {
  const GRADIENT = {
    error500: '#F04438',
    warning500: '#F79009',
    warning300: '#FEC84B',
    success500: '#12B76A',
    success900: '#054F31',
  };
  const fixedScore = currentScore && Number(currentScore).toFixed(0);
  let colorToUse = GRADIENT.success500;
  if (fixedScore < 20) colorToUse = GRADIENT.error500;
  if (fixedScore >= 20 && fixedScore <= 39) colorToUse = GRADIENT.warning500;
  if (fixedScore >= 40 && fixedScore <= 59) colorToUse = GRADIENT.warning300;
  if (fixedScore >= 60 && fixedScore <= 79) colorToUse = GRADIENT.success500;
  if (fixedScore >= 80) colorToUse = GRADIENT.success900;

  return (
    <div id="scoreArea">
      <CircularProgressbarWithChildren
        value={currentScore ?? 0}
        styles={{
          // Customize the root svg element
          root: {},
          // Customize the path, i.e. the "completed progress"
          path: {
            // Path color
            stroke: colorToUse, //`rgba(25, 135, 84)`,
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'round',
            // Customize transition animation
            transition: 'stroke-dashoffset 0.5s ease 0s',
            // Rotate the path
            // transform: 'rotate(0.25turn)',
            // transformOrigin: 'center center',
          },
          // Customize the circle behind the path, i.e. the "total progress"
          // trail: {
          //   // Trail color
          //   stroke: '#d6d6d6',
          //   // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          //   strokeLinecap: 'butt',
          //   // Rotate the trail
          //   transform: 'rotate(0.25turn)',
          //   transformOrigin: 'center center',
          // },
          // Customize the text
          // text: {
          //   // Text color
          //   fill: '#f88',
          //   // Text size
          //   fontSize: '16px',
          // },
          // Customize background - only used when the `background` prop is true
          // background: {
          //   fill: '#3e98c7',
          // },
        }}
      >
        <div className="scoreInfo">
          <p className="score" style={{ margin: 0 }}>
            {currentScore ? `${currentScore}/100` : 'N/A'}
          </p>
          <p className="scoreRange" style={{ margin: 0 }}>
            {currentScore ? bracketDescription : null}
          </p>
        </div>
      </CircularProgressbarWithChildren>
      {/* <ProgressGradient idCSS="wellnessGradient" rotation={0} /> */}
      {/* <div
        id="conGradient"
        style={{ width: '40px', height: '40px', background: 'conic-gradient(red, orange, yellow, green, blue)' }}
      /> */}
    </div>
  );
}

/* 

Color gradient
 Error/500 
background: #F04438;
 Warning/500 
background: #F79009;
 Warning/300 
background: #FEC84B;
 Success/500 
background: #12B76A;
 Success/900 
background: #054F31;


Background ring css
border: 16px solid #EAECF0;
*/

/*   <div
      className="circleProgress"
      style={{
        width: '160px',
        height: '160px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        paddingRight: '12px',
      }}
    >
      <div
        className="outer"
        style={{ width: '160px', height: '160px', padding: '16px', borderRadius: '50%', backgroundColor: '#EAECF0' }}
      >
        <div
          className="inner"
          style={{
            width: '128px',
            height: '128px',
            borderRadius: '50%',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="scoreInfo" style={{ textAlign: 'center' }}>
            <p>{`${currentScore}/73`}</p>
            <p>Medium Low</p>
          </div>
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="160px"
        height="160px"
        style={{ position: 'absolute', top: 0, left: 0, zIndex: 4 }}
      >
        <defs>
          <linearGradient>
            <stop offset="0%" stop-color="red" />
            <stop offset="100%" stop-color="green" />
          </linearGradient>
        </defs>
        <circle
          cx="74"
          cy="80"
          r="69"
          stroke-linecap="round"
          style={{ fill: 'none', stroke: 'red', strokeWidth: '16px' }}
        />
      </svg>
    </div> */
