import React from 'react';
import CardContainer from '../CardContainer';
import CardModal from '../CardModal';
import healthIcon from '../../assets/icons/healthIcon.svg';

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
export default function TileDebt({planDetails}) {
  return (
    <CardContainer className="goalTile debtTile">
      <div className="tileContent">
        <img className="tileIcon" src={healthIcon} alt="Health Icon" />
        <div className="tileInfo">
          <div>
            <h3>Don’t take on new debt.</h3>
            <p className="infoTitle">Taking on new debt can negatively impact your credit score.</p>
          </div>
          <CardModal id="monthly-debtInfo">
            <img className="tileIcon" src={healthIcon} alt="Health Icon" />
            <h3>Don’t take on new debt.</h3>
            <p className="muted">
              Taking on new debt can negatively impact your credit score. Increased debt also makes it harder to pay
              your bills on time.
            </p>
          </CardModal>


         
        </div>
        
      </div>

{planDetails?( planDetails['promises']['chatBeforeNewDebt'] === true || planDetails['promises']['noNewDebtUpcomingMonth'] === true || planDetails['promises']['chatForDebtReduction'] === true || planDetails['promises']['description3'] ? (
          <>
            <div className="wideCntr planForm container" style={{ textAlign: 'left', width: 'auto' }}>
            <Card>
              <Card.Header><h7>Plan</h7></Card.Header>
              <ListGroup variant="flush">
                {planDetails['promises']['chatBeforeNewDebt'] === true && (
                  <ListGroup.Item>I will chat to Parachute before taking on new debt</ListGroup.Item>
                )}
                {planDetails['promises']['noNewDebtUpcomingMonth'] === true && (
                  <ListGroup.Item>I will not take on new debt this coming month</ListGroup.Item>
                )}
                {planDetails['promises']['chatForDebtReduction'] === true && (
                  <ListGroup.Item>I will chat with Parachute abount how i can reduce the additional debt i have taken out</ListGroup.Item>
                )}
                {planDetails['promises']['description3'] && (
                  <ListGroup.Item>{planDetails['promises']['description3']}</ListGroup.Item>
                )}
              </ListGroup>
            </Card>
            </div>
          </>) : (<></>)
):(<></>)}

    </CardContainer>
  );
}
