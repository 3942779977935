/* Admin API, axios instance is separate from clients  */
import axios from './a-axios';
import cookies from '../../utils/cookies';
const lendersUser = cookies.processenv.REACT_APP_LENDERS_USERNAME;

const putReport = async (lendersKey, reportID, report, options) => {
  if (!reportID) throw new Error('Need to assign a report ID');
  const immediateMatching = options?.immediate;
  let putURL = `https://www.lendersapi.com/reports/${reportID}`;
  if (immediateMatching) {
    putURL += '?immediate=true';
  }
  try {
    axios.defaults.auth = {
      username: lendersUser,
      password: lendersKey,
    };
    return await axios.put(putURL, report);
  } catch (e) {
    throw e;
  }
};
const submitReport = putReport; //Lenders API uses PUT for its create and update operations.
const updateReport = putReport;

const viewReport = async (lendersKey, reportID) => {
  const getURL = `https://www.lendersapi.com/reports/${reportID}`;
  console.log('Getting API key...');
  try {
    axios.defaults.auth = {
      username: lendersUser,
      password: lendersKey,
    };
    return await axios.get(getURL);
  } catch (e) {
    throw e;
  }
};

const searchHits = async (lendersKey, reportID) => {
  const getURL = `https://www.lendersapi.com/reports/${reportID}/hits`;
  try {
    axios.defaults.auth = {
      username: lendersUser,
      password: lendersKey,
    };
    return await axios.get(getURL);
  } catch (e) {
    throw e;
  }
};

const exports = { submitReport, updateReport, viewReport, searchHits };
export default exports;
