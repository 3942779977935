import React, { useEffect, useState } from "react";
// import * as widgetSdk from "@mxenabled/web-widget-sdk";
import CardContainer from "../CardContainer";
import { TOOLTIP } from "../../utils/constants";
import CardTitleWTip from "../CardTitleWTip";
import axios from "../../utils/axios";
import Spinner from "react-bootstrap/Spinner";

import Button from 'react-bootstrap/Button';
import useAuth from '../../hooks/useAuth';
// const widget = new widgetSdk.ConnectWidget({
//   container: "<CONTAINER SELECTOR>",
//   url: "<Widget URL>",
//   // additional widget options
// });

import * as widgetSdk from "@mxenabled/web-widget-sdk";
export default function AccountsCard({ overrideUserID }) {
  const auth = useAuth();
  const userID = overrideUserID || auth?.user?.id;

  const [spendingWidget, setSpendingWidget] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    async function spendSummaryWidgetUrlApi() {
      try {
        setIsLoading(true);
        const params = { widget_type: "accounts_widget" };
        const endUrl = `/users/${params?.widget_type}/customMxWidgetUrls/${userID}`;
        const res = await axios.get(endUrl, { params });
        if (res.data) {
          setSpendingWidget(res?.data?.widgetUrl?.url ? (res?.data?.widgetUrl?.url) : (null));
        
          try {
            setTimeout(() => {
              let widget = new widgetSdk.ConnectWidget({
                container: "#spending-widget",
                url:res?.data?.widgetUrl?.url ,
                // additional widget options
              });
            }, 1500);

          } catch (error) {
            console.log(error);
          }

          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
    spendSummaryWidgetUrlApi();
  }, [userID]);

  return (
    <>
      {spendingWidget ? (<CardContainer id="monthlySpendMx" className="monthlySpendMxWidgetDiv" style={{ width: "100%" }}>
        <CardTitleWTip tipObject={TOOLTIP.TOP_MERCHANT} >
          {/* TRANSACTIONS: */}
          {isLoading && (
            <>
              <p>Finalizing connection please wait...</p>
              <Spinner className="flinksSpinner" animation="border" size="md" />
            </>
          )}

          {spendingWidget && (
            //   <iframe
            //   title="Connect Accounts"
            //   src={spendingWidget}
            // />
            <>
               {/* <Button variant="link" onClick={refrershWidget} >Reload</Button> */}
            <div id="spending-widget"></div>
            </>
         
          )}

        </CardTitleWTip>
      </CardContainer>) : ''}

    </>


  );
}