/* 29 Nov 2022 - PAR-1045 - Moved utilization to manual entry from users.  Monthly goal tile is now static / info tile.  Left in former code for poss. future changes */

import React from 'react';
import CardContainer from '../CardContainer';
import CardModal from '../CardModal';
import utilizatinIcon from '../../assets/icons/utilizatinIcon.svg';
// import Notification from '../Notification/Index';
// import LoanInfo from '../LoanInfo';
// import ErrorNotification from '../ErrorNotification';
// import { formatToDollar, objectHasMissingData } from '../../utils/helpers';

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
export default function TileUtilization({ planDetails, DEBT }) {
  // const isDataPresent = !!DEBT && DEBT?.utilizationPercent !== undefined;
  // const showError = !DEBT || objectHasMissingData(DEBT);
  return (
    <CardContainer className="goalTile">
      <div className="tileContent">
        <img className="tileIcon" src={utilizatinIcon} alt="Utilizatin Icon" />
        <div className="tileInfo">
          <div>
            <h3>Maintain low credit utilization.</h3>
            <p className="infoTitle">Aim to use a maximum of 50% of your available credit each month.</p>
          </div>
          {/*  {!showError && (
            <Notification
              data={{
                //Color breaks @ 50%
                type: DEBT.utilizationPercent >= 50 ? 'error' : 'success',
                body: !isNaN(DEBT.utilizationPercent) ? `Credit utilization at ${DEBT.utilizationPercent}%` : 'N/A',
              }}
            />
          )} */}

          <CardModal id="monthly-utilizationDetails">
            <img className="tileIcon" src={utilizatinIcon} alt="Utilizatin Icon" />
            <h3>Maintain low credit utilization.</h3>
            <p className="muted">
              Credit utilization represents how much of your available credit you’re using. In simple terms if you have
              a credit card with a limit of $5,000 and you’re using $2,500, you’re at 50% credit utilization.
            </p>
            <p className="muted">
              Your goal is to miminize your credit utilization, which can improve your credit score. It also means you
              have more available credit for things like emergency expenses.
            </p>
            {/* {!showError && (
              <div className="divided-Info">
                <LoanInfo
                  title={'Total Credit'}
                  body={!isNaN(DEBT.totalDebt) ? `$${formatToDollar(DEBT.totalDebt, { noDecimal: true })}` : 'N/A'}
                />
                <LoanInfo
                  title={'Utilized Credit'}
                  body={!isNaN(DEBT.utilization) ? `$${formatToDollar(DEBT.utilization, { noDecimal: true })}` : 'N/A'}
                />
                <LoanInfo
                  title={'Credit Utilization Percentage'}
                  body={!isNaN(DEBT.utilizationPercent) ? `${DEBT.utilizationPercent}%` : 'N/A'}
                />
              </div>
            )} */}
            {/*   {showError && (
              <ErrorNotification style={{ marginTop: '24px' }}>
                We cannot determine credit utilization.
              </ErrorNotification>
            )} */}
          </CardModal>
             
        </div>
      </div>
      {/* {showError && <ErrorNotification>We cannot determine credit utilization.</ErrorNotification>} */}

{planDetails?(
planDetails['promises']['applySavingsToCreditCard'] === true || planDetails['promises']['payMoreOnCreditCard'] === true || planDetails['promises']['payOffCreditCard'] === true || planDetails['promises']['description2'] ? (
  <>
     <div className="wideCntr planForm container" style={{ textAlign: 'left', width: 'auto' }}>
    <Card>
      <Card.Header><h7>Plan</h7></Card.Header>
      <ListGroup variant="flush">
        {planDetails['promises']['applySavingsToCreditCard'] === true && (
          <ListGroup.Item>I will save up to $50 this coming month and apply the saving to my credit card</ListGroup.Item>
        )}
        {planDetails['promises']['payMoreOnCreditCard'] === true && (
          <ListGroup.Item>I will pay more than the minimum amount on my credit card</ListGroup.Item>
        )}
        {planDetails['promises']['payOffCreditCard'] === true && (
          <ListGroup.Item>I will pay off my credit card</ListGroup.Item>
        )}
        {planDetails['promises']['description2'] && (
          <ListGroup.Item>{planDetails['promises']['description2']}</ListGroup.Item>
        )}
      </ListGroup>
    </Card>
    </div>
  </>) : (<></>)):(<></>)}

    </CardContainer>
    
  );
}
