//As per Flinks Nov 2021 File
export const FLINKS_INSITUTION_IDS = [
  {
    Flinks_Insitution_ID: 21,
    Institution_Name: 'Alterna Bank',
    Institution_URL: 'https://www.alternabank.ca/',
  },
  {
    Flinks_Insitution_ID: 22,
    Institution_Name: 'Alterna Savings',
    Institution_URL: 'https://www.alterna.ca/',
  },
  {
    Flinks_Insitution_ID: 23,
    Institution_Name: 'Servus Credit Union',
    Institution_URL: 'https://banking.servus.ca/Online/Retail',
  },
  {
    Flinks_Insitution_ID: 25,
    Institution_Name: 'Prospera Credit Union - Canada',
    Institution_URL: 'https://www.prospera.ca/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 26,
    Institution_Name: 'North Shore Credit Union',
    Institution_URL: 'https://www.nscu.com/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 27,
    Institution_Name: 'Manulife Bank of Canada',
    Institution_URL: 'https://client.manulifebank.com/MBCClientUI/',
  },
  {
    Flinks_Insitution_ID: 28,
    Institution_Name: 'Wealth One Bank of Canada',
    Institution_URL: 'https://www.wealthonebankofcanada.com/',
  },
  {
    Flinks_Insitution_ID: 30,
    Institution_Name: 'Envision Financial',
    Institution_URL: 'https://online.envisionfinancial.ca/',
  },
  {
    Flinks_Insitution_ID: 31,
    Institution_Name: 'Steinbach Credit Union (SCU)',
    Institution_URL: 'https://www.scudirect.ca/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 32,
    Institution_Name: 'BlueShore Financial Credit Union',
    Institution_URL: 'https://www.blueshorefinancial.com/',
  },
  {
    Flinks_Insitution_ID: 33,
    Institution_Name: 'UNI Financial Cooperation',
    Institution_URL:
      'https://accweb.mouv.acadie.com/identifiantunique/identification?langueCible=en&domaineVirtuel=acadie',
  },
  {
    Flinks_Insitution_ID: 34,
    Institution_Name: 'FirstOntario Credit Union',
    Institution_URL: 'https://banking.firstontario.com/',
  },
  {
    Flinks_Insitution_ID: 35,
    Institution_Name: 'Credit Union Heritage (Nova Scotia)',
    Institution_URL: 'https://www.heritagecu.ca/Personal/',
  },
  {
    Flinks_Insitution_ID: 36,
    Institution_Name: 'Peoples Trust Company',
    Institution_URL: 'https://www6.memberdirect.net/brand/bc_peoplestrust/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 37,
    Institution_Name: 'Amaranth Credit Union',
    Institution_URL: 'https://bank.amaranthcu.mb.ca/req/login/',
  },
  {
    Flinks_Insitution_ID: 38,
    Institution_Name: 'Assiniboine Credit Union',
    Institution_URL: 'https://www.assiniboine.mb.ca/',
  },
  {
    Flinks_Insitution_ID: 39,
    Institution_Name: 'Access Credit Union',
    Institution_URL: 'https://www.accesscu.ca/Personal/',
  },
  {
    Flinks_Insitution_ID: 41,
    Institution_Name: 'Beautiful Plains Credit Union',
    Institution_URL: 'https://www6.memberdirect.net/brand/celero_beautifulplains/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 42,
    Institution_Name: 'Belgian-Alliance Credit Union',
    Institution_URL: 'https://www.belgianalliancecu.mb.ca/',
  },
  {
    Flinks_Insitution_ID: 43,
    Institution_Name: 'Caisse Financial Group',
    Institution_URL: 'https://secure1.telpay.ca/cgi-bin/caisse',
  },
  {
    Flinks_Insitution_ID: 44,
    Institution_Name: 'Cambrian Credit Union',
    Institution_URL: 'https://online.cambrian.mb.ca/retail/',
  },
  {
    Flinks_Insitution_ID: 45,
    Institution_Name: 'Carpathia Credit Union',
    Institution_URL: 'https://www.carpathiacu.mb.ca/',
  },
  {
    Flinks_Insitution_ID: 46,
    Institution_Name: 'Casera Credit Union',
    Institution_URL: 'https://www.caseracu.ca/',
  },
  {
    Flinks_Insitution_ID: 47,
    Institution_Name: 'Compass Credit Union',
    Institution_URL: 'https://www6.memberdirect.net/brand/celero_crocus/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 48,
    Institution_Name: 'Crosstown Civic Credit Union',
    Institution_URL: 'https://www6.memberdirect.net/brand/mb_crosstowncivic/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 49,
    Institution_Name: 'Entegra Credit Union',
    Institution_URL: 'https://entegra.ca/',
  },
  {
    Flinks_Insitution_ID: 51,
    Institution_Name: 'Flinflon Credit Union',
    Institution_URL: 'https://www.ffcu.ca/Personal/',
  },
  {
    Flinks_Insitution_ID: 52,
    Institution_Name: 'Fusion Credit Union',
    Institution_URL: 'https://www6.memberdirect.net/brand/celero_vanguard/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 53,
    Institution_Name: 'Me-dian Credit Union',
    Institution_URL: 'https://www6.memberdirect.net/brand/celero_me-dian/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 54,
    Institution_Name: 'MCU (Sunrise Credit Union)',
    Institution_URL: 'https://www.minnedosacu.mb.ca/',
  },
  {
    Flinks_Insitution_ID: 55,
    Institution_Name: 'Niverville Credit Union',
    Institution_URL: 'https://www.nivervillecu.mb.ca/',
  },
  {
    Flinks_Insitution_ID: 56,
    Institution_Name: 'Noventis',
    Institution_URL: 'https://noventis.ca/',
  },
  {
    Flinks_Insitution_ID: 57,
    Institution_Name: 'RCU Rosenort Credit Union',
    Institution_URL: 'https://www6.memberdirect.net/brand/celero_rosenort/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 58,
    Institution_Name: 'Sunova',
    Institution_URL: 'https://online.sunovacu.ca/',
  },
  {
    Flinks_Insitution_ID: 59,
    Institution_Name: 'Stride Credit Union',
    Institution_URL: 'https://www.stridecu.ca/',
  },
  {
    Flinks_Insitution_ID: 61,
    Institution_Name: 'Swan Valley Credit Union',
    Institution_URL: 'https://www6.memberdirect.net/brand/celero_swanvalley/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 62,
    Institution_Name: 'Westoba Credit Union',
    Institution_URL: 'https://www6.memberdirect.net/brand/mb_westoba/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 63,
    Institution_Name: 'Winnipeg Police Credit Union',
    Institution_URL: 'https://wpcu.ca/',
  },
  {
    Flinks_Insitution_ID: 64,
    Institution_Name: 'AcceleRate Financial',
    Institution_URL: 'https://www6.memberdirect.net/brand/mb_crosstowncivic/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 65,
    Institution_Name: 'Achieva Credit Union',
    Institution_URL: 'https://online.cambrian.mb.ca/achievaretail',
  },
  {
    Flinks_Insitution_ID: 66,
    Institution_Name: 'Hubert Happy Savings',
    Institution_URL: 'https://online.happysavings.ca/',
  },
  {
    Flinks_Insitution_ID: 67,
    Institution_Name: 'Ideal Savings',
    Institution_URL: 'https://www.carpathiacu.mb.ca/Personal/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 68,
    Institution_Name: 'Implicity Financial',
    Institution_URL: 'https://www.implicity.ca/',
  },
  {
    Flinks_Insitution_ID: 69,
    Institution_Name: 'Maxa Financial',
    Institution_URL: 'https://www6.memberdirect.net/brand/mb_maxafinancial/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 70,
    Institution_Name: 'Outlook Financial',
    Institution_URL: 'https://www6.memberdirect.net/brand/celero_outlook/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 71,
    Institution_Name: 'Canadian Western Bank - CWBDirect Online Banking',
    Institution_URL: 'https://direct.cwbank.com/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 72,
    Institution_Name: 'Canadian Tire Bank - Mastercard',
    Institution_URL: 'https://www.ctfs.com/content/dash/en/public/login.html',
  },
  {
    Flinks_Insitution_ID: 73,
    Institution_Name: 'Icici Bank Canada',
    Institution_URL:
      'https://infinity.icicibank.ca/CARET/BANKAWAY?Action.RetUser.Init1.001=Y&AppSignonBankId=ICI86&AppType=corporate',
  },
  {
    Flinks_Insitution_ID: 74,
    Institution_Name: 'First Nations Bank',
    Institution_URL: 'https://www.fnbc.ca/Personal/',
  },
  {
    Flinks_Insitution_ID: 75,
    Institution_Name: 'Habib Canadian Bank',
    Institution_URL: 'https://sub.habibbank.com/CAN/hPLUS',
  },
  {
    Flinks_Insitution_ID: 76,
    Institution_Name: 'Capital One Canada',
    Institution_URL: 'https://verified.capitalone.com/auth/signin?Product=Card&CountryCode=CA&Locale_Pref=en_CA',
  },
  {
    Flinks_Insitution_ID: 77,
    Institution_Name: "President's Choice Financial",
    Institution_URL: 'https://secure.pcfinancial.ca/en/login',
  },
  {
    Flinks_Insitution_ID: 78,
    Institution_Name: 'First Commercial Bank',
    Institution_URL: 'https://www.firstcommercialbk.com/online-banking/',
  },
  {
    Flinks_Insitution_ID: 79,
    Institution_Name: 'Dundee Bank of Canada',
    Institution_URL: 'https://www.dundeebanking.com/',
  },
  {
    Flinks_Insitution_ID: 82,
    Institution_Name: 'Community Trust',
    Institution_URL: 'https://secure.communitytrust.ca/portcullis/ctc/gwwam500',
  },
  {
    Flinks_Insitution_ID: 84,
    Institution_Name: 'Rapport Credit Union',
    Institution_URL: 'https://www.rapportcu.ca/Personal/',
  },
  {
    Flinks_Insitution_ID: 85,
    Institution_Name: 'Mainstreet Credit Union',
    Institution_URL: 'https://banking.mainstreetcu.ca/',
  },
  {
    Flinks_Insitution_ID: 86,
    Institution_Name: 'Peace Hills Trust Business Banking',
    Institution_URL: 'https://business.memberdirect.net/business/default.jsp?inst=/bus/ab/peacehillstrust&app=Business',
  },
  {
    Flinks_Insitution_ID: 87,
    Institution_Name: 'Investors Group',
    Institution_URL: 'https://sso.investorsgroup.com/isam/sps/auth',
  },
  {
    Flinks_Insitution_ID: 88,
    Institution_Name: 'Sun Life Financial',
    Institution_URL: 'https://www.sunlife.ca/',
  },
  {
    Flinks_Insitution_ID: 89,
    Institution_Name: 'Agf Trust Company',
    Institution_URL: 'https://www.agf.com/t2scr/static/app/universalLogin/login.jsp?lang=en',
  },
  {
    Flinks_Insitution_ID: 90,
    Institution_Name: 'Duca Financial Credit Union',
    Institution_URL: 'https://www6.memberdirect.net/brand/on_duca/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 91,
    Institution_Name: 'Progressive Credit Union',
    Institution_URL: 'https://www.progressivecu.nb.ca/Home/',
  },
  {
    Flinks_Insitution_ID: 92,
    Institution_Name: 'Advance Savings Credit Union',
    Institution_URL: 'https://www.advancesavings.ca/Home/',
  },
  {
    Flinks_Insitution_ID: 93,
    Institution_Name: 'Beaubear Credit Union',
    Institution_URL: 'https://www.beaubear.ca/Home/',
  },
  {
    Flinks_Insitution_ID: 94,
    Institution_Name: 'Blackville Credit Union',
    Institution_URL: 'https://www6.memberdirect.net/brand/at_713/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 95,
    Institution_Name: 'NBTA Credit Union',
    Institution_URL: 'https://www6.memberdirect.net/brand/at_711/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 96,
    Institution_Name: 'Omista Credit Union',
    Institution_URL: 'https://www.omista.com/Home/',
  },
  {
    Flinks_Insitution_ID: 97,
    Institution_Name: 'The Credit Union',
    Institution_URL: 'https://www6.memberdirect.net/brand/at_708/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 98,
    Institution_Name: 'Community Credit Union',
    Institution_URL: 'https://www.ccunl.ca/Home/',
  },
  {
    Flinks_Insitution_ID: 99,
    Institution_Name: 'Eagle River Credit Union',
    Institution_URL: 'https://www.eaglerivercu.com/Home/',
  },
  {
    Flinks_Insitution_ID: 101,
    Institution_Name: 'Eastern Edge Credit Union',
    Institution_URL: 'https://www.easternedgecu.com/Home/',
  },
  {
    Flinks_Insitution_ID: 102,
    Institution_Name: 'Hamilton Sound Credit Union',
    Institution_URL: 'https://www.hscunl.ca/Home/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 103,
    Institution_Name: 'Leading Edge Credit Union',
    Institution_URL: 'https://www.lecu.ca/Home/',
  },
  {
    Flinks_Insitution_ID: 104,
    Institution_Name: 'Public Service Credit Union',
    Institution_URL: 'https://www.pscu.ca/Home/',
  },
  {
    Flinks_Insitution_ID: 105,
    Institution_Name: 'Reddy Kilowatt Credit Union',
    Institution_URL: 'https://www.reddyk.net/Home/',
  },
  {
    Flinks_Insitution_ID: 106,
    Institution_Name: 'Venture Online Credit Union',
    Institution_URL: 'https://www.venturecu.ca/Home/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 107,
    Institution_Name: 'Caisse Populaire Acadienne',
    Institution_URL: 'https://www.acadiancreditu.ca/Home/',
  },
  {
    Flinks_Insitution_ID: 108,
    Institution_Name: 'Acadian Credit Union',
    Institution_URL: 'https://www.acadiancreditu.ca/Home/',
  },
  {
    Flinks_Insitution_ID: 109,
    Institution_Name: 'East Coast Credit Union',
    Institution_URL: 'https://www.eastcoastcu.ca//Home/',
  },
  {
    Flinks_Insitution_ID: 110,
    Institution_Name: 'Clare Credit Union',
    Institution_URL: 'https://www.caissepopclare.com/Home/',
  },
  {
    Flinks_Insitution_ID: 111,
    Institution_Name: 'Cape Breton Credit Union',
    Institution_URL: 'https://www.cbcu.ca/Home/OnlineBanking/AboutOnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 112,
    Institution_Name: 'Coastal Financial Credit Union',
    Institution_URL: 'https://www.coastalfinancial.ca/Home/',
  },
  {
    Flinks_Insitution_ID: 113,
    Institution_Name: 'Community Credit Union Member Redirect',
    Institution_URL: 'https://www6.memberdirect.net/brand/at_335/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 114,
    Institution_Name: 'CUA Credit Union Atlantic',
    Institution_URL: 'https://www.cua.com/Home/',
  },
  {
    Flinks_Insitution_ID: 115,
    Institution_Name: 'Dominion Credit Union',
    Institution_URL: 'https://www6.memberdirect.net/brand/at_027/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 116,
    Institution_Name: 'Glace Bay Central Credit Union',
    Institution_URL: 'https://www.gbccu.ca/Home/',
  },
  {
    Flinks_Insitution_ID: 117,
    Institution_Name: 'iNova Credit Union',
    Institution_URL: 'https://www.inovacreditunion.coop/Home/',
  },
  {
    Flinks_Insitution_ID: 118,
    Institution_Name: 'Lahave River Credit Union',
    Institution_URL: 'https://www.lahaverivercreditunion.ca/Home/',
  },
  {
    Flinks_Insitution_ID: 119,
    Institution_Name: 'New Ross Credit Union',
    Institution_URL: 'https://www.newrosscreditunion.ca/Home/',
  },
  {
    Flinks_Insitution_ID: 121,
    Institution_Name: 'North Sydney Credit Union',
    Institution_URL: 'https://www.northsydneycreditunion.com/Home/',
  },
  {
    Flinks_Insitution_ID: 122,
    Institution_Name: 'Princess Credit Union',
    Institution_URL: 'https://www6.memberdirect.net/brand/at_359/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 123,
    Institution_Name: 'Provincial Government Employees Credit Union',
    Institution_URL: 'https://www.provincialemployees.com/Home/',
  },
  {
    Flinks_Insitution_ID: 124,
    Institution_Name: "St Joseph's Credit Union",
    Institution_URL: 'https://www.stjosephscreditu.ca/Home/',
  },
  {
    Flinks_Insitution_ID: 125,
    Institution_Name: 'Sydney Credit Union',
    Institution_URL: 'https://www.sydneycreditunion.com/Home/',
  },
  {
    Flinks_Insitution_ID: 126,
    Institution_Name: 'Valley Credit Union',
    Institution_URL: 'https://www.valleycreditunion.com/Home/',
  },
  {
    Flinks_Insitution_ID: 127,
    Institution_Name: 'Victory Credit Union',
    Institution_URL: 'https://www.victorycreditunion.ca/Home/',
  },
  {
    Flinks_Insitution_ID: 128,
    Institution_Name: 'Consolidated',
    Institution_URL: 'https://www.consolidatedcreditu.com/Home/',
  },
  {
    Flinks_Insitution_ID: 129,
    Institution_Name: 'Évangéline-Central Credit Union',
    Institution_URL: 'https://www.eccu.ca/Home/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 130,
    Institution_Name: 'Malpque Bay Credit Union',
    Institution_URL: 'https://www.malpequebaycreditu.com/Home/',
  },
  {
    Flinks_Insitution_ID: 131,
    Institution_Name: 'Morell Credit Union',
    Institution_URL: 'https://www.morellcreditu.com/Home/',
  },
  {
    Flinks_Insitution_ID: 132,
    Institution_Name: 'Provincial Credit Union',
    Institution_URL: 'https://www.provincialcu.com/Home/',
  },
  {
    Flinks_Insitution_ID: 133,
    Institution_Name: 'Souris Credit Union',
    Institution_URL: 'https://www.souriscreditu.com/Home/',
  },
  {
    Flinks_Insitution_ID: 134,
    Institution_Name: 'Tignish Credit Union',
    Institution_URL: 'https://www.tignishcreditu.com/Home/',
  },
  {
    Flinks_Insitution_ID: 135,
    Institution_Name: 'Concentra Financial',
    Institution_URL: 'https://www.concentra.ca/Pages/index.aspx#',
  },
  {
    Flinks_Insitution_ID: 136,
    Institution_Name: 'Golden Horseshoe Credit Union',
    Institution_URL: 'https://ghcu.ca/',
  },
  {
    Flinks_Insitution_ID: 137,
    Institution_Name: 'Saskcentral Credit Union Central of Saskatchewan',
    Institution_URL: 'https://www6.memberdirect.net/brand/sk_ceamscelero_saskcentral/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 138,
    Institution_Name: "Alliance Caisses Pop. de l'Ontario",
    Institution_URL:
      'https://accweb.mouv.desjardins.com/identifiantunique/identification?domaineVirtuel=acpol&langueCible=fr',
  },
  {
    Flinks_Insitution_ID: 139,
    Institution_Name: 'Airline Financial Credit Union',
    Institution_URL: 'https://www.airlinecreditunion.ca/',
  },
  {
    Flinks_Insitution_ID: 140,
    Institution_Name: 'St Stanislaus Credit Union',
    Institution_URL: 'https://www.polcu.com/Personal/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 141,
    Institution_Name: 'Community First Credit Union',
    Institution_URL: 'https://www.communityfirst-yncu.com/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 142,
    Institution_Name: 'Caisse Populaire Alliance',
    Institution_URL: 'https://caissealliance.mycardinfo.com/',
  },
  {
    Flinks_Insitution_ID: 143,
    Institution_Name: 'Dundalk District Credit Union',
    Institution_URL: 'https://www.dundalkcu.ca/',
  },
  {
    Flinks_Insitution_ID: 144,
    Institution_Name: 'Sunrise Credit Union',
    Institution_URL: 'https://www.sunrisecu.mb.ca/Personal/',
  },
  {
    Flinks_Insitution_ID: 145,
    Institution_Name: 'Affinity Credit Union Business Banking',
    Institution_URL: 'https://ebank.affinitycu.ca/affinity/business/',
  },
  {
    Flinks_Insitution_ID: 146,
    Institution_Name: 'Conexus Credit Union',
    Institution_URL: 'https://www.conexus.ca/Personal/',
  },
  {
    Flinks_Insitution_ID: 148,
    Institution_Name: 'Motusbank',
    Institution_URL: 'https://banking.motusbank.ca/Security_AutoLogin',
  },
  {
    Flinks_Insitution_ID: 149,
    Institution_Name: 'CCEC Credit Union',
    Institution_URL: 'https://www6.memberdirect.net/brand/bc_ccec/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 151,
    Institution_Name: 'First Calgary Financial',
    Institution_URL: 'https://my.firstcalgary.com/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 152,
    Institution_Name: 'Integris Credit Union',
    Institution_URL: 'https://my.integriscu.ca/',
  },
  {
    Flinks_Insitution_ID: 153,
    Institution_Name: 'Northern Credit Union',
    Institution_URL: 'https://onlinebanking.northerncu.com/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 154,
    Institution_Name: 'Libro Credit Union',
    Institution_URL: 'https://my.libro.ca/Login',
  },
  {
    Flinks_Insitution_ID: 162,
    Institution_Name: 'City Banamex',
    Institution_URL: 'https://bancanet.banamex.com/MXGCB/JPS/portal/LocaleSwitch.do?locale=es_MX&JFP_TOKEN=3TFSI6EB',
  },
  {
    Flinks_Insitution_ID: 163,
    Institution_Name: 'BBVA',
    Institution_URL: 'https://www.bbva.mx/',
  },
  {
    Flinks_Insitution_ID: 164,
    Institution_Name: 'Banco Azteca',
    Institution_URL: 'https://web.bancoazteca.com.mx/eBanking/securityLDAP/loginOptimizado.jsp',
  },
  {
    Flinks_Insitution_ID: 166,
    Institution_Name: '1st Choice Savings',
    Institution_URL: 'https://www.1stchoicesavings.ca/',
  },
  {
    Flinks_Insitution_ID: 167,
    Institution_Name: 'Bayview Credit Union',
    Institution_URL: 'https://www.bayviewnb.com/Home/',
  },
  {
    Flinks_Insitution_ID: 168,
    Institution_Name: 'Coastal Community Credit Union',
    Institution_URL: 'https://www.cccu.ca/Personal/',
  },
  {
    Flinks_Insitution_ID: 169,
    Institution_Name: 'Community Savings Credit Union',
    Institution_URL: 'https://www.comsavings.com/Personal/',
  },
  {
    Flinks_Insitution_ID: 171,
    Institution_Name: 'Copperfin Credit Union',
    Institution_URL: 'https://copperfin.ca/',
  },
  {
    Flinks_Insitution_ID: 172,
    Institution_Name: 'G&F Financial Group',
    Institution_URL: 'https://online.gffg.com/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 173,
    Institution_Name: 'Innovation Credit Union',
    Institution_URL: 'https://innovationcu.ca/Personal/',
  },
  {
    Flinks_Insitution_ID: 174,
    Institution_Name: 'Interior Savings Credit Union',
    Institution_URL: 'https://online.interiorsavings.com/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 175,
    Institution_Name: 'Island Savings Credit Union',
    Institution_URL: 'https://islandsavings.ca/Personal/',
  },
  {
    Flinks_Insitution_ID: 176,
    Institution_Name: 'Kawartha Credit Union',
    Institution_URL: 'https://www.kawarthacu.com/',
  },
  {
    Flinks_Insitution_ID: 177,
    Institution_Name: 'Khalsa Credit Union',
    Institution_URL: 'https://www6.memberdirect.net/brand/bc_khalsa/OnlineBanking/Accounts/',
  },
  {
    Flinks_Insitution_ID: 178,
    Institution_Name: 'Ladysmith & District Credit Union',
    Institution_URL: 'https://www.ldcu.ca/Personal/',
  },
  {
    Flinks_Insitution_ID: 179,
    Institution_Name: 'Lakeland Credit Union',
    Institution_URL: 'https://www.lakelandcreditunion.com/Personal/',
  },
  {
    Flinks_Insitution_ID: 181,
    Institution_Name: 'MountainView Credit Union',
    Institution_URL: 'https://my.mountainviewfinancial.com/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 182,
    Institution_Name: 'Newfoundland and Labrador Credit Union',
    Institution_URL: 'https://www.nlcu.com/Home/',
  },
  {
    Flinks_Insitution_ID: 183,
    Institution_Name: 'Northern Savings Credit Union',
    Institution_URL: 'https://www.northsave.com/',
  },
  {
    Flinks_Insitution_ID: 184,
    Institution_Name: 'Sudbury Credit Union',
    Institution_URL: 'https://sudburycu.com/',
  },
  {
    Flinks_Insitution_ID: 185,
    Institution_Name: 'Sunshine Coast Credit Union',
    Institution_URL: 'https://online.sunshineccu.com/',
  },
  {
    Flinks_Insitution_ID: 186,
    Institution_Name: 'Synergy Credit Union',
    Institution_URL: 'https://www.synergycu.ca/Personal/',
  },
  {
    Flinks_Insitution_ID: 187,
    Institution_Name: 'Ukrainian Credit Union',
    Institution_URL: 'https://ukrainiancu.com/UCU/',
  },
  {
    Flinks_Insitution_ID: 188,
    Institution_Name: 'Valley First Credit Union',
    Institution_URL: 'https://www.valleyfirst.com/Personal/',
  },
  {
    Flinks_Insitution_ID: 189,
    Institution_Name: 'Vision Credit Union',
    Institution_URL: 'https://www.visioncu.ca/',
  },
  {
    Flinks_Insitution_ID: 191,
    Institution_Name: 'Westminster Savings',
    Institution_URL: 'https://www.wscu.com/Personal/',
  },
  {
    Flinks_Insitution_ID: 192,
    Institution_Name: 'Windsor Family Credit Union',
    Institution_URL: 'https://www.wfcu.ca/Personal/',
  },
  {
    Flinks_Insitution_ID: 193,
    Institution_Name: 'Encompass Credit Union',
    Institution_URL: 'https://www.encompasscu.ca/Personal/',
  },
  {
    Flinks_Insitution_ID: 194,
    Institution_Name: 'RBC Express',
    Institution_URL: 'https://www6.rbc.com/webapp/ukv0/signin/logon.xhtml',
  },
  {
    Flinks_Insitution_ID: 195,
    Institution_Name: 'HSBC Corporate Account',
    Institution_URL: 'https://apply.business.hsbc.com/pws-cd/login.htm',
  },
  {
    Flinks_Insitution_ID: 196,
    Institution_Name: 'BMO Online Banking for Business',
    Institution_URL: 'https://www21.bmo.com/?eaiLocaleString=en',
  },
  {
    Flinks_Insitution_ID: 197,
    Institution_Name: 'First Credit Union',
    Institution_URL: 'https://www.firstcu.ca/Personal/',
  },
  {
    Flinks_Insitution_ID: 200,
    Institution_Name: 'CIBC SmartBanking™ for Business',
    Institution_URL: 'https://digitalbusiness.cibc.com/',
  },
  {
    Flinks_Insitution_ID: 201,
    Institution_Name: 'Coast Capital Business Banking',
    Institution_URL: 'https://business.memberdirect.net/business/default.jsp?inst=/bus/bc/coast/&app=Business',
  },
  {
    Flinks_Insitution_ID: 202,
    Institution_Name: 'HSBC Business HSBCnet',
    Institution_URL: 'https://www2.secure.hsbcnet.com/uims/portal/IDV_CAM10_AUTHENTICATION',
  },
  {
    Flinks_Insitution_ID: 204,
    Institution_Name: 'National Bank Business Banking',
    Institution_URL: 'https://commercial.bnc.ca/',
  },
  {
    Flinks_Insitution_ID: 205,
    Institution_Name: 'TD Commercial Banking',
    Institution_URL: 'https://businessbanking.tdcommercialbanking.com/WBB/LoginDisplay',
  },
  {
    Flinks_Insitution_ID: 206,
    Institution_Name: 'Canadian Western Bank - CWBDirect Business Online Banking',
    Institution_URL: 'https://business.memberdirect.net/business/default.jsp?inst=/bus/ab/cwbank/&app=B...',
  },
  {
    Flinks_Insitution_ID: 207,
    Institution_Name: 'Affinity Credit Union Personal Banking',
    Institution_URL: 'https://personal.affinitycu.ca/',
  },
  {
    Flinks_Insitution_ID: 208,
    Institution_Name: 'Peace Hills Trust Personal Banking',
    Institution_URL: 'https://online.peacehills.com/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 209,
    Institution_Name: 'Canadian Tire Bank - Savings',
    Institution_URL: 'https://www.myctfs.com/OnlineBanking/',
  },
  {
    Flinks_Insitution_ID: 211,
    Institution_Name: 'National Bank Business (SecurID passkey)',
    Institution_URL:
      'https://commercial.bnc.ca/auth/Login?GAURI=https%3A%2F%2Fcommercial.bnc.ca%2FSBIComWeb%2FLogonDisp...',
  },
  {
    Flinks_Insitution_ID: 212,
    Institution_Name: 'American Express Online Cards',
    Institution_URL: '',
  },
  {
    Flinks_Insitution_ID: 213,
    Institution_Name: 'Laurentienne LBC Digital',
    Institution_URL: '',
  },
  {
    Flinks_Insitution_ID: 214,
    Institution_Name: 'Meridian Credit Union - Commercial Banking',
    Institution_URL: '',
  },
  {
    Flinks_Insitution_ID: 215,
    Institution_Name: 'Servus Credit Union - Business Online Banking',
    Institution_URL: '',
  },
  {
    Flinks_Insitution_ID: 216,
    Institution_Name: 'Servus Credit Union - Commercial Net Banking',
    Institution_URL: '',
  },
  {
    Flinks_Insitution_ID: 217,
    Institution_Name: 'Vancity Online Business Plus',
    Institution_URL: '',
  },
  {
    Flinks_Insitution_ID: 219,
    Institution_Name: 'Titanium Prepaid Mastercard',
    Institution_URL: '',
  },
  {
    Flinks_Insitution_ID: 222,
    Institution_Name: 'KOHO',
    Institution_URL: '',
  },
  {
    Flinks_Insitution_ID: 223,
    Institution_Name: 'Neo Financial',
    Institution_URL: '',
  },
  {
    Flinks_Insitution_ID: 212930,
    Institution_Name: 'Vancity - Business Banking',
    Institution_URL: 'https://www.vancity.com/MyBusiness/BusinessBanking/BusinessAccounts/',
  },
  {
    Flinks_Insitution_ID: 217010,
    Institution_Name: 'Tangerine Business Banking',
    Institution_URL: 'https://secure.tangerine.ca/web/Tangerine.html?command=displayBusinessLoginRegular',
  },
  {
    Flinks_Insitution_ID: 229300,
    Institution_Name: 'Scotiabank Business - ScotiaConnect',
    Institution_URL: 'https://uas1.cams.scotiabank.com/aos/sco/login/scologin.bns',
  },
  {
    Flinks_Insitution_ID: 244660,
    Institution_Name: 'ATB Financial - Business',
    Institution_URL: 'https://business.atb.com/',
  },
  {
    Flinks_Insitution_ID: 250940,
    Institution_Name: 'Meridian Credit Union - Small Business Online Banking',
    Institution_URL: 'https://banking.meridiancu.ca/Security_SmallBusinessLogin',
  },
];
