import React, { useState } from "react";
// import useAuth from "../../hooks/useAuth";
// import api from "../../utils/api";
import ErrorNotification from "../ErrorNotification";
// import { parseIdFromLink } from "../../utils/helpers";
// import MxConnectModal from "./MxConnectModal";

export default function MxSettings() {
  // const auth = useAuth();
  // const userID = auth.user.id;

  const [dataPresent] = useState(null); //Legacy, was isSynced but has been moved to account item, patche code to still handle render logic, need to clean up
  const [accounts] = useState(null); //Array of insitutions.
  const [showError] = useState(true); //Generally due to API Error
  // const [showMxModal, setShowMxModal] = useState(false);

  window.history.pushState(
    { page: "accounts" },
    "Account Settings",
    "/client/settings/accounts"
  );

  //   useEffect(() => {
  //     const getMxAccounts = async () => {
  //       setShowError(false);

  //       try {
  //         const accountsByInstituteAndAccount = {};
  //         const rawMxLogins = await api.getUsersFlinksLoginData(userID);
  //         const mxLogins = rawMxLogins.data._embedded.mxLogins;
  //         const accountDataToFetch = [];
  //         mxLogins.forEach(async (institute) => {
  //           //Update Inst Name
  //           let instName = institute.institution || "Pending Account(s)";

  //           const instituteExist = !!accountsByInstituteAndAccount[institute];
  //           if (!instituteExist) {
  //             accountsByInstituteAndAccount[instName] = {};
  //           }
  //           const accountLink = institute._links.self.href;
  //           accountDataToFetch.push(accountLink);
  //         });

  //         //Fetch account Info
  //         const allAccountInfo = await Promise.all(
  //           accountDataToFetch.map((x) => api.axiosGet(x))
  //         );

  //         allAccountInfo.forEach((institute) => {
  //           const instData = institute.data;
  //           const instAccounts = instData._embedded.flinksAccounts; //array of accounts
  //           let instName = instData.institution || "Pending Account(s)";
  //           //   if (!isNaN(instName)) {
  //           //     /* Some flinks insitutions use a number, as the inst name.  Following searches a constant table for the appropriate name and reassigns the name */
  //           //     const instDetails = FLINKS_INSITUTION_IDS.find(
  //           //       (x) => x.Flinks_Insitution_ID === Number(instName)
  //           //     );
  //           //     if (instDetails) instName = instDetails.Institution_Name;
  //           //   }
  //           const accountLink = instData._links.self.href;

  //           //Parse Account Info
  //           instAccounts?.forEach((acctDetails) => {
  //             const acctNo = acctDetails.accountNumber;
  //             accountsByInstituteAndAccount[instName][acctNo] = acctDetails;
  //             accountsByInstituteAndAccount[instName][acctNo]["mxLoginID"] =
  //               parseIdFromLink(accountLink);
  //           });
  //         });
  //         setDataPresent(mxLogins.length > 0 ? true : false);
  //         setAccounts(mxLogins.length > 0 ? accountsByInstituteAndAccount : null);
  //       } catch (e) {
  //         // console.log('!!!!e', e);
  //         setShowError(true);
  //       }
  //     };
  //     getMxAccounts();
  //   }, [userID, forceRefresh]);

  const linkAccount = (
    <div>
      {dataPresent === false && <hr />}
      {dataPresent === false && (
        <h3 className="accounts-title">Link your account.</h3>
      )}
      {dataPresent === false && (
        <p className="muted">
          To earn cash-back and keep track of your loan, please link your bank
          account.
        </p>
      )}
      {/* <MxConnectModal
        userID={userID}
        setForceRefresh={setForceRefresh}
        showMxModal={showMxModal}
        setShowMxModal={setShowMxModal}
      /> */}
    </div>
  );

  const accountMsg = (isSync) => {
    if (accounts === null) return "No account(s) synced.";
    if (isSync === true) return "Account(s) working as expected.";
    if (isSync === false)
      return "There is an issue with one or more of your linked accounts.";
    return "Loading...";
  };

  return (
    <div id="userAccounts">
      <div className="titleArea">
        <h3 className="accounts-title">Linked Accounts</h3>
        {!showError && <p className="muted"> {accountMsg(dataPresent)}</p>}
      </div>
      {/* 3 options for p muted, null, synced, notSynced */}

      {/* ------------ Account Items */}
      {/* {!showError && dataPresent === true && parsedAccounts} */}
      {showError && (
        <ErrorNotification>Account data unavailable.</ErrorNotification>
      )}
      {linkAccount}
    </div>
  );
}
