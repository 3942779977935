import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import api from '../../../../utils/api';
import { formatDateForDisplay, parseIdFromLink } from '../../../../utils/helpers';

export default function QuizActivity({ userToView, index }) {
  const { id } = userToView;

  const [userData, setUserData] = useState({ mood: null, inspiration: null, quiz: null, cbRecords: null });
  useEffect(() => {
    const getUserActivity = async () => {
      //Fetch user quizAnswers /
      const resQuizAns = await api.getAllUsersQuizAnswers(id);
      const quizAnswers = resQuizAns.data._embedded.quizAnswers;

      const userQuizData = quizAnswers.map((answer) => {
        return {
          //Answer Info
          answer: answer.answer,
          dateAnswered: answer.createDateTime,
          //Quiz Info
          correctAnswer: answer.quiz.correctAnswer,
          availableDate: answer.quiz.availableDate,
          question: answer.quiz.question,
          quizID: parseIdFromLink(answer.quiz._links.self.href),
        };
      });

      setUserData({
        ...userData,
        quiz: userQuizData.sort((x, y) => y.availableDate.localeCompare(x.availableDate)),
      });
    };
    getUserActivity();
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <Accordion.Item eventKey={index}>
        <Accordion.Header>Quiz Answers - {userData?.quiz?.length ?? '0'}</Accordion.Header>
        <Accordion.Body>
          <p>
            <i>Missed, and unanswered quizzes are not shown</i>
          </p>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Quiz ID</th>
                <th>Quiz Available</th>
                <th>Date answered</th>
                <th>Question</th>
                <th>User's Answer</th>
                <th>Is Correct?</th>
              </tr>
            </thead>
            <tbody>
              {userData.quiz &&
                Object.values(userData.quiz)
                  // .sort((x, y) => y.availableDate.localeCompare(x.availableDate))
                  .map((q, i) => {
                    return (
                      <tr key={`userQuizData-${i}`}>
                        <td>{q?.quizID}</td>
                        <td>{formatDateForDisplay(q?.availableDate, { shortMonth: true })}</td>
                        <td>{formatDateForDisplay(q?.dateAnswered, { shortMonth: true })}</td>
                        <td>{q?.question}</td>
                        <td>{q?.answer}</td>
                        <td>{q?.answer === q?.correctAnswer ? '✔️' : '❌'}</td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
}
