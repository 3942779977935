import React from 'react';
// import api from '../../../utils/api';

import ApplicantGraph from '../../GraphComponet/ApplicantGraph';


// import $ from 'jquery';
import 'bootstrap-daterangepicker/daterangepicker.css';

export default function TreeViewer() {
  // var children1 = {
  //   "2023-04-18": {
  //     "Clearscore": {
  //       "Incomplete Pre-qualification": 2,
  //       "Total": 2
  //     },
  //     "CreditKarma": {
  //       "Incomplete Pre-qualification": 1,
  //       "Total": 1
  //     },
  //     "SmarterLoans": {
  //       "Incomplete Pre-qualification": 1,
  //       "Total": 3,
  //       "Knockout in pre-qualification": 2
  //     },
  //     "Others": {
  //       "Incomplete Pre-qualification": 7,
  //       "Total": 7
  //     }
  //   },
  //   "2023-04-17": {
  //     "Clearscore": {
  //       "Cancelled/Not Interested": 2,
  //       "Total": 12,
  //       "Knockout in pre-qualification": 4,
  //       "Rejected": 4,
  //       "WaitingForApproval": 1,
  //       "Waiting for Banking Verifications": 1
  //     },
  //     "CreditKarma": {
  //       "BankAccount_Verification": 1,
  //       "Total": 3,
  //       "Knockout in pre-qualification": 1,
  //       "Rejected": 1
  //     },
  //     "hs_automation": {
  //       "WaitingForApproval": 1,
  //       "Total": 1
  //     },
  //     "SmarterLoans": {
  //       "Knockout in pre-qualification": 1,
  //       "Total": 2,
  //       "Moved to Underwriting Queue": 1
  //     },
  //     "Others": {
  //       "BankAccount_Verification": 2,
  //       "Total": 14,
  //       "Cancelled/Not Interested": 1,
  //       "Incomplete Pre-qualification": 1,
  //       "Knockout in pre-qualification": 5,
  //       "Moved to TKL": 1,
  //       "Rejected": 3,
  //       "Spam": 1
  //     }
  //   },
  //   "2023-04-16": {
  //     "Clearscore": {
  //       "BankAccount_Verification": 1,
  //       "Total": 4,
  //       "Incomplete Pre-qualification": 1,
  //       "Rejected": 2
  //     },
  //     "CreditKarma": {
  //       "Rejected": 2,
  //       "Total": 2
  //     },
  //     "SmarterLoans": {
  //       "Knockout in pre-qualification": 2,
  //       "Total": 2
  //     },
  //     "Others": {
  //       "Knockout in pre-qualification": 1,
  //       "Total": 3,
  //       "Rejected": 2
  //     }
  //   },
  //   "2023-04-15": {
  //     "Borrowell": {
  //       "BankAccount_Verification": 1,
  //       "Total": 1
  //     },
  //     "Clearscore": {
  //       "Auto Rejected By TKL": 1,
  //       "Total": 6,
  //       "Closed_Canceled": 1,
  //       "Incomplete Pre-qualification": 1,
  //       "Rejected": 2,
  //       "WaitingForApproval": 1
  //     },
  //     "SmarterLoans": {
  //       "Knockout in pre-qualification": 3,
  //       "Total": 4,
  //       "Rejected": 1
  //     },
  //     "Others": {
  //       "BankAccount_Verification": 2,
  //       "Total": 5,
  //       "Incomplete Pre-qualification": 1,
  //       "Knockout in pre-qualification": 1,
  //       "Rejected": 1
  //     }
  //   },
  //   "2023-04-14": {
  //     "Clearscore": {
  //       "Auto Rejected By TKL": 1,
  //       "Total": 7,
  //       "BankAccount_Verification": 1,
  //       "Cancelled/Not Interested": 1,
  //       "Incomplete Pre-qualification": 1,
  //       "Rejected": 2,
  //       "Waiting for Banking Verifications": 1
  //     },
  //     "CreditKarma": {
  //       "Auto Rejected By TKL": 2,
  //       "Total": 4,
  //       "Knockout in pre-qualification": 1,
  //       "WaitingForApproval": 1
  //     },
  //     "GoPeer": {
  //       "Moved to TKL": 1,
  //       "Total": 2,
  //       "Rejected": 1
  //     },
  //     "SmarterLoans": {
  //       "Knockout in pre-qualification": 4,
  //       "Total": 4
  //     },
  //     "Others": {
  //       "Cancelled/Not Interested": 1,
  //       "Total": 8,
  //       "Closed_Canceled": 1,
  //       "Incomplete Pre-qualification": 1,
  //       "Knockout in pre-qualification": 1,
  //       "Rejected": 4
  //     }
  //   },
  //   "2023-04-13": {
  //     "borrowell": {
  //       "Closed_Canceled": 1,
  //       "Total": 2,
  //       "Rejected": 1
  //     },
  //     "Clearscore": {
  //       "BankAccount_Verification": 1,
  //       "Total": 4,
  //       "Closed_Canceled": 1,
  //       "Incomplete Pre-qualification": 1,
  //       "Knockout in pre-qualification": 1
  //     },
  //     "CreditKarma": {
  //       "Incomplete Pre-qualification": 1,
  //       "Total": 1
  //     },
  //     "hellosafe": {
  //       "Incomplete Pre-qualification": 1,
  //       "Total": 1
  //     },
  //     "SmarterLoans": {
  //       "Rejected": 1,
  //       "Total": 1
  //     },
  //     "Others": {
  //       "Auto Rejected By TKL": 2,
  //       "Total": 9,
  //       "BankAccount_Verification": 1,
  //       "Incomplete Pre-qualification": 1,
  //       "Knockout in pre-qualification": 1,
  //       "Rejected": 4
  //     }
  //   },
  //   "2023-04-12": {
  //     "Clearscore": {
  //       "Auto Rejected By TKL": 1,
  //       "Total": 9,
  //       "BankAccount_Verification": 1,
  //       "Closed_Canceled": 2,
  //       "Incomplete Pre-qualification": 1,
  //       "Knockout in pre-qualification": 3,
  //       "WaitingForApproval": 1
  //     },
  //     "hellosafe": {
  //       "Knockout in pre-qualification": 1,
  //       "Total": 1
  //     },
  //     "SmarterLoans": {
  //       "Closed_Canceled": 1,
  //       "Total": 6,
  //       "Knockout in pre-qualification": 4,
  //       "Rejected": 1
  //     },
  //     "Others": {
  //       "Cancelled in TKL": 2,
  //       "Total": 16,
  //       "Closed_Canceled": 1,
  //       "Incomplete Pre-qualification": 5,
  //       "Knockout in pre-qualification": 3,
  //       "Rejected": 2,
  //       "WaitingForApproval": 2,
  //       "Waiting for Banking Verifications": 1
  //     }
  //   }
  // }
  return (
    <>
      {/* <ApplicantGraph data={children1} /> */}
      {/* <div style={{ paddingTop: '18rem' }}> */}
        <ApplicantGraph />
      {/* </div> */}
    </>
  );
}
