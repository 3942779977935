const ficoReasons = {
  '01': {
    code: '01',
    statement: 'Amount owed on accounts is too high',
    description:
      'FICO® Scores consider how much a person owes on credit accounts, such as credit cards and non-mortgage loans. Your score was impacted because amounts owed on accounts on your credit report are too high.',
    helper:
      'Keep in mind: In general, people who pay off their current debts and keep low balances appear less risky to lenders. However, consolidating or moving debt from one account to another will usually not help since the total amount owed remains the same.',
  },
  '02': {
    code: '02',
    statement: 'Delinquency on accounts',
    description:
      'Missed and late payments, including the number of late payments, how late they were, and how recently they occurred, are important to FICO® Scores. Your score was impacted by missed payments, or you had a serious derogatory indicator, such as a bankruptcy. ',
    helper:
      "Keep in mind: It's important to pay bills on time. Generally, people who remain current on payments appear less risky to lenders.",
  },
  '03': {
    code: '03',
    statement: 'Proportion of loan balances to loan amounts is too high',
    description:
      'FICO® Scores weigh the balances of mortgage and non-mortgage installment loans (such as auto or student loans) against the original loan amounts shown on a person®s credit report. Your score was impacted because your proportion of installment loan balances to the original loan amounts is too high.',
    helper:
      'Keep in mind: In general, when a person first obtains an installment loan, the balance is high. A person with a loan balance that is high in relation to the original loan amount tends to be viewed as more risky to lenders.',
  },
  '04': {
    code: '04',
    statement: 'Lack of recent installment loan information',
    description:
      'FICO® Scores consider recent non-mortgage installment loan (such as auto or student loans) information on a person®s credit report. Your score was impacted because your credit report shows no recent non-mortgage installment loans or insufficient recent information about your loans.',
    helper:
      'Keep in mind: In general, people who purchase with an installment loan and pay back the loan on time tend to demonstrate the ability to manage a variety of credit types. However, a new account opening, and to a lesser extent the inquiry associated with applying for a new account, may demonstrate higher risk in the short term.',
  },
  '05': {
    code: '05',
    statement: 'Too many accounts with balances',
    description:
      'FICO® Scores consider the total number of accounts a consumer holds with balances, including credit card balance amounts that appear from the most recent account statements®even if the balance was paid off. Your score was impacted by having too many accounts with balances. ',
    helper:
      'Keep in mind: Generally, people who limit the number of accounts they have with balances appear less risky to lenders. ',
  },
  '06': {
    code: '06',
    statement: 'Too many consumer finance company accounts',
    description:
      'FICO® Scores consider whether a person has any consumer finance company loans on their credit report. Your credit report shows one or more consumer finance company loans, which impacted your score.',
    helper:
      'Keep in mind: Consumer finance companies typically assume more risk by lending to people with less than perfect credit. The fact that a person has a consumer finance company loan on their credit report means that they generally represent a higher risk to lenders than someone with no consumer finance company loans.',
  },
  '07': {
    code: '07',
    statement: 'Account payment history is too new to rate',
    description:
      'FICO® Scores consider the extent of credit account payment information on a person®s credit report as a factor to determine if they are a responsible borrower. Your score was impacted because none of the credit accounts on your credit report contain enough payment information. ',
    helper:
      'Keep in mind: People without enough payment information or an established history for credit accounts on their credit report tend to appear more risky to lenders.',
  },
  '08': {
    code: '08',
    statement: 'Too many inquiries last 12 months',
    description:
      'FICO® Scores look at the number of times a person applies for credit, since people who are actively seeking credit tend to pose more of a risk to lenders than those who are not. Your FICO® Score was impacted due to the number of applications for credit, or credit inquiries, within the last 12 months.',
    helper:
      'Keep in mind: Typically, the presence of inquiries on a credit report has only a small impact, carrying much less importance than late payments, the amount owed, and the length of time a person has used credit. FICO® Scores consider recent inquiries less as time passes, provided no new inquiries are added.',
  },
  '09': {
    code: '09',
    statement: 'Too many accounts recently opened',
    description:
      'FICO® Scores consider the number of recent credit account openings. Your FICO® Score was impacted because of too many recent credit account openings.',
    helper:
      'Keep in mind: Opening several credit accounts in a short time period is generally reflective of greater risk ® especially for people with a short credit history.',
  },
  10: {
    code: '10',
    statement:
      'Proportion of balances to credit limits is too high on bank/national, LOC, or other revolving accounts ',
    description:
      'As one of the most important score factors, FICO® Scores evaluate account balances in relation to available credit on revolving, open-ended and/or line-of-credit (LOC) accounts. Your score was impacted because your proportion of balances to credit limits on these accounts is too high.',
    helper:
      'Keep in mind: People who pay revolving and/or open-ended account balances, such as credit cards, as agreed tend to show responsible credit behavior to lenders. However, consolidating or moving debt from one account to another will usually not help since the total amount owed remains the same.',
  },
  11: {
    code: '11',
    statement: 'Amount owed on revolving accounts is too high',
    description:
      'FICO® Scores evaluate how much is owed on revolving and/or open-ended accounts, such as credit cards. Your score was impacted because of the amount you owe on these accounts.',
    helper:
      'Keep in mind: People with lower balances on revolving and/or open-ended accounts generally demonstrate lower risk to lenders. Also, consolidating or moving your debt from one account to another will usually not help since the total amount owed remains the same.',
  },
  12: {
    code: '12',
    statement: 'Length of time revolving accounts have been established',
    description:
      'FICO® Scores consider the age of a person®s oldest revolving and/or open-ended account, and/or the average age of these accounts. Your score was impacted by the relatively low age of your oldest revolving and/or open-ended account, and/or the average age of these accounts.',
    helper:
      'Keep in mind: People who do not frequently open new accounts and have longer credit histories generally pose less risk to lenders.',
  },
  13: {
    code: '13',
    statement: 'Time since delinquency is too recent or unknown',
    description:
      'Missed and late payments, including the number of late payments, how late they were, and how recently they occurred, are important to FICO® Scores. Your score was impacted because the time since your most recent missed payment, or other serious derogatory indicator, such as a bankruptcy, was too recent. ',
    helper:
      'Keep in mind: In general, people who continually pay their bills on time and demonstrate a good payment history tend to appear less risky to lenders.',
  },
  14: {
    code: '14',
    statement: 'Length of time accounts have been established',
    description:
      'FICO® Scores consider the age of a person®s oldest account and/or the average age of accounts. Your score was impacted by the relatively low age of your oldest account and/or the average age of your accounts.',
    helper:
      'Keep in mind: People who do not frequently open new accounts and have longer credit histories generally pose less risk to lenders.',
  },
  15: {
    code: '15',
    statement: 'Lack of recent bank/national revolving information',
    description:
      'FICO® Scores evaluate a person®s mix of credit cards, loans, and mortgages, and whether a person®s credit report shows open credit cards and/or open-ended accounts, or sufficient recent information about these accounts. Your score was impacted by too little recent credit card and/or open-ended account information on your credit report.',
    helper:
      'Keep in mind: People who demonstrate responsible use of different types of credit generally appear less risky to lenders. However, opening a new card and/or open-ended account, and to a lesser extent the inquiry associated with applying for a new credit account, may demonstrate higher risk in the short term.',
  },
  16: {
    code: '16',
    statement: 'Lack of recent revolving account information',
    description:
      'FICO® Scores evaluate a person®s mix of credit products, and whether a person®s credit report shows open revolving and/or open-ended accounts or sufficient recent information about these accounts. Your score was impacted by too little recent revolving and/or open-ended account information on your credit report.',
    helper:
      'Keep in mind: People who demonstrate responsible use of different types of credit generally appear less risky to lenders. However, opening a new revolving and/or open-ended account, and to a lesser extent the inquiry associated with applying for a new account, may demonstrate higher risk in the short term.',
  },
  17: {
    code: '17',
    statement: 'No recent non-mortgage balance information',
    description:
      'FICO® Scores consider whether a person®s credit report shows open or recently reported accounts, other than a mortgage. Your score was impacted by not having recent information on these accounts appearing on your credit report.',
    helper:
      'Keep in mind: People who demonstrate responsible use of different types of credit generally appear less risky to lenders. However, keep in mind that opening a new account, and to a lesser extent the inquiry associated with applying for one, may demonstrate higher risk in the short term.',
  },
  18: {
    code: '18',
    statement: 'Number of accounts with delinquency',
    description:
      'Missed and late payments, including the number of late payments, how late they were, and how recently they occurred, are important to FICO® Scores. Your score was impacted because your credit report shows one or more accounts with missed payments or you had some other serious derogatory indicator, such as a bankruptcy.',
    helper:
      'Keep in mind: It®s important to pay bills on time. Generally, people who remain current on payments appear less risky to lenders.',
  },
  19: {
    code: '19',
    statement: 'Date of last inquiry too recent',
    description:
      'FICO® Scores look at the length of time since a person last made an inquiry for credit, since people who are actively seeking credit tend to pose more of a risk to lenders than those who are not. Your FICO® Score was impacted by recent credit inquiries. ',
    helper:
      'Keep in mind: Typically, the presence of inquiries on a credit report has only a small impact, carrying much less importance than late payments, the amount owed, and the length of time a person has used credit. FICO® Scores consider recent inquiries less as time passes, provided no new inquiries are added.',
  },
  20: {
    code: '20',
    statement: 'Time since derogatory public record or collection is too short',
    description:
      'FICO® Scores consider the recency of a derogatory public record (such as a bankruptcy or tax lien) or collection on a person®s credit report as a powerful predictor of future payment risk. Your score was impacted by the length of time since a public record or collection.',
    helper:
      'Keep in mind: Most public records and collections stay on a person®s report for many years, depending upon the type of action and province of residency. However, as the item ages, its impact will gradually decrease.',
  },
  21: {
    code: '21',
    statement: 'Amount past due on accounts',
    description:
      'FICO® Scores consider payments past due on accounts. Your score was impacted by the amounts past due on accounts.',
    helper:
      'Keep in mind: People who stay caught up on amounts due and continue to pay their bills on time are generally viewed as less risky to lenders. Generally, the greater amount that is past due, the greater the risk appears to lenders.',
  },
  24: {
    code: '24',
    statement: 'No recent revolving balances',
    description:
      'FICO® Scores consider whether a person®s credit report shows recent balances on revolving and/or open-ended accounts. Your FICO® Score was impacted because you are not currently demonstrating active revolving and/or open-ended credit management.',
    helper:
      'Keep in mind: People who show moderate and conscientious use of revolving and/or open-ended accounts, such as having low balances and paying them on time, generally demonstrate responsible financial behavior. People without recent credit activity on these accounts tend to be viewed as higher risk by lenders.',
  },
  26: {
    code: '26',
    statement: 'Too many bank/national revolving accounts',
    description:
      'FICO® Scores consider the total number of credit card and/or open-ended accounts a person has on their credit report. Your score was impacted by having too many credit card accounts.',
    helper:
      'Keep in mind: Consumers with a moderate number of revolving and/or open-ended accounts on their credit report generally represent lower risk than consumers with either a relatively large number or a limited number of these accounts.',
  },
  27: {
    code: '27',
    statement: 'Too few accounts currently paid as agreed',
    description:
      "FICO® Scores consider the number of accounts that are paid as agreed. Your score was impacted because the number of these accounts is too low, or because you've missed payments recently on some of your accounts.",
    helper:
      'Keep in mind: In general, people that have few accounts paid as agreed or have missed payments recently on some of their accounts tend to appear more risky to lenders.',
  },
  28: {
    code: '28',
    statement: 'Too many established accounts',
    description:
      'FICO® Scores look at the total number of accounts on a person®s credit report. Your score was impacted because you have a relatively high number of accounts on your credit report.',
    helper:
      'Keep in mind:  People who responsibly maintain a moderate number of open accounts®rather than a relatively high number of accounts®tend to demonstrate lower risk to lenders.',
  },
  29: {
    code: '29',
    statement: 'No recent bank/national revolving balances',
    description:
      'FICO® Scores evaluate recent balances on the credit card and/or open-ended accounts shown on a person®s credit report. Your FICO® Score was impacted because you are not currently demonstrating active credit card and/or open-ended credit management.',
    helper:
      'Keep this in mind:People who demonstrate moderate and conscientious use of revolving credit card and/or open-ended accounts, such as maintaining low balances and paying them on time, tend to demonstrate lower risk to lenders.',
  },
  30: {
    code: '30',
    statement: 'Time since most recent account opening is too short',
    description:
      'FICO® Scores consider how recently a person opened a new credit account as shown on their credit report. Your score was impacted because of the time since you opened a new account.',
    helper: 'Keep in mind: People who recently opened a new credit account tend to be viewed as more risky to lenders.',
  },
  31: {
    code: '31',
    statement: 'Amount owed on delinquent accounts',
    description:
      'Late payments are generally a very powerful predictor of future payment risk, and FICO® Scores consider the balances on past-due accounts on a person®s credit report. Your score was impacted by the relatively high amount you owe on past-due accounts or accounts with some other serious derogatory indicator, such as a bankruptcy.',
    helper:
      'Keep in mind: The higher the balances on past-due accounts on a person®s credit report, generally the greater the risk appears to lenders.',
  },
  35: {
    code: '35',
    statement: 'Too many bank/national revolving accounts with balances',
    description:
      'FICO® Scores consider the total number of credit card and/or open-ended accounts a consumer holds with balances, including balance amounts that appear from the most recent account statements®even if the balance was paid off. Your score was impacted by having too many accounts with balances.',
    helper:
      'Keep in mind: Generally, people who limit the number of credit card and/or open-ended accounts they have with balances appear less risky to lenders.',
  },
  36: {
    code: '36',
    statement: 'Payments due on accounts ',
    description:
      'FICO® Scores consider payment amounts on accounts. Your score was impacted by the payment amounts on your accounts.',
    helper:
      'Keep in mind: In general, people who keep low balances appear less risky to lenders. However, consolidating or moving debt from one account to another will usually not help since the total amount owed remains the same.',
  },
  37: {
    code: '37',
    statement: 'Lack of recent LOC revolving account information',
    description:
      'FICO® Scores evaluate a person®s mix of credit products, and whether a person®s credit report shows open revolving lines of credit (LOC) or sufficient recent information about revolving LOC accounts. Your score was impacted by too little recent revolving LOC information on your credit report.',
    helper:
      'Keep in mind: People who demonstrate responsible use of different types of credit generally appear less risky to lenders. However, opening a new revolving LOC account, and to a lesser extent the inquiry associated with applying for a new account, may demonstrate higher risk in the short term.',
  },
  38: {
    code: '38',
    statement: 'Serious delinquency, and public record or collection filed',
    description:
      'FICO® Scores consider the presence of both a public record or collection and a serious delinquency on a person®s credit report as a powerful predictor of future payment risk. Your score was impacted because your credit report shows a public record or collection in addition to a delinquency, or you had some other serious derogatory indicator, such as a bankruptcy.',
    helper:
      'Keep in mind: Most collections, public records and delinquencies stay on a person®s credit report for many years, depending upon the type of action and province of residency. However, as an item ages, its impact will gradually decrease.',
  },
  39: {
    code: '39',
    statement: 'Serious delinquency',
    description:
      'FICO® Scores consider the presence of a serious delinquency (very late payment) on a person®s credit report as a powerful predictor of future payment risk. Your score was impacted because your credit report shows one or more serious delinquencies, or you had some other serious derogatory indicator, such as a bankruptcy.',
    helper:
      'Keep in mind: People with previous late payments are more likely to pay late in the future and tend to be viewed as risky to lenders. Most late payments stay on a person®s credit report for up to six years.',
  },
  40: {
    code: '40',
    statement: 'Derogatory public record or collection filed',
    description:
      'FICO® Scores consider the presence of a derogatory public record (such as a bankruptcy or tax lien) or collection on a person®s credit report as a powerful predictor of future payment risk. Your score was impacted because your report shows one or more public records or collections.',
    helper:
      'Keep in mind: People with a public record or collection on their credit report tend to appear more risky to lenders. Most public records and collections stay on a person®s credit report for many years, depending upon the type of action and province of residency.',
  },
  41: {
    code: '41',
    statement: 'No recent retail balances',
    description:
      'FICO® Scores evaluate the mix of credit products on a person®s credit report. Your score was impacted because there are no recent retail account balances on your credit report.',
    helper:
      'Keep in mind: People who demonstrate moderate and conscientious use of retail accounts, such as maintaining low balances and paying them on time, tend to demonstrate lower risk to lenders.',
  },
  42: {
    code: '42',
    statement: 'Time since most recent consumer finance company account opening is too short',
    description:
      'FICO® Scores consider how recently a person opened a new consumer finance company account as shown on their credit report. Your score was impacted because of the time since you opened a new finance company account.',
    helper:
      'Keep in mind: Consumer finance companies typically assume more risk by lending to people with less than perfect credit and people who recently opened a new consumer finance company account tend to be viewed as more risky to lenders.',
  },
  43: {
    code: '43',
    statement: 'Too few recently active accounts',
    description:
      'FICO® Scores consider the number of accounts which a person is actively using and paying as agreed as shown on their credit report. Your score was impacted by having few accounts or not using your accounts recently.',
    helper:
      'Keep in mind: People with few accounts or that have not used credit accounts recently generally appear more risky to lenders. However, be aware that a new account opening and to a lesser extent the inquiry associated with applying for a new account, may demonstrate higher risk in the short term.',
  },
  45: {
    code: '45',
    statement: 'Length of time bank/national revolving accounts have been established',
    description:
      'FICO® Scores consider the frequency of new credit card and/or open-ended account openings and the length of time credit cards and/or open-ended accounts have been open on a person®s credit report. Your score was impacted by the relatively low age of your oldest credit card and/or open-ended account and/or the relatively low average age of these accounts.',
    helper:
      'Keep in mind: People who frequently open new accounts and have shorter credit histories generally are considered higher risk to lenders.',
  },
  46: {
    code: '46',
    statement: 'Lack of recent non-LOC revolving account information',
    description:
      'FICO® Scores evaluate a person®s mix of credit products, and whether a person®s credit report shows open non-line of credit (LOC) revolving and/or open-ended accounts, or sufficient recent information about those accounts. Your score was impacted by too little recent information about these types of accounts on your credit report.',
    helper:
      'Keep in mind: People who demonstrate responsible use of different types of credit generally appear less risky to lenders. However, opening a new non-LOC revolving and/or open-ended account and to a lesser extent the inquiry associated with applying for a new account, may demonstrate higher risk in the short term.',
  },
  48: {
    code: '48',
    statement: 'Number of accounts with recent delinquency',
    description:
      'Missed and late payments, including the number of late payments, how late they were, and how recently they occurred, are important to FICO® Scores. Your score was impacted because your credit report shows accounts with recently missed payments, or you had some other serious derogatory indicator, such as a bankruptcy.',
    helper:
      'Keep in mind: It®s important to pay bills on time. Generally, people who remain current on payments appear less risky to lenders.',
  },
  49: {
    code: '49',
    statement: 'Time since account activity is too long',
    description:
      'FICO® Scores evaluate whether a person®s credit report shows recent information about credit accounts. Your score was impacted by too little recent account activity information on your credit report.',
    helper: 'Keep in mind: People who demonstrate responsible use of credit generally appear less risky to lenders.',
  },
  50: {
    code: '50',
    statement: 'Lack of recent retail account information',
    description:
      'FICO® Scores evaluate the mix of credit products and related activity on a person®s credit report. Your score was impacted by a lack of recent retail account information on your credit report.',
    helper:
      'Keep in mind: People who demonstrate responsible use of different types of credit are generally viewed as less risky by lenders. However, a new account opening, and to a lesser extent the inquiry associated with applying for a new account, may demonstrate higher risk in the short term.',
  },
  51: {
    code: '51',
    statement: 'Amount owed on personal loans or other installment loans is high',
    description:
      'FICO® Scores evaluate how much is owed on personal loans or other installment loans. Your score was impacted because of the amount you owe on these accounts.',
    helper:
      'Keep in mind: People with lower balances on personal loans or other installment loans generally demonstrate lower risk to lenders. Also, consolidating or moving debt from one account to another will usually not help since the total amount owed remains the same.',
  },
  52: {
    code: '52',
    statement: 'Amount paid down on recently active installment loans is low',
    description:
      'FICO® Scores consider how much a person owes on their recently active installment loans relative to the original loan amounts. Your score was impacted because of the relatively high amount owed on these installment loans in relation to their original loan amounts.',
    helper:
      'Keep in mind: Generally, the less paid down on recently active installment loans, the greater the risk posed to lenders. Also, consolidating or moving debt from one account to another will usually not help since the total amount owed remains the same. Note, having an active loan that is substantially paid down is generally viewed as less risky than having no active loans.',
  },
  53: {
    code: '53',
    statement: 'Length of time LOC revolving accounts have been established',
    description:
      'FICO® Scores consider the age of a person®s oldest LOC revolving account, and/or the average age of these accounts. Your score was impacted by the relatively low age of your oldest LOC revolving account, and/or the average age of these accounts.',
    helper:
      'Keep in mind: People who do not frequently open new accounts and have longer credit histories generally pose less risk to lenders.',
  },
  54: {
    code: '54',
    statement: 'Too many inquiries',
    description:
      'FICO® Scores look at the number of times a person applies for credit, since people who are actively seeking credit tend to pose more of a risk to lenders than those who are not. Your FICO® Score was impacted due to the number of applications for credit, or credit inquiries, within the last 12 months.',
    helper:
      'Keep in mind: Typically, the presence of inquiries on a credit report has only a small impact, carrying much less importance than late payments, the amount owed, and the length of time a person has used credit. FICO® Scores consider recent inquiries less as time passes, provided no new inquiries are added.',
  },
  56: {
    code: '56',
    statement: 'Amount owed on retail accounts',
    description:
      'FICO® Scores evaluate how much is owed on retail accounts. Your score was impacted because of the amount you owe on these accounts.',
    helper:
      'Keep in mind: People with lower balances on retail accounts generally appear to demonstrate lower risk to lenders. Also, consolidating or moving debt from one account to another will usually not help since the total amount owed remains the same.',
  },
  63: {
    code: '63',
    statement: 'No recent non-LOC bank/national revolving balances',
    description:
      'FICO® Scores evaluate recent balances on the non-line of credit (LOC) credit card accounts shown on a person®s credit report. Your FICO® Score was impacted because you are not currently demonstrating active non-LOC credit card credit management.',
    helper:
      'Keep this in mind: People who demonstrate moderate and conscientious use of non-LOC revolving credit card and/or open-ended accounts, such as maintaining low balances and paying them on time, tend to demonstrate lower risk to lenders.',
  },
  66: {
    code: '66',
    statement: 'Amount owed on bank/national revolving accounts',
    description:
      'FICO® Scores evaluate how much is owed on credit card and/or open-ended accounts. Your score was impacted because of the amount you owe on credit card accounts.',
    helper:
      'Keep in mind: People with lower balances on credit card and/or open-ended accounts generally demonstrate lower risk to lenders. Also, consolidating or moving your debt from one account to another will usually not help since the total amount owed remains the same.',
  },
  72: {
    code: '72',
    statement: 'Accounts last reported in delinquent status',
    description:
      'FICO® Scores consider the presence of a delinquency (late payment) on a person®s credit report as a powerful predictor of future payment risk. Your score was impacted because your credit report shows one or more delinquencies, or you had some other serious derogatory indicator, such as a bankruptcy.',
    helper:
      'Keep in mind: People with previous late payments are more likely to pay late in the future and tend to be viewed as risky to lenders. Most late payments stay on a person®s credit report for up to seven years.',
  },
  73: {
    code: '73',
    statement: 'Time since most recent bank/national revolving account opening is too short',
    description:
      'FICO® Scores consider how recently a person opened a new credit card and/or open-ended account as shown on their credit report. Your score was impacted because of the time since you opened a new credit card account.',
    helper:
      'Keep in mind: People who recently opened a new credit card and/or open-ended account tend to be viewed as more risky to lenders.',
  },
  75: {
    code: '75',
    statement: 'Time since most recent installment loan account opening is too short',
    description:
      'FICO® Scores consider how recently a person opened a new installment loan account as shown on their credit report. Your score was impacted because of the time since you opened a new installment loan account.',
    helper:
      'Keep in mind: People who recently opened a new installment loan account tend to be viewed as more risky to lenders.',
  },
  76: {
    code: '76',
    statement: 'Frequency of delinquency',
    description:
      'Missed and late payments, including the number of late payments, how late they were, and how recently they occurred, are important to FICO® Scores. Your score was impacted by the number of late payments or you had some other serious derogatory indicator, such as a bankruptcy.',
    helper:
      'Keep in mind: In general, people who continually pay their bills on time and demonstrate a good payment history tend to appear less risky to lenders.',
  },
  77: {
    code: '77',
    statement: 'Bankruptcy filing reported',
    description:
      'FICO® Scores consider the presence of a bankruptcy filing on a person®s credit report as a powerful predictor of future payment risk. Your score was impacted because your report shows a bankruptcy filing.',
    helper:
      'Keep in mind: People with a bankruptcy filing on their credit report tend to appear more risky to lenders. Bankruptcies may remain on a person®s credit report for six years, although this can vary by province.',
  },
  78: {
    code: '78',
    statement: 'Too few established accounts ',
    description:
      'FICO® Scores look at the total number of accounts on a person®s credit report. Your score was impacted because you have a relatively low number of accounts on your credit report.',
    helper:
      'Keep in mind: People who responsibly maintain a moderate number of open accounts®rather than a relatively low number of accounts®tend to demonstrate lower risk to lenders. However, be aware that a new account opening, and to a lesser extent the inquiry associated with applying for a new account, may demonstrate higher risk in the short term.',
  },
  79: {
    code: '79',
    statement: 'No recent LOC revolving balances',
    description:
      'FICO® Scores evaluate recent balances on the line of credit (LOC) accounts shown on a person®s credit report. Your FICO® Score was impacted because you are not currently demonstrating active LOC revolving credit management.',
    helper:
      'Keep this in mind: People who demonstrate moderate and conscientious use of LOC revolving accounts, such as maintaining low balances and paying them on time, tend to demonstrate lower risk to lenders.',
  },
  82: {
    code: '82',
    statement: 'No recent non-LOC revolving balances',
    description:
      'FICO® Scores consider whether a person®s credit report shows recent balances on non-line of credit (LOC) revolving and/or open-ended accounts. Your FICO® Score was impacted because you are not currently demonstrating active credit management on these types of accounts.',
    helper:
      'Keep in mind: People who show moderate and conscientious use of revolving and/or open-ended accounts, such as having low balances and paying them on time, generally demonstrate responsible financial behavior. People without recent credit activity on these types of accounts tend to be viewed as higher risk by lenders.',
  },
  89: {
    code: '89',
    statement: 'Proportion of balances to credit limits on bank/national revolving accounts is too high',
    description:
      'FICO® Scores weigh the balances in relation to available credit on credit card and/or open-ended accounts shown on a person®s credit report. Your score was impacted because your proportion of credit card and/or open-ended account balances to credit limits is too high.',
    helper:
      'Keep in mind: In general, people who carry lower credit card and/or open-ended account balances in relation to the credit limit are considered less risky to lenders.',
  },
  91: {
    code: '91',
    statement: 'Too many recently active accounts',
    description:
      'FICO® Scores consider the number of accounts showing recent usage on a person®s credit report. Your score was impacted by having too many accounts showing recent activity.',
    helper:
      'Keep in mind: Using several credit accounts in a short time period is generally reflective of greater risk ® especially for people with a short credit history.',
  },
  92: {
    code: '92',
    statement: 'Too few recently active retail accounts',
    description:
      'FICO® Scores consider the number of active retail accounts which a person is actively using and paying as agreed as shown on their credit report. Your score was impacted by having few retail accounts or not using your retail accounts recently.',
    helper:
      'Keep in mind: People with few retail accounts or that have not used retail accounts recently generally appear more risky to lenders. However, be aware that a new account opening, and to a lesser extent the inquiry associated with applying for a new account, may demonstrate higher risk in the short term.',
  },
  93: {
    code: '93',
    statement: 'Too many recently active retail accounts',
    description:
      'FICO® Scores consider the number of retail accounts showing recent usage on a person®s credit report. Your score was impacted by having too many retail accounts recently showing activity.',
    helper:
      'Keep in mind: Using several retail accounts in a short time period is generally reflective of greater risk ® especially for people with a short credit history.',
  },
  94: {
    code: '94',
    statement: 'Too many retail accounts with balances',
    description:
      'FICO® Scores consider the total number of retail accounts a consumer holds with balances, including balance amounts that appear from the most recent account statements®even if the balance was paid off. Your score was impacted by having too many retail accounts with balances.',
    helper:
      'Keep in mind: Generally, people who limit the number of accounts they have with balances appear less risky to lenders.',
  },
  95: {
    code: '95',
    statement: 'Too many recently opened accounts with balances',
    description:
      'FICO® Scores consider the number of new credit account openings that have balances shown on a person®s credit report. Your score was impacted because of too many recently opened credit accounts that have balances.',
    helper:
      'Keep in mind: In general, people who open several credit accounts in a short time period and carry a balance on each account tend to demonstrate greater risk to lenders ® especially for people with relatively short credit histories.',
  },
  96: {
    code: '96',
    statement: 'Lack of recent non-LOC bank/national revolving account information',
    description:
      'FICO® Scores evaluate a person®s mix of credit cards, loans, and mortgages, and whether a person®s credit report shows open non-line of credit (LOC) credit cards and/or open-ended accounts, or sufficient recent information about these accounts. Your score was impacted by too little recent non-LOC credit card and/or open-ended account information on your credit report.',
    helper:
      'Keep in mind: People who demonstrate responsible use of different types of credit generally appear less risky to lenders. However, opening a new card and/or open-ended account, and to a lesser extent the inquiry associated with applying for new credit, may demonstrate higher risk in the short term.',
  },
};

export default ficoReasons;
