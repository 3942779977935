import React from 'react';
import fICOScoreWTag from '../../assets/FICOScoreWTag.svg';
import './ScoreArea.scss';

export default function ScoreArea({ score }) {
  return (
    <div id="ficoScoreArea" style={score ? null : { height: '134px' }}>
      <img src={fICOScoreWTag} alt="FICO Score - The score lenders use." />
      {score && (
        <div className="scoreValueArea">
          <p className="score">{score ?? 'N/A'}</p>
        </div>
      )}
    </div>
  );
}
