import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import useAuth from "../hooks/useAuth";

import CardContainer from "../components/CardContainer";
// import FlinksSettings from '../components/FlinksSettings/Index';
import MxSettings from "../components/MxSettings";

import arrow_right from "../assets/icons/arrow_right.svg";
import ChangePasswordForm from "../components/ChangePasswordForm/Index";

import "../styling/layout/Settings.scss";
import MxConnectModal from "../components/MxSettings/MxConnectModal";
import { Container } from "react-bootstrap";

export default function SettingsView({ initMode }) {
  const auth = useAuth();
  const isUser = auth?.user?.role !== "ROLE_SUPER_ADMIN";
  window.history.pushState(
    { page: "settings" },
    "Settings",
    "/client/settings",
    "client/forum"
  );
  const [mode, setMode] = useState(initMode || "root");
  const [showMxModal, setShowMxModal] = useState(false);

  useEffect(() => {
    setMode(initMode);
  }, [initMode]);

  const settingsRoot = (
    <>
      <h2 className="settings-title">Settings</h2>
      <section className="settings-options">
        {isUser && (
          <div className="settings-option" onClick={() => setShowMxModal(true)}>
            <h3>Linked account(s)</h3>
            <img src={arrow_right} alt="Arrow" />
          </div>
        )}
        <div className="settings-option" onClick={() => setMode("changePass")}>
          <h3>Change Password</h3>
          <img src={arrow_right} alt="Arrow" />
        </div>
      </section>
      <h3 id="settings-logout">
        <Link to="/logout">Logout</Link>
      </h3>
    </>
  );

  const backBtn = (
    <div className="settings-backBtn-container">
      <div className="settings-backBtn" onClick={() => setMode("root")}>
        <img className="leftFacing" src={arrow_right} alt="Arrow" />
        <h3>Back</h3>
      </div>
    </div>
  );

  return (
    <>
    { showMxModal &&  <MxConnectModal {...{ showMxModal, setShowMxModal }} />}

      <Container className="py-4">
        <CardContainer id="settings-card" className="card-settings">
          {mode !== "root" && backBtn}
          {mode === "root" && settingsRoot}
          {/* {mode === 'accounts' && <FlinksSettings />} */}
          {mode === "accounts" && <MxSettings />}
          {mode === "changePass" && (
            <ChangePasswordForm
              setRootMode={() => {
                setMode("root");
              }}
            />
          )}
        </CardContainer>
      </Container>
    </>
  );
}
