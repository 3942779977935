import React from 'react';
import ceoMsgThumb from '../../assets/onboarding/ceoMsgThumb.png';
// import second from './'
// import closeIcon from '../../assets/onboarding/closeIcon.svg';
// import playIcon from '../../assets/onboarding/playIcon.svg';

// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';

export default function WelcomeSlide() {
  return (
    <div>
      <img className="onboard-img" src={ceoMsgThumb} alt="Earn cash-back each month" />
      <div className="sptText">
        <h3>A message from our CEO.</h3>
        <p className="muted">Welcome to Parachute! Watch this quick intro from Bruce Hodges, CEO and co-founder.</p>
        {/* Play video button and modal are handled by onboarding index */}
      </div>
    </div>
  );
}
