import React from "react";
import CardContainer from "../CardContainer";
import CardTitleWTip from "../CardTitleWTip";
import DiscrepancyModal from "./DiscrepancyModal";
import HistoryModal from "./HistoryModal";
import { TOOLTIP } from "../../utils/constants";
import {
  formatToDollar,
  startOfNextMonthFormatted,
} from "../../utils/helpers";
import "./CashBackCard.scss";
import ErrorNotification from "../ErrorNotification";

function CashBack({ data, overrideUserID, isCurrAssessmentComplete }) {
  const showError = !data || (data && !Object.values(data).some((x) => x));
  const totalCbEarned = data?.totalCbEarned;
  const totalPossibleCB = data?.totalPossibleCB;

  /* Filter out curr Month if !currAssessmnet */
  const cbHistory = isCurrAssessmentComplete
    ? data?.cbHistory
    : data?.cbHistory?.slice(1); //rmv 1st entry curr month if no curr Assessment

  const clawback = data?.clawBack;
  const showDiscrepancy = !!clawback;

  return (
    <CardContainer id="cashbackContainer">
      <CardTitleWTip tipObject={TOOLTIP.CASHBACK}>
        Total Cash-Back
      </CardTitleWTip>
      {/* Need both earned and possible CB  */}
      {!isNaN(totalCbEarned) && !isNaN(totalPossibleCB) ? (
        <div className="cashbackInfo">
          {/* !isNaN only filters out undefined (and is done above), explicit !null will make the ternary work as expected  */}
          <h1>
            {totalCbEarned !== null
              ? `$${formatToDollar(totalCbEarned, { noDecimal: true })}`
              : "N/A"}
          </h1>
          <h1 className="muted">{`/`}</h1>
          <h1 className="muted">
            {totalPossibleCB !== null
              ? `$${formatToDollar(totalPossibleCB, { noDecimal: true })}`
              : "N/A"}
          </h1>
        </div>
      ) : (
        <div className="cashbackInfo">
          <h1>{`N/A`}</h1>
        </div>
      )}
      <h2 className="assessment">{`Next self assessment on ${startOfNextMonthFormatted()}`}</h2>
      <hr />
      {showError && (
        <ErrorNotification>
          We cannot determine cash-back amount.
        </ErrorNotification>
      )}
      {showDiscrepancy && (
        <DiscrepancyModal
          data={clawback}
          clearClawbackFromState={data?.clearClawbackFromState}
        />
      )}
      {!showError && !showDiscrepancy && (
        <HistoryModal
          totalEarned={totalCbEarned}
          cbHistory={cbHistory}
          // cbAmount={potential}
          overrideUserID={overrideUserID}
        />
      )}

      {/* Will likely be based on a notification of some sort */}
    </CardContainer>
  );
}

export default CashBack;
