import React, { useState, useEffect } from 'react';
import CardContainer from '../CardContainer';
import WideBtn from '../WideBtn';
import icon from '../../assets/Logo_Icon.svg';
import Form from 'react-bootstrap/Form';
import DebtForm from './DebtForm';
import TextButton from '../TextButton';
import { validateAndFormat } from './validatePreQual';
import Spinner from 'react-bootstrap/Spinner';

export default function DebtToConsolidate({ navForm, updatePreQualState, numOfDebts, stateData, status }) {
  const DEBT_TEMPLATE = {
    type: '',
    amount: '',
    // monthlyPayments: '',
    // interestRate: '',
    creditor: '',
  };

  const initialState = () => {
    if (numOfDebts >= 8) return [];
    if (stateData?.length > 0) return stateData;
    return [{ ...DEBT_TEMPLATE }]; //Return null and use ore in useState becau
  };
  const [formData, setFormData] = useState(initialState());
  const [totalDebtItems, setTotalDebtItems] = useState(numOfDebts);
  useEffect(() => {
    stateData?.length && setFormData([...stateData]);
  }, [stateData]);

  useEffect(() => {
    const totalDebts = numOfDebts + formData.length;
    setTotalDebtItems(totalDebts);
  }, [formData.length, numOfDebts]);

  const handleChange = (e, indexToUpdate) => {
    const key = e.target.id;
    let value = e.target.value;

    const formattedValue = validateAndFormat('debt', key, value);
    if (formattedValue === undefined || formattedValue === null) return;

    const prevObject = { ...formData[indexToUpdate] };
    const newData = {
      ...prevObject,
      [key]: formattedValue,
      needsConsolidate: false,
    };

    const newFormData = [...formData];
    newFormData[indexToUpdate] = newData;
    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //TRIM empty object (!values.every) from the formData (so only existing data is saved)
    const PREQUAL_STATE_KEY = 'nonConsolidatedDebt'; //This key has a special handler in updatePreQual
    updatePreQualState(PREQUAL_STATE_KEY, formData);
    window.scrollTo(0, 0);
  };

  const handleAddType = (e) => {
    e.preventDefault();
    console.log('Add another debt...');
    setFormData((p) => {
      return [...p, { ...DEBT_TEMPLATE }];
    });
  };

  const debtEntryArea = (
    <>
      {formData?.map((debt, i) => {
        return <DebtForm formNumber={8} formData={debt} index={i} handleChange={handleChange} status={status} />;
      })}

      {!totalDebtItems || totalDebtItems < 8 ? (
        <WideBtn
          variant="outline-secondary"
          onClick={handleAddType}
          disabled={status.loading}
          style={{ marginTop: '8px', marginBottom: '24px' }}
        >
          Add another type
        </WideBtn>
      ) : (
        <p>Max debt items is 8 (consolidated and non-consolidated)</p>
      )}
      <div style={{ marginTop: '0', marginBottom: '0', backgroundColor: '#0000001F', height: '1px' }} />
    </>
  );

  const isNextDisabled = status.disabled;
  return (
    <>
      <CardContainer>
        <div>
          <div className="titleBlock">
            {icon && <img className="titleBlock--icon" src={icon} alt={icon} />}
            <h3 className="titleBlock--title">What other debts do you have? (Not to be consolidated)</h3>
            <p className="muted titleBlock--body">
              We won’t consolidate these, but we need to know about all of your debts in order to qualify you faster.
            </p>
            {/*  <p className="muted titleBlock--body">
              If you have any questions, or need additional support, please text or call us at{' '}
              <a href="tel:8446702403">(844) 670-2403</a> or email us at{' '}
              <a href="email:contactus@myparachute.co">contactus@myparachute.co</a>
            </p> */}
          </div>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {debtEntryArea}

            <WideBtn type="submit" onClick={handleSubmit} disabled={isNextDisabled} id="submitNonConsolidatedDebt">
              {status.loading ? <Spinner animation="border" role="status" size="sm" /> : `Next`}
            </WideBtn>
            {status.error && <p className="error">{status.error}</p>}
            <TextButton
              disabled={status.loading}
              onClick={() => {
                navForm('back');
              }}
              // style={{ color: '#98A2B3' }}
            >
              Back
            </TextButton>
          </Form>
        </div>
      </CardContainer>
    </>
  );
}
