import React, { useEffect, useState } from "react";
import GoogleSignin from "./btn_google_signin_dark_pressed_web.png";
import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import userIcon from '../../../assets/user.png';
import cookies from "../../../utils/cookies";
// import { IonAvatar } from "@ionic/react";

import { MONTH_NUM_TO_NAME, USER_TYPE } from '../../../utils/constants';

import api from '../../../utils/api';
// import {  ListGroup } from "react-bootstrap";
const NavBar = () => {


  const [totalTableElements, setTotalTableElements] = useState(0);

  const [searchList, setSearchList] = useState([]);

  const [lastSearchList, setLastSearchList] = useState([]);
  const today = cookies.getToday();
  const [selectedMonth, setSelectedMonth] = useState(MONTH_NUM_TO_NAME[today.month()]);
  const [selectedYear, setSelectedYear] = useState(today.year());
  // const [user] = useAuthState(auth);
  let user = {
    "uid": cookies.getUserID(),
    "email": cookies.getUsername(),
    
    "role": cookies.getUserRole(),
    "emailVerified": true,
    "displayName": cookies.getUserDisplayName(),
    "isAnonymous": false,
    "photoURL": "https://lh3.googleusercontent.com/a/AAcHTtfM0vJ00b9jMVMnRrEvCGJmb1hGGTmlDN5eW-BFPy0t=s96-c",
    "providerData": [
      {
        "providerId": "google.com",
        "uid": cookies.getUserID(),
        "displayName": cookies.getUserDisplayName(),
        "email": cookies.getUsername(),
        "phoneNumber": null,
        "photoURL": "https://lh3.googleusercontent.com/a/AAcHTtfM0vJ00b9jMVMnRrEvCGJmb1hGGTmlDN5eW-BFPy0t=s96-c"
      }
    ],
    "stsTokenManager": {
      "refreshToken": "AMf-vBxJdwOtJEFSDPmkgj3eagT2Ky-pX560MEClfO5acL5i379U9CZewAk4VaDuKgZnEGrfUJs5I376m-C03l8RNkZbd1QKYSmGH5kAZapYn9vhHRirtlyjxJx47fzfiByRK6J1rEwduFFZcQ91O3uaaIYdJNqCplj3jcsBRcfvD7ZSradpGIs0vM7rM6YvjFYP3v5sLjfCWdUXOKMBKzsgcxJVqRgf8-oW0dkOz0XTRV9HjOs-EW7I6r1c4v1SwSATkK8-ACOoqIAIhBovTgq0qt4i7i-GbqG_pTU07OWiasKji6MNtChNlhztqPRi7Y3IkArGnxaa56BDvSq_RIu4QTLy-h3PRihXZbwukGXsJ2XL69_QfmGCIh3UXCeQr6BLuHxGZe_riAEzKcqqV-AkUQI4Yzi93jgs2Fz59qJvpDcSJr8cR8-dyj8MXH7jMBltJOUDmI7w",
      "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjE5MGFkMTE4YTk0MGFkYzlmMmY1Mzc2YjM1MjkyZmVkZThjMmQwZWUiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoibmFuZGFuIGQiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUFjSFR0Zk0wdkowMGI5ak1WTW5SckV2Q0dKbWIxaEdHVG1sRE41ZVctQkZQeTB0PXM5Ni1jIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL215cGFyYWNodXRlLW5vdGlmaWNhdGlvbnMiLCJhdWQiOiJteXBhcmFjaHV0ZS1ub3RpZmljYXRpb25zIiwiYXV0aF90aW1lIjoxNjk0MTcxMDIyLCJ1c2VyX2lkIjoiVURsV0JFeG5EN1k3OFA3WVU5TXlVeEFYcFN2MSIsInN1YiI6IlVEbFdCRXhuRDdZNzhQN1lVOU15VXhBWHBTdjEiLCJpYXQiOjE2OTQxNzEwMjIsImV4cCI6MTY5NDE3NDYyMiwiZW1haWwiOiJuYW5kYW5kLmNvbUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJnb29nbGUuY29tIjpbIjEwODAwNTA5NTQ0MTE4ODgzMjgzMCJdLCJlbWFpbCI6WyJuYW5kYW5kLmNvbUBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.BPYsTubcOzPYfh164Ko3pPMRR65tIKIfEcowXWTUudClwLDz-JGn2GCuUNAs5Oqn5tDN1b4hCcSkMun5-qbRT_sQzq8B9LqVaUQnrb-28X6QpWmeQazjG_MPGs3RdWfx529MvnM3dkNituG6kHxXnIEVhQ2gqpNN6AS8MiEGKNYuQfb-gDIRirm5tOlJeLCjs8J_6019u2kE8YrbO5iAjxnrgw7NNJwBlZjEn6fQ3fxEUMVyIrJzomLcbxrY_wVBo0Zujw4qLgJJHds4tekdI8MOnQCjFrT1AlPPwiFgAPInUNTjz_l_qckLLLzlchSaHX3nP9BKYXqrKZsEFPEfSg",
      "expirationTime": 1694174622656
    },
    "createdAt": "1693995393108",
    "lastLoginAt": "1694171022347",
    "apiKey": JSON.parse(localStorage.getItem('firebaseConfig')).apiKey,
    "appName": "[DEFAULT]"
  };

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };
  // console.log(JSON.stringify(user));
  const signOut = () => {
    auth.signOut();
  };
  useEffect(() => {
    let data = {
      "month": cookies.getMonthNumber(selectedMonth),
      "year": selectedYear,
      "page": 0,
      "size": 10
    }
    api.getAllChatRoom(data)
      .then(resp => resp?.data)
      .then(res => {
        console.log(res)
        let initUsers = [];
        res?.content.forEach(element => {
          initUsers.push(element)
        });



        console.log(initUsers);
        setTotalTableElements(res?.totalElements || initUsers?.length)
        // params.successCallback(initUsers, res?.totalElements || res?.length);
        setSearchList(initUsers);
      }).catch(err => {
        // gridApi.hideOverlay();
        console.log(err);
        // params.successCallback([], 0);
        setSearchList([]);
      });


  }, []);
  const handleShow = () => {
    let chatTextInput = document.getElementById("chatTextInput").value;
    setLastSearchList(searchList.length > lastSearchList.length ? searchList : lastSearchList);
    let searchMapList = searchList.length > lastSearchList.length ? searchList : lastSearchList;
    let searchMatchList = [];
    searchMapList.forEach(element => {
      let displayName = element.firstName + " " + element.lastName;
      console.log(displayName);
      console.log(chatTextInput);
      if (displayName.toLowerCase().includes(chatTextInput.toLowerCase())) {
        searchMatchList.push(element);
      }
    });

    setSearchList(searchMatchList);

  };
  const handleCrossClick = () => {
    // alert("hi");
    setSearchList(searchList.length > lastSearchList.length ? searchList : lastSearchList);
  };

  return (
    <>

      <nav className="navbar bg-white">
        {/*<div className="container-fluid px-4">
        <a className="navbar-brand" href="http://www.w3.org/2000/svg">
        <svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6012 0.393066V12.9943H0C0 6.03474 5.64167 0.393066 12.6012 0.393066Z" fill="#41FA8D"/>
          <path d="M12.6012 0.393066V12.9943H6.3006C6.3006 6.03474 9.12187 0.393066 12.6012 0.393066Z" fill="#41FA8D"/>
          <path d="M12.6012 0.393066V12.9943H25.2024C25.2024 6.03474 19.5607 0.393066 12.6012 0.393066Z" fill="#41FA8D"/>
          <path d="M12.6012 0.393066V12.9943H18.9018C18.9018 6.03474 16.0805 0.393066 12.6012 0.393066Z" fill="#41FA8D"/>
          <path d="M12.6012 0.393066V12.9943H0C0 6.03474 5.64167 0.393066 12.6012 0.393066Z" fill="#039855"/>
          <path d="M12.6012 0.393066V12.9943H6.3006C6.3006 6.03474 9.12187 0.393066 12.6012 0.393066Z" fill="#12B76A"/>
          <path d="M12.6012 0.393066V12.9943H25.2024C25.2024 6.03474 19.5607 0.393066 12.6012 0.393066Z" fill="#6CE9A6"/>
          <path d="M12.6012 0.393066V12.9943H18.9018C18.9018 6.03474 16.0805 0.393066 12.6012 0.393066Z" fill="#32D583"/>
        </svg>

        </a>
        <h5 className="navbar-text">Chat Room</h5>
        
      </div>*/}
        <>


          {/* Listing with Searchbar */}
          <div className="bg-dark d-flex flex-column w-100 py-2 px-4 text-light">
            <form className="form-search d-flex align-items-center">
              <input onChange={handleShow}
                onSearch={handleShow} name="searchInput" type="search" className="form-control form-control form-control-solid" placeholder="Search User..." id="chatTextInput" />
              {/* <button type="button" className="btn btn-search me-3" onClick={handleShow}>
                <svg   xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </button> */}
            </form>
          </div>

        </>
        {searchList.length ? (<div className="container-fluid bg-dark py-3 px-4">
          <div className="user-avatar-list">

            {searchList.map((user, index) => {
              return (
                <>
                  {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}

                  <a className="user d-inline-flex flex-column" 
                  
                  href={`../chatViewer?room=${user?.chatRoomKey?.userId}&username=${user?.userName}&firstName=${user?.userName.split(" ")[0]}&lastName=${user?.userName.split(" ")[1]}`}
                  
                
                    >
                      <div className="symbol">
                        <img src={`${userIcon}`}
                          alt="user avatar" />
                        <div className="symbol-badge bg-success text-light">
                          {user.unReadMessageAdmin}
                        </div>
                      </div>
                      <div className="mb-0 text-light w-100 small text-truncate">{user.userName}</div>
                  </a>

                </>
              )
            })}


          </div>
        </div>) : (<div className="container-fluid bg-lite p-4"><p>No users found for search!</p></div>)}




        {/* {user ? (
        <button onClick={signOut} className="sign-out" type="button">
          Sign Out
        </button>
      ) : (
        <button className="sign-in">
          <img
            onClick={googleSignIn}
            src={GoogleSignin}
            alt="sign in with google"
            type="button"
          />
        </button>
      )} */}
      </nav>
    </>


  );
};

export default NavBar;