import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { isDST } from '../../../utils/helpers';
import InspirationViewer from './InspirationViewer';

export default function InspirationUploader() {
  /* Upload Image
Note image ID
Create inspiration, reference image ID and use Date */
  const [dataToUpload, setDataToUpload] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [status, setStatus] = useState({ loading: false, error: null, success: null });

  const handleSubmit = (e) => {
    e.preventDefault();
    const inputDate = e.target[0].value;
    const inputFile = e.target[2].files[0];

    const datesMidnight = isDST(new Date(inputDate)) ? '04:00:00Z' : '05:00:00Z';

    setDataToUpload({
      availableDate: new Date(`${inputDate} ${datesMidnight}`), //Release time will be midnight EST
      inspirationImage: inputFile,
    });
  };

  useEffect(() => {
    const uploadData = async () => {
      setStatus({ loading: true, success: null, error: null });
      //Upload Image to API
      const formData = new FormData();
      formData.append('file', selectedImage);
      try {
        const uploadedImg = await api.uploadImage(formData);
        //GET IMAGE ID
        const imageLink = uploadedImg.data._links.self.href;
        //Upload Inspiration
        /* {
  "availableDate": "2022-08-25T04:00:00.001Z",
  "file": "{{baseUrl}}/files/5653"
} */
        imageLink &&
          (await api.createInspiration({
            availableDate: dataToUpload.availableDate,
            file: imageLink,
          }));
        //Clear state
        setDataToUpload(null);
        setSelectedImage(null);
        setStatus({ loading: false, success: true, error: null });
      } catch (e) {
        console.log(' inspiration ERROR', e);
        setStatus({ loading: false, success: false, error: `${e}` });
        setDataToUpload(null);
        setSelectedImage(null);
      }
    };
    dataToUpload && uploadData();
  }, [dataToUpload, selectedImage]);

  return (
    <div>
      <h1>Upload Inspration</h1>
      <form onSubmit={handleSubmit}>
        <label for="availableDate">Available Date (midnight EST):</label>
        <br />
        <input type="date" id="availableDate" name="availableDate" />
        <br />
        {selectedImage && (
          <div>
            <img alt="not found" width={'250px'} src={URL.createObjectURL(selectedImage)} />
            <br />
            <button onClick={() => setSelectedImage(null)}>Remove</button>
          </div>
        )}
        <label for="image">Inspiration Image:</label>
        <br />
        <input
          type="file"
          name="image"
          onChange={(event) => {
            setSelectedImage(event.target.files[0]);
          }}
        />
        <br />
        <br />
        <button>{status.loading ? 'Loading...' : 'Upload'}</button>
      </form>
      {status.success && <p>Inspiration Succussfully uploaded</p>}
      {status.error && <p className="error">{status.error}</p>}
      <hr style={{ width: '85vw' }} />
      <InspirationViewer successUpload={status.success} />
    </div>
  );
}
