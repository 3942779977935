import React from 'react';
import './LoanInfo.scss';

export default function LoanInfo({ title, body, className, testid }) {
  const classToUse = ['loanInfo'];
  className && classToUse.push(className);
  return (
    <div className={classToUse.join(' ')} data-testid={testid}>
      <h3 className="loanInfo-title">{title}</h3>
      <h3 className="loanInfo-body">{body}</h3>
    </div>
  );
}
