import React, { useEffect, useState } from "react";
// import * as widgetSdk from "@mxenabled/web-widget-sdk";
import CardContainer from "../CardContainer";
import { TOOLTIP } from "../../utils/constants";
import CardTitleWTip from "../CardTitleWTip";
import axios from "../../utils/axios";
import Spinner from "react-bootstrap/Spinner";
import Button from 'react-bootstrap/Button';
import $ from 'jquery';
import useAuth from '../../hooks/useAuth';

import * as widgetSdk from "@mxenabled/web-widget-sdk";
// const widget = new widgetSdk.ConnectWidget({
//   container: "<CONTAINER SELECTOR>",
//   url: "<Widget URL>",
//   // additional widget options
// });

export default function TopMerchant({ overrideUserID }) {
  const [topMerchantWidget, setTopMerchantWidget] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const auth = useAuth();
  const userID = overrideUserID || auth?.user?.id;
  // useEffect(() => {
  //   async function loadSpendingWidget() {
  //     const endUrl = "http://localhost:8000/api/get_mx_widget_url";

 
  useEffect(() => {
    async function topMerchantWidgetUrlApi() {
      try {
        setIsLoading(true);
        const params = { widget_type: "pulse_widget" };
        const endUrl = `/users/${params?.widget_type}/customMxWidgetUrls/` + userID;
        const res = await axios.get(endUrl, { params });
        if (res.data) {
          setTopMerchantWidget(res?.data?.widgetUrl?.url ? (res?.data?.widgetUrl?.url) : (null));

          try {
            setTimeout(() => {

              let widget = new widgetSdk.ConnectWidget({
                container: "#top-merchant-widget",
                url: res?.data?.widgetUrl?.url,
                // additional widget options
              });
            }, 1500);
          } catch (error) {
            console.log(error);
          }
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
    topMerchantWidgetUrlApi();
  }, [userID]);

  return (
    <>
      {topMerchantWidget ? (<CardContainer id="monthlySpendMx" className="pulseWidgetDiv">
        <CardTitleWTip tipObject={TOOLTIP.TOP_MERCHANT}>

          {isLoading && (
            <>
              <p>Finalizing connection please wait...</p>
              <Spinner className="flinksSpinner" animation="border" size="md" />
            </>
          )}
          {topMerchantWidget && (
            // <iframe
            //   className="flinksIframe"
            //   title="Connect Accounts"
            //   src={topMerchantWidget}
            // />
            <>

              {/* <Button variant="link" onClick={refrershWidget} >Reload</Button> */}
              <div id="top-merchant-widget"></div>


            </>



          )}
        </CardTitleWTip>
      </CardContainer>) : ''}

    </>

  );
}
