import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';


import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import thumbDown from '../../../assets/icons/thumbDown.svg';
import thumbUp from '../../../assets/icons/thumbUp.svg';



import $ from 'jquery';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useCallback } from 'react'

import Container from 'react-bootstrap/Container';

import cookies from '../../../utils/cookies';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

export default function InspirationsViewer() {

  const [totalTableElements, setTotalTableElements] = useState(0);

  const d = cookies.getToday();
  const [selectedMonth, setSelectedMonth] = useState(d.month() + 1);
  const [selectedYear, setSelectedYear] = useState(d.year());

  const handleChange1 = (event) => {
    setSelectedMonth(event.target.value);
  };
  const handleChange2 = (event) => {
    setSelectedYear(event.target.value);
  };


  /*moods*/
  const [gridApi, setGridApi] = useState(null);
  const perPage = 10;
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const weeklyAnalyticsCall = useCallback(async () => {
    try {
      let fromDate = new Date($('#fromToDate').val()?.split(' - ')[0]);
      fromDate.setUTCHours(0, 0, 0, 0);
      fromDate.setDate(fromDate.getDate() + 1).toLocaleString('en-US');

      let toDate = new Date($('#fromToDate').val()?.split(' - ')[1]);
      toDate.setUTCHours(23, 59, 59, 999);
      toDate.setDate(toDate.getDate() + 1).toLocaleString('en-US');

      let data = {
        "month": selectedMonth,
        "year": selectedYear
      }
      const weeklyAnalyticsRes = await api.findAllUsersWithInspirationCompleted(data, 0, perPage);
      console.log(weeklyAnalyticsRes?.data?.content);

      // setInspirationsList(weeklyAnalyticsRes?.data?.content);
      // console.log(inspirationsList);
    } catch (e) {
      console.error(e);
    }
  }, [selectedMonth, selectedYear]);
  useEffect(() => {
    weeklyAnalyticsCall();
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);

          gridApi.showLoadingOverlay();
          const page = params.endRow / perPage;
          let data = {
            "month": selectedMonth,
            "year": selectedYear
          }
          api.findAllUsersWithInspirationCompleted(data, page - 1, perPage)
            .then(resp => resp?.data)
            .then(res => {
              gridApi.hideOverlay();


              const initUsers = res.content.filter((x) => x.firstName);

              console.log(initUsers);
              setTotalTableElements(res.totalElements);
              params.successCallback(initUsers, res.totalElements);
            }).catch(err => {
              gridApi.hideOverlay();
              console.log(err);
              params.successCallback([], 0);
            });
        }
      }
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, weeklyAnalyticsCall, selectedMonth, selectedYear]);

  const answerFormatter = ({ value }) => {
    if (value === 'thumbUp') {
      return <>Thumbs Up <img src={thumbUp} alt={value} width="50px" height="50px" /></>
    } else {
      return <>Thumbs Down <img src={thumbDown} alt={value} width="50px" height="50px" /></>
    }

  }

  return (
    <>
      <>
        <Container>
          <Row>

            <Col xs={9}>
              <Card>
                <Card.Body>


                  Customers who have completed an INSPIRATION in a given month


                  <Button variant="dark" className="float-end">
                    Total Records: <Badge bg="info">{totalTableElements}</Badge>
                  </Button>

                </Card.Body>
              </Card>
            </Col>
            <Col xs={3}>


              <Card style={{ width: '18rem' }}>
                {/* <Card.Header>Highlights</Card.Header> */}
                <ListGroup variant="flush">
                  <ListGroup.Item>

                    <Row>
                      <Col>
                        <Form.Select aria-label="SELECT MONTTH" onChange={handleChange1} value={selectedMonth}>
                          <option selected value='1'>Janaury</option>
                          <option value='2'>February</option>
                          <option value='3'>March</option>
                          <option value='4'>April</option>
                          <option value='5'>May</option>
                          <option value='6'>June</option>
                          <option value='7'>July</option>
                          <option value='8'>August</option>
                          <option value='9'>September</option>
                          <option value='10'>October</option>
                          <option value='11'>November</option>
                          <option value='12'>December</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Select aria-label="SELECT YEAR" onChange={handleChange2} value={selectedYear}>
                          <option value="2018">2018</option>
                          <option value="2019">2019</option>
                          <option value="2020">2020</option>
                          <option value="2021">2021</option>
                          <option value="2022">2022</option>
                          <option value="2023">2023</option>
                        </Form.Select>
                      </Col>
                    </Row>

                  </ListGroup.Item>


                </ListGroup>
              </Card>
            </Col>
          </Row>

        </Container>
        <br></br>

        <div id="applicanttable">
          <div className="App">
            <div className="ag-theme-alpine ag-style">
              <AgGridReact
                pagination={true}
                rowModelType={'infinite'}
                paginationPageSize={perPage}
                cacheBlockSize={perPage}
                onGridReady={onGridReady}
                rowHeight={60}
                sortable={true}
                unSortIcon={true}
                defaultColDef={{ flex: 1, sortable: true, unSortIcon: true, sortingOrder: ['ASC', 'DESC'] }}
                overlayLoadingTemplate={
                  '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
                }
                overlayNoRowsTemplate={
                  '<span className="ag-overlay-loading-center">No data found to display.</span>'
                }
              >
                <AgGridColumn  field="userId" headerName="User ID" cellClass="vertical-middle" />

                <AgGridColumn  field="firstName" headerName="First Name" cellClass="vertical-middle" />
                <AgGridColumn  field="lastName" headerName="Last Name" cellClass="vertical-middle" />
                <AgGridColumn  field="answer" headerName="Responded Answer?" cellRendererFramework={answerFormatter} cellClass="vertical-middle" />
                {/*  */}
              </AgGridReact>
            </div>
          </div>



        </div>





      </>

    </>


  );
}
