import React, { useState, useEffect } from 'react';
// import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import api from '../../../utils/api';
// import { compareNames, formatDateForDisplay, parseIdFromLink } from '../../../utils/helpers';
import { formatDateForDisplay } from '../../../utils/helpers';

import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
// import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isValid from 'date-fns/isValid';
// import getYear from 'date-fns/getYear';
import { MONTH_NUM_TO_NAME, USER_TYPE } from '../../../utils/constants';

import cookies from '../../../utils/cookies';

import { Button } from "react-bootstrap";

import { useCallback } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

export default function ChatRoomsViewer() {
  const [message, setMessage] = useState('');
  const [statusMsg, setStatusMsg] = useState(null);

  const handleChange = (e) => {
    const value = e.target.value;
    setMessage(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const sendNotification = async (message) => {
        setStatusMsg(null);
        try {
          const res = await api.sendNotification(message);
          console.log(res);
          if (res) {
            setMessage('');
            setStatusMsg('Notification sent!');
          } else {
            setStatusMsg('Something went wrong!');
          }
        } catch (e) {

          setStatusMsg('Something went wrong!');
          console.error(e);
        }
      };
      sendNotification(message);
      // setMessage('');
    } catch (e) {
      setStatusMsg('Something went wrong!');
      // setMessage('');
    }
  };

  const [totalTableElements, setTotalTableElements] = useState(0);
  console.log(totalTableElements)


  const [gridApi, setGridApi] = useState(null);
  const perPage = 10;
  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    })
  };

  const onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  }

  const actionsFormatterCreateDateTime = ({ value, data }) => {
    if (!value) {
      return 'N/A';
    }
    return <>
      {formatDateForDisplay(value)}
    </>;
  }
  const actionsFormatterTicks = ({ value, data }) => {

    return <>
      {value === true ? '✅' : data?.assessmentMonth ? '❌' : '-'}
    </>;
  }

  const actionsFormatterA = ({ value, data }) => {

    return <>

      <Button
        variant="outline-success"
        size="sm"
        href={`../chatViewer?room=${data?.chatRoomId}`}
      >
        Chat
      </Button>

    </>;
  }
  const actionsFormatterUsername = ({ value, data }) => {

    return <>

      <a
        href={`../admin/userViewer?username=${value}`}
      >
        {value}
      </a>

    </>;
  }

  const actionsFormatterlastMessageTime = ({ value, data }) => {
    return <>
      {new Date(value?.seconds * 1000 + value?.nanos / 1000000).toGMTString()}
    </>;
  }

  const actionsFormatterDisplayname = ({ value, data }) => {
    return <>
    {data?.userName && 
      <div className='d-flex flex-column py-2 w-100'>
        <div className='d-flex flex-row align-items-center mb-1'>
          <a href={`../admin/userViewer?username=${data?.userName}`}><h6 className='mb-0 me-3'><stong>{data?.userName}</stong></h6></a>
          <span className="btn btn-sm btn-primary py-0 rounded-pill position-relative">
            Unread
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
              {data?.unReadMessageAdmin}
              <span className="visually-hidden">unread messages</span></span>
          </span>
          &nbsp;&nbsp;
          &nbsp;&nbsp;
          <Button variant="success" href={`../chatViewer?room=${data?.chatRoomKey?.userId}&username=${data?.userName}&firstName=${data?.userName.split(' ')[0]}&lastName=${data?.userName.split(' ')[1]}`}>
            Chat
          </Button>
        </div>
        <br></br>
        {/* <div className="d-flex flex-row">
          <span>{"Total Messages: " + 0}</span>
        </div> */}
        <div>

        </div>
        <div className="d-flex flex-column">
          <span className="mb-1"><b>Last Message Date & Time: </b>{new Date(data?.updateDateTime).toLocaleString()}</span>
          {/* <p className="flex-grow-1"><b>Last Message: </b>{" " + "N/A"}</p> */}
        </div>
      </div>
  }
   {!data?.username && <p><br></br><br></br><br></br>Please wait while we are fetching chat room(s)...<br></br><br></br><br></br></p>}
    
    </>;
  }

  const weeklyAnalyticsCall = useCallback(async () => {
    // const getAndSetAllUsers = async () => {
    //   try {
    //     const rawUsersRes = await api.getAllUsersSortByfirstNameByPage();
    //     const initUsers = rawUsersRes.data._embedded.users.filter((x) => x.role.name === 'ROLE_USER');
    //     setUsers(initUsers);
    //   } catch (e) {
    //     console.error(e);
    //   }
    // };
    // getAndSetAllUsers();
  }, []);

  const today = cookies.getToday();
  const [selectedMonth, setSelectedMonth] = useState(MONTH_NUM_TO_NAME[today.month()]);
  const [selectedYear, setSelectedYear] = useState(today.year());
  const [selectedUserType, setSelectedUserType] = useState('USER');
  const [refreshCounter, setRefreshCounter] = useState(0);
  /*
  const [assessmentData, setAssessmentData] = useState(null);
  const [status, setStatus] = useState({ loading: true, error: null });
*/
  const handleViewMonth = (e) => {
    const value = e.target.value;
    setSelectedMonth(value);
  };

  const handleYear = (e) => {
    const value = e.target.value;
    if (value.length > 4) return;
    setSelectedYear(value);
  };

  const handleViewUserType = (e) => {
    const value = e.target.value;
    setSelectedUserType(value);
  };

  //================================================================User handling
  // const [users, setUsers] = useState(null);

  useEffect(() => {
    //Get All users, limit to 25 pagination
    //[] Should it update when the dates change? like a refresh?

  }, []);

  //================================================================Assessment handling

  useEffect(() => {

    weeklyAnalyticsCall();
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);

          gridApi.showLoadingOverlay();
          const page = params.endRow / perPage;

          const dateString = `15 ${selectedMonth} ${selectedYear}`;
          const date = new Date(dateString); //15th is arbitrary, time is midnight EST
          let currDateRange = null;
          const selectedYearString = `${selectedYear}`;

          if (selectedYearString.length === 4 && isValid(date)) {
            currDateRange = {
              startDate: startOfMonth(date).toISOString(),
              endDate: endOfMonth(date).toISOString(),
            };
          }
          console.log(currDateRange)
          let data = {
            "month": cookies.getMonthNumber(selectedMonth),
            "year": selectedYear,
            "page": page-1,
            "size": perPage
          }
          api.getAllChatRoom(data)
            .then(resp => resp?.data)
            .then(res => {

              console.log(res)
              let initUsers = [];
              res?.content.forEach(element => {
                initUsers.push(element)
              });
              console.log(initUsers);
              setTotalTableElements(res?.totalElements || res?.length)
              params.successCallback(initUsers, res?.totalElements || res?.length);
              if (initUsers.length > 0) {
                gridApi.hideOverlay();
              }
            }).catch(err => {
              gridApi.hideOverlay();
              console.log(err);
              params.successCallback([], 0);
            });
        }
      }
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, weeklyAnalyticsCall, selectedMonth, selectedYear, selectedUserType,refreshCounter]);

const refreshChatRoom = () => {
  let random = Math.floor(Math.random() * 1000);
  setRefreshCounter(random);
};
  return (
    <>
      <div className='container d-flex flex-column pt-4'>
        <h5>{`Chat Rooms List`} <Button onClick={refreshChatRoom}  size="sm">Refresh</Button></h5>
        
      </div>

      <div className='container d-flex flex-column flex-grow-1 py-4'>
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
          <div style={{ flexGrow: '1' }}>

            {/* <div> */}

            {/* <h2>Select Month to view</h2>
            <div>
              <Form.Select
                id="quizMonth"
                className="mr-8"
                aria-label="Month to view"
                onChange={handleViewMonth}
                value={selectedMonth}
              >
                {Object.values(MONTH_NUM_TO_NAME)?.map((i) => (
                  <option key={`dobM-${i}`} value={i}>
                    {i}
                  </option>
                ))}
              </Form.Select>
              <Form.Control value={selectedYear} type="number" onChange={handleYear} />
            </div>

            <hr/>
            <h2>Select User Type to view</h2>
            <div>
              <Form.Select
                id="userType"
                className="mr-8"
                aria-label="User Type to view"
                onChange={handleViewUserType}
                value={selectedUserType}
              >
                {Object.values(USER_TYPE)?.map((i) => (
                  <option key={`userType-${i}`} value={i}>
                    {i==='USER' ? 'CUSTOMER' :i}
                  </option>
                ))}
              </Form.Select>
            </div> */}

            {/* </div> */}
            {/* <p>✅ = Correct ❌ = Wrong Answer ⚪ = No Answer</p>
            <p>
              Display Order: Passed, Failed assessments, then incomplete assessments. Each Section is sorted by last name then
              first name
            </p> */}
            <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
              <AgGridReact
                pagination={true}
                rowModelType={'infinite'}
                paginationPageSize={perPage}
                cacheBlockSize={perPage}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                rowHeight={200}
                defaultColDef={{
                  flex: 1,
                  sortable: false,
                  resizable: false,
                  cellClass: 'cell-wrap-text',
                }}
                overlayLoadingTemplate={'<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'}
                overlayNoRowsTemplate={'<span className="ag-overlay-loading-center">No data found to display.</span>'}
              >
                {/* <AgGridColumn width='100' field="id" headerName="Actions" cellRendererFramework={actionsFormatterA}/> */}

                <AgGridColumn headerName="Chat Rooms" suppressSizeToFit="true" autoHeight="true" flex="1" cellRendererFramework={actionsFormatterDisplayname} />
                {/* <AgGridColumn field="totalDocuments" headerName="Total Messages" cellClass="vertical-middle" />
                <AgGridColumn field="sentDocuments" headerName="Unread Messages" cellClass="vertical-middle" />
                <AgGridColumn field="lastMessage" headerName="Last Message" cellClass="vertical-middle" />
                <AgGridColumn field="lastMessageTime" headerName="Last Message Date & Time" cellRendererFramework={actionsFormatterlastMessageTime} cellClass="vertical-middle" />
                <AgGridColumn field="username" headerName="Username" cellRendererFramework={actionsFormatterUsername} cellClass="vertical-middle" /> */}
              </AgGridReact>
            </div>


            {/* <AgGridColumn field="chatRoomId" headerName="Chat Room Key" cellClass="vertical-middle" /> */}

            {/* <AgGridColumn field="lastName" headerName="Last Name" cellClass="vertical-middle" /> */}
            {/* <AgGridColumn field="username" headerName="Username" cellClass="vertical-middle" /> */}


            {/*   <AgGridColumn field="loanStartDate" headerName="Loan Start Date" cellClass="vertical-middle" cellRendererFramework={actionsFormatterCreateDateTime} />
        <AgGridColumn field="assessmentMonth" headerName="Create Date Time" cellClass="vertical-middle" cellRendererFramework={actionsFormatterCreateDateTime} />
        <AgGridColumn field="noNewDebt" headerName="No New Debt" cellClass="vertical-middle" cellRendererFramework={actionsFormatterTicks} />
        <AgGridColumn field="paidBillsOnTime" headerName="Paid Bills On Time" cellClass="vertical-middle" cellRendererFramework={actionsFormatterTicks} />
        <AgGridColumn field="underUtilization" headerName="Under Utilization" cellClass="vertical-middle" cellRendererFramework={actionsFormatterTicks} /> */}
            {/* <AgGridColumn field="utilizationFlag" headerName="Utilization Flag" cellClass="vertical-middle" />
        <AgGridColumn field="passedAssessment" headerName="Passed" cellClass="vertical-middle" /> */}
            {/* <hr></hr>
        <h1>{`SEND PUSH NOTIFICATION`}</h1>
        <Form>
          <Form.Group className="mb-3" controlId="message" onSubmit={handleSubmit}>
            <Form.Label>Message for the notification</Form.Label>
            <Form.Control type="textarea" placeholder="Enter message" onChange={handleChange} value={message} />
          </Form.Group>

          <Button variant="primary" type="submit" disabled={!message} onClick={handleSubmit}>
            Submit
          </Button>
          {statusMsg && <p className="info">{statusMsg}</p>}
        </Form> */}
          </div>
        </div>
      </div></>
  );
}
