import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import WideBtn from '../WideBtn';
import { PRE_QUAL } from '../../utils/constants';
import api from '../../utils/api';

export default function SurveySlide({
  financialLiteracy,
  behaviour,
  stateData,
  incrementIndex,
  setSurveyData,
  surveyData,
  handleCloseModal,
  usersApplicationID,
  userEmail,
}) {
  const { FIN_LITERACY_QUESTIONS, BEHAVIOUR_QUESTIONS, SURVEY_OPTIONS, 
    // TRUE_FALSE, 
    FIN_LITERACY_OPTIONS } = PRE_QUAL; //CONSTANTS for Form Options
  const [formData, setFormData] = useState(stateData || {});
  const [validated, setValidated] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ loading: false, error: null });

  useEffect(() => {
    stateData && setFormData({ ...stateData });
  }, [stateData]);

  let header = '';
  let QUESTIONS = [];
  let ANSWER_OPTIONS = [];
  const updateComponentVariables = () => {
    if (behaviour) {
      QUESTIONS = BEHAVIOUR_QUESTIONS;
      ANSWER_OPTIONS = SURVEY_OPTIONS;
      header = 'Let us know how the statements below describe you:';
    }
    if (financialLiteracy) {
      QUESTIONS = FIN_LITERACY_QUESTIONS;
      ANSWER_OPTIONS = FIN_LITERACY_OPTIONS;
      header = 'Please answer true or false for the following questions.';
    }
  };
  updateComponentVariables();

  const handleChange = (e) => {
    const key = e.target.id;
    let value = e.target.value;
    const questionIndex = key.slice(1);

    setFormData((p) => {
      return { ...p, [key]: { question: QUESTIONS[questionIndex], answer: value } };
    });
  };

  const surveryQuestions = QUESTIONS.map((question, questionIndex) => {
    const notLastItem = QUESTIONS.length - 1 !== questionIndex;
    return (
      <div key={`q-${question}`} className="mb-3">
        <p className="muted onboard-surveryQuestion">{question}</p>
        {behaviour ? (
        ANSWER_OPTIONS.map((option, ansIndex) => {
          const type = 'radio';
          return (
            <Form.Check
              className="onboard-surveyCheck"
              onChange={handleChange}
              name={question}
              key={`${question}-${ansIndex}`}
              id={`q${questionIndex}`} //Question
              type={type}
              label={option}
              value={option}
              checked={formData?.[`q${questionIndex}`]?.answer === option}
              required
              isInvalid={validated && !formData?.[`q${questionIndex}`]?.answer}
            />
          );
        })
  ):''}


        {financialLiteracy ? (
          ANSWER_OPTIONS[questionIndex].map((option, ansIndex) => {
            const type = 'radio';
            return (
              <Form.Check
                className="surveyCheck"
                // reverse
                onChange={handleChange}
                name={question}
                key={`${question}-${ansIndex}`}
                id={`q${questionIndex}`} //Question
                type={type}
                label={option}
                value={option}
                checked={formData?.[`q${questionIndex}`]?.answer === option}
                required={true}
                isInvalid={validated && !formData?.[`q${questionIndex}`]?.answer}
              />
            );
          })
        ) : ''}


        {notLastItem && <hr className="onboard-surveryDivider" />}
      </div>
    );
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (behaviour) {
      //Pass Survey to Parent (will submit to API as the end)
      setSurveyData({
        behaviourQuestions: formData,
      });

      incrementIndex();
      //Clear Data (not sure why this is req'd b/c it should be a new component...)
      setFormData({});
      setValidated(false);
    }
    if (financialLiteracy) {
      setStatus({ loading: true, error: null });
      const prevSurveyData = { ...surveyData };
      const newSurveyData = {
        ...prevSurveyData,
        financialLiteracyQuestions: formData,
      };
      setSurveyData(newSurveyData);
      // Submit survery answers to api
      const dataForAPI = {
        email: userEmail,

        behaviourQuestionsA0: newSurveyData?.behaviourQuestions?.q0?.answer,
        behaviourQuestionsA1: newSurveyData?.behaviourQuestions?.q1?.answer,
        behaviourQuestionsA2: newSurveyData?.behaviourQuestions?.q2?.answer,
        behaviourQuestionsA3: newSurveyData?.behaviourQuestions?.q3?.answer,
        behaviourQuestionsA4: newSurveyData?.behaviourQuestions?.q4?.answer,
        behaviourQuestionsA5: newSurveyData?.behaviourQuestions?.q5?.answer,
        behaviourQuestionsA6: newSurveyData?.behaviourQuestions?.q6?.answer,

        financialLiteracyQuestionsA0: newSurveyData?.financialLiteracyQuestions?.q0?.answer,
        financialLiteracyQuestionsA1: newSurveyData?.financialLiteracyQuestions?.q1?.answer,
        financialLiteracyQuestionsA2: newSurveyData?.financialLiteracyQuestions?.q2?.answer,
        financialLiteracyQuestionsA3: newSurveyData?.financialLiteracyQuestions?.q3?.answer,
        financialLiteracyQuestionsA4: newSurveyData?.financialLiteracyQuestions?.q4?.answer,
        financialLiteracyQuestionsA5: newSurveyData?.financialLiteracyQuestions?.q5?.answer,
        financialLiteracyQuestionsA6: newSurveyData?.financialLiteracyQuestions?.q6?.answer,
      };
      try {
        usersApplicationID && (await api.updateApplication(dataForAPI, usersApplicationID));
        !usersApplicationID && (await api.submitApplication(dataForAPI));
        //Close on boarding
        await handleCloseModal();
      } catch (e) {
        setStatus({ loading: false, error: `${e}` });
      }
    }
  };

  console.log('formData', formData);
  const BEHAVIOUR_REQUIRED_FIELDS = ['q0', 'q1', 'q2', 'q3', 'q4', 'q5'];
  const isBehaviourComplete = behaviour && BEHAVIOUR_REQUIRED_FIELDS.every((x) => !!formData?.[x]);
  const FINLIT_REQUIRED_FIELDS = ['q0', 'q1', 'q2', 'q3', 'q4'];
  const isFinLitComplete = financialLiteracy && FINLIT_REQUIRED_FIELDS.every((x) => !!formData?.[x]);

  return (
    <div className="onboard-survey">
      <h3 className="onboard-surveryTitle">{header}</h3>

      <hr />

      <Form className="onboard-surveryForm" onSubmit={handleSubmit} validated={validated}>
        {surveryQuestions}
        {behaviour && <WideBtn disabled={!isBehaviourComplete}>Next</WideBtn>}
        {financialLiteracy && (
          <>
            <WideBtn disabled={!isFinLitComplete}>
              {status.loading ? <Spinner animation="border" role="status" size="sm" /> : 'All Done!'}
            </WideBtn>
            {status.error && <p className="error">{status.error}</p>}
          </>
        )}
      </Form>
    </div>
  );
}
