import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CardContainer from '../CardContainer';
import WideBtn from '../WideBtn';
import ContactForm from './ContactForm';
import icon from '../../assets/Logo_Icon.svg';
import { LINKS } from '../../utils/constants';
import api from '../../utils/api';

import './Index.scss';

export default function Index() {
  const navigate = useNavigate();
  const [form, setForm] = useState({ email: '', name: '', reason: '', details: '' });
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dataToPost, setDataToPost] = useState(null);
  const handleChange = (e) => {
    const field = e.target.id;
    const value = e.target.value;
    setForm((p) => {
      return {
        ...p,
        [field]: value,
      };
    });
  };
  useEffect(() => {
    const submitForm = async () => {
      setLoading(true);
      setError(null);
      try {
        await api.submitContactEmail(dataToPost);
        setDataToPost(null);
        setSuccess(true);
        setLoading(false);
      } catch (e) {
        console.log('submit contact error', e);
        setDataToPost(null);
        setSuccess(false);
        setError(e);
        setLoading(false);
      }
    };
    dataToPost && submitForm();
  }, [dataToPost]);

  const handleSubmit = (e) => {
    e.preventDefault();
    //Trigger useEffect to post body
    setDataToPost(form);
  };
  const isSubmitDisabled = loading || Object.values(form).some((x) => !x); // All have a value to submit
  const navToDash = () => {
    navigate('/client');
  };

  const navToSupportDocs = (e) => {
    e.preventDefault();
    window.open(LINKS.supportDocs, '_blank');
    /* Couldn't get Link or navigate to work (kept using relative urls) */
  };

  const SuccessMsg = (
    <>
      <h3>We’ll be in touch shortly!</h3>
      <p className="muted">
        Meanwhile, you may find more information in our{' '}
        <a href={LINKS.supportDocs} target="_blank" rel="noopener noreferrer">
          Support Docs
        </a>
        .
      </p>
    </>
  );

  const initialMsg = (
    <>
      <h2>Support</h2>
      <hr />
      <h3>Knowledge Base</h3>
      <p className="muted">Explore our Knowledge Base to the find answers to your questions.</p>
      <WideBtn className="knowledgeBaseLink" onClick={navToSupportDocs}>
        Visit Knowledge Base
      </WideBtn>
      <hr />
      <h3>Contact Us</h3>
      <p className="muted">Can’t find the answers you’re looking for? Get in touch with one of our experts.</p>
    </>
  );

  return (
    <CardContainer id="contactForm">
      <div className="titleBlock">
        {icon && <img className="titleBlock--icon" src={icon} alt={icon} />}

        {success === true && SuccessMsg}
        {!success && initialMsg}
      </div>
      {!success && (
        <ContactForm
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          isSubmitDisabled={isSubmitDisabled}
          error={error}
        />
      )}
      {success && <WideBtn onClick={navToDash}>Back to dashboard</WideBtn>}
    </CardContainer>
  );
}
