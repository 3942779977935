import React from 'react';
import Button from 'react-bootstrap/Button';
import SimpleField from './SimpleField';

/* SimpleFields with Add button*/
export default function FieldGroup({
  fieldID,
  label,
  placeholder,
  typeOptions,
  parentType,
  fieldData,
  addFieldToIndvForm,
  updateField,
  removeField,
  isReadOnly,
}) {
  const addField = () => {
    addFieldToIndvForm(fieldID);
  };

  const parsedFields = fieldData?.map((data, index) => {
    return (
      <SimpleField
        fieldID={fieldID}
        label={label}
        placeholder={placeholder}
        typeOptions={typeOptions}
        parentType={parentType}
        data={data}
        updateFieldState={updateField}
        index={index}
        removeField={removeField}
        isReadOnly={isReadOnly}
      />
    );
  });
  return (
    <div>
      {!isReadOnly && <Button variant="outline-success" onClick={addField}>{`${label} +`}</Button>}
      {parsedFields}
    </div>
  );
}
