import React from 'react';
import './Disclaimer.scss';

export default function Disclaimer() {
  return (
    <div className="assessment--disclaimer">
      <p className="muted">Subject to Review & Internal validation.</p>
    </div>
  );
}
