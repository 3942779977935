import React from 'react';
import IconButton from '../IconButton';
import LabelButton from '../LabelButton';
import './CommunityTile.scss';

import { allEmojis } from '../../assets/emojiSVG/emojis';
import ErrorNotification from '../ErrorNotification';

function CommunityTile({ data, mood, inspire }) {
  const showError = !data;
  const feeling = data?.feeling;
  const why = data?.why;
  // [] TODO: make generic variable names feeling and reason may not be always present or appropriate, perhaps and array?

  return (
    <section className="feedbackContainer">
      {feeling && (
        <span>
          <h3>{'Most of our community is feeling:'}</h3>
          <IconButton disabled>
            {/* {inspire ? <img src={allEmojis[feeling]} alt={feeling} style={{ marginRight: 0 }} /> : `${feeling}`} */}
            <img src={allEmojis[feeling]} alt={feeling} style={{ marginRight: 0 }} />
          </IconButton>
        </span>
      )}

      {why && (
        <span>
          <h3>{'And they feel that way because:'}</h3>
          <LabelButton disabled>
            {/* {why} */}
            <>
              <img src={allEmojis[why.toLowerCase()]} alt={why} style={{ marginRight: 0 }} />
              <p>{why}</p>
            </>
          </LabelButton>
        </span>
      )}
      {showError && <ErrorNotification>Community data missing.</ErrorNotification>}
      {/* <div className="feedback">{feedback}</div> */}
    </section>
  );
}

export default CommunityTile;
