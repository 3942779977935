import React from 'react';
import './IconButton.scss';
function IconButton({ children, onClick, style, className, small, disabled, selected }) {
  let handleClick = onClick;
  const classToUse = ['iconButton'];
  if (disabled) {
    handleClick = null;
    classToUse.push('disabledBtn');
  }
  if (small) classToUse.push('smallIcon');

  if (selected) {
    const childValue = children.props?.alt || children; //props.alt is the image ALT which is used as the answer value
    const isItemSelected = selected.toString() === childValue.toString();
    isItemSelected ? classToUse.push('selected') : classToUse.push('noHover');
  }
  if (className) classToUse.push(className);
  return (
    <span className={classToUse.join(' ')} style={style} onClick={handleClick}>
      {children}
    </span>
  );
}

export default IconButton;
