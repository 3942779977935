import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { ToastContainer, toast } from 'react-toastify';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
//Import circular progress css
import 'react-circular-progressbar/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      
      <ToastContainer/>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
