import React, { useState, useEffect } from 'react';
import EntityForm from './EntityForm';
import IndividualForm from './IndividualForm';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

export default function ReportItem({ itemType, removeItem, formData, eventKey, updateList, isReadOnly }) {
  const makeAccordionTitle = () => {
    let title = itemType;
    if (itemType === 'ENTITY' && formData?.entity_name) title = formData?.entity_name;
    if (itemType === 'INDIVIDUAL' && formData?.legal_name) title = formData?.legal_name;
    return title;
  };
  const [accordionTitle, setaccordionTitle] = useState(makeAccordionTitle());
  const handleDelete = (e) => {
    e.preventDefault();
    removeItem(itemType, formData.formID);
  };
  const name = formData?.entity_name || formData?.legal_name;

  useEffect(() => {
    setaccordionTitle(makeAccordionTitle());
    //eslint-disable-next-line
  }, [name, formData?.entity_name, formData?.legal_name]);

  return (
    <Accordion.Item eventKey={eventKey}>
      {/* <Accordion.Header>{`${itemType[0]} - ${formData?.entity_name}`}</Accordion.Header> */}
      <Accordion.Header>{accordionTitle}</Accordion.Header>

      {/* <Accordion.Body></Accordion.Body> */}
      <Accordion.Body>
        {!isReadOnly && (
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        )}
        {/* ===========================================================Invidual FORM */}
        {itemType === 'INDIVIDUAL' && (
          <IndividualForm formData={formData} updateList={updateList} isReadOnly={isReadOnly} />
        )}
        {itemType === 'ENTITY' && <EntityForm formData={formData} updateList={updateList} isReadOnly={isReadOnly} />}
      </Accordion.Body>
    </Accordion.Item>
  );
}
