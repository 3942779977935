import React, { useState, useEffect, useCallback } from "react";
// import { Link } from 'react-router-dom';
import supportIcon from '../assets/icons/supportIcon.svg';
import chatIcon from '../assets/icons/chat-text.svg';
import finHubIcon from '../assets/icons/msgIcon.svg';
import menuIcon from '../assets/icons/bars-solid.svg';
import settingsIcon from '../assets/icons/settingsIcon.svg';
import useAuth from '../hooks/useAuth';
// import Button from 'react-bootstrap/Button';
import { LINKS } from '../utils/constants';

import Button from 'react-bootstrap/Button';
import api from "../utils/api";
import cookies from "../utils/cookies";



import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavbarToggle from 'react-bootstrap/NavbarToggle';

const AuthStatus = () => {
  const [unread, setUnread] = useState(+window.localStorage.getItem("unreadMessageCount") || 0);
  const [unreadForumReplies, setUnreadForumReplies] = useState(0);
  const savedRole = cookies.getUserRole();
  /* Component - Login / logout based on auth state */
  let auth = useAuth();
  // let navigate = useNavigate();
  // const navToLogin = () => navigate('/login');

  // let activeStyle = {
  //   textDecoration: 'underline',
  // };
  // let activeClassName = 'underline';


  const timer = () => {
    setTimeout(() => {
      timer();
    }, 5000);
    console.log("Timer called");
    const thirtySeconds = 5 * 1000; // 30 seconds in milliseconds
    var lastCallTime = window.localStorage.getItem("lastCallTime") || 0; // Get the last call time from localStorage
    // Check if 30 seconds have passed since the last call
    if (Date.now() - lastCallTime > thirtySeconds) {
      if (cookies.getJWT()) {
        try {
          let data1 = {
            "userId": cookies.getUserID()
          };
          api.unreadMessageCountNew(data1)
            .then(resp => resp?.data)
            .then(res => {
              console.log(res);

              setUnread(+res);
              window.localStorage.setItem("unreadMessageCount", res ? +res : 0); // Update the unread message count

              window.localStorage.setItem("lastCallTime", Date.now()); // Update the last call time
            })
            .catch(err => {
              console.log(err);
            });


        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  useEffect(() => {
    timer();
  }, []); // useEffect will run whenever lastCallTime changes

  const settingsLink = (
    <li>
      <a className='nav-link' href={'/client/settings'}>
        <img className="link-icon" src={settingsIcon} alt="Settings" />
        <span className="link-text">Settings</span>
      </a>


      {/* <Link to={'/client/settings'}>Settings</Link> */}

      {/* NavLink underlines when active, however this is outside of the design specs */}
      {/* <NavLink to="client/settings" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
          Settings
        </NavLink> */}
    </li>
  );
  const clientForumLink = (
    <li>
      <a className='nav-link' href={LINKS.client_forum}>
        {/* <img className="link-icon" src={supportIcon} alt="Forum" /> */}
        <span className="link-text">FinHub ({unreadForumReplies})</span>
      </a>
    </li>
  );
  const hamMenuClientForumLink = (
    <Nav.Link href={LINKS.client_forum}> <img className="link-icon" src={finHubIcon} alt="FinHub" /> FinHub ({unreadForumReplies})</Nav.Link>
  );
  const hamMenuSettingsLink = (
    <Nav.Link href={'/client/settings'}> <img className="link-icon" src={settingsIcon} alt="Settings" /> Settings</Nav.Link>

  );

  const adminForumLink = (
    <li>
      <a className='nav-link' href={LINKS.admin_forum}>
        {/* <img className="link-icon" src={supportIcon} alt="Forum" /> */}
        <span className="link-text">FinHub ({unreadForumReplies})</span>
      </a>
    </li>
  );
  const chatLink = (
    <li>
      <a className='nav-link' href={LINKS.chat}>
        <img className="link-icon" src={chatIcon} alt="Chat" />
        <span className="link-text">Chat ({unread})</span>
      </a>
    </li>
  );
  const hamMenuChatLink = (
    <Nav.Link href={LINKS.chat}> <img className="link-icon" style={{width:'21px'}} src={chatIcon} alt="Chat" /> Chat ({unread})</Nav.Link>

  );
  const hamMenuchatRoomsLink = (

    <Nav.Link href={LINKS.chatrooms}> <img className="link-icon" src={chatIcon} alt="Chat" /> Chat Rooms ({unread})</Nav.Link>


  );

  const chatRoomsLink = (
    <li>
      <a className='nav-link' href={LINKS.chatrooms}>
        <img className="link-icon" src={chatIcon} alt="Chat" />
        <span className="link-text">Chat Rooms ({unread})</span>
      </a>
    </li>
  );
  const supportLink = (
    <li>
      <a className='nav-link' href={LINKS.support}>
        <img className="link-icon" src={supportIcon} alt="Support" />
        <span className="link-text">Support</span>
      </a>
    </li>
  );


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const btnMenuToggle = (
    <>
      <li>
        <a className='nav-link' href={LINKS.support}>
          <img className="link-icon" src={menuIcon} alt="Menu" />
          <span className="link-text">Menu</span>
        </a>
      </li>
    </>
  );

  const userLoggedIn = (

    <>

      {/* <ul className="navbar-links">
        {clientForumLink}
        {savedRole !== 'ROLE_SUPER_ADMIN' && chatLink}
        {savedRole === 'ROLE_SUPER_ADMIN' && chatRoomsLink}
        {settingsLink}
      </ul> */}

      <Navbar expand="xl" className="bg-body-tertiary w-100">
        <Container>
          <Button variant="outline-success" onClick={handleShow} className='btn-toggle-menu' aria-controls="offcanvasNavbar-expand-xl">
            <span className='nav-link'>
              <img className="link-icon" style={{ width: '20px' }} src={menuIcon} alt="Menu" />
              {/* <span className="link-text">&nbsp;</span> */}
            </span>
          </Button>
          {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-xl"
            placement="end" show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-xl">
                Menu
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-center flex-grow-1 pe-3">



                {hamMenuClientForumLink}
                {savedRole !== 'ROLE_SUPER_ADMIN' && hamMenuChatLink}
                {savedRole === 'ROLE_SUPER_ADMIN' && hamMenuchatRoomsLink}
                {hamMenuSettingsLink}


              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

    </>


  );

  // const userLoggedOut = (
  //   <>
  //     <Button className="loginBtn" variant="outline-secondary">
  //       <Link to="/client">Log In</Link>
  //     </Button>
  //   </>
  // );

  return <div className="loginStatus">{auth.user?.jwt ? userLoggedIn : null}</div>;
};

export default AuthStatus;
