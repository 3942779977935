import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';

export default function LoanDetails({ formData, isReadOnly, updateLoan }) {
  //   const [loanInfo, setloanInfo] = useState(formData);
  const handleChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const field = e.target.id;
    updateLoan(field, val);
  };
  return (
    <Accordion defaultActiveKey="loan0">
      <Accordion.Item eventKey="loan0">
        <Accordion.Header>Loan Info</Accordion.Header>
        <Accordion.Body>
          <Form.Group as={Col} className="mb-3" controlId="amount_requested ">
            <Form.Label>Amount Requested</Form.Label>
            <Form.Control
              placeholder="Amount that the borrower requests from the lender in their application."
              value={formData?.amount_requested}
              disabled={isReadOnly}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="amount_funded ">
            <Form.Label>Amount Funded</Form.Label>
            <Form.Control
              placeholder="Amount that the borrower receives from the lender."
              value={formData?.amount_funded}
              disabled={isReadOnly}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} className="mb-3" controlId="currency_code">
            <Form.Label>Currency</Form.Label>
            <Form.Control
              placeholder="Currency of the loan. ISO 4217 format."
              value={formData?.currency_code}
              disabled={isReadOnly}
              onChange={handleChange}
            />
          </Form.Group>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
