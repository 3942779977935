import React, { useState, useEffect } from 'react';
import CardContainer from '../CardContainer';
import WideBtn from '../WideBtn';
import icon from '../../assets/Logo_Icon.svg';
import Form from 'react-bootstrap/Form';
import { PRE_QUAL } from '../../utils/constants';
import TextButton from '../TextButton';
import Spinner from 'react-bootstrap/Spinner';

export default function WellnessQuestions({ navForm, updatePreQualState, stateData, status }) {
  const { WELLNESS_QUESTIONS, DESCRIPTION_OPTIONS, TIME_OPTIONS } = PRE_QUAL; //CONSTANTS for Form Options

  const [formData, setFormData] = useState(stateData || {});
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    stateData && setFormData({ ...stateData });
  }, [stateData]);

  const handleChange = (e) => {
    const key = e.target.id;
    let value = e.target.value;
    const questionIndex = key.slice(1);
    console.log('chahe', key, questionIndex, value);

    setFormData((p) => {
      return { ...p, [key]: { question: WELLNESS_QUESTIONS[questionIndex], answer: value } };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const PREQUAL_STATE_KEY = 'wellnessQuestions';
    setValidated(true);
    const formElement = e.target.innerHTML;
    const formContainsInvalidField = formElement.includes('is-invalid');
    if (validated && formContainsInvalidField !== true) return updatePreQualState(PREQUAL_STATE_KEY, formData);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (validated === true) {
      handleSubmit({ target: window.document.getElementsByClassName('applyForm')[0], preventDefault: () => { } });
    }
    //eslint-disable-next-line
  }, [validated]);
  console.log('formDataSURVERY', formData);
  const surveryQuestions = WELLNESS_QUESTIONS.map((question, questionIndex) => {
    const notLastItem = WELLNESS_QUESTIONS.length - 1 !== questionIndex;
    return (
      <div key={`q-${question}`} id={`question${questionIndex}`} className="surveyItem  mb-3">
        <p className="muted surveryQuestion">{question}</p>
        {questionIndex <= 2 &&
          DESCRIPTION_OPTIONS.map((option, ansIndex) => {
            const type = 'radio';
            return (
              <Form.Check
                className="surveyCheck"
                // reverse
                onChange={handleChange}
                name={question}
                key={`${question}-${option}`}
                id={`q${questionIndex}`} //Question
                type={type}
                label={option}
                value={option}
                checked={formData?.[`q${questionIndex}`]?.answer === option}
                required
                isInvalid={validated && !formData?.[`q${questionIndex}`]?.answer}
                readOnly={status.loading}
              />
            );
          })}
        {questionIndex > 2 &&
          TIME_OPTIONS.map((option, ansIndex) => {
            const type = 'radio';
            return (
              <Form.Check
                className="surveyCheck"
                // reverse
                onChange={handleChange}
                name={question}
                key={`${question}-${option}`}
                id={`q${questionIndex}`} //Question
                type={type}
                label={option}
                value={option}
                checked={formData?.[`q${questionIndex}`]?.answer === option}
                required
                isInvalid={validated && !formData?.[`q${questionIndex}`]?.answer}
                readOnly={status.loading}
              />
            );
          })}
        {notLastItem && <hr className="mv-24" />}
      </div>
    );
  });

  return (
    <>
      <CardContainer>
        <div>
          <div className="titleBlock">
            {icon && <img className="titleBlock--icon" src={icon} alt={icon} />}
            <h3 className="titleBlock--title">
              To know where you’re going, you need to know where you’re starting from.
            </h3>
            <p className="muted titleBlock--body">
              Let’s get a better picture about how you feel about money. This has no
              impact on your loan application.
            </p>
            {/*  <p className="muted titleBlock--body">
              If you have any questions, or need additional support, please text or call us at{' '}
              <a href="tel:8446702403">(844) 670-2403</a> or email us at{' '}
              <a href="email:contactus@myparachute.co">contactus@myparachute.co</a>
            </p> */}
          </div>
          <hr className="mv-24" />

          <Form className="applyForm " onSubmit={handleSubmit} noValidate validated={validated}>
            {surveryQuestions}
            <WideBtn disabled={status.loading} style={{ marginTop: '8px' }} id="submitWellnessHappy">
              {status.loading ? <Spinner animation="border" role="status" size="sm" /> : `Next`}
            </WideBtn>
            {status.error && <p className="error">{status.error}</p>}
            <TextButton
              disabled={status.loading}
              onClick={() => {
                navForm('back');
              }}
            >
              Back
            </TextButton>
          </Form>
        </div>
      </CardContainer>

    
    


    </>
  );
}
