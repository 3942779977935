import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// import Card from 'react-bootstrap/Card';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheck } from '@fortawesome/free-solid-svg-icons';
// import { Editor } from '@tinymce/tinymce-react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import api from '../utils/api';
import cookies from '../utils/cookies';
import Spinner from 'react-bootstrap/Spinner';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { set } from 'lodash';
export default function ManageNotificationView() {


  const [loader, setLoader] = useState(true);

  const [option, setOption] = useState(null);



  const handleChange = (e) => {
    setOption(e.target.value);
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here

    //Random Alphanumeric with length 20
    var randomId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    let userId = cookies.getUserID();
    let data = {
      "id": randomId,
      "userId": userId,
      "option": option,
      "pushDeviceToken": window.localStorage.getItem('pushDeviceToken')
    };
    console.log('option', option);
    if (option === true || option === "true") {
      api.enableNotification(data).then(resp => resp?.data)
        .then(res1 => {
          console.log('enableNotification', res1);
          toast.dismiss();
          toast.info('Enabled Notification successfully', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }).catch(err => {
          console.log('submit error', err);
        });
    } else {
      api.disableNotification(data).then(resp => resp?.data)
        .then(res1 => {
          console.log('disableNotification', res1);
          toast.dismiss();
          toast.info('Disabled Notification successfully', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }).catch(err => {
          console.log('submit error', err);
        });

    }


  };

  const fetchSelected = () => {

    let data = {
      "id": cookies.getUserID(),
    };

    api.notificationStatus(data).then(resp => resp?.data)
      .then(res1 => {
        console.log('notificationStatus', res1);
        setOption(res1 === 'ENABLED' ? true : false);
      }).catch(err => {
        console.log('notificationStatus error', err);
      });

  }

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(12);
  const [topics, setTopics] = useState([]);


  setTimeout(() => {
    setLoader(false);
  }, 3000);

  useEffect(() => {
    // fetchData();
    fetchSelected();
  }, []);
  return (
    <>

      {loader ? <h3><br></br><br></br><br></br><br></br><br></br><br></br><br></br><Spinner animation="border" size="sm" variant="success" />  Loading form...<br></br><br></br><br></br><br></br><br></br><br></br></h3> : (


        <div style={{ width: '80%' }}>

          <br />
          <Button variant="secondary" onClick={() => {

            window.location.href = "/client/forum";


          }}>
            Go Back to FinHub
          </Button>
          <hr></hr>
          <h1>Manage Notification</h1>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="category">Enable or disable notifications</label>

              <select
                className="form-control"
                id="optionId"
                name="optionId"
                value={option}
                onChange={handleChange}
              >
                <option value="">Select an option</option>
                <option value={'true'}>Enable</option>
                <option value={'false'}>Disable</option>
              </select>
            </div>

            <br />
            <button type="submit" className="btn btn-success">
              Submit
            </button>
          </form>

          <br />


        </div>)}



    </>
  );
}
