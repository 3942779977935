/* https://www.lendersapi.com/docs/api#submit-report */
/* From to submit > later use again to update report */
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import fromUnixTime from 'date-fns/fromUnixTime';

import FlagSection from './FlagSection';
import LoanSection from './LoanDetails';
import ErrorToast from './ErrorToast';
import { REPORT } from '../../utils/A-Dashboard/constants';

import Accordion from 'react-bootstrap/Accordion';
import REPORTS from './reportStructure';
import ReportItem from './ReportItem';
import api from '../../utils/A-Dashboard/api';

const randomNum = () => {
  return Math.floor(1000 + Math.random() * 9000);
};
const initRptState = {
  rptID: '',
  status: '',
  loan: {
    amount_requested: null,
    amount_funded: null,
    currency_code: 'CAD',
  },
  flags: [],
};

export default function LendersAPIForm({ setHits, hits, lendersKey }) {
  //=======================================================State
  const [reportInfo, setReportInfo] = useState(initRptState);
  const [entities, setEntities] = useState([]); //Entities are read only, para wont use this array

  const [individuals, setIndividuals] = useState([]); //Submit as an Array
  const [dataToSubmit, setDataToSubmit] = useState(null); //Built Report Triggers API call
  const [dataToFind, setDataToFind] = useState(null); //Rpt ID Triggers API call (Read Rpt and Hits)
  const [readOnly, setIsReadOnly] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isReadOnly = readOnly || isLoading;

  //=======================================================State Handlers
  const clearError = () => {
    setError(null);
  };
  const updateReportInfo = (e) => {
    e.preventDefault();
    const field = e.target.id;
    const value = e.target.value;
    setReportInfo((p) => {
      return { ...p, [field]: value };
    });
  };

  const updateEntities = (newList) => {
    setEntities((p) => {
      return [...p, newList];
    });
  };

  const updateStateList = (type, newInfo) => {
    const newList = type === 'ENTITY' ? entities : individuals;
    const setState = type === 'ENTITY' ? setEntities : setIndividuals;
    const currFormID = newInfo.formID;
    const indexToChange = newList.findIndex((x) => x.formID === currFormID);
    newList[indexToChange] = newInfo;
    setState([...newList]);
  };

  // const addEntityToReport = () => {
  //   setEntities((p) => {
  //     const newObject = { ...REPORTS.entity, formID: randomNum() };
  //     return [...p, newObject];
  //   });
  // };
  const addIndividualToReport = () => {
    const newObject = { ...REPORTS.individual, formID: randomNum() };
    const oldIndvList = individuals;
    const newList = [...oldIndvList, newObject];
    setIndividuals(newList);
  };
  const removeItem = (type, id) => {
    const filterOutDeletedObject = (dataArray, idToDelete) => {
      const updatedArray = dataArray.filter((x) => x.formID !== idToDelete);
      return updatedArray;
    };
    if (type === 'INDIVIDUAL') {
      setIndividuals((p) => {
        const updatedState = filterOutDeletedObject(p, id);
        setIndividuals(updatedState);
      });
    }
    if (type === 'ENTITY') {
      setEntities((p) => {
        const updatedState = filterOutDeletedObject(p, id);
        setEntities(updatedState);
      });
    }
  };

  const addFlag = (e) => {
    const val = e.target.text;
    setReportInfo((p) => {
      const newFlags = p.flags;
      newFlags.push(val);
      return {
        ...p,
        flags: newFlags,
      };
    });
  };

  const removeFlag = (e) => {
    const val = e.target.textContent;
    setReportInfo((p) => {
      let newFlags = p.flags;
      newFlags = newFlags.filter((x) => x !== val);
      return {
        ...p,
        flags: newFlags,
      };
    });
  };

  const updateLoan = (key, val) => {
    setReportInfo((p) => {
      const newLoanInfo = p.loan;
      newLoanInfo[key] = val;
      return {
        ...p,
        loan: newLoanInfo,
      };
    });
  };

  const resetState = () => {
    setReportInfo(initRptState);
    setEntities([]); //REPORTS.defaultParaEntity
    setIndividuals([]);
    setDataToSubmit(null);
    setDataToFind(null);
    setIsReadOnly(null);
    setHits(null);
    setError(null);
    setIsLoading(false);
  };

  //==========================================================API Handlers
  const handleSubmitForm = (e) => {
    e.preventDefault();
    /* build lenderAPI form / body, and update state to trigger useEffect API */
    //Validate
    //Build new form
    /* Need to clean the data, by removing empty fields, and local only fields */
    const rmvEmptyKeyPairs = (inputObj) => {
      Object.entries(inputObj).forEach(([key, value]) => {
        if (value.length === 0 || value === '') {
          delete inputObj[key];
        }
      });
      return inputObj;
    };
    // Remove Form IDs (local only)
    const rptInfoToSubmit = { ...reportInfo };
    delete rptInfoToSubmit.rptID;
    delete rptInfoToSubmit.reference;
    delete rptInfoToSubmit.reported;

    const entitiesToSubmit = [...entities];
    entitiesToSubmit.forEach((x) => {
      rmvEmptyKeyPairs(x);

      delete x.formID;
    });

    const indvidualsToSubmit = [...individuals];
    indvidualsToSubmit.forEach((indv) => {
      rmvEmptyKeyPairs(indv);
      const isOMVICEmpty =
        indv.omvic_registration_certificate && Object.values(indv.omvic_registration_certificate).every((x) => !x);
      if (isOMVICEmpty) delete indv.omvic_registration_certificate;
      const isDLEmpty = indv.drivers_license && Object.values(indv.drivers_license).every((x) => !x);
      if (isDLEmpty) delete indv.drivers_license;
      const isPassport = indv.passport && Object.values(indv.passport).every((x) => !x);
      if (isPassport) delete indv.passport;
      delete indv.formID;
    });

    //Remove Empty Fields
    const noLoanInfo =
      rptInfoToSubmit.loan &&
      (rptInfoToSubmit.loan.amount_requested === null || rptInfoToSubmit.loan.amount_funded === null);

    if (noLoanInfo) delete rptInfoToSubmit.loan;
    // const noFlags = rptInfoToSubmit.flags.length === 0;
    // if (noFlags) delete rptInfoToSubmit.flags;

    const lendersAPIForm = {
      ...rptInfoToSubmit,
      entities: entitiesToSubmit,
      individuals: indvidualsToSubmit,
    };

    //Submit to APi
    setDataToSubmit(lendersAPIForm);
  };

  useEffect(() => {
    //Submit form to lenders API
    const submitToLenders = async (report) => {
      setIsLoading(true);
      try {
        setError(null);
        const SHOW_HITS_AS_RES = true;
        const res = await api.submitReport(lendersKey, reportInfo.rptID, report, { immediate: SHOW_HITS_AS_RES });
        setIsReadOnly(true);
        if (SHOW_HITS_AS_RES === true) {
          //Set Hits
          setHits(res.data);
        }
        setDataToSubmit(null);
        setIsLoading(false);
      } catch (e) {
        setError(e);
        setDataToSubmit(null);
        setIsLoading(false);
      }
    };
    dataToSubmit && submitToLenders(dataToSubmit);
    //eslint-disable-next-line
  }, [dataToSubmit]);

  const searchLender = (e) => {
    e.preventDefault();
    const rptIDToFind = reportInfo.rptID;
    if (!rptIDToFind) throw new Error('Need an ID To search');

    resetState();
    setDataToFind(rptIDToFind);
  };
  useEffect(() => {
    const searchLenderAPI = async (rptID) => {
      try {
        setIsLoading(true);
        setError(null);
        if (!rptID) throw new Error('Missing report ID');
        const rptRes = await api.viewReport(lendersKey, rptID);
        const hitsRes = await api.searchHits(lendersKey, rptID);
        const report = rptRes.data.report;
        const hits = hitsRes.data; //{status, reports: []}

        // const reportres = await import('../../utils/A-Dashboard/Examples/ex-Read.json');
        // const hitsres = await import('../../utils/A-Dashboard/Examples/exHits.json');
        // const report = reportres.report;
        // const hits = { status: hitsres.status, reports: hitsres.reports };

        setHits(hits);
        setReportInfo({ ...report, rptID: rptID });
        setIsReadOnly(true);
        setEntities(report.entities);
        setIndividuals(report.individuals);
        setDataToFind(null);
        setIsLoading(false);
      } catch (e) {
        setDataToFind(null);
        setIsLoading(false);
        setError(e);
      }
    };
    dataToFind && searchLenderAPI(dataToFind);
    //eslint-disable-next-line
  }, [dataToFind]);

  //===================================================Parse data for rendering
  const parsedEntities =
    entities &&
    Object.values(entities)?.map((x, i) => {
      return (
        <ReportItem
          itemType="ENTITY"
          key={`entity-item-${i}`}
          eventKey={`e${i}`}
          formData={x}
          removeItem={removeItem}
          updateFormState={updateEntities}
          updateList={updateStateList}
          isReadOnly={true} //Parachute wont use Entities so read only
        />
      );
    });

  const parsedIndividuals =
    individuals &&
    Object.values(individuals)?.map((x, i) => {
      return (
        <ReportItem
          itemType="INDIVIDUAL"
          key={`indv-item-${i}`}
          eventKey={`i${i}`}
          formData={x}
          removeItem={removeItem}
          updateList={updateStateList}
          parentList={individuals}
          isReadOnly={isReadOnly}
        />
      );
    });

  //=======================================================Rendering
  return (
    <div id="lendersSubmitRpt">
      <Form onSubmit={handleSubmitForm}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="rptID">
            <Form.Label>Report reference</Form.Label>
            <span style={{ display: 'flex' }}>
              <Form.Control
                placeholder=" View Report or Assign reference"
                onChange={updateReportInfo}
                value={reportInfo.rptID}
                required
              />

              <Button
                style={{ marginLeft: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                as={Col}
                onClick={searchLender}
              >
                {isLoading ? <Spinner animation="border" role="status" size="sm" /> : 'View'}
              </Button>

              {(reportInfo.reported || hits) && (
                <Button style={{ marginLeft: '16px' }} as={Col} onClick={resetState}>
                  {/* Reset page */}
                  New Report
                </Button>
              )}
            </span>
            {/* {reportInfo.reported && <p>{`Reported: ${new Date(reportInfo.reported * 1000)}`}</p>} */}
            {reportInfo.reported && <p>{`Reported: ${fromUnixTime(reportInfo.reported)}`}</p>}
          </Form.Group>
        </Row>

        <Form.Group as={Col} controlId="status">
          <Form.Label>Application Status</Form.Label>
          <Form.Select
            defaultValue=""
            onChange={updateReportInfo}
            value={reportInfo.status}
            disabled={isReadOnly}
            required
          >
            {REPORT.status.map((s, i) => (
              <option key={`status-opt-${i}`}>{s}</option>
            ))}
          </Form.Select>
        </Form.Group>

        {isReadOnly && reportInfo.loan && !Object.values(reportInfo.loan).some((x) => x) && (
          <LoanSection formData={reportInfo.loan} isReadOnly={isReadOnly} updateLoan={updateLoan} />
        )}

        <FlagSection flagsState={reportInfo.flags} addFlag={addFlag} removeFlag={removeFlag} isReadOnly={isReadOnly} />

        <br />
        {entities.length > 0 && (
          <section>
            {isReadOnly && <h4>Entity(s)</h4>}
            {/* {isReadOnly ? <h4>Entity(s)</h4> : <Button onClick={addEntityToReport}>+ Entities(s)</Button>} */}
            <Accordion defaultActiveKey={['e0']}>{parsedEntities}</Accordion>
            <br />
          </section>
        )}
        <section>
          {isReadOnly ? <h4>Individual(s)</h4> : <Button onClick={addIndividualToReport}>+ Individual(s)</Button>}
          <Accordion defaultActiveKey={['i0']}>{parsedIndividuals}</Accordion>
        </section>
        <hr />
        {isReadOnly ? (
          <Button
            variant="outline-primary"
            onClick={(e) => {
              e.preventDefault();
              setDataToSubmit(null);
              setDataToFind(null);
              setIsReadOnly(false);
            }}
          >
            Edit Report
          </Button>
        ) : (
          <Button variant="primary" type="submit">
            {isLoading ? <Spinner animation="border" role="status" size="sm" /> : 'Submit'}
          </Button>
        )}
        {reportInfo.reported && !isReadOnly && (
          <Button
            variant="primary"
            style={{ marginLeft: '16px' }}
            onClick={(e) => {
              e.preventDefault();
              setIsReadOnly(true);
            }}
          >
            Cancel
          </Button>
        )}
      </Form>
      <ErrorToast clearError={clearError} error={error} />
    </div>
  );
}
