import React from 'react';
import IconButton from './IconButton';
import LabelButton from './LabelButton';
import './QuestionTile.scss';

function QuestionTile({ question, answerOptions, onClick, labelButtons, selected, inspireImage, mood, disabled }) {
  const ActiveComponent = labelButtons ? LabelButton : IconButton;
  const optionButtons =
    answerOptions &&
    answerOptions.map((option, index) => {
      return (
        <ActiveComponent key={`questOpt-${index}}`} onClick={onClick} selected={selected} disabled={disabled}>
          {option}
        </ActiveComponent>
      );
      /* [] May need a better key (if this tile is used multiple times in the app)*/
    });
  const classToUse = ['questionOptions'];
  if (mood) classToUse.push('mood');
  return (
    <section className="questionContainer">
      <div>
        {inspireImage && <img className="inspireImg" src={inspireImage} alt="Inspire Me" />}
        {<h3 className="surveyQuestion">{question}</h3>}
      </div>
      <div className={classToUse.join(' ')}>{optionButtons}</div>
    </section>
  );
}

export default QuestionTile;
