import React, { useState, useEffect } from 'react';
// import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import api from '../../../utils/api';
// import { compareNames, formatDateForDisplay, parseIdFromLink } from '../../../utils/helpers';
import { formatDateForDisplay } from '../../../utils/helpers';

import { toast } from 'react-toastify';
import { Button } from "react-bootstrap";
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
// import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isValid from 'date-fns/isValid';
// import getYear from 'date-fns/getYear';
import { MONTH_NUM_TO_NAME, USER_TYPE,PARTNERS_MAP } from '../../../utils/constants';

import Modal from 'react-bootstrap/Modal';
import cookies from '../../../utils/cookies';

import checkCircleIcon from '../../../assets/icons/blueCheckCircleIcon.svg';
import ModalTitleBlock from '../../ModalTitleBlock';
import WideBtn from '../../WideBtn';

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { useCallback } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
export default function AssessmentViewer() {


  const [confirmationData, setConfirmationData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);

  const [totalTableElements, setTotalTableElements] = useState(0);
  console.log(totalTableElements)
  const [selectedOption, setSelectedOption] = useState('all');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [gridApi, setGridApi] = useState(null);
  const perPage = 10000;
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const actionsFormatterCreateDateTime = ({ value, data }) => {
    if (!value) {
      return 'N/A';
    }
    return <>
      {formatDateForDisplay(value)}
    </>;
  }

  const actionsFormatterWrapText = ({ value, data }) => {
    if (!value) {
      return 'N/A';
    }
    return <>
      <div class="request-top" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
        <p>{value}</p>
      </div>
    </>;
  }

  const actionsFormatterTicks = ({ value, data }) => {

    return <>
      {value === true ? '✅' : data?.assessmentMonth ? '❌' : '-'}
    </>;
  }
  const weeklyAnalyticsCall = useCallback(async () => {
    // const getAndSetAllUsers = async () => {
    //   try {
    //     const rawUsersRes = await api.getAllUsersSortByfirstNameByPage();
    //     const initUsers = rawUsersRes.data._embedded.users.filter((x) => x.role.name === 'ROLE_USER');
    //     setUsers(initUsers);
    //   } catch (e) {
    //     console.error(e);
    //   }
    // };
    // getAndSetAllUsers();
  }, []);

  const [report, setReport] = useState(null);
  // Function to convert data to CSV format
  const convertToCSV = () => {

    const header = Object.keys(report[0]).join(',');
    const rows = report.map(obj => Object.values(obj).join(',')).join('\n');
    return `${header}\n${rows}`;




  };

  // Function to trigger download
  const downloadCSV = () => {
    if (report.length !== 0) {
      const csvContent = convertToCSV();
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'assessments_data.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.dismiss();
      toast.success("No data to download!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
   
  };

  const today = cookies.getToday();
  const [selectedMonth, setSelectedMonth] = useState(MONTH_NUM_TO_NAME[today.month() + 1]);
  const [selectedYear, setSelectedYear] = useState(today.year());
  const [selectedUserType, setSelectedUserType] = useState('USER');
  const [selectedPartner, setSelectedPartner] = useState('ALL');
  /*
  const [assessmentData, setAssessmentData] = useState(null);
  const [status, setStatus] = useState({ loading: true, error: null });
*/
  const handleViewMonth = (e) => {
    const value = e.target.value;
    setSelectedMonth(value);
  };
  const handleYear = (e) => {
    const value = e.target.value;
    if (value.length > 4) return;
    setSelectedYear(value);
  };

  const handleViewUserType = (e) => {
    const value = e.target.value;
    setSelectedUserType(value);
  };
  const handlePartner = (e) => {
    const value = e.target.value;
    setSelectedPartner(value);
  };
  //================================================================User handling
  // const [users, setUsers] = useState(null);
  const [showAssessmentModal, setshowAssessmentModal] = useState(false);
  useEffect(() => {
    //Get All users, limit to 25 pagination
    //[] Should it update when the dates change? like a refresh?


  }, []);
  const closeAssessmentModal = () => {
    setshowAssessmentModal(false);
  };
  const fetchPlanDetails = (userID) => {
    if (!userID) return;

    console.log(userID)
    // userID = 164043;
    // let today = new Date();
    // today = today.setMonth(today.getMonth() - 1);
    // let month = ('0' + (today.getMonth() + 1)).slice(-2);
    // let year = today.getFullYear();

    // let datestring = year + "-" + month;

    let datestring = `${selectedYear}-${cookies.getMonthNumber(selectedMonth)}`;


    let fetchSapData = {
      "userId": userID,
      "assessmentMonth": datestring,
    };

    api.fetchSapAdmin(fetchSapData).then(resp => resp?.data)
      .then(res1 => {
        if (!res1) {
          showToast(res1?.message || 'No plan details to display');
          return;
        }
        console.log('submit success', res1);
        setshowAssessmentModal(true)
        setConfirmationData(res1);
        // setIsLoading(false);
        // setConfirmationData(res1);
        // // closeAssessment();
        // console.log('submit success', res1);

        // toast.dismiss();
        // toast.success("Your plan is set and submitted!", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      }).catch(err => {
        showToast(err?.response?.data?.message || 'No plan details to display');
        // setIsLoading(false);
        console.log('submit contact error', err);
      });

  }


  const showToast = async (msg) => {
    try {
      toast.dismiss();
      toast.info(msg, {
        position: toast.POSITION.TOP_CENTER
      });
    } catch (error) {
      console.log('error', error);
    }

  }


  //================================================================Assessment handling
  const actionsFormatterA = ({ value, data }) => {

    return <>
      <Button
        variant="outline-primary"
        size="sm"

        onClick={() => fetchPlanDetails(data?.id)}
      // href={`./userViewer?username=${data?.username}`}
      >
        Plan Details
      </Button>
      &nbsp;&nbsp;
      <Button
        variant="outline-success"
        size="sm"

        onClick={() => fetchPlanDetails(data?.id)}
      // href={`./userViewer?username=${data?.username}`}
      >
       {/* DEMO - info@encriss.com */}
       {data?.username}
      </Button>
    </>;
  }
  useEffect(() => {



    weeklyAnalyticsCall();
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);

          gridApi.showLoadingOverlay();
          const page = params.endRow / perPage;




          /*
                    const getAssessmentsOfUsers = async (dateRange, totalElements, params,page, perPage) => {
                      try {
                        setStatus({ loading: true, error: null });
                        setAssessmentData(null);
                
                     
                        const monthEnd = endOfMonth(new Date(dateString)); //15th is arbitrary, time is midnight EST
                        const filteredUsers = users.filter((x) => {
                          const diff = differenceInCalendarDays(new Date(x.loanStartDate), monthEnd);
                          const isTestAccount = cookies.processenv.REACT_APP_ENV === 'api.prod' && x.firstName === 'FE Test';
                          const filterItem = !isTestAccount && x.loanStartDate ? diff <= 0 : false;
                          return filterItem;
                        });
                        const cbHistoryToFetch = filteredUsers.map((x) => parseIdFromLink(x._links.self.href));
                        const resPromise = await Promise.all(cbHistoryToFetch.map((uid) => api.getCBHistory(uid, dateRange)));
                      
                        const data = filteredUsers.map((x, i) => {
                          const { firstName, lastName, username, loanStartDate } = x;
                
                          const cbHistory = resPromise[i].data && Object.entries(resPromise[i].data);
                          if (cbHistory.length === 0) {
                            return {
                              id: parseIdFromLink(x._links.self.href),
                              firstName,
                              lastName,
                              username,
                              noNewDebt: '',
                              paidBillsOnTime: '',
                              paidParachuteOnTime: '',
                              underUtilization: '',
                              passed: 'No CB History',
                              loanStartDate,
                            };
                          }
                          const [historyMonth, historyDetails] = cbHistory[0];
                          const {
                            noNewDebt,
                            paidBillsOnTime,
                            paidParachuteOnTime,
                            underUtilization,
                            assessmentCreateDateTime,
                            utilizationFailureReason,
                          } = historyDetails;
                          const passedAssessment = [noNewDebt, paidBillsOnTime, paidParachuteOnTime, underUtilization].every(
                            (x) => x === true
                          ); 
                          const createUtilizationFlag = (failureReason) => {
                              if (failureReason === null) return failureReason;
                            if (failureReason === 'OUT_OF_SYNC') return '⚠️ Acct(s) Out of Sync';
                            if (failureReason === 'NOT_CONNECTED') return '❗ No Acct. Links';
                          };
                          const userData = {
                            id: parseIdFromLink(x._links.self.href),
                            firstName,
                            lastName,
                            username,
                            noNewDebt: noNewDebt === true ? '✅' : assessmentCreateDateTime ? '❌' : '-',
                            paidBillsOnTime: paidBillsOnTime === true ? '✅' : assessmentCreateDateTime ? '❌' : '-',
                            underUtilization: underUtilization === true ? '✅' : assessmentCreateDateTime ? '❌' : '-',
                            paidParachuteOnTime: paidParachuteOnTime === true ? '✅' : '❌',
                            historyMonth,
                            passed: passedAssessment ? 'PASS' : 'FAIL',
                            loanStartDate,
                            assessmentCreateDateTime,
                            utilizationFlag: createUtilizationFlag(utilizationFailureReason),
                          };
                
                          return userData;
                        });
                
                         const passedAssessment = data.filter((x) => x.passed === 'PASS').sort(compareNames);
                        const failedAssessment = data
                          .filter((x) => x.passed === 'FAIL' && x.assessmentCreateDateTime)
                          .sort(compareNames);
                        const incompleteAssessment = data
                          .filter((x) => x.passed === 'FAIL' && x.assessmentCreateDateTime === null)
                          .sort(compareNames);
                
                        const sortedData = [...passedAssessment, ...failedAssessment, ...incompleteAssessment];
                
                
                
                
                        sortedData.sort((a, b) => a.firstName.localeCompare(b.firstName));
                        setAssessmentData(sortedData);
                        params.successCallback(sortedData, totalElements);
                        setStatus({ loading: false, error: null });
                      } catch (e) {
                        setStatus({ loading: false, error: `${e}` });
                      }
                    };
          */
          const dateString = `15 ${selectedMonth} ${selectedYear}`;
          const date = new Date(dateString); //15th is arbitrary, time is midnight EST
          let currDateRange = null;
          const selectedYearString = `${selectedYear}`;

          if (selectedYearString.length === 4 && isValid(date)) {
            currDateRange = {
              startDate: startOfMonth(date).toISOString(),
              endDate: endOfMonth(date).toISOString(),
            };
          }
          console.log(currDateRange)
          // let totalElements=0;
          //  currDateRange && users && getAssessmentsOfUsers(currDateRange, totalElements, params,page, perPage);
          let data = {
            "month": cookies.getMonthNumber(selectedMonth),
            "year": selectedYear
          }
          api.findAllCompletedSelfAssessmentInMonthByUserType(data, currentPage, pageSize, selectedUserType)

            .then(resp => resp?.data)
            .then(res => {
              gridApi.hideOverlay();
              console.log("Akshat",res)
              const initUsers = res.content;
              setTotalPages(res.totalPages || 0);
              const filteredUserspartner = initUsers.filter((user) => {
                
                return selectedPartner === 'ALL'|| user.partner?.name === selectedPartner;
              });

              let filteredUsers = filteredUserspartner.filter((x) => {
                console.log(x)
                let filterItem = true;
                if (selectedOption === 'all') {
                  //do nothing
                  filterItem = true;
                } else if (selectedOption === 'enabled') {
                  filterItem = x.enabled === true ? true : false;
                } else if (selectedOption === 'disabled') {
                  filterItem = x.enabled === false ? true : false;
                } else {
                  //do nothing
                }
                return filterItem;
              });
              setReport(filteredUsers ? filteredUsers : {});
              console.log(filteredUsers);
              setTotalTableElements(filteredUsers.length)
              params.successCallback(filteredUsers, filteredUsers.length);

            }).catch(err => {
              gridApi.hideOverlay();
              console.log(err);
              params.successCallback([], 0);
            });
        }
      }
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, weeklyAnalyticsCall, selectedMonth, selectedYear, selectedUserType, selectedOption, selectedPartner, currentPage, pageSize]);

  return (

    <>
      <div className='d-flex flex-column flex-grow-1 w-100'>
        <div className='container pt-4'>
          <h4>{`Self Assessment Results`}</h4>
          <div className='row'>
            <div className="col-sm-6">
              <label>Select Month to view</label>
              <Form.Select
                id="quizMonth"
                className="mb-4"
                aria-label="Month to view"
                onChange={handleViewMonth}
                value={selectedMonth}
              >
                {Object.values(MONTH_NUM_TO_NAME)?.map((i) => (
                  <option key={`dobM-${i}`} value={i}>
                    {i}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="col-sm-6">
              <label>Select Year to view</label>
              <Form.Control value={selectedYear} className="mb-4" type="number" onChange={handleYear} />
            </div>
          </div>


          <div className='row'>
            <div>
              <div colSpan="6">{`Filter by enabled/disable`}
                <hr></hr>
                <div>
                  <label>
                    <input
                      type="radio"
                      value="all"
                      checked={selectedOption === 'all'}
                      onChange={handleOptionChange}
                    />
                    &nbsp; &nbsp;all
                  </label>
                  &nbsp; &nbsp;
                  <label>
                    <input
                      type="radio"
                      value="enabled"
                      checked={selectedOption === 'enabled'}
                      onChange={handleOptionChange}
                    />
                    &nbsp; &nbsp;enabled
                  </label>
                  &nbsp; &nbsp;
                  <label>
                    <input
                      type="radio"
                      value="disabled"
                      checked={selectedOption === 'disabled'}
                      onChange={handleOptionChange}
                    />
                    &nbsp; &nbsp;disabled
                  </label>
                  <hr></hr>
                  <div>Selected option: {selectedOption}</div>
                </div>

              </div>
            </div>
          </div>



          <div className='row'>
            <div className="col-sm-6 col-md-4">
              <label>Select User Type to view</label>
              <Form.Select
                id="userType"
                className="mb-4"
                aria-label="User Type to view"
                onChange={handleViewUserType}
                value={selectedUserType}>
                {Object.values(USER_TYPE)?.map((i) => (
                  <option key={`userType-${i}`} value={i}>
                    {i === 'USER' ? 'CUSTOMER' : i}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>

          <div className='row'>
            <div className="col-sm-6 col-md-4">
              <label>Partner</label>
              <Form.Select
                id="partner"
                className="mr-8"
                aria-label="User Type to view12"
                onChange={handlePartner}
                value={selectedPartner}>
                {Object.values(PARTNERS_MAP)?.map((i) => (
              <option key={`userType-${i}`} value={i}>
                {i}
              </option>
            ))}
              </Form.Select>
            </div>
          </div>


          <div className='d-flex align-items-center mb-4'>
            <span className='me-2 me-md-4 small fw-bold'>✅ = Correct</span>
            <span className='me-2 me-md-4 small fw-bold'>❌ = Wrong Answer</span>
            <span className='small fw-bold'>⚪ = No Answer</span>
          </div>
          <p>
            <strong>Display Order: </strong>
            Passed, Failed assessments, then incomplete assessments. Each Section is sorted by last name thenfirst name
          </p>

          <Button variant="primary" onClick={downloadCSV}>Download CSV</Button>

        </div>
        <br></br>



        <div className='container pb-4 flex-grow-1'>
          <div className='grid-responsive'>
            <div className="ag-theme-alpine" style={{ width: '1850px', height: '750px' }}>
              <AgGridReact
                pagination={true}
                rowModelType={'infinite'}
                paginationPageSize={perPage}
                cacheBlockSize={perPage}
                onGridReady={onGridReady}
                minColWidth={60}
                rowHeight={60}
                sortable={true}
                unSortIcon={true}
                defaultColDef={{ flex: 1, resizable: true, sortable: true, unSortIcon: true, sortingOrder: ['ASC', 'DESC'] }}
                overlayLoadingTemplate={
                  '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
                }
                overlayNoRowsTemplate={
                  '<span className="ag-overlay-loading-center">No data found to display.</span>'
                }
              >
                <AgGridColumn width={60} field="id" headerName="User ID" cellClass="vertical-middle" />
                <AgGridColumn minWidth={350} field="applicantId" headerName="Actions" cellRendererFramework={actionsFormatterA} cellClass="vertical-middle" />

                <AgGridColumn width={150} cellRendererFramework={actionsFormatterWrapText} field="firstName" headerName="First Name" cellClass="vertical-middle" />
                <AgGridColumn width={150} cellRendererFramework={actionsFormatterWrapText} field="lastName" headerName="Last Name" cellClass="vertical-middle" />
                <AgGridColumn width={200} cellRendererFramework={actionsFormatterWrapText} field="username" headerName="Username" cellClass="vertical-middle" />
                <AgGridColumn width={200} field="loanStartDate" headerName="Loan Start Date" cellClass="vertical-middle" cellRendererFramework={actionsFormatterCreateDateTime} />
                <AgGridColumn width={150} field="createDateTime" headerName="Create Date Time" cellClass="vertical-middle" cellRendererFramework={actionsFormatterCreateDateTime} />
                <AgGridColumn width={150} field="noNewDebt" headerName="No New Debt" cellClass="vertical-middle" cellRendererFramework={actionsFormatterTicks} />
                <AgGridColumn width={150} field="paidBillsOnTime" headerName="Paid Bills On Time" cellClass="vertical-middle" cellRendererFramework={actionsFormatterTicks} />
                <AgGridColumn width={150} field="underUtilization" headerName="Under Utilization" cellClass="vertical-middle" cellRendererFramework={actionsFormatterTicks} />
                <AgGridColumn width={150} field="paidParachuteOnTime" headerName="Paid Parachute On Time" cellClass="vertical-middle" cellRendererFramework={actionsFormatterTicks} />
                <AgGridColumn width={150} field="utilizationFlag" headerName="Utilization Flag" cellClass="vertical-middle" />
                <AgGridColumn width={150} field="passedAssessment" headerName="Passed" cellClass="vertical-middle" />
              </AgGridReact>
            </div>
          </div>
        </div>
      </div>




      {confirmationData && showAssessmentModal ? (

        <>
          {/* <pre>{JSON.stringify(confirmationData, null, 2)}</pre> */}
          <Modal
            id="goalsAssessment--modal"
            show={showAssessmentModal}
            animation={false}
            // onHide={handleClose}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >




            <div className="wideCntr planForm container" style={{ textAlign: 'left', width: 'auto' }}>
              <br></br>
              <ModalTitleBlock
                title={`Plan Details`}
                body={``}
                icon={checkCircleIcon}
              />

              {/* display only if any one is true */
                confirmationData['promises']['automaticWithdrawal'] === true || confirmationData['promises']['spendLess'] === true || confirmationData['promises']['contactBiller'] === true || confirmationData['promises']['description1'] ? (
                  <>
                    <br></br>
                    <hr></hr>
                    <h5>"Pay all my bills on time"</h5>
                    <Card>
                      <Card.Header><h7>Plan</h7></Card.Header>
                      <ListGroup variant="flush">
                        {confirmationData['promises']['automaticWithdrawal'] === true && (
                          <ListGroup.Item>I will set up automatic withdrawals, so funds come directly out of my account</ListGroup.Item>
                        )}

                        {confirmationData['promises']['spendLess'] === true && (

                          <ListGroup.Item>I will spend less so I have money left to pay my bills</ListGroup.Item>
                        )}
                        {confirmationData['promises']['contactBiller'] === true && (
                          <ListGroup.Item>I will contact billers and align payment dates to my pay schedule</ListGroup.Item>
                        )}

                        {confirmationData['promises']['description1'] && (
                          <ListGroup.Item>{confirmationData['promises']['description1']}</ListGroup.Item>
                        )}
                      </ListGroup>
                    </Card>

                  </>) : (<></>)}

              {/* display only if any one is true */

                confirmationData['promises']['applySavingsToCreditCard'] === true || confirmationData['promises']['payMoreOnCreditCard'] === true || confirmationData['promises']['payOffCreditCard'] === true || confirmationData['promises']['description2'] ? (
                  <>
                    <br></br>
                    <hr></hr>
                    <h5>"Maintain credit card utilization of 50% or less"</h5>
                    <Card>
                      <Card.Header><h7>Plan</h7></Card.Header>
                      <ListGroup variant="flush">
                        {confirmationData['promises']['applySavingsToCreditCard'] === true && (
                          <ListGroup.Item>I will save up to $50 this coming month and apply the saving to my credit card</ListGroup.Item>
                        )}
                        {confirmationData['promises']['payMoreOnCreditCard'] === true && (
                          <ListGroup.Item>I will pay more than the minimum amount on my credit card</ListGroup.Item>
                        )}
                        {confirmationData['promises']['payOffCreditCard'] === true && (
                          <ListGroup.Item>I will pay off my credit card</ListGroup.Item>
                        )}
                        {confirmationData['promises']['description2'] && (
                          <ListGroup.Item>{confirmationData['promises']['description2']}</ListGroup.Item>
                        )}
                      </ListGroup>
                    </Card>
                  </>) : (<></>)}


              {/* display only if any one is true */
                confirmationData['promises']['chatBeforeNewDebt'] === true || confirmationData['promises']['noNewDebtUpcomingMonth'] === true || confirmationData['promises']['chatForDebtReduction'] === true || confirmationData['promises']['description3'] ? (
                  <>
                    <br></br>
                    <hr></hr>
                    <h5>"I will not take on new debt"</h5>
                    <Card>
                      <Card.Header><h7>Plan</h7></Card.Header>
                      <ListGroup variant="flush">
                        {confirmationData['promises']['chatBeforeNewDebt'] === true && (
                          <ListGroup.Item>I will chat to Parachute before taking on new debt</ListGroup.Item>
                        )}
                        {confirmationData['promises']['noNewDebtUpcomingMonth'] === true && (
                          <ListGroup.Item>I will not take on new debt this coming month</ListGroup.Item>
                        )}
                        {confirmationData['promises']['chatForDebtReduction'] === true && (
                          <ListGroup.Item>I will chat with Parachute abount how i can reduce the additional debt i have taken out</ListGroup.Item>
                        )}
                        {confirmationData['promises']['description3'] && (
                          <ListGroup.Item>{confirmationData['promises']['description3']}</ListGroup.Item>
                        )}
                      </ListGroup>
                    </Card>
                  </>) : (<></>)


              }


              <WideBtn onClick={closeAssessmentModal}>
                Close
              </WideBtn>

            </div>

          </Modal>


        </>

      ) : (<></>)}
<div>
        <div
          className="pagination"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {/* Previous Button */}
          <button
            style={{
              backgroundColor: currentPage > 0 ? "green" : "white",
              color: currentPage > 0 ? "white" : "black",
              border: "1px solid #ccc",
              padding: "10px 20px",
              cursor: currentPage > 0 ? "pointer" : "not-allowed",
              margin: "0",
              minWidth: "100px", // Ensures buttons have the same width
              textAlign: "center",
            }}
            disabled={currentPage === 0}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
          >
            Previous
          </button>

          {/* Page Info */}
          <span
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
              flex: "1", // Ensures it aligns properly between the buttons
            }}
          >
            {`Page ${currentPage + 1} of ${totalPages}`}
          </span>

          {/* Next Button */}
          <button
            style={{
              backgroundColor: currentPage + 1 < totalPages ? "green" : "white",
              color: currentPage + 1 < totalPages ? "white" : "black",
              border: "1px solid #ccc",
              padding: "10px 20px",
              cursor: currentPage + 1 < totalPages ? "pointer" : "not-allowed",
              margin: "0",
              minWidth: "100px", // Ensures buttons have the same width
              textAlign: "center",
            }}
            disabled={currentPage + 1 === totalPages}
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages - 1))}
          >
            Next
          </button>
        </div>

      </div>

    </>


  );
}
