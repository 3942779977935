const FLAGS = [
  'identity-theft',
  'identity-fraud',
  'washing-transactions',
  'rerouting-sales',
  'consumer-proposal',
  'personal-bankruptcy',
  'incorporated-bankruptcy',
  'document-tampering',
  'misrepresentation-of-business-ownership',
  'sells-business-without-notifying-lender',
  'first-payment-default',
  'misrepresents-services',
  'misrepresents-loan-product',
  'failure-to-deliver-services',
  'illegal-or-abusive-sales-practices',
  'mishandling-borrower-data',
];
const STATUS = ['', 'submitted', 'rejected', 'funded', 'withdrawn', 'paid-in-full', 'charged-off'];
const ADDRESS_TYPES = ['', 'home', 'office', 'mailing', 'other'];
const LINK_TYPE = ['', 'website'];
const PHONE_TYPES = ['', 'personal', 'home', 'work', 'office', 'fax', 'other'];

export const REPORT = {
  flags: FLAGS,
  status: STATUS,
};

export const ENTITIES = {
  role: ['', 'merchant-borrower', 'merchant-partner'],
  entity_type: ['', 'corporation', 'partnership', 'sole-proprietorship', 'cooperative'],
  ADDRESS_TYPES: ADDRESS_TYPES,
  LINK_TYPE: LINK_TYPE,
  EMAIL_TYPES: ['', 'support', 'other'],
  PHONE_TYPES: PHONE_TYPES,
};

export const INDIVIDUALS = {
  role: ['', 'consumer-borrower', 'merchant-partner-rep', 'merchant-borrower-rep'],
  ADDRESS_TYPES: ADDRESS_TYPES,
  LINK_TYPE: LINK_TYPE,
  EMAIL_TYPES: ['', 'personal', 'work', 'other'],
  PHONE_TYPES: PHONE_TYPES,
};
