import React from 'react';
import './TextButton.scss';

const TextButton = ({ children, onClick, id, className, style }) => {
  const classToUse = ['textButton'];
  className && classToUse.push(`${className}`);
  return (
    <h5 id={id} className={classToUse.join(' ')} onClick={onClick} style={style}>
      {children}
    </h5>
  );
};

export default TextButton;
