/* Optional Slide, to say why the goals were not met
Can submit reason
    -After saving Shows thanks with an btn that will close the modal
Can Cancel
    - Return to results

- Saving function and data will be passed in from Index
- Reason will also be stored @ higher level and drilled down.

*/

import React, { useState, useEffect } from 'react';
import ModalTitleBlock from '../ModalTitleBlock';
import WideBtn from '../WideBtn';
import missedGoal from '../../assets/icons/missedGoal.svg';
import achievementIcon from '../../assets/icons/achievementIcon.svg';
import Spinner from 'react-bootstrap/Spinner';

import Form from 'react-bootstrap/Form';
import TextButton from '../TextButton';

import './TellMore.scss';

export default function TellMore({ submitReason, closeAssessment, backToSummary, skipReason, status, reasonPatched }) {
  const [mode, setMode] = useState(skipReason ? 'thanks' : 'feedback');
  const [form, setForm] = useState('');
  const { loading, error } = status;
  const indicator = <Spinner animation="border" size="sm" />;
  const handleSubmit = async (e) => {
    e.preventDefault();
    submitReason(form);
  };
  const handleChange = (e) => {
    const v = e.target.value;
    setForm(v);
  };
  useEffect(() => {
    reasonPatched && setMode('thanks');
  }, [reasonPatched]);

  const feedback = (
    <>
      <ModalTitleBlock
        title="What happened?"
        body="We want to know more about why you didn’t meet your monthly goals so we can help you stay on track. Did something get in your way? Were the goals unclear? Was it something else?"
        icon={missedGoal}
      />
      <hr />
      <div>
        <Form id="assessment--reasonForm">
          <Form.Group controlId="reason">
            <Form.Label>Reason</Form.Label>
            <Form.Control as="textarea" value={form} onChange={handleChange} />
          </Form.Group>
        </Form>
        <WideBtn onClick={handleSubmit} disabled={loading}>
          {loading ? indicator : 'Submit'}
        </WideBtn>
        {error && (
          <p style={{ textAlign: 'center' }} className="error">
            {error}
          </p>
        )}
        <TextButton onClick={backToSummary}>Cancel</TextButton>
        {/* Cancel set slide back to summary */}
      </div>
    </>
  );

  const thanks = (
    <>
      <ModalTitleBlock title="Thanks for the feedback!" body="We’ll get back to you soon." icon={achievementIcon} />
      <hr />
      <ModalTitleBlock
        className="thanks"
        title="Get on track next month."
        body="Try again to reach the goal for next month and you can earn cash-back! The first step is to commit."
      />
      <WideBtn onClick={closeAssessment}>Stay on track next month</WideBtn>
    </>
  );
  return (
    <div id="tellMore">
      {mode === 'feedback' && feedback}
      {mode === 'thanks' && thanks}
    </div>
  );
}
