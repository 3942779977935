import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import api from '../../../utils/api';

export default function AssessmentForm() {
  const [formData, setFormData] = useState({});
  const [dataToUpload, setDataToUpload] = useState(null);
  const [validated, setValidated] = useState(false);
  const [lastUploadSuccess, setLastUploadSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isValidUser, setIsValidUser] = useState(true);  // To track if the user is valid

  // User validation function
  const validateUser = async (userName) => {
    try {
      const res = await api.checkUser({ email: userName });
      console.log('User validation response:', res);
      if (res.valid) {
        setIsValidUser(true);
        setError(null);
      } else {
        setIsValidUser(false);
        setError('Invalid user. You are not allowed to proceed.');
      }
    } catch (e) {
      console.error('User validation error:', e);
      setError('Error validating user. Please try again.');
      setIsValidUser(false);
    }
  };

  // API call to create assessment
  useEffect(() => {
    const uploadAssessment = async () => {
      try {
        const res = await api.createAssessment(dataToUpload); // API call
        console.log('API Response:', res);
        setLastUploadSuccess(true);
        setValidated(false);
        setDataToUpload(null);
        setFormData({});
      } catch (e) {
        console.error('API Error:', e);
        setError(e.message || 'Unknown error');
        setDataToUpload(null);
      }
    };

    if (dataToUpload) {
      uploadAssessment();
    }
  }, [dataToUpload]);

  // Handling form input changes
  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;

    if (type === 'datetime-local') {
      // Handle datetime-local input
      const formattedDate = new Date(value).toISOString().replace('Z', '-04:00');
      setFormData((prev) => ({
        ...prev,
        [id]: formattedDate,
      }));
    } else if (type === 'radio') {
      // For radio buttons, convert the value to a boolean (true/false)
      const booleanValue = value === "true"; // value will be "true" or "false" (string)
      setFormData((prev) => ({
        ...prev,
        [id]: booleanValue,
      }));
    } else {
      // Handle other input types (checkbox, text, etc.)
      setFormData((prev) => ({
        ...prev,
        [id]: type === 'checkbox' ? checked : value,
      }));
    }

    // Reset lastUploadSuccess state
    setLastUploadSuccess(false);
  };

  // Validate user when focus leaves the username field
  const handleBlur = async (e) => {
    const { value } = e.target;

    if (value) {
      await validateUser(value); // Validate user on blur
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    setError(null);

    // Basic validation
    if (!formData.username || !formData.assessmentMonth) {
      setError('Username and Assessment Month are required.');
      return;
    }

    // If user is not valid, prevent form submission
    if (!isValidUser) {
      setError('Invalid user. Please correct the username.');
      return;
    }

    // Finalize data to send to API
    const apiData = { ...formData };
    setDataToUpload(apiData); // Trigger API call via useEffect
  };

  return (
    <div style={{ width: '70vw' }}>
      <h2>Submit Assessment</h2>
      {lastUploadSuccess && <h3>Upload Successful</h3>}
      {error && <h3 style={{ color: 'red' }}>{`Error: ${error}`}</h3>}
      <Form onSubmit={handleSubmit} validated={validated.toString()}>
        <Form.Group className="mb-3" controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter username"
            value={formData.username || ''}
            onChange={handleChange}
            onBlur={handleBlur}  // Trigger validation on blur
            required
            isInvalid={validated && !formData.username}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="assessmentMonth">
  <Form.Label>Assessment Month</Form.Label>
  <Form.Control
    type="month"  // User can select only month and year
    value={formData.assessmentMonth ? formData.assessmentMonth.slice(0, 7) : ''}  // Only month and year (YYYY-MM)
    onChange={(e) => {
      const selectedMonth = e.target.value;
      // Create the ISO string with default day (01), time (10:00:00) and time zone (-04:00)
      const formattedDate = `${selectedMonth}-01T10:00:00-04:00`;  // Default day 1, time 10:00:00, and time zone -04:00
      setFormData((prev) => ({
        ...prev,
        assessmentMonth: formattedDate,  // Set the formatted date
      }));
    }}
    required
    isInvalid={validated && !formData.assessmentMonth}
    max={new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 7)}  // Disable the current month and allow up to the previous month
    min={new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().slice(0, 7)}  // Allow months from last year (or whichever minimum you prefer)
  />
</Form.Group>





        <Form.Group className="mb-3" controlId="noNewDebt">
          <Form.Label>No New Debt</Form.Label>
          <Form.Check
            type="radio"
            label="Yes"
            name="noNewDebt" // Ensure both radio buttons have the same name
            value="true"
            checked={formData.noNewDebt === true} // Check if the value is true
            onChange={handleChange}
          />
          <Form.Check
            type="radio"
            label="No"
            name="noNewDebt" // Same name as the 'Yes' radio button
            value="false"
            checked={formData.noNewDebt === false} // Check if the value is false
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="paidBillsOnTime">
          <Form.Label>Paid Bills On Time</Form.Label>
          <Form.Check
            type="radio"
            label="Yes"
            name="paidBillsOnTime"
            value="true"
            checked={formData.paidBillsOnTime === true}
            onChange={handleChange}
          />
          <Form.Check
            type="radio"
            label="No"
            name="paidBillsOnTime"
            value="false"
            checked={formData.paidBillsOnTime === false}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="underUtilization">
          <Form.Label>Under Utilization</Form.Label>
          <Form.Check
            type="radio"
            label="Yes"
            name="underUtilization"
            value="true"
            checked={formData.underUtilization === true}
            onChange={handleChange}
          />
          <Form.Check
            type="radio"
            label="No"
            name="underUtilization"
            value="false"
            checked={formData.underUtilization === false}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="paidParachuteOnTime">
          <Form.Label>Paid Parachute On Time</Form.Label>
          <Form.Check
            type="radio"
            label="Yes"
            name="paidParachuteOnTime"
            value="true"
            checked={formData.paidParachuteOnTime === true}
            onChange={handleChange}
          />
          <Form.Check
            type="radio"
            label="No"
            name="paidParachuteOnTime"
            value="false"
            checked={formData.paidParachuteOnTime === false}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="reason">
          <Form.Label>Reason</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter your reason here"
            value={formData.reason || ''}
            onChange={handleChange}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}
