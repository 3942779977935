import React from 'react';
import msgIcon from '../../assets/icons/msgIcon.svg';

export default function SupportLink({ noIcon }) {
  return (
    <div className="applySupport">
      {!noIcon && <img src={msgIcon} alt="Message Bubble" />}
      <span>
        <p>We’re here to help.</p>
        <p>
          <a href="email:contactus@myparachute.co">contactus@myparachute.co</a>
        </p>
        <p>
          <a href="tel:8446702403">(844) 670-2403</a>
        </p>
      </span>
    </div>
  );
}
