import React from 'react';
import connectImg from '../../assets/onboarding/connect.svg';

export default function ConnectSlide() {
  return (
    <div>
      <img className="onboard-img" src={connectImg} alt="Connect to your accounts" />
      <div className="sptText">
        <h3>Securely connect your account(s).</h3>
        <p className="muted">
          In order to track your goals and earn cash-back, you’ll need to connect your financial account(s).
        </p>
      </div>
    </div>
  );
}
