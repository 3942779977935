import React from 'react';
import Button from 'react-bootstrap/Button';
import './WideBtn.scss';

export default function WideBtn({ children, onClick, variant = 'success', className, disabled, type, style, id }) {
  const classToUse = ['wideBtn'];
  disabled && classToUse.push('disabled');
  className && classToUse.push(className);
  return (
    <div className="wideCntr d-grid gap-2">
      <Button
        id={id}
        style={style}
        className={classToUse.join(' ')}
        variant={variant}
        size="lg"
        onClick={onClick}
        disabled={disabled}
        type={type}
      >
        {children}
      </Button>
    </div>
  );
}
