/* 
[]TODO: Selected Correct Answer should be bright (as if it isn't disabled)
-Style only, still want disabled behaviour 
Perhaps overide css or style from bootstrap?

[]TODO: propData should have better name,
likely the answer will be an object to offer clearer names (rather than obscure indexes)

NOTE: x !== null, is used frequesntly this is required due to nullish bahaviour of 0. If moving from indexes 
it could be changed
*/
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import ErrorNotification from '../../ErrorNotification';
import WideBtn from '../../WideBtn';
import './QuizTile.scss';

export default function QuizTile({ questionData, submitAnswer, id, handleCloseModal, disabled }) {
  const showError = !questionData;
  const { question, answerOptions, correctAnswer, userAnswer, explanation } = questionData;
  const [userAns, setUserAns] = useState(userAnswer);
  useEffect(() => {
    setUserAns(userAnswer || null);
  }, [questionData, userAnswer]);

  const validateAnswer = (userRes, correctAns) => {
    return userRes === correctAns ? true : false;
  };

  const handleAnswer = (e) => {
    const selection = e.target.textContent;
    // const indexOfuserRes = answerOptions.indexOf(selection);
    submitAnswer(selection); //Answer Index   //submit answer to api (handled by parent)
    setUserAns(selection);
  };
  const isResponseCorrect = userAns !== null ? validateAnswer(userAns, correctAnswer) : null;

  const optionButtons = answerOptions?.map((ans, i) => {
    let btnVariant = 'success'; //Green background
    let isBtnDisabled = false;
    //Format Button Style (via classnames or bootstrap options)
    //Iteration is by button / option
    if (userAns !== null) {
      const isBtnCorrectAnswer = answerOptions[i] === correctAnswer;
      const isBtnUserRes = userAns === answerOptions[i];

      isBtnDisabled = true; //Disable Click & Hover for ALL Btns

      //Add Appropriate style / variant
      if (isBtnUserRes) {
        // if (isBtnCorrectAnswer); //Leave with Green background
        if (!isBtnCorrectAnswer) btnVariant = 'outline-danger'; //Red Outline
      }
      if (!isBtnUserRes) {
        // if (isBtnCorrectAnswer); //Leave with Green background
        if (!isBtnCorrectAnswer) btnVariant = 'outline-secondary'; //Incorrect - plain outline
      }
    }

    return (
      <Button
        key={`bs-${i}-${ans}`}
        onClick={handleAnswer}
        className="quizBtn"
        variant={btnVariant}
        disabled={disabled || isBtnDisabled}
        size="lg"
      >
        {ans}
      </Button>
    );
  });

  const AnswerBlock = ({ isResponseCorrect, answer, explanation }) => {
    return (
      <div className="answerTile">
        <p className={isResponseCorrect ? 'response' : 'response incorrect'}>
          {isResponseCorrect ? 'You got it right!' : "You're incorrect."}
        </p>
        {!isResponseCorrect && <p className="response answer"> {`The correct answer is ${answer}`} </p>}
        <h3>{explanation}</h3>
      </div>
    );
  };

  return (
    <>
      {showError && (
        <div className="quizLoadError" style={{ display: 'grid', gap: '16px' }}>
          <ErrorNotification>Quiz unavailable.</ErrorNotification>
          <WideBtn onClick={handleCloseModal}>Continue</WideBtn>
        </div>
      )}
      {!showError && (
        <div id={id} className="quizBlock">
          <div className={id === 'onboardQuiz' ? 'borderedQuiz' : ''}>
            <div className="quizQuestion">
              <h2 className="light">{question}</h2>
            </div>
            <div className="quizOptions">{optionButtons}</div>
          </div>
          {userAns !== null && isResponseCorrect !== null && (
            <AnswerBlock isResponseCorrect={isResponseCorrect} answer={correctAnswer} explanation={explanation} />
          )}
        </div>
      )}
    </>
  );
}
