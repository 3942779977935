import axios from 'axios';
// import clientAPI from '../api';
// import cookies from '../cookies';

// const getAPIKey = async () => {
//   try {
//     const res = await clientAPI.getKeys();
//     return res.data.LENDERS_API_KEY;
//   } catch (e) {
//     throw e;
//   }
// };
const instance = axios.create({
  baseURL: `https://www.lendersapi.com/`,
});

instance.defaults.timeout = 30000;
//Only run if super admin, user to env
// const userRole = cookies.getRole();
// const user = cookies.processenv.REACT_APP_LENDERS_USERNAME;
// userRole === 'ROLE_SUPER_ADMIN' &&
//   getAPIKey().then((apiKey) => {
//     instance.defaults.auth = {
//       username: user,
//       password: apiKey,
//     };
//   });

export default instance;
