import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';


import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import $ from 'jquery';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useCallback } from 'react'

import Container from 'react-bootstrap/Container';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

import cookies from '../../../utils/cookies';
export default function UserAnalyticsViewer() {




  const d = cookies.getToday();
  const [selectedMonth, setSelectedMonth] = useState(d.month()+1);
  const [selectedYear, setSelectedYear] = useState(d.year());

  const handleChange1 = (event) => {
    setSelectedMonth(event.target.value );
  };
  const  handleChange2 = (event) => {
    setSelectedYear( event.target.value );
  };

 



  const [totalTableElements, setTotalTableElements] = useState(0);
  const [totalTableElements1, setTotalTableElements1] = useState(0);
  const [totalTableElements2, setTotalTableElements2] = useState(0);
  const [totalTableElements3, setTotalTableElements3] = useState(0);
  const [totalTableElements4, setTotalTableElements4] = useState(0);
  const [totalTableElements5, setTotalTableElements5] = useState(0);




  const [inspirationsList, setInspirationsList] = useState(null);

  const [inspirationsList1, setInspirationsList1] = useState(null);




  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }


  /*moods*/
  const [gridApi, setGridApi] = useState(null);
  const perPage = 10;
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const weeklyAnalyticsCall = useCallback(async () => {
    try {
      let fromDate = new Date($('#fromToDate').val()?.split(' - ')[0]);
      fromDate.setUTCHours(0, 0, 0, 0);
      fromDate.setDate(fromDate.getDate() + 1).toLocaleString('en-US');

      let toDate = new Date($('#fromToDate').val()?.split(' - ')[1]);
      toDate.setUTCHours(23, 59, 59, 999);
      toDate.setDate(toDate.getDate() + 1).toLocaleString('en-US');

      let data = {
        "month": selectedMonth,
        "year": selectedYear
      }
      const weeklyAnalyticsRes = await api.findAllLoggedInUsersByDate(data, 0, perPage);
      console.log(weeklyAnalyticsRes?.data?.content);

    } catch (e) {
      console.error(e);
    }
  }, [selectedMonth,selectedYear]);
  useEffect(() => {
    weeklyAnalyticsCall();
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);

          gridApi.showLoadingOverlay();
          const page = params.endRow / perPage;
          let data = null;
          api.findAllLoggedInUsersByDate(data, page - 1, perPage)
            .then(resp => resp?.data)
            .then(res => {
              gridApi.hideOverlay();


              const initUsers = res.content.filter((x) => x.firstName);

              console.log(initUsers);
              setTotalTableElements(res.totalElements);
           
              params.successCallback(initUsers, res.totalElements);
            }).catch(err => {
              gridApi.hideOverlay();
              console.log(err);
              params.successCallback([], 0);
            });
        }
      }
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, weeklyAnalyticsCall,selectedMonth,selectedYear]);





  /*moods*/
  const [gridApi1, setGridApi1] = useState(null);
  const perPage1 = 10;
  const onGridReady1 = (params) => {
    setGridApi1(params.api);
  };
  const weeklyAnalyticsCall1 = useCallback(async () => {
    try {
      let fromDate = new Date($('#fromToDate').val()?.split(' - ')[0]);
      fromDate.setUTCHours(0, 0, 0, 0);
      fromDate.setDate(fromDate.getDate() + 1).toLocaleString('en-US');

      let toDate = new Date($('#fromToDate').val()?.split(' - ')[1]);
      toDate.setUTCHours(23, 59, 59, 999);
      toDate.setDate(toDate.getDate() + 1).toLocaleString('en-US');

      let data = {
        "email": '',
        "firstName": '',
        "fromDate": isValidDate(fromDate) ? fromDate : "1991-02-15T00:00:00.000Z",
        "lastName": '',
        "leadStatus": "",
        "toDate": isValidDate(toDate) ? toDate : "2050-02-15T23:59:59.999Z",
        "utmSource": '',
      }
      const weeklyAnalyticsRes = await api.findAllNotLoggedInUsersByDate(data, 0, perPage1);
      console.log(weeklyAnalyticsRes?.data?.content);

    } catch (e) {
      console.error(e);
    }
  }, []);
  useEffect(() => {
    weeklyAnalyticsCall1();
    if (gridApi1) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);

          gridApi1.showLoadingOverlay();
          const page = params.endRow / perPage1;
          let data = null;
          api.findAllNotLoggedInUsersByDate(data, page - 1, perPage1)
            .then(resp => resp?.data)
            .then(res => {
              gridApi1.hideOverlay();


              const initUsers = res.content.filter((x) => x.firstName);

              console.log(initUsers);
              setTotalTableElements1(res.totalElements);
           
              params.successCallback(initUsers, res.totalElements);
            }).catch(err => {
              gridApi1.hideOverlay();
              console.log(err);
              params.successCallback([], 0);
            });
        }
      }
      gridApi1.setDatasource(dataSource);
    }
  }, [gridApi1, weeklyAnalyticsCall1]);






  /*moods*/
  const [gridApi2, setGridApi2] = useState(null);
  const perPage2 = 10;
  const onGridReady2 = (params) => {
    setGridApi2(params.api);
  };
  const weeklyAnalyticsCall2 = useCallback(async () => {
    try {
      let fromDate = new Date($('#fromToDate').val()?.split(' - ')[0]);
      fromDate.setUTCHours(0, 0, 0, 0);
      fromDate.setDate(fromDate.getDate() + 1).toLocaleString('en-US');

      let toDate = new Date($('#fromToDate').val()?.split(' - ')[1]);
      toDate.setUTCHours(23, 59, 59, 999);
      toDate.setDate(toDate.getDate() + 1).toLocaleString('en-US');

      let data = {
        "email": '',
        "firstName": '',
        "fromDate": isValidDate(fromDate) ? fromDate : "1991-02-15T00:00:00.000Z",
        "lastName": '',
        "leadStatus": "",
        "toDate": isValidDate(toDate) ? toDate : "2050-02-15T23:59:59.999Z",
        "utmSource": '',
      }
      const weeklyAnalyticsRes = await api.findAllUsersByCompleteOnBoarding(data, 0, perPage2);
      console.log(weeklyAnalyticsRes?.data?.content);

    } catch (e) {
      console.error(e);
    }
  }, []);
  useEffect(() => {
    weeklyAnalyticsCall2();
    if (gridApi2) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);

          gridApi2.showLoadingOverlay();
          const page = params.endRow / perPage2;
          let data = null;
          api.findAllUsersByCompleteOnBoarding(data, page - 1, perPage1)
            .then(resp => resp?.data)
            .then(res => {
              gridApi2.hideOverlay();


              const initUsers = res.content.filter((x) => x.firstName);

              console.log(initUsers);
              setTotalTableElements2(res.totalElements);
           
              params.successCallback(initUsers, res.totalElements);
            }).catch(err => {
              gridApi2.hideOverlay();
              console.log(err);
              params.successCallback([], 0);
            });
        }
      }
      gridApi2.setDatasource(dataSource);
    }
  }, [gridApi2, weeklyAnalyticsCall2]);






  /*moods*/
  const [gridApi3, setGridApi3] = useState(null);
  const perPage3 = 10;
  const onGridReady3 = (params) => {
    setGridApi3(params.api);
  };
  const weeklyAnalyticsCall3 = useCallback(async () => {
    try {
      let fromDate = new Date($('#fromToDate').val()?.split(' - ')[0]);
      fromDate.setUTCHours(0, 0, 0, 0);
      fromDate.setDate(fromDate.getDate() + 1).toLocaleString('en-US');

      let toDate = new Date($('#fromToDate').val()?.split(' - ')[1]);
      toDate.setUTCHours(23, 59, 59, 999);
      toDate.setDate(toDate.getDate() + 1).toLocaleString('en-US');

      let data = {
        "email": '',
        "firstName": '',
        "fromDate": isValidDate(fromDate) ? fromDate : "1991-02-15T00:00:00.000Z",
        "lastName": '',
        "leadStatus": "",
        "toDate": isValidDate(toDate) ? toDate : "2050-02-15T23:59:59.999Z",
        "utmSource": '',
      }
      const weeklyAnalyticsRes = await api.findAllUsersByNotCompleteOnBoarding(data, 0, perPage3);
      console.log(weeklyAnalyticsRes?.data?.content);

    } catch (e) {
      console.error(e);
    }
  }, []);
  useEffect(() => {
    weeklyAnalyticsCall3();
    if (gridApi3) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);

          gridApi3.showLoadingOverlay();
          const page = params.endRow / perPage3;
          let data = null;
          api.findAllUsersByNotCompleteOnBoarding(data, page - 1, perPage3)
            .then(resp => resp?.data)
            .then(res => {
              gridApi3.hideOverlay();


              const initUsers = res.content.filter((x) => x.firstName);

              console.log(initUsers);
              setTotalTableElements3(res.totalElements);
           
              params.successCallback(initUsers, res.totalElements);
            }).catch(err => {
              gridApi3.hideOverlay();
              console.log(err);
              params.successCallback([], 0);
            });
        }
      }
      gridApi3.setDatasource(dataSource);
    }
  }, [gridApi3, weeklyAnalyticsCall3]);



  const [gridApi4, setGridApi4] = useState(null);
  const perPage4 = 10;
  const onGridReady4 = (params) => {
    setGridApi4(params.api);
  };
  const weeklyAnalyticsCall4 = useCallback(async () => {
    try {
      let fromDate = new Date($('#fromToDate').val()?.split(' - ')[0]);
      fromDate.setUTCHours(0, 0, 0, 0);
      fromDate.setDate(fromDate.getDate() + 1).toLocaleString('en-US');

      let toDate = new Date($('#fromToDate').val()?.split(' - ')[1]);
      toDate.setUTCHours(23, 59, 59, 999);
      toDate.setDate(toDate.getDate() + 1).toLocaleString('en-US');

      let data = {
        "email": '',
        "firstName": '',
        "fromDate": isValidDate(fromDate) ? fromDate : "1991-02-15T00:00:00.000Z",
        "lastName": '',
        "leadStatus": "",
        "toDate": isValidDate(toDate) ? toDate : "2050-02-15T23:59:59.999Z",
        "utmSource": '',
      }
      const weeklyAnalyticsRes = await api.findCountOfLoggedInUsersPerMonth(data, 0, perPage4);
      console.log(weeklyAnalyticsRes?.data?.content);

      try {
     
        setTotalTableElements4(weeklyAnalyticsRes?.data['loggedInUsersCount']);
      } catch (error) {
        
      }


         
      setInspirationsList(weeklyAnalyticsRes?.data);
    } catch (e) {
      console.error(e);
    }
  }, []);
  useEffect(() => {
    weeklyAnalyticsCall4();
    if (gridApi4) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);

          gridApi4.showLoadingOverlay();
          const page = params.endRow / perPage4;
          let data = null;
          api.findCountOfLoggedInUsersPerMonth(data, page - 1, perPage4)
            .then(resp => resp?.data)
            .then(res => {
              gridApi4.hideOverlay();


              const initUsers = res.content.filter((x) => x.firstName);

              console.log(initUsers);
              params.successCallback(initUsers, res.totalElements);
            }).catch(err => {
              gridApi4.hideOverlay();
              console.log(err);
              params.successCallback([], 0);
            });
        }
      }
      gridApi4.setDatasource(dataSource);
    }
  }, [gridApi4, weeklyAnalyticsCall4]);

  console.log(onGridReady4)




  const [gridApi5, setGridApi5] = useState(null);
  const perPage5 = 10;
  const onGridReady5 = (params) => {
    setGridApi5(params.api);
  };
  const weeklyAnalyticsCall5 = useCallback(async () => {
    try {
      let fromDate = new Date($('#fromToDate').val()?.split(' - ')[0]);
      fromDate.setUTCHours(0, 0, 0, 0);
      fromDate.setDate(fromDate.getDate() + 1).toLocaleString('en-US');

      let toDate = new Date($('#fromToDate').val()?.split(' - ')[1]);
      toDate.setUTCHours(23, 59, 59, 999);
      toDate.setDate(toDate.getDate() + 1).toLocaleString('en-US');

      let data = {
        "email": '',
        "firstName": '',
        "fromDate": isValidDate(fromDate) ? fromDate : "1991-02-15T00:00:00.000Z",
        "lastName": '',
        "leadStatus": "",
        "toDate": isValidDate(toDate) ? toDate : "2050-02-15T23:59:59.999Z",
        "utmSource": '',
      }
      const weeklyAnalyticsRes = await api.findCountOfLoggedInUsersPerWeek(data, 0, perPage5);
      console.log(weeklyAnalyticsRes?.data?.content);
     
      setInspirationsList1(weeklyAnalyticsRes?.data);
      try {
        setTotalTableElements5(weeklyAnalyticsRes?.data['loggedInUsersCount']);
  
      } catch (error) {
        
      }
    } catch (e) {
      console.error(e);
    }
  }, []);
  useEffect(() => {
    weeklyAnalyticsCall5();
    if (gridApi5) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);

          gridApi5.showLoadingOverlay();
          const page = params.endRow / perPage5;
          let data = null;
          api.findCountOfLoggedInUsersPerWeek(data, page - 1, perPage5)
            .then(resp => resp?.data)
            .then(res => {
              gridApi5.hideOverlay();


              const initUsers = res.content.filter((x) => x.firstName);

              console.log(initUsers);
              params.successCallback(initUsers, res.totalElements);
            }).catch(err => {
              gridApi5.hideOverlay();
              console.log(err);
              params.successCallback([], 0);
            });
        }
      }
      gridApi5.setDatasource(dataSource);
    }
  }, [gridApi5, weeklyAnalyticsCall5]);

  console.log(onGridReady5)

  return (
    <>

      <>

        <Container>
          <Row>

            <Col xs={9}>
              <Card>
                <Card.Body>Customers with login activity



                  <Button variant="dark" className="float-end">
                    Total Records: <Badge bg="info">{totalTableElements}</Badge>
                  </Button>

                </Card.Body>
              </Card>
            </Col>
            <Col xs={3}>


            <Card style={{ width: '18rem' }}>
          {/* <Card.Header>Highlights</Card.Header> */}
          <ListGroup variant="flush">
            <ListGroup.Item>

            <Row>
                      <Col>   
                      <Form.Select aria-label="SELECT MONTTH" onChange={handleChange1} value={selectedMonth}>
                        <option selected value='1'>Janaury</option>
                        <option value='2'>February</option>
                        <option value='3'>March</option>
                        <option value='4'>April</option>
                        <option value='5'>May</option>
                        <option value='6'>June</option>
                        <option value='7'>July</option>
                        <option value='8'>August</option>
                        <option value='9'>September</option>
                        <option value='10'>October</option>
                        <option value='11'>November</option>
                        <option value='12'>December</option>
                      </Form.Select>
                      </Col>
                      <Col>  
                      <Form.Select aria-label="SELECT YEAR" onChange={handleChange2} value={selectedYear}>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                      </Form.Select>
                      </Col>
                    </Row>


            </ListGroup.Item>


          </ListGroup>
        </Card>
            </Col>
          </Row>

        </Container>



        <br />


        <div id="applicanttable">
          <div className="App">
            <div className="ag-theme-alpine ag-style">
              <AgGridReact
                pagination={true}
                rowModelType={'infinite'}
                paginationPageSize={perPage}
                cacheBlockSize={perPage}
                onGridReady={onGridReady}
                rowHeight={60}
                sortable={true}
                unSortIcon={true}
                defaultColDef={{ flex: 1, sortable: true, unSortIcon: true, sortingOrder: ['ASC', 'DESC'] }}
                overlayLoadingTemplate={
                  '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
                }
                overlayNoRowsTemplate={
                  '<span className="ag-overlay-loading-center">No data found to display.</span>'
                }
              >
                <AgGridColumn  field="userId" headerName="User ID" cellClass="vertical-middle" />
                <AgGridColumn  field="firstName" headerName="First Name" cellClass="vertical-middle" />
                <AgGridColumn  field="lastName" headerName="Last Name" cellClass="vertical-middle" />
              </AgGridReact>
            </div>
          </div>



        </div>





      </>



      <hr></hr>


      <>

        <Container>
          <Row>


            <Col xs={9}>
              <Card>
                <Card.Body>Customers with no login activites




                  <Button variant="dark" className="float-end">
                    Total Records: <Badge bg="info">{totalTableElements1}</Badge>
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={3}>


              <Card style={{ width: '18rem' }}>
                {/* <Card.Header>Highlights</Card.Header> */}
                <ListGroup variant="flush">
                  <ListGroup.Item>

                    <Row>
                      <Col>   <Form.Select aria-label="Default select example" disabled>
                        <option>Month</option>
                        <option selected value='1'>Janaury</option>
                        <option value='2'>February</option>
                        <option value='3'>March</option>
                        <option value='4'>April</option>
                        <option value='5'>May</option>
                        <option value='6'>June</option>
                        <option value='7'>July</option>
                        <option value='8'>August</option>
                        <option value='9'>September</option>
                        <option value='10'>October</option>
                        <option value='11'>November</option>
                        <option value='12'>December</option>
                      </Form.Select></Col>
                      <Col>  <Form.Select aria-label="Default select example" disabled>
                        <option>Year</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                      </Form.Select></Col>
                    </Row>

                  </ListGroup.Item>


                </ListGroup>
              </Card>
            </Col>
          </Row>

        </Container>



        <br />


        <div id="applicanttable">
          <div className="App">
            <div className="ag-theme-alpine ag-style">
              <AgGridReact
                pagination={true}
                rowModelType={'infinite'}
                paginationPageSize={perPage1}
                cacheBlockSize={perPage1}
                onGridReady={onGridReady1}
                rowHeight={60}
                sortable={true}
                unSortIcon={true}
                defaultColDef={{ flex: 1, sortable: true, unSortIcon: true, sortingOrder: ['ASC', 'DESC'] }}
                overlayLoadingTemplate={
                  '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
                }
                overlayNoRowsTemplate={
                  '<span className="ag-overlay-loading-center">No data found to display.</span>'
                }
              >
                <AgGridColumn  field="userId" headerName="User ID" cellClass="vertical-middle" />
                <AgGridColumn  field="firstName" headerName="First Name" cellClass="vertical-middle" />
                <AgGridColumn  field="lastName" headerName="Last Name" cellClass="vertical-middle" />
              </AgGridReact>
            </div>
          </div>



        </div>





      </>



      <hr></hr>
      <>

        <Container>
          <Row>

            <Col xs={12}>
              <Card>
                <Card.Body>Customers who completed Onboarding


                  <Button variant="dark" className="float-end">
                    Total Records: <Badge bg="info">{totalTableElements2}</Badge>
                  </Button>

                </Card.Body>
              </Card>
            </Col>

          </Row>

        </Container>



        <br />


        <div id="applicanttable">
          <div className="App">
            <div className="ag-theme-alpine ag-style">
              <AgGridReact
                pagination={true}
                rowModelType={'infinite'}
                paginationPageSize={perPage2}
                cacheBlockSize={perPage2}
                onGridReady={onGridReady2}
                rowHeight={60}
                sortable={true}
                unSortIcon={true}
                defaultColDef={{ flex: 1, sortable: true, unSortIcon: true, sortingOrder: ['ASC', 'DESC'] }}
                overlayLoadingTemplate={
                  '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
                }
                overlayNoRowsTemplate={
                  '<span className="ag-overlay-loading-center">No data found to display.</span>'
                }
              >
                <AgGridColumn  field="userId" headerName="User ID" cellClass="vertical-middle" />
                <AgGridColumn  field="firstName" headerName="First Name" cellClass="vertical-middle" />
                <AgGridColumn  field="lastName" headerName="Last Name" cellClass="vertical-middle" />
              </AgGridReact>
            </div>
          </div>



        </div>





      </>



      <hr></hr>


      <>

        <Container>
          <Row>


            <Col xs={12}>
              <Card>
                <Card.Body>Customers who did not complete onboaring



                  <Button variant="dark" className="float-end">
                    Total Records: <Badge bg="info">{totalTableElements3}</Badge>
                  </Button>
                </Card.Body>
              </Card>
            </Col>

          </Row>

        </Container>



        <br />


        <div id="applicanttable">
          <div className="App">
            <div className="ag-theme-alpine ag-style">
              <AgGridReact
                pagination={true}
                rowModelType={'infinite'}
                paginationPageSize={perPage3}
                cacheBlockSize={perPage3}
                onGridReady={onGridReady3}
                rowHeight={60}
                sortable={true}
                unSortIcon={true}
                defaultColDef={{ flex: 1, sortable: true, unSortIcon: true, sortingOrder: ['ASC', 'DESC'] }}
                overlayLoadingTemplate={
                  '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
                }
                overlayNoRowsTemplate={
                  '<span className="ag-overlay-loading-center">No data found to display.</span>'
                }
              >
                <AgGridColumn  field="userId" headerName="User ID" cellClass="vertical-middle" />
                <AgGridColumn  field="firstName" headerName="First Name" cellClass="vertical-middle" />
                <AgGridColumn  field="lastName" headerName="Last Name" cellClass="vertical-middle" />
              </AgGridReact>
            </div>
          </div>



        </div>





      </>





      <hr></hr>


      <>

        <Container>
          <Row>


            <Col xs={12}>
              <Card>
                <Card.Body>Customers who logged-in this month



                  <Button variant="dark" className="float-end">
                    Total Records: <Badge bg="info">{totalTableElements4}</Badge>
                  </Button>

                </Card.Body>
              </Card>
            </Col>

          </Row>

        </Container>



        <br />



        {inspirationsList ? (

          <Card style={{ width: '24rem' }}>
            {/* <Card.Header>Highlights</Card.Header> */}
            <ListGroup variant="flush">
              <ListGroup.Item>Total Users Count : {inspirationsList['totalCount']}</ListGroup.Item>
              <ListGroup.Item>Logged-In Users Count : {inspirationsList['loggedInUsersCount']}</ListGroup.Item>
            </ListGroup>
          </Card>


          //  <p><div><pre>{ JSON.stringify(inspirationsList, null, 2) }</pre></div></p>



        ) : (<p>No records to show!</p>)}





      </>

      <hr></hr>


      <>

        <Container>
          <Row>


            <Col xs={12}>
              <Card>
                <Card.Body>Customers who logged-in this week


                  <Button variant="dark" className="float-end">
                    Total Records: <Badge bg="info">{totalTableElements5}</Badge>
                  </Button>

                </Card.Body>
              </Card>
            </Col>

          </Row>

        </Container>



        <br />



        {inspirationsList1 ? (

          <Card style={{ width: '24rem' }}>
            {/* <Card.Header>Highlights</Card.Header> */}
            <ListGroup variant="flush">
              <ListGroup.Item>Total Users Count : {inspirationsList1['totalCount']}</ListGroup.Item>
              <ListGroup.Item>Logged-In Users Count : {inspirationsList1['loggedInUsersCount']}</ListGroup.Item>
            </ListGroup>
          </Card>


          //  <p><div><pre>{ JSON.stringify(inspirationsList, null, 2) }</pre></div></p>



        ) : (<p>No records to show!</p>)}





      </>
    </>


  );
}
