
import React, { useState, useEffect } from 'react';
import clientAPI from '../utils/api';
import Dropdown from 'react-bootstrap/Dropdown';
import LendersAPIForm from '../components/A-Dashboard/LendersAPI';
import EntityForm from '../components/A-Dashboard/EntityForm';
import IndividualForm from '../components/A-Dashboard/IndividualForm';
import Accordion from 'react-bootstrap/Accordion';
import './AdminView.scss';
import fromUnixTime from 'date-fns/fromUnixTime';
import InspirationLoader from '../components/A-Dashboard/InspirationLoader/Index';
import QuizUploader from '../components/A-Dashboard/QuizUploader/Index';
import UserActivation from '../components/A-Dashboard/UserActivation/Index';
import UserViewer from '../components/A-Dashboard/UserViewer/Index';
import ChatViewer from '../components/A-Dashboard/ChatViewer/Index';



import StreakViewer from '../components/A-Dashboard/StreakViewer/Index';
// import GraphView from '../views/GraphView';

import CIFViewer from '../components/A-Dashboard/CIFViewer/Index';
import CIFViewerRD from '../components/A-Dashboard/CIFViewerRD/Index';
import QuizSubmitViewer from '../components/A-Dashboard/QuizSubmitViewer/Index';
import MoodsViewer from '../components/A-Dashboard/MoodsViewer/Index';

import InspirationsViewer from '../components/A-Dashboard/InspirationsViewer/Index';

import TreeViewer from '../components/A-Dashboard/TreeViewer/Index';
import EngagementsViewer from '../components/A-Dashboard/EngagementsViewer/Index';




import UserAnalyticsViewer from '../components/A-Dashboard/UserAnalyticsViewer/Index';
import ReportsViewer from '../components/A-Dashboard/ReportsViewer/Index';
import SelfAssessmentViewer from '../components/A-Dashboard/SelfAssessmentViewer/Index';
import MxViewer from '../components/A-Dashboard/MxViewer/Index';
import AddQuiz from '../components/A-Dashboard/AddQuiz';
import AddManualQuiz from '../components/A-Dashboard/AddManualQuiz';
import QuizViewer from '../components/A-Dashboard/QuizViewer/Index';
import CashBackViewer from '../components/A-Dashboard/CashBackViewer/Index';

import ApplicantViewer from '../components/A-Dashboard/ApplicantViewer/Index';
import ApplicantViewerRD from '../components/A-Dashboard/ApplicantViewerRD/Index';
import TradeViewer from '../components/A-Dashboard/TradeViewer/Index';


import TimelineViewer from '../components/A-Dashboard/TimelineViewer/Index';

import AssessmentViewer from '../components/A-Dashboard/AssessmentViewer/Index';
import ChatRoomsViewer from '../components/A-Dashboard/ChatRoomsViewer/Index';



import DashboardViewer from '../components/A-Dashboard/DashboardViewer/Index';

import GraphView from "../components/GraphComponet/ApplicantGraph";
import TransactionViewerRD from "../components/A-Dashboard/TransactionViewerRD/Index";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import NavbarToggle from 'react-bootstrap/NavbarToggle';
import Button from 'react-bootstrap/Button';


export default function AdminView({
  lendersAPI,
  inspiration,
  quizzes,
  userActivation,
  userViewer,
  chatViewer,
  dashboardViewer,
  streakViewer,
  moodsViewer,
  inspirationsViewer,
  treeViewer,
  engagementsViewer,
  userAnalyticsViewer,
  reportsViewer,
  selfAssessmentViewer,
  mxViewer,
  quizViewer,
  cashBackViewer,
  cIFViewer,
  graphView,
  cIFViewerRD,
  quizSubmitViewer,
  applicantViewer,
  applicantViewerRD,
  tradeViewer,
  transactionViewerRD,
  addQuiz,
  addManualQuiz,
  timelineViewer,
  assessmentViewer,
  chatRoomsViewer
}) {
  // window.history.pushState({ page: 'admin' }, 'Admin', '/admin'); //Set browser url path

  const [hits, setHits] = useState();
  const [lendersKey, setlendersKey] = useState("");

  useEffect(() => {

    try {

      const getAndSetAPIKey = async () => {
        const keyRes = await clientAPI.getKeys();
        const key = keyRes.data.LENDERS_API_KEY;
        setlendersKey(key);
      };

      getAndSetAPIKey();
    } catch (error) {
      console.log(error);
    }

  }, []);

  const LenderHits = ({ hits }) => {
    const parsedReports =
      hits?.reports &&
      hits?.reports.map((rpt) => {
        const parsedEntitys =
          rpt.entities &&
          rpt.entities.map((data, i) => {
            const entityName = data.entity_name || "Entity";
            return (
              <Accordion defaultActiveKey={`ev${i}`}>
                {/* May need to move accordion to wrap items in render component */}
                <Accordion.Item eventKey="ev0">
                  <Accordion.Header>{entityName}</Accordion.Header>
                  <Accordion.Body>
                    <EntityForm formData={data} isReadOnly={true} />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            );
          });

        const parsedIndv =
          rpt.individuals &&
          rpt.individuals.map((data, i) => {
            const entityName = data.legal_name || "Individual";
            return (
              <Accordion defaultActiveKey={`in${i}`}>
                <Accordion.Item eventKey="in0">
                  <Accordion.Header>{entityName}</Accordion.Header>
                  <Accordion.Body>
                    <IndividualForm formData={data} isReadOnly={true} />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            );
          });
        return (
          <li>
            <div className="container">
              <p>{`ID: ${rpt.id}`}</p>
              <p>{`Status: ${rpt.status}`}</p>
              {/* <p>{`Reported: ${new Date(rpt.reported * 1000)}`}</p> */}
              <p>{`Reported: ${fromUnixTime(rpt.reported)}`}</p>
              {parsedEntitys}
              {parsedIndv}
            </div>
          </li>
        );
      });
    return (
      <div id="lenderHits">
        <hr />
        <h4>{`Search Hits (${hits?.reports.length}):`}</h4>
        <h5>{`Status: ${hits?.status}`}</h5>
        <ul>{parsedReports}</ul>
      </div>
    );
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar expand="xl" className="bg-body-tertiary border-bottom w-100">
        <Container>
          <Button variant="outline-success" onClick={handleShow} className='btn-toggle-menu' aria-controls="offcanvasNavbar-expand-xl">
            Toggle Menu
          </Button>
          {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}

          <Navbar.Offcanvas
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-xl"
            placement="end" show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel-expand-xl">
                Dashboard
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-center flex-grow-1 pe-3">
                <NavDropdown title="Dashboard Management" id="collapsible-nav-dropdown">
                  <NavDropdown.Item href="/admin/dashboardViewer">Dashboard</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/admin/MoodsViewer">Moods</NavDropdown.Item>
                  <NavDropdown.Item href="/admin/QuizViewer">Quizes</NavDropdown.Item>
                  <NavDropdown.Item href="/admin/SelfAssessmentViewer">Self Assessments</NavDropdown.Item>
                  <NavDropdown.Item href="/admin/CashBackViewer">Cash Backs</NavDropdown.Item>
                  <NavDropdown.Item href="/admin/EngagementsViewer">Engagements</NavDropdown.Item>
                  <NavDropdown.Item href="/admin/InspirationsViewer">Inspirations</NavDropdown.Item>
                  <NavDropdown.Item href="/admin/UserAnalyticsViewer">UserAnalytics</NavDropdown.Item>
                  <NavDropdown.Item href="/admin/ReportsViewer">Reports</NavDropdown.Item>
                  {/* <NavDropdown.Item href="/admin/CIFViewerRD">CIF  R&D</NavDropdown.Item> */}
                </NavDropdown>
                <Nav.Link href="/admin/lendersAPI">Lender API</Nav.Link>
                <Nav.Link href="/admin/inspirations">Upload Inspirations</Nav.Link>
                <Nav.Link href="/admin/quiz">Upload Quizzes</Nav.Link>
                {/* <Nav.Link href="/admin/quizStreaks">Quiz Streaks</Nav.Link> */}
                {/* <Nav.Link href="/admin/assessmentViewer">Assessments</Nav.Link> */}
                <NavDropdown title="Quiz Streaks" id="collapsible-nav-dropdown" drop="down-centered" style={{ marginRight: '10px' }}>
                  <NavDropdown.Item href="/admin/quizStreaks">Quiz Streaks</NavDropdown.Item>
                  <NavDropdown.Item href="/admin/addManualQuiz">Add Manual Quiz</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="Assessments" id="collapsible-nav-dropdown" drop="down-centered" style={{ marginRight: '10px' }}>
                  <NavDropdown.Item href="/admin/assessmentViewer">Self Assessment</NavDropdown.Item>
                  <NavDropdown.Item href="/admin/addQuiz">Add Self Assessment</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="Users Management" id="collapsible-nav-dropdown" drop="down-centered">
                  <NavDropdown.Item href="/admin/dashboardViewer">Dashboard</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/admin/userActivation">User Management & Activation</NavDropdown.Item>
                  <NavDropdown.Item href="/admin/userViewer">User Viewer</NavDropdown.Item>
                  <NavDropdown.Item href="/admin/CIFViewer">Customers</NavDropdown.Item>

                  {/* <NavDropdown.Item href="/admin/QuizSubmitViewer">Quiz Submit Viewer</NavDropdown.Item> */}

                  <NavDropdown.Item href="/admin/ApplicantViewer">Applicants</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      {lendersAPI && (
        <div id="adminView">
          Admin View - Lenders API:
          <hr />
          {/* <div style={{ display: 'flex', justifyContent: 'space-between', width: '80vw' }}> */}
          <div>
            <LendersAPIForm
              setHits={setHits}
              hits={hits}
              lendersKey={lendersKey}
            />
            {hits && <LenderHits hits={hits} />}
          </div>
        </div>
      )}
      {quizzes && <QuizUploader />}
      {inspiration && <InspirationLoader />}
      {userActivation && <UserActivation />}
      {userViewer && <UserViewer />}

      {chatViewer && <ChatViewer />}
      {dashboardViewer && <DashboardViewer />}
      {streakViewer && <StreakViewer />}
      {graphView && <GraphView />}
      {cIFViewer && <CIFViewer />}
      {quizSubmitViewer && <QuizSubmitViewer />}

      {moodsViewer && <MoodsViewer />}

      {inspirationsViewer && <InspirationsViewer />}
      {treeViewer && <TreeViewer />}


      {engagementsViewer && <EngagementsViewer />}



      {userAnalyticsViewer && <UserAnalyticsViewer />}

      {reportsViewer && <ReportsViewer />}
      {selfAssessmentViewer && <SelfAssessmentViewer />}
      {mxViewer && <MxViewer />}
      {quizViewer && <QuizViewer />}
      {cashBackViewer && <CashBackViewer />}

      {cIFViewerRD && <CIFViewerRD />}
      {applicantViewer && <ApplicantViewer />}
      {applicantViewerRD && <ApplicantViewerRD />}
      {tradeViewer && <TradeViewer />}
      {transactionViewerRD && <TransactionViewerRD />}
      {timelineViewer && <TimelineViewer />}
      {assessmentViewer && <AssessmentViewer />}
      {addQuiz && <AddQuiz />}
      {addManualQuiz && <AddManualQuiz />}
      {chatRoomsViewer && <ChatRoomsViewer />}
    </>
  );
}
