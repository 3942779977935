/* 
Get all users
Get all quizzes for month
GET Each users quiz answers (lazyload?)
*/
import React, { useState, useEffect } from 'react';
// import Table from 'react-bootstrap/Table';
import api from '../../../utils/api';
import { Button, Col, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableColumnType,
  TableHeader
} from "react-bs-datatable";

// import TABLE_BODY from "./data.json";

// type ArrayElementType = typeof TABLE_BODY[number] & {
//   button: any
// };

// Create table headers consisting of 4 columns.
const STORY_HEADERS: TableColumnType<ArrayElementType>[] = [
  {
    prop: "firstName",
    title: "First Name",
    isFilterable: true,
    isSortable: true
  },
  {
    prop: "lastName",
    title: "Last Name",
    isFilterable: true,
    isSortable: true
  },
  {
    prop: "email",
    title: "Email",

    isSortable: true
  },
  {
    prop: "initial_utm_source",
    title: "UTM Source",

    isSortable: true
  },
  
  {
    prop: "createDateTime",
    title: "Created ",
    cell: (row) => (
      <>

        <p>{new Date(row.createDateTime).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })}</p>

      </>

    ),
    isSortable: true
  },
  {
    prop: "updateDateTime",
    title: "Updated",
    cell: (row) => (
      <>

        <p>{new Date(row.createDateTime).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })}</p>

      </>

    ),
    isSortable: true
  },
  {
    prop: "button",
    cell: (row) => (
      <>

        <Button
          variant="outline-danger"
          size="sm"
          onClick={() => {
               alert(`Refreshing ${row.email}'s status`);
          }}
        >
          Refresh
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="primary"
          size="sm"
          onClick={() => {
            // window.open(`./TimelineViewer?username=${row.id}`, '_blank');
            // window.location.replace(`./userViewer?username=${row.username}`);
            alert(`Opening ${row.email}'s details`);
          }}
        >
          Details
        </Button>

      </>

    )
  }
];

export default function Index() {

  const [users, setUsers] = useState(null);

  useEffect(() => {
    //Get All users, limit to 25 pagination
    //[] Should it update when the dates change? like a refresh?

    const getAndSetAllUsers = async () => {
      try {
        const rawUsersRes = await api.getAllApplicants(100, 0);
        const initUsers = rawUsersRes.data._embedded.applicants.filter((x) => x.email);
        setUsers(initUsers);
      } catch (e) {
        console.error(e);
      }
    };
    getAndSetAllUsers();
  }, []);
  return (
    <div id="applicanttable">
      <div>
        <h1>{`Applicant Table`}</h1>
      </div>
      <hr />

      {users ? (<DatatableWrapper
        body={users}
        headers={STORY_HEADERS}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: 5,
            options: [5, 10, 15, 20]
          }
        }}
      >
        <Row className="mb-4 p-2">
          <Col
            xs={12}
            lg={4}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Filter />
          </Col>
          <Col
            xs={12}
            sm={6}
            lg={4}
            className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
          >
            <PaginationOptions />
          </Col>
          <Col
            xs={12}
            sm={6}
            lg={4}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Pagination />
          </Col>
        </Row>
        <Table>
          <TableHeader />
          <TableBody />
        </Table>
      </DatatableWrapper>) : (<p>Fetching Applicant(s) List...</p>)}



    </div>
  );
}
