/* Simple hook to minimize boilerplate with authetication context */

import React, { useEffect, useState } from 'react';
import AuthContext from '../context/AuthContext';
import useAPI from './useAPI';
// import { setJWT, setUserID, removeJWTAndUserID, getJWT, getUserID, setUsername, getUsername } from '../utils/cookies';
import cookies, { setUserProfile } from '../utils/cookies';
import { useNavigate } from 'react-router-dom';
import { Plugins } from '@capacitor/core';
import { set } from 'lodash';


function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthProvider({ children }) {
  //Context Provider so it is the same useAuth across the app?
  let [user, setUser] = useState(null);
  let [username, setUsernameState] = useState(null);

  const navigate = useNavigate();
  const [{ isLoading, isError, data }, setFetchingData] = useAPI();

  const addIdentityToHeap = (name) => {

    try {
      if (name && window.heap?.appid) {
        window.heap.identify(name, 'username');
      }
    } catch (error) {

    }


  };

  useEffect(() => {
    /* Restore session using cookies */
    const uName = cookies.getUsername();
    const savedJWT = cookies.getJWT();
    const savedUID = cookies.getUserID();
    const savedRole = cookies.getUserRole();

    const UIDLink = `https://${cookies.processenv.REACT_APP_ENV}.${cookies.processenv.REACT_APP_API}/users/${savedUID}`;
    const userObejct = { id: savedUID, jwt: savedJWT, link: UIDLink, name: uName, role: savedRole };
    //[] TODO: Validate JWT, just because a jwt is present doesn't mean its valid (could be expired)
    // do a small fetch to validate it?
    setUser(userObejct);
    // console.log('window.heap init', window.heap);
    addIdentityToHeap(uName);
    // uName && window.heap.length !== 0 && window.heap.identify(uName, 'username');

  }, []);

  let signout = (callback) => {
    console.log('Logging out...');
    setUser(null);
    setUsernameState(null);
    setUserProfile(null);
    cookies.removeJWTAndUserID();
    callback();
  };

  const handleSuccess = async (data, callback) => {

    const jwt = data.token;
    const role = data.role;
    const userID = data.userID;
    const UIDLink = `https://${cookies.processenv.REACT_APP_ENV}.${cookies.processenv.REACT_APP_API}/users/${userID}`;
    cookies.setUserID(userID);

    cookies.setUserDisplayName("FirstName-LastName");
    cookies.setUserDisplayName(data?.userProfile?.firstName + "-" + data?.userProfile?.lastName);
    cookies.setUserProfile(JSON.stringify(data));
    cookies.setJWT(jwt);
    cookies.setUserRole(role);

    setUser({ id: userID, jwt, link: UIDLink, name: username, role: role });
    console.log('window.heap login', window.heap);
    addIdentityToHeap(username);
    // username && window.heap?.identify(username, 'username');
    // if(role==='ROLE_FREEMIUM'){
    //   navigate('/freemium', { replace: true });
    //  }else{
    //   navigate('/client', { replace: true });
    //  }

    switch (role) {
      case 'ROLE_EMPLOYEE':
        navigate('/employee', { replace: true });
        break;
      case 'ROLE_USER':
        navigate('/client', { replace: true });
        break;
      case 'ROLE_FREEMIUM':
        navigate('/freemium', { replace: true });
        break;
      default:
        navigate('/client', { replace: true });
        break;
    }
    // setTimeout(() => {
    //   window.location.reload();
    // }, 500);
  };

  let signin = async (inputData, callback) => {
    const { username, password } = inputData;
    try {

      let data = {
        username: username.trim().toLowerCase(),
        password: password.trim(),
        pushDeviceToken: window.localStorage.getItem('pushDeviceToken')
      };
      if (!window.localStorage.getItem('pushDeviceToken')) {
        data = {
          username: username.trim().toLowerCase(),
          password: password.trim(),

        }
      } else {
        data = {
          username: username.trim().toLowerCase(),
          password: password.trim(),
          pushDeviceToken: window.localStorage.getItem('pushDeviceToken')
        }
      }

      const postData = {
        method: 'post',
        url: '/authenticate',
        data: data,
      };

      // alert('postData: ' + JSON.stringify(postData));
      await setFetchingData(postData); // Does this need to be in try block? as the attempt is occuring in useAPI?
      const formattedName = username.trim().toLowerCase();
      setUsernameState(formattedName);
      cookies.setUsername(formattedName);
      callback(formattedName);
      /* error handling currently tied to useAPI status' */
      // const res = await api.authenticate(username, password);
      // const { data } = res;
      // handleSuccess(data, callback);
      //DATA is delayed, need to wait for fetch to complere before setting cookies...
      /* useEffect and cookies */
    } catch (e) {
      // setActionOnSuccess(null);
      signout();
    }
  };

  useEffect(() => {
    if (data?.token) {
      handleSuccess(data);
    }
    //eslint-disable-next-line
  }, [data, isLoading, isError]);

  const clearError = () => {
    setFetchingData(null);
  };

  const status = { isError, isLoading, data };
  let value = { user, signin, signout, status, clearError };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default useAuth;
