import React, { useState, useEffect } from 'react';
import CardContainer from '../CardContainer';
import WideBtn from '../WideBtn';
import icon from '../../assets/Logo_Icon.svg';
import Form from 'react-bootstrap/Form';
import DebtForm from './DebtForm';
import TextButton from '../TextButton';
import { validateAndFormat } from './validatePreQual';
import { formatToDollar } from '../../utils/helpers';
import Spinner from 'react-bootstrap/Spinner';

export default function DebtToConsolidate({
  amountRequested,
  navForm,
  updatePreQualState,
  amountToConsolidate,
  numOfDebts,
  stateData,
  status,
}) {
  const DEBT_TEMPLATE = {
    type: '',
    amount: '',
    // monthlyPayments: '',
    // interestRate: '',
    creditor: '',
  };
  const [formData, setFormData] = useState(stateData || [{ ...DEBT_TEMPLATE }]);
  const totalDebtItems = formData.length; //Dont need to refer to parent state because on submit formdata will repalce it and remove non-consolidated debts too.
  useEffect(() => {
    stateData?.length && setFormData([...stateData]);
  }, [stateData]);

  const handleChange = (e, indexToUpdate) => {
    const key = e.target.id;
    let value = e.target.value;

    const formattedValue = validateAndFormat('debt', key, value);
    if (formattedValue === undefined || formattedValue === null) return;

    const prevObject = { ...formData[indexToUpdate] };
    const newData = {
      ...prevObject,
      [key]: formattedValue,
      needsConsolidate: true,
    };

    const newFormData = [...formData];
    newFormData[indexToUpdate] = newData;
    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //TRIM empty object (!values.every) from the formData (so only existing data is saved)
    // console.log('!!formData', formData);
    //Iterate formData []
    // each item {} check values,
    //if every value is "" remove that array
    const debtDataWithContent = [];
    formData.forEach((item) => {
      if (Object.values(item).every((x) => x !== '')) {
        debtDataWithContent.push(item);
      }
    });
    // console.log('!debtDataWithContent', debtDataWithContent);
    const PREQUAL_STATE_KEY = 'debt';
    updatePreQualState(PREQUAL_STATE_KEY, debtDataWithContent, { clearNonConsolidated: true });
  };

  const handleAddType = (e) => {
    e.preventDefault();
    console.log('Add another debt...');
    setFormData((p) => {
      return [...p, { ...DEBT_TEMPLATE }];
    });
  };
  console.log('formData', formData);
  const debtEntryArea = (
    <>
      {formData?.map((debt, i) => {
        return <DebtForm consolidated formNumber={7} formData={debt} index={i} handleChange={handleChange} status={status} />;
      })}

      {totalDebtItems < 8 ? (
        <WideBtn variant="outline-secondary" onClick={handleAddType} disabled={status.loading}>
          Add another type
        </WideBtn>
      ) : (
        <p>Max debt items is 8 (consolidated and non-consolidated)</p>
      )}
      <div style={{ marginTop: '0', marginBottom: '0', backgroundColor: '#0000001F', height: '1px' }} />
    </>
  );

  const isNextDisabled = (formData[0] && !Object.values(formData[0]).every((x) => x)) || status.loading;
  return (
    <>
      <CardContainer>
        <div>
          <div className="titleBlock">
            {icon && <img className="titleBlock--icon" src={icon} alt={icon} />}
            <h3 className="titleBlock--title">
              Let us know the specific debts you want consolidated and their amounts.
            </h3>
            <div className="debtRequestAmount">
              <h4>Amount to be consolidated:</h4>
              <div className="badge">
                <p>{amountRequested ? `$${formatToDollar(amountRequested)}` : 'N/A'}</p>
              </div>
            </div>

            {/*  <p className="muted titleBlock--body">
              If you have any questions, or need additional support, please text or call us at{' '}
              <a href="tel:8446702403">(844) 670-2403</a> or email us at{' '}
              <a href="email:contactus@myparachute.co">contactus@myparachute.co</a>
            </p> */}
          </div>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {debtEntryArea}

            <WideBtn type="submit" onClick={handleSubmit} disabled={isNextDisabled} id="submitDebtToConsolidate">
              {status.loading ? <Spinner animation="border" role="status" size="sm" /> : `Next`}
            </WideBtn>
            {status.error && <p className="error">{status.error}</p>}
            <TextButton
              onClick={() => {
                navForm('back');
              }}
            >
              Back
            </TextButton>
          </Form>
        </div>
      </CardContainer>
    </>
  );
}
