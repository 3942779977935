import React from 'react';
import cashbackImg from '../../assets/onboarding/cashback.svg';

export default function CashbackSlide() {
  return (
    <div>
      <img className="onboard-img" src={cashbackImg} alt="Earn cash-back each month" />
      <div className="sptText">
        <h3>Earn cash-back each month.</h3>
        <p className="muted">We’ll ask you a few questions each month to see if you’ve earned your cash-back reward!</p>
      </div>
    </div>
  );
}
