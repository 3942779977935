import React, { useState, useEffect, useRef } from 'react';
import CardContainer from '../CardContainer';
import Form from 'react-bootstrap/Form';
import WideBtn from '../WideBtn';
import { sendOTP, checkOTP } from './OTPApi';
import Spinner from 'react-bootstrap/Spinner';
import icon from '../../assets/Logo_Icon.svg';
import TextButton from '../TextButton';

export default function Form2OTP({ navForm, updatePreQualState, debugMode }) {
  const [mode, setMode] = useState('sendOTP');
  const [phone, setPhone] = useState('');
  const [OTPCode, setOTPCode] = useState(['', '', '', '', '', '']);
  const [error, setError] = useState(null);
  const [triggerApi, setTriggerApi] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  /* Add useEffect to handle async tasks. */

  useEffect(() => {
    const sendOTPCode = async () => {
      setError('');
      setIsLoading(true);
      try {
        const response = await sendOTP(phone);
        console.log('response', response);
        if (response.status === 429) {
          setTriggerApi(null);
          setIsLoading(false);
          setError(
            `Error 429, Too many requests. If you received a code, enter it in the form. Otherwise, please wait 10 minutes and try again.`
          );
        } else if (response.status >= 400) {
          setTriggerApi(null);
          setIsLoading(false);
          setError(`Error ${response.status}`);
        } else {
          //On Success go to next form
          setIsLoading(false);
          setMode('rxOTP');
          setTriggerApi(null);
        }
      } catch (e) {
        setError(`${e}`);
        setTriggerApi(null);
        setIsLoading(false);
      }
    };
    const confirmOTP = async () => {
      setError('');
      setIsLoading(true);
      console.log('Confirming OTP!!', phone, OTPCode);
      try {
        const response = await checkOTP(phone, OTPCode.join(''));
        console.log('response', response);
        if (response.success) {
          //On Success confirmation, clear state and go to next form
          // navForm('next');
          setTriggerApi(null);
          const PREQUAL_STATE_KEY = 'clientPhone';
          updatePreQualState(PREQUAL_STATE_KEY, phone);
          resetState();
        }
        if (response.success === false) {
          //On Success confirmation, clear state and go to next form
          setError(response.message);
          setOTPCode(['', '', '', '', '', '']);
          setTriggerApi(null);
        }
        setIsLoading(false);
      } catch (e) {
        console.log('OTP Error', e);
        setIsLoading(false);
        setError(e);
        setTriggerApi(null);
      }
    };

    triggerApi === 'send' && sendOTPCode();
    triggerApi === 'confirm' && confirmOTP();
    //eslint-disable-next-line
  }, [triggerApi, navForm]);

  const resetState = () => {
    setMode('sendOTP');
    setPhone('');
    setOTPCode([]);
  };

  const handleInput = (e) => {
    const val = e.target.value;

    //Validate for #s only
    !isNaN(val) && setPhone(val);
  };

  const cfm0Ref = useRef();
  const cfm1Ref = useRef();
  const cfm2Ref = useRef();
  const cfm3Ref = useRef();
  const cfm4Ref = useRef();
  const cfm5Ref = useRef();

  const handleOTPCode = (e) => {
    const val = e.target.value;
    // //Validate for #s only, max length (1) is set in the form control
    if (isNaN(val)) return;
    const indexToUpdate = e.target.id.charAt(e.target.id.length - 1);
    const newCode = [...OTPCode];
    newCode[indexToUpdate] = val;
    setOTPCode(newCode);
    //Move Focus to next input
    switch (indexToUpdate) {
      case '0':
        cfm1Ref.current.focus();
        break;
      case '1':
        cfm2Ref.current.focus();
        break;
      case '2':
        cfm3Ref.current.focus();
        break;
      case '3':
        cfm4Ref.current.focus();
        break;
      case '4':
        cfm5Ref.current.focus();
        break;
      default:
        console.warn('FOCUS REF NOT FOUND...');
    }
  };
  const submitPhone = (e) => {
    e.preventDefault();
    setOTPCode([]);
    if (isNaN(phone)) return;
    if (phone === 5555555555) {
      const PREQUAL_STATE_KEY = 'clientPhone';
      return updatePreQualState(PREQUAL_STATE_KEY, phone);
    }
    //Trigger UE Submit Phone to Twilio API
    setTriggerApi('send');
  };

  const submitOTPConfirmation = (e) => {
    e.preventDefault();
    //Trigger UE Cfm code to Twilio API
    setTriggerApi('confirm');
  };

  return (
    <CardContainer>
      {mode === 'sendOTP' && (
        <>
          <div className="titleBlock">
            {icon && <img className="titleBlock--icon" src={icon} alt={icon} />}
            <h3 className="titleBlock--title">{`We just need to make sure you’re not a robot. Please enter your phone number to get a verification code.`}</h3>
            {/*   <p className="muted titleBlock--body">
              If you have any questions, or need additional support, please text or call us at{' '}
              <a href="tel:8446702403">(844) 670-2403</a> or email us at{' '}
              <a href="email:contactus@myparachute.co">contactus@myparachute.co</a>
            </p> */}
          </div>
          <hr />
          <Form id="opt-init" onSubmit={submitPhone}>
            <Form.Group className="mb-3" controlId="phone">
              <Form.Label>Phone number</Form.Label>
              <Form.Control
                type="tel"
                placeholder="(555) 555-5555"
                maxLength={10}
                onChange={handleInput}
                value={phone} //[] Add formatting (similar to SDAW) (555) 555-5555 when 5555555555 is entered
                disabled={isLoading}
                isInvalid={error || phone === 5555555555}
                readOnly={isLoading}
              />
              <Form.Control.Feedback type="invalid">
                {error || (phone === 5555555555 && 'Click Next to Bypass OTP Text Msg...')}
              </Form.Control.Feedback>
            </Form.Group>

            <WideBtn disabled={isLoading || phone.length < 10} style={{ marginTop: '8px' }}>
              {isLoading ? <Spinner animation="border" role="status" size="sm" /> : 'Next'}
            </WideBtn>
            <TextButton
              onClick={() => {
                navForm('back');
              }}
            >
              Back
            </TextButton>
          </Form>
          {debugMode && (
            <p
              className="muted"
              onClick={() => {
                setPhone('5555555555');
                setMode('rxOTP');
              }}
            >
              DEBUG - SKIP
            </p>
          )}
        </>
      )}
      {mode === 'rxOTP' && (
        <>
          <div className="titleBlock">
            {icon && <img className="titleBlock--icon" src={icon} alt={icon} />}
            <h3 className="titleBlock--title">{`Verification code`}</h3>
            <p className="muted titleBlock--body">{`Enter the code sent to ${phone}`}</p>
          </div>
          <hr />
          <Form id="opt-init" onSubmit={submitOTPConfirmation}>
            <Form.Label>Confirm number</Form.Label>
            <div className="otpInputArea">
              <Form.Group className="otpDigitInput" controlId="cfm0">
                <Form.Control
                  type="tel"
                  placeholder="0"
                  onChange={handleOTPCode}
                  value={OTPCode[0]}
                  disabled={isLoading}
                  maxLength={1}
                  ref={cfm0Ref}
                  autoFocus
                  readOnly={isLoading}
                />
              </Form.Group>
              <Form.Group className="otpDigitInput" controlId="cfm1">
                <Form.Control
                  type="tel"
                  placeholder="0"
                  onChange={handleOTPCode}
                  value={OTPCode[1]}
                  disabled={isLoading}
                  maxLength={1}
                  ref={cfm1Ref}
                  readOnly={isLoading}
                />
              </Form.Group>
              <Form.Group className="otpDigitInput" controlId="cfm2">
                <Form.Control
                  type="tel"
                  placeholder="0"
                  onChange={handleOTPCode}
                  value={OTPCode[2]}
                  disabled={isLoading}
                  maxLength={1}
                  ref={cfm2Ref}
                  readOnly={isLoading}
                />
              </Form.Group>
              <Form.Group className="otpDigitInput" controlId="cfm3">
                <Form.Control
                  type="tel"
                  placeholder="0"
                  onChange={handleOTPCode}
                  value={OTPCode[3]}
                  disabled={isLoading}
                  maxLength={1}
                  ref={cfm3Ref}
                  readOnly={isLoading}
                />
              </Form.Group>
              <Form.Group className="otpDigitInput" controlId="cfm4">
                <Form.Control
                  type="tel"
                  placeholder="0"
                  onChange={handleOTPCode}
                  value={OTPCode[4]}
                  disabled={isLoading}
                  maxLength={1}
                  ref={cfm4Ref}
                  readOnly={isLoading}
                />
              </Form.Group>
              <Form.Group className="otpDigitInput" controlId="cfm5">
                <Form.Control
                  type="tel"
                  placeholder="0"
                  onChange={handleOTPCode}
                  value={OTPCode[5]}
                  disabled={isLoading}
                  maxLength={1}
                  ref={cfm5Ref}
                  readOnly={isLoading}
                />
              </Form.Group>
            </div>
            {error && <p className="OTPError error">{error}</p>}

            <WideBtn disabled={isLoading || OTPCode.join('').length !== 6} id="confirmPhone">
              {isLoading ? <Spinner animation="border" role="status" size="sm" /> : `Confirm code`}
            </WideBtn>
            <TextButton
              disabled={isLoading}
              onClick={() => {
                navForm('back');
              }}
            >
              Back
            </TextButton>
          </Form>
          {debugMode && (
            <p
              className="muted"
              onClick={() => {
                const PREQUAL_STATE_KEY = 'clientPhone';
                updatePreQualState(PREQUAL_STATE_KEY, phone);
              }}
            >
              DEBUG - SKIP
            </p>
          )}
        </>
      )}
    </CardContainer>
  );
}
