import React from 'react';
import disabledIcon from '../../../assets/icons/checkbox_grey2.svg';
import passIcon from '../../../assets/icons/Checkbox-Green.svg';
import failIcon from '../../../assets/icons/dismissed_icon_Filled.svg';

export default function ResultIcon({ pass, fail, disabled }) {
  let iconToUse = disabledIcon;

  if (disabled) iconToUse = disabledIcon;
  if (fail) iconToUse = failIcon;
  if (pass) iconToUse = passIcon;

  return (
    <>
      <img src={iconToUse} alt={iconToUse} />
    </>
  );
}
