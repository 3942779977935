import React from 'react';

import CardContainer from '../CardContainer';
import LoanInfo from '../LoanInfo';
import ErrorNotification from '../ErrorNotification';
import { formatToDollar, objectHasMissingData } from '../../utils/helpers';
import './Index.scss';

function InfoCard({ loanData }) {
  //   const { nextPaymentDate, paymentAmount, remainingBalance, totalLoan } = content;
  const requiredFields = ['nextPaymentDate', 'paymentAmount', 'remainingBalance', 'totalLoan'];
  let showError = !loanData || objectHasMissingData(loanData, requiredFields);

  const userReadableKeys = {
    nextPaymentDate: 'Next Payment Date',
    nextPaymentAmount: 'Next Payment Amount',
    // remainingBalance: 'Remaining Balance', //Hidden for init launch, expecting re-design
    totalLoan: 'Total Loan Amount',
    outstandingPrincipal: 'Outstanding Principal',
  };
  let dataToDisplay = Object.entries(userReadableKeys).map(([key, value], index) => {
    const dataValue = loanData?.[key];
    const formattedValue = (v) => {
      /* [] DRY code - nextpayment hadling of 0 is very edge case, shouldn't happen... */
      if (key === 'nextPaymentDate') {
        if (v === 0 || v === undefined) {
          showError = true;
          return 'N/A';
        }
        return v;
      }
      if (v !== 'NaN') {
        return isNaN(v) ? v : `$${formatToDollar(v)}`; //Convert Numbers to strings
      }
      return 'N/A';
    };
    return (
      <LoanInfo
        testid={`loanDetails-${key}`}
        key={`loanTile-${index}`}
        title={value}
        body={formattedValue(dataValue)}
      />
    );
  });

  //[] What did this do?
  // if (loanData) {
  //   Object.entries(loanData).map(([key, value], index) => {
  //     const formattedValue = isNaN(value) ? value : `$${value}`;
  //     return <LoanInfo key={`loanTile-${index}`} title={userReadableKeys[key]} body={formattedValue} />;
  //   });
  // }

  return (
    <CardContainer className="divided" id="loanDetails">
      <h2 className="titleLine">{'Loan Details'}</h2>
      {dataToDisplay}
      {showError && <ErrorNotification>We cannot determine loan details.</ErrorNotification>}
    </CardContainer>
  );
}

export default InfoCard;
