import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

import QuizActivity from './QuizActivity';
import MoodActivity from './MoodActivity';
import InspirationActivity from './InspirationActivity';
import CashbackActivity from './CashbackActivity';
import UserFlinks from './UserFlinks';
import CoachingActivities from './CoachingActivities';
import SelfAssessmentsActivity from './SelfAssessmentsActivity';
import UserWellnessScores from './UserWellnessScores';
import UsersFICOScores from './UsersFICOScores';
import UsersUtilization from './UsersUtilization';

export default function UserActivities({ userToView }) {
  const componentsToRender = [
    QuizActivity,
    MoodActivity,
    InspirationActivity,
    SelfAssessmentsActivity,
    CashbackActivity,
    UserWellnessScores,
    UsersFICOScores,
    UsersUtilization,
    UserFlinks,
    CoachingActivities,
  ];
  return (
    <div id="userActivities" style={{ maxWidth: '1216px' }}>
      <h4>Activities</h4>
      <Accordion>
        {componentsToRender.map((Component, i) => {
          return <Component key={`userActivityItem-${i}`} userToView={userToView} index={i} />;
        })}
      </Accordion>
    </div>
  );
}
