import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import icon from '../assets/icons/ToolTip_Icon.svg';
import './ToolTipIcon.scss';
import CloseButton from 'react-bootstrap/CloseButton';
export default function ToolTipIcon({ id, data }) {
  // const tipdFormat = (
  //   <Tooltip id={`tooltip-${id}`}>
  //     {/* popover maybe better for this style */}
  //     <p>{data?.title}</p>
  //     <p>{data?.body || 'TBD'}</p>
  //   </Tooltip>
  // );

  const popover = (
    <Popover id="popover-basic" style={{ minWidth: '320px' }}>
      <Popover.Header as="h4" style={{ borderBottom: 0, backgroundColor: 'white' }}>
      <CloseButton  className="float-end"
        />
      <br/>
        {data?.title}

        
      </Popover.Header>
      <Popover.Body style={{ paddingTop: 0 }}>{data?.body || 'TBD'}
      </Popover.Body>

    </Popover>
  );
  return (
    <OverlayTrigger key={`overlay-${id}`} placement="auto" overlay={popover}>
      <img src={icon} alt={'Tool Tip'} />
    </OverlayTrigger>
  );
}
