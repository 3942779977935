import React, { useEffect } from 'react';
import './ApplicantGraph.scss';
import * as am5 from "@amcharts/amcharts5";

import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";

import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";


function ApplicantGraph(props) {

  useEffect (() => {

    let root = am5.Root.new("chartdiv");
    const myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll({
      fill: am5.color(0xFF0000),
      fontSize: "1em"
    });


    root.setThemes([
      am5themes_Animated.new(root),
      myTheme,

    ]);


    var container = root.container.children.push(am5.Container.new(root, {
      width: am5.percent(100),
      height: am5.percent(100),
      layout: root.verticalLayout
    }));



    var series = container.children.push(am5hierarchy.Tree.new(root, {
      singleBranchOnly: false,
      downDepth: 1,
      initialDepth: 10,
      valueField: "value",
      categoryField: "name",
      childDataField: "children",
     }));
 
    series.circles.template.setAll({
      radius: 60,
      
    });
    series.outerCircles.template.setAll({
      radius: 60.1
    });

    let content = JSON.parse(localStorage.getItem('treeChartData'));
console.log(content);

    var newdata = {
      name: "Total_Applicant",

      children: [],
    }
    var  children1= content;
  
   console.log(children1);
    // let overallTotalValue = 0;

    Object.entries(children1).map(([key,value])=>{
      var date={
        name:key,
        value: key,
        children:[],
      }
      Object.entries(value).map(([k,v])=>{
        var newchild ={ }
        newchild.name= k;
        newchild.children=[];
        Object.entries(v).map(([k1,v1])=>{
          var newchild1={};
          newchild1.name=k1;
          newchild1.children=[];
          newchild1.value=parseInt(v1);
          newchild.children.push(newchild1);
          return newchild1;
        })
        date.children.push(newchild);
        return newchild;
      })
      newdata.children.push(date);
      return date;
    })

    // Object.entries(children1).map(([key, value]) => {


    //   let rootTotalValue = 0;
    //   var date = {
    //     name: key,
    //     value: key,
    //     children: [],
    //   }
    //   Object.entries(value).map(([k, v]) => {
    //     var newchild = {}
    //     newchild.name = k;
    //     newchild.children = [];
    //     let totalValue = 0;
    //     Object.entries(v).map(([k1, v1]) => {
    //       var newchild1 = {};
    //       newchild1.name = k1 + " : " + parseInt(v1);
    //       newchild1.children = [];
    //       newchild1.value = parseInt(v1);
    //       if (k1 !== 'Total') {
    //         newchild.children.push(newchild1);
            
    //       totalValue = totalValue + newchild1.value;
    //       }
    //     })
    //     newchild.name = k + " : " + totalValue;
    //     rootTotalValue = rootTotalValue + totalValue;
    //     date.children.push(newchild);
    //   })
    //   date.name = date.name + " : " + rootTotalValue;
    //   newdata.children.push(date);
    //   overallTotalValue = overallTotalValue + rootTotalValue;
    // })
    // newdata.name = newdata.name + " : " + overallTotalValue;
    series.data.setAll([newdata]);
    series.set("selectedDataItem", series.dataItems[0]);





    series.appear(1000, 100);
    return () => {
      root.dispose();
    };


    
  });

  return (
    <>
  <div id="chartdiv" style={{ width: "100%", height: "850px"}}></div>
  
    </>
    );
}
export default ApplicantGraph;




