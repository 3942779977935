import React, { useEffect, useRef, useState } from "react";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { db } from "./firebase";
import Message from "./Message";
import SendMessage from "./SendMessage";
import cookies from "../../../utils/cookies";
import { useLocation } from 'react-router-dom';
import { Button, ListGroup, Accordion } from "react-bootstrap";

import api from '../../../utils/api';
const ChatBox = () => {




  const location = useLocation();
  const searchParams1 = new URLSearchParams(location.search);
  const id = searchParams1.get("room");
  const username = searchParams1.get("username");

  const firstName = searchParams1.get("firstName");

  const lastName = searchParams1.get("lastName");
  const [customerReferenceId] = useState(id);
  const [customerReferenceUsername] = useState(username);

  const [customerReferenceFirstName] = useState(firstName);

  const [customerReferenceLastName] = useState(lastName);
  const [oppIds, setOppIds] = useState([]);
  let chatroomname = "";
  if (customerReferenceId) {
    if (customerReferenceId !== undefined && customerReferenceId !== null) {
      chatroomname = "messages" + "-" + customerReferenceId;
    }
  } else {
    if (cookies.getUserID() !== undefined && cookies.getUserID() !== null) {
      chatroomname = "messages" + "-" + cookies.getUserID();
    }

  }


  const [inputMessage, setInputMessage] = useState(null);
  const [messages, setMessages] = useState(null);
  const [suggestions, setSuggestions] = useState(null);
  // const [chatRoomList, setChatRoomList] = useState(null);
  const scroll = useRef();

  const savedRole = cookies.getUserRole();
  const handleSuggestionClick = (input) => {
    console.log(input);

    //  document.getElementById("chatTextInput").value = input;
    setInputMessage(input);
  };
  const timerCall = () => {
   
    const thirtySeconds = 5 * 1000; // 5 seconds in milliseconds
    var lastCallTime = window.localStorage.getItem("lastMessagesCallTime") || 0; // Get the last call time from localStorage
    // Check if 5 seconds have passed since the last call
    if ((Date.now() - lastCallTime) > thirtySeconds) {
      if (cookies.getJWT()) {
        initChatMessages();
      }
    }
    setTimeout(() => {
      
      timerCall();
      }, 5000);
  };

  useEffect(() => {
    setTimeout(() => {
      
    timerCall();
    }, 5000);
  }, []); // useEffect will run whenever lastCallTime changes

  const initChatMessages = () => {
    let data1 = {
      "chatRoomKey": {
        "userId": id ? id : cookies.getUserID(),
        "adminId": cookies.adminID
      }
    }
    api.getChatMessages(data1)
      .then(resp => resp?.data)
      .then(res => {


        console.log(res?.content);


        let messagesList = [];
        if (Array.isArray(res?.content)) {
          messagesList = res?.content.reverse();


          window.localStorage.setItem("lastMessagesCallTime", Date.now()); // Update the last call time
        } else {
          messagesList = [];
          window.localStorage.setItem("lastMessagesCallTime", Date.now()); // Update the last call time
        }



        messagesList.map((doc) => {
          console.log(doc)
          console.log(cookies.getUserID())
          let arr = [];
          // if (doc.senderId.toString() !== cookies.getUserID().toString()) {
          if (cookies.getUserRole() === 'ROLE_SUPER_ADMIN') {
            if (doc.senderId.toString() !== cookies.getUserID().toString()) {
            if (!doc.read) {

              try {
                read(doc.id);
              } catch (error) {

              }
            }
          }
          } else {
            // alert("doc.senderId.toString() " +doc.senderId.toString())
            // alert("cookies.getUserID().toString() "+cookies.getUserID().toString())
            if (doc.senderId.toString() !== cookies.getUserID().toString()) {
              if (!doc.read) {

                try {
                  read(doc.id);
                } catch (error) {

                }
              }
            }
          }
          // }

        }
        );

        setMessages(messagesList);

      }).catch(err => {
        // gridApi.hideOverlay();
        console.log(err);
        // params.successCallback([], 0);
        setMessages([]);
      });
  }
  function read(id) {
    console.log(id)
    if (id === undefined || id === null) {
      return;
    }
    try {
      let data = {
        id: id
      }
      const res = api.readMessageNew(data);
      console.log(res);
      if (res) {
        console.log("success!");
      } else {
        console.log("dailed!");
      }
    } catch (e) {
      console.error(e);
    }
  }
  useEffect(() => {

    let data = {
      userId: id ? id : cookies.getUserID(),
      adminId: cookies.adminID
    }
    api.getChatSuggestionsNew(data)
      .then(resp => resp?.data)
      .then(res => {


        console.log(res);
        if (!res) {
          // create chat room
          let data = {
            userId: id ? id : cookies.getUserID(),
            adminId: cookies.adminID
          }
          api.createChatRoom(data)
            .then(resp => resp?.data)
            .then(res => {


              console.log(res);
              if (res?.content) {
                // create chat room
                setSuggestions(res?.content);
              }


            }).catch(err => {
              // gridApi.hideOverlay();
              console.log(err);
              // params.successCallback([], 0);
              setSuggestions([]);
            });
        } else {
          setSuggestions(res);
        }


      }).catch(err => {
        // gridApi.hideOverlay();
        console.log(err);
        // params.successCallback([], 0);
        setSuggestions([]);
      });




    // const q = query(
    //   collection(db, chatroomname),
    //   orderBy("createdAt", "desc"),
    //   limit(50)
    // );

    // const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
    //   const fetchedMessages = [];
    //   QuerySnapshot.forEach((doc) => {
    //     console.log(doc.data().uid)
    //     console.log(cookies.getUserID())
    //     let arr = [];
    //     if (doc.data().uid.toString() !== cookies.getUserID().toString()) {
    //       console.log(doc.data().status)
    //       if (doc.data().status !== 'read') {
    //         // if (arr.includes(doc.data().uid) !== -1) {
    //         try {
    //           read(doc.data().uid);
    //         } catch (error) {

    //         }
    //         // }
    //       }
    //     }
    //     fetchedMessages.push({ ...doc.data(), id: doc.id });
    //   });
    //   const sortedMessages = fetchedMessages.sort(
    //     (a, b) => a.createdAt - b.createdAt
    //   );

    //   setMessages(sortedMessages ? sortedMessages : []);
    // });
    // return () => unsubscribe;

    initChatMessages();

  }, [chatroomname]);

  return (
    <>
      {/* <p><div><pre>{ JSON.stringify(chatRoomList, null, 2) }</pre></div></p> */}


      {1 == 1


        ? (<>
          <div className="bg-dark d-flex flex-column w-100 py-3 px-4 text-light">
            <div className="d-flex align-items-center">
              <span className="me-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chat-text" viewBox="0 0 16 16">
                  <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
                  <path d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8zm0 2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z" />
                </svg>
              </span>
              <span>{customerReferenceUsername ? (<span>Chatting with <span><strong>{customerReferenceFirstName} {customerReferenceLastName}</strong></span></span>) : 'Chatting with Admin'}

                {/* {savedRole !== 'ROLE_SUPER_ADMIN' ? (<> financial coach</>):(<> user</>)} */}
              </span>
            </div>
          </div>

          {/* <ListGroup.Item className="d-flex flex-column py-4 px-4">
          <div className="d-flex align-items-center">
              <div className="symbol">
                <img alt="Pic" src="https://ionicframework.com/docs/img/demos/avatar.svg"/>
              </div>              
              <div class="ms-2 text-start">
                  <div className="fw-semibold text-success mb-2">You</div>
                  <div className="text-dark">Bank Related Problem, Bank Related Problem Bank Related ProblemBank Related ProblemBank Related Problem</div>
              </div>
          </div>
        </ListGroup.Item>
        
        <ListGroup.Item className="d-flex flex-column py-4 px-4">
          <div className="d-flex align-items-center mb-4">
              <div className="symbol">
                <img alt="Pic" src="https://ionicframework.com/docs/img/demos/avatar.svg"/>
              </div>              
              <div class="ms-2 text-start">
                  <div className="fw-semibold text-dark mb-2">Coach</div>
                  <div className="text-dark">Thank You, Tell us more..</div>
              </div>
          </div>
          <div className="d-grid gap-2">
            <Button variant="outline-secondary rounded-0 text-start border-gray-300">
              Bank Related Problem
            </Button>
            <Button variant="outline-secondary rounded-0 text-start border-gray-300">
              Others
            </Button>
          </div>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex flex-column py-4 px-4">
          <div className="d-flex align-items-center">
              <div className="symbol">
                <img alt="Pic" src="https://ionicframework.com/docs/img/demos/avatar.svg"/>
              </div>              
              <div class="ms-2 text-start">
                  <div className="fw-semibold text-success mb-2">You</div>
                  <div className="text-dark">Bank Related Problem, Bank Related Problem Bank Related ProblemBank Related ProblemBank Related Problem</div>
              </div>
          </div>
        </ListGroup.Item> */}

          {savedRole !== 'ROLE_SUPER_ADMIN' && suggestions && suggestions.length ? (<>

            <Accordion defaultActiveKey={(!messages || messages?.length === 0) ? 1 : 0} flush>
              <Accordion.Item eventKey={(!messages || messages?.length === 0) ? 1 : 0}>
                <Accordion.Header>

                </Accordion.Header>
                <Accordion.Body className="pt-0">

                  <div className="d-flex align-items-center mb-4">
                    <div className="symbol">
                      <img alt="Pic" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
                    </div>
                    <div class="ms-2 text-start">
                      <div className="fw-semibold text-dark mb-2">   {savedRole !== 'ROLE_SUPER_ADMIN' ? (<> Coach</>) : (<> Coach </>)}</div>
                      <div className="text-dark">   {savedRole !== 'ROLE_SUPER_ADMIN' ? (<> Hi, Please choose your concern</>) : (<> Please choose your response</>)}</div>
                    </div>
                  </div>
                  <ListGroup variant="flush">
                    <ListGroup.Item className="d-flex flex-column py-0 px-0">
                      <div className="d-grid gap-2">

                        {suggestions.map((suggestion, index) => {
                          return (
                            <Button variant="outline-secondary rounded-0 text-start border-gray-300" onClick={() => handleSuggestionClick(suggestion)} >
                              {suggestion}
                            </Button>
                          )
                        })}

                        {/* <Button variant="outline-secondary rounded-0 text-start border-gray-300" onClick={() => handleSuggestionClick('KYC Related Problem')} >
                          KYC Related Problem
                        </Button>
                        <Button variant="outline-secondary rounded-0 text-start border-gray-300" onClick={() => handleSuggestionClick('Bank Related Problem')} >
                          Bank Related Problem
                        </Button>
                        <Button variant="outline-secondary rounded-0 text-start border-gray-300" onClick={() => handleSuggestionClick('Others')} >
                          Others
                        </Button> */}
                      </div>
                    </ListGroup.Item>
                  </ListGroup>



                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>) : (<></>)}


        </>) : (<>

          {/* <ListGroup variant="flush">
            <ListGroup.Item className="d-flex flex-column py-4 px-4">
              <div className="d-flex align-items-center">
                  <div className="symbol">
                    <img alt="Pic" src="https://ionicframework.com/docs/img/demos/avatar.svg"/>
                  </div>              
                  <div class="ms-2 text-start">
                      <div className="fw-semibold text-success mb-2">Doe</div>
                      <div className="text-dark">Bank Related Problem, Bank Related Problem Bank Related ProblemBank Related ProblemBank Related Problem</div>
                  </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex flex-column py-4 px-4">
              <div className="d-flex align-items-center">
                  <div className="symbol">
                    <span className="symbol-label">J</span>
                  </div>              
                  <div class="ms-2 text-start">
                      <div className="fw-semibold text-success mb-2">John</div>
                      <div className="text-dark">Bank Related Problem, Bank Related Problem Bank Related ProblemBank Related ProblemBank Related Problem</div>
                  </div>
              </div>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex flex-column py-4 px-4">
              <div className="d-flex align-items-center">
                  <div className="symbol">
                    <img alt="Pic" src="https://ionicframework.com/docs/img/demos/avatar.svg"/>
                  </div>              
                  <div class="ms-2 text-start">
                      <div className="fw-semibold text-success mb-2">Jullia</div>
                      <div className="text-dark">Bank Related Problem, Bank Related Problem Bank Related ProblemBank Related ProblemBank Related Problem</div>
                  </div>
              </div>
            </ListGroup.Item>
          </ListGroup> */}
        </>)}
      {(!messages || messages?.length === 0) ? (" Start your conversations!") : ("")}
      {messages ? (
        <div className="chat-box">
          <div className="messages-wrapper">
            {messages?.map((message) => (
              <Message key={message.id} message={message} username={customerReferenceUsername} />
            ))}
          </div>
          {/* when a new message enters the chat, the screen scrolls down to the scroll div */}
          <span ref={scroll}></span>
          <SendMessage scroll={scroll} customerReferenceId={customerReferenceId} inputMessage={inputMessage} initchatmessages={initChatMessages} />
        </div>) : (<p>Fetching messages...</p>)}
    </>
  );
};

export default ChatBox;
