import Table from 'react-bootstrap/Table';
import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import moment from 'moment';


export default function DashboardViewer() {

  const [weeklyAnalytics, setWeeklyAnalytics] = useState(null);
  const [totalLoanDisbursed, setLoanDisbursed] = useState(null);
  // const [inspirationsList, setInspirationsList] = useState(null);

  const keys = ["Wk Ending", "Lead Recd.", " ", "# (New Loans)", "Amount Disbursed"];

  useEffect(() => {
    const weeklyAnalytics = async () => {
      try {
        const weeklyAnalyticsRes = await api.weeklyLoanSummary();
        console.log(weeklyAnalyticsRes);
        let weekHeadingArr = { "headings": "" };
        weeklyAnalyticsRes?.data?.weeklyAnalytics.unshift(weekHeadingArr);
        setLoanDisbursed(weeklyAnalyticsRes?.data?.totalLoanDisbursed);
        setWeeklyAnalytics(weeklyAnalyticsRes?.data?.weeklyAnalytics);
      } catch (e) {
        console.error(e);
      }
    };
    weeklyAnalytics();
  }, []);

  function currencyFormat(num) {
    return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const getSundayFromWeekNum = (weekNum, year) => {
    const sunday = new Date(year, 0, ((weekNum) * 7));
    while (sunday.getDay() !== 1) {
      sunday.setDate(sunday.getDate() - 1);
    }

    return moment(new Date(sunday)).add('days', 0).format("MM/DD") + " - " + moment(new Date(sunday)).add('days', 6).format("MM/DD");
  }

  return (
    <>
      <br>
      </br>
      <div id="dashboard">



        {weeklyAnalytics ? (<Table responsive striped  >

          <tbody>

            {weeklyAnalytics &&
              Object.values(keys)
                .map((q, i) => {
                  let totalAmountDisbursed = 0;
                  return (
                    <tr idval={i}>
                      {
                        q &&
                        Object.values(weeklyAnalytics)
                          .map((q1, i1) => {
                            let rowVal = null;
                            if (i1 === 0) {
                              if (q === 'Wk Ending') {
                                rowVal = (<td class="headcol"><strong>{q}</strong></td>)
                              } else if (q === 'Difference') {
                                rowVal = (<td class="headcol"><strong>{q}</strong></td>)
                              } else if (q === 'Total Amount Disbursed') {
                                rowVal = (<td class="headcol"><strong>{q}</strong></td>)
                              } else {
                                rowVal = (<td class="headcol">{q}</td>)
                              }

                            } else {

                              totalAmountDisbursed = totalAmountDisbursed + q1.weeklyLoanAmount;
                              if (q === ' ') {
                                rowVal = (<td class="long"></td>)
                              } else if (q === 'Wk Ending') {
                                rowVal = (<td class="long"><strong>
                                  {/* #{q1.weekNo}  */}
                                  {getSundayFromWeekNum(q1.weekNo, q1.year)}</strong></td>)
                              } else if (q === 'Target Benchmark') {
                                rowVal = (<td class="long">{q1.benchmark}</td>)
                              } else if (q === 'Lead Recd.') {
                                rowVal = (<td class="long">{q1.prospects}</td>)
                              } else if (q === 'Difference') {
                                let diff = q1.prospects - q1.benchmark;
                                if (diff > 0) {
                                  rowVal = (<td class="long text-success"><strong>+{diff}</strong></td>)
                                } else {
                                  rowVal = (<td class="long text-danger"><strong>({Math.abs(diff)})</strong></td>)
                                }
                              } else if (q === '# (New Loans)') {
                                rowVal = (<td class="long" >{q1.loans}</td>)
                              } else if (q === 'Amount Disbursed') {
                                rowVal = (<td class="long">{currencyFormat(q1.weeklyLoanAmount)}</td>)
                              } else if (q === 'Total Amount Disbursed') {
                                rowVal = (<td class="long"><strong>{currencyFormat(totalAmountDisbursed)}</strong></td>)
                              }
                            }
                            return rowVal;


                          })}
                    </tr>


                  );
                })}


          </tbody>
        </Table>) : (<p>No record(s) to display!</p>)}





      </div>

      <br>
      </br>

      {totalLoanDisbursed && (


        <div class="row" style={{ display: 'flex', justifyContent: 'flex-start' }}>

          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Total Loan Disbursed</h4>
              </div>
              <div class="card-body">
                <h1 class="mb-0">{currencyFormat(totalLoanDisbursed)}</h1>
              </div>
            </div>
          </div>
        </div>

      )
      }
      <br>
      </br>
    </>


  );
}
