import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
// import Table from 'react-bootstrap/Table';
import api from '../../../../utils/api';
import { parseIdFromLink } from '../../../../utils/helpers';
import cookies from '../../../../utils/cookies';

// import { formatDateForDisplay } from '../../../../utils/helpers';

import { useCallback } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

export default function InspirationActivity({ userToView, index }) {
  const { link } = userToView;
  const userLink = link;



  const [gridApi, setGridApi] = useState(null);
  const perPage = 10;
  const onGridReady = (params) => {
    setGridApi(params.api);
  };


  const [totalTableElements, setTotalTableElements] = useState(0);
  console.log(totalTableElements)

  const [userData, setUserData] = useState({ mood: null, inspiration: null, quiz: null, cbRecords: null });
  useEffect(() => {
    const getUserActivity = async () => {
      //Inspirations
      const rawInspirations = await api.getUsersInspirationAnswerForToday(userLink, cookies.getDateRange());
      const userInspirationResponses = rawInspirations.data._embedded.inspirationAnswers;
      const userInspData = await Promise.all(
        userInspirationResponses.map(async (ansObj) => {
          const quizLink = ansObj._links.inspiration.href;
          const rawInspDetails = await api.makeAxiosCall(quizLink.replace('{?projection}', ''));
          const inspDetails = rawInspDetails.data;
          const userInspData = {
            answer: ansObj.answer,
            //   dateAnswered: ansObj.
            // correctAnswer: inspDetails.correctAnswer,
            availDate: inspDetails.availableDate,
            inspURL: inspDetails.url,
            inspID: parseIdFromLink(inspDetails._links.self.href),
          };
          return userInspData;
        })
      );

      setUserData({
        ...userData,
        // quiz: userQuizData.sort((x, y) => x.quizID.localeCompare(y.quizID)),
        inspiration: userInspData,
        // mood: userMoodResponses,
        // cbRecords: userCBRecords,
      });
    };
    getUserActivity();
    //eslint-disable-next-line
  }, []);
  const weeklyAnalyticsCall = useCallback(async () => {
    try {

      let data = cookies.getDateRangeByInput('01 Jan 2022');
      console.log(data)
      const weeklyAnalyticsRes = await api.getUsersInspirationAnswerForTodayPaginated(userLink, cookies.getDateRangeByInput('01 Jan 2022'), 0, perPage);
      console.log(weeklyAnalyticsRes.data._embedded.inspirationAnswers);

    } catch (e) {
      console.error(e);
    }
  }, [userLink]);

  useEffect(() => {

    const getUserActivityForDT = async (userInspirationResponses,totalElements,params) => {
   
      const userInspData = await Promise.all(
        userInspirationResponses.map(async (ansObj) => {
          const quizLink = ansObj._links.inspiration.href;
          const rawInspDetails = await api.makeAxiosCall(quizLink.replace('{?projection}', ''));
          const inspDetails = rawInspDetails.data;
          const userInspData = {
            answer: ansObj.answer,
            //   dateAnswered: ansObj.
            // correctAnswer: inspDetails.correctAnswer,
            availDate: inspDetails.availableDate,
            inspURL: inspDetails.url,
            inspID: parseIdFromLink(inspDetails._links.self.href),
          };
          return userInspData;
        })
      );

      // const sortedUserInspData = userInspData.sort(function (a, b) { return a.availDate - b.availDate });
      params.successCallback(userInspData, totalElements );
    };

    weeklyAnalyticsCall();
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);

          gridApi.showLoadingOverlay();
          const page = params.endRow / perPage;
          let data = cookies.getDateRangeByInput('01 Jan 2022')

          api.getUsersInspirationAnswerForTodayPaginated(userLink, data, page - 1, perPage)
            .then(resp => resp?.data)
            .then(res => {
              gridApi.hideOverlay();
              console.log(res)
              const initUsers = res._embedded.inspirationAnswers.filter((x) => x.createDateTime);
              console.log(initUsers);
              setTotalTableElements(res.page.totalElements)
              getUserActivityForDT(initUsers,res.page.totalElements,params);
            }).catch(err => {
              gridApi.hideOverlay();
              console.log(err);
              params.successCallback([], 0);
            });
        }
      }
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, weeklyAnalyticsCall,userLink]);

  const actionsFormatterA = ({ value, data }) => {
    if (!value) {
      return 'N/A';
    }
    return <>
      {value}
    </>;
  }

  const actionsFormatterB = ({ value, data }) => {
    if (!value) {
      return 'N/A';
    }
    return <>
    <a  rel="noreferrer" href={value}>View</a>
    </>;
  }

  const actionsFormatterC = ({ value, data }) => {
    if (!value) {
      return 'N/A';
    }
    return <>
    {(value)}
    </>;
  }
  const actionsFormatterD = ({ value, data }) => {
    if (!value) {
      return 'N/A';
    }
    return <>
    {value=== 'thumbUp' ? '✔️' : '❌'}
    </>;
  }
  return (
    <>
      <Accordion.Item eventKey={index}>
        <Accordion.Header>Inspiration Reponses - {totalTableElements ?? '0'}</Accordion.Header>
        <Accordion.Body>
          {/* <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Inspiration ID</th>
                <th>Image Link</th>
                <th>Was Available (Date)</th>
                <th>User's Answer (Helped them)</th>
                  </tr>
            </thead>
            <tbody>
              {userData.inspiration &&
                Object.values(userData.inspiration)
                   .map((q, i) => {
                    return (
                      <tr key={`userInspResponse-${i}`}>
                        <td>{q?.inspID}</td>
                        <td>
                          <a href={q?.inspURL}>View</a>
                        </td>
                        <td>{q?.availDate}</td>
                        <td>{q?.answer === 'thumbUp' ? '✔️' : '❌'}</td>
                        </tr>
                    );
                  })}
            </tbody>
          </Table> */}


<div id="applicanttable1">
            <div className="App">

              <div className='container d-flex flex-column flex-grow-1 py-4'>
                <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                  <div style={{ flexGrow: '1' }}>


                    <div className="ag-theme-alpine ag-style" style={{ height: '100%', width: '100%' }}>
                <AgGridReact
                  pagination={true}
                  rowModelType={'infinite'}
                  paginationPageSize={perPage}
                  cacheBlockSize={perPage}
                  onGridReady={onGridReady}
                  rowHeight={60}
                  sortable={true}
                  unSortIcon={true}
                  defaultColDef={{ flex: 1, sortable: true, unSortIcon: true, sortingOrder: ['ASC', 'DESC'] }}
                  overlayLoadingTemplate={
                    '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
                  }
                  overlayNoRowsTemplate={
                    '<span className="ag-overlay-loading-center">No data found to display.</span>'
                  }
                >
                  <AgGridColumn  field="inspID" headerName="Inspiration ID" cellClass="vertical-middle" cellRendererFramework={actionsFormatterA} />
                  <AgGridColumn  field="inspURL" headerName="Image Link" cellClass="vertical-middle" cellRendererFramework={actionsFormatterB} />
                  <AgGridColumn  field="availDate" headerName="Was Available (Date)" cellClass="vertical-middle" cellRendererFramework={actionsFormatterC} />
                  <AgGridColumn  field="answer" headerName="User's Answer (Helped them)" cellClass="vertical-middle" cellRendererFramework={actionsFormatterD} />
                
                </AgGridReact>
              </div>
            </div>



          </div>
</div>
</div>
</div>

        </Accordion.Body>
      </Accordion.Item>
    </>
  );
}
