import React, { useEffect, useState } from "react";
// import * as widgetSdk from "@mxenabled/web-widget-sdk";
import CardContainer from "../CardContainer";
import { TOOLTIP } from "../../utils/constants";
import CardTitleWTip from "../CardTitleWTip";
import axios from "../../utils/axios";
import Spinner from "react-bootstrap/Spinner";

import useAuth from '../../hooks/useAuth';
// const widget = new widgetSdk.ConnectWidget({
//   container: "<CONTAINER SELECTOR>",
//   url: "<Widget URL>",
//   // additional widget options
// });

import * as widgetSdk from "@mxenabled/web-widget-sdk";
export default function BudgetsCard({overrideUserID}) {
  const auth = useAuth();
  const userID = overrideUserID || auth?.user?.id;

  const [spendingWidget, setSpendingWidget] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   async function loadSpendingWidget() {
  //     const endUrl = "http://localhost:8000/api/get_mx_widget_url";

  useEffect(() => {
    async function spendSummaryWidgetUrlApi() {
      try {
        setIsLoading(true);
        const params = { widget_type: "budgets_widget" };
       const endUrl = `/users/${params?.widget_type}/customMxWidgetUrls/${userID}`;
        const res = await axios.get(endUrl, { params });
        if (res.data) {
          setSpendingWidget(res?.data?.widgetUrl?.url?(res?.data?.widgetUrl?.url):(null));
        
          try {
            setTimeout(() => {
              let  widget = new widgetSdk.ConnectWidget({
                container: "#budget-widget",
                url: res?.data?.widgetUrl?.url,
                // additional widget options
              });
            }, 1500);
           
          } catch (error) {
            console.log(error);
          }
          setIsLoading(false);
        }
      } catch (err) {
        setIsLoading(false);
      }
    }
    spendSummaryWidgetUrlApi();
  }, [userID]);

  return (
<>
{spendingWidget?( <CardContainer id="monthlySpendMx" className="budgetsCardDiv">
<CardTitleWTip tipObject={TOOLTIP.TOP_MERCHANT} >
 {/* TRANSACTIONS: */}
  {isLoading && (
    <>
      <p>Finalizing connection please wait...</p>
      <Spinner className="flinksSpinner" animation="border" size="md" />
    </>
  )}
  {spendingWidget && (
  //   <iframe
  //   className="flinksIframe"
  //   title="Connect Accounts"
  //   src={spendingWidget}
  // />

  
  <div id="budget-widget"></div>
  )}
</CardTitleWTip>
</CardContainer>):''}
   
</>


  );
}