import React from 'react';
import './CardContainer.scss';

function Card({ children, id, className, style }) {
  const classToUse = ['cardContainer'];
  className && classToUse.push(className);

  return (
    <article id={id} className={classToUse.join(' ')} style={style}>
      {children}
    </article>
  );
}

export default Card;
