import React from 'react';
// import quizImg from '../../assets/onboarding/quizzes.svg';
import quizImg from '../../assets/onboarding/q.png';

export default function QuizSlide() {
  return (
    <div>
      <img className="onboard-img" src={quizImg} alt="Quiz images" />
      <div className="sptText">
        <h3>Your wellbeing and finances are connected.</h3>
        <p className="muted">
          Through daily moodbeats and inspiration you’ll learn how you’re financial wellbeing and personal wellness are
          connected.
        </p>
      </div>
    </div>
  );
}
