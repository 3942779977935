import React, { useState, useEffect } from 'react';
// import { PRE_QUAL } from '../../../utils/constants';
import { parseIdFromLink, formatDateForDisplay } from '../../../utils/helpers';
import api from '../../../utils/api';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import GenericModal from '../GenericModal';
import QuizEditor from './QuizEditor';

import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import isValid from 'date-fns/isValid';
// import getYear from 'date-fns/getYear';
import { MONTH_NUM_TO_NAME } from '../../../utils/constants';

import cookies from '../../../utils/cookies';
export default function QuizViewer({ lastUploadSuccess }) {
  const today = cookies.getToday();
  const [selectedMonth, setSelectedMonth] = useState(MONTH_NUM_TO_NAME[today.month() + 1]);
  const [selectedYear, setSelectedYear] = useState(today.year());
  const [forceRefresh, setForceRefresh] = useState(0);
  const [lastFetch, setLastFetch] = useState(null);

  const [quizzesForMonth, setQuizzesForMonth] = useState(null);
  const [status, setStatus] = useState({ loading: false, error: null });
  const selectedYearString = `${selectedYear}`;

  const [wasEditted, setWasEditted] = useState(false);

  useEffect(() => {
    const getQuizzesForMonth = async (dateRange) => {
      try {
        setStatus({ loading: true, error: null });
        setQuizzesForMonth([]);

        const rawRes = await api.getQuizzesByDates(dateRange);
        const monthsQuizzes = rawRes.data._embedded.quizzes.sort((x, y) =>
          x.availableDate.localeCompare(y.availableDate)
        );
        setQuizzesForMonth(monthsQuizzes);
        setLastFetch(cookies.getDate());
        setStatus({ loading: false, error: null });
      } catch (e) {
        setStatus({ loading: false, error: `${e}` });
      }
    };
    /* Update date range to view */
    const dateString = `15 ${selectedMonth} ${selectedYear}`;
    const date = new Date(dateString); //15th is arbitrary, time is midnight EST
    let currDateRange = null;
    if (selectedYearString.length === 4 && isValid(date)) {
      currDateRange = {
        startDate: startOfMonth(date).toISOString(),
        endDate: endOfMonth(date).toISOString(),
      };
    }
    currDateRange && getQuizzesForMonth(currDateRange);
    // if (lastUploadSuccess) {
    //   getQuizzesForMonth(currDateRange);
    // }
  }, [selectedMonth, selectedYear, forceRefresh, selectedYearString]);

  const handleViewMonth = (e) => {
    const value = e.target.value;
    setSelectedMonth(value);
  };
  const handleYear = (e) => {
    const value = e.target.value;
    if (value.length > 4) return;
    setSelectedYear(value);
  };

  const deleteItem = async (quizID) => {
    setStatus({ loading: true, error: null });

    await api.deleteQuiz(quizID);
    //Remove that quiz from state
    const prevState = quizzesForMonth;
    const newState = prevState.filter((x) => !x._links.self.href.includes(quizID));
    setQuizzesForMonth(newState);
    setStatus({ loading: false, error: null });
  };

  return (
    <section style={{ width: '75vw' }}>
      <div>
        <h1>{`Quizzes - Showing: ${selectedMonth}, ${selectedYearString.length === 4 ? selectedYear : '___'}`}</h1>
        {status.error && <p className="error">{status.error}</p>}
        <h2>Select Month to view</h2>
        <div>
          <Form.Select
            id="quizMonth"
            className="mr-8"
            aria-label="Month to view"
            onChange={handleViewMonth}
            value={selectedMonth}
            // isInvalid={validated && !incomeDetails.dobMonth}
          >
            {Object.values(MONTH_NUM_TO_NAME)?.map((i) => (
              <option key={`dobM-${i}`} value={i}>
                {i}
              </option>
            ))}
          </Form.Select>
          <Form.Control
            value={selectedYear}
            type="number"
            onChange={handleYear}
            // min={2019}
          />
        </div>
      </div>
      <span>
        <Button variant="info" size="sm" style={{ margin: '24px 0px' }} onClick={() => setForceRefresh((p) => p + 1)}>
          REFRESH
        </Button>
        <p>{`As of: ${lastFetch}`}</p>
      </span>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>
              {'Available Date'}
              <br />
              {'Midnight EST'}
            </th>
            <th>Question</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {quizzesForMonth?.map((q, i) => {
            return (
              <tr key={`questItem-${i}`}>
                <td>{parseIdFromLink(q._links.self.href)}</td>
                <td style={{ textAlign: 'center' }}>{formatDateForDisplay(q.availableDate, { truncated: true })}</td>
                <td>{q.question}</td>
                <td>
                  <GenericModal
                    modalBtn="Details"
                    header="Question Details"
                    primaryBtn={'Close'}
                    style={{ width: '100%' }}
                    onClose={() => {
                      if (wasEditted) {
                        setForceRefresh((p) => p + 1);
                        setWasEditted(false);
                      }
                    }}
                  >
                    <QuizEditor q={q} setWasEditted={setWasEditted} />
                  </GenericModal>
                  {/* <Button size="sm" onClick={openDetailModal}> */}
                  {/* DETAILS */}
                  {/* </Button> */}
                </td>
                <td>
                  <GenericModal
                    modalBtn="Delete"
                    variant="danger"
                    header="Do you want to this quiz question?"
                    primaryBtn={'DELETE'}
                    style={{ width: '100%' }}
                    primaryAction={() => deleteItem(parseIdFromLink(q._links?.self?.href))}
                    primaryVariant="danger"
                    secondaryBtn="Cancel"
                  >
                    <>
                      {/* <h4>Do you want to this quiz question?</h4> */}
                      <p className="muted">
                        This cannot be undone, any answers associated with this quiz will also be deleted
                      </p>
                      <p>{`Question: ${q.question}`}</p>
                      <p>{`Available: ${q.availableDate}`}</p>
                    </>
                  </GenericModal>
                  {/*  <Button variant="danger" size="sm" onClick={cfmDeleteItem} style={{ width: '100%' }}>
                    Delete
                  </Button> */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </section>
  );
}
