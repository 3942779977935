//Mood Feelings
import happy from './happy.svg';
import impartial from './impartial.svg';
import sad from './sad.svg';
import thrilled from './thrilled.svg';
import verySadIcon from './verySadIcon.svg';

//Mood Reasons
import exercise from './exerciseIcon.svg';
import family from './familyIcon.svg';
import food from './foodIcon.svg';
import friends from './friendsIcon.svg';
import health from './healthIcon.svg';
import money from './moneyIcon.svg';
import sleep from './sleepIcon.svg';
import weather from './weatherIcon.svg';
import work from './workIcon.svg';

//Inspiration Options
import thumbDown from './thumbDown.svg';
import thumbUp from './thumbUp.svg';

export const allEmojis = {
  happy,
  sad,
  verySadIcon,
  thrilled,
  impartial,
  thumbDown,
  thumbUp,
  exercise,
  family,
  food,
  friends,
  health,
  money,
  sleep,
  weather,
  work,
};
export const feelingEmojis = {
  /* Ranks places icon left to right */
  verySadIcon: { img: verySadIcon, rank: 1 },
  sad: { img: sad, rank: 2 },
  impartial: { img: impartial, rank: 3 },
  happy: { img: happy, rank: 4 },
  thrilled: { img: thrilled, rank: 5 },
};
export const feelingReasons = {
  /* Ranks places icon left to right +  top to bottom */
  work: { img: work, rank: 1 },
  sleep: { img: sleep, rank: 2 },
  exercise: { img: exercise, rank: 3 },
  family: { img: family, rank: 4 },
  friends: { img: friends, rank: 5 },
  money: { img: money, rank: 6 },
  weather: { img: weather, rank: 7 },
  health: { img: health, rank: 8 },
  food: { img: food, rank: 9 },
};

export const thumbEmojis = { thumbDown: { img: thumbDown, rank: 2 }, thumbUp: { img: thumbUp, rank: 1 } };

const exports = { feelingEmojis, feelingReasons, thumbEmojis, allEmojis };
export default exports;
