import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// import Card from 'react-bootstrap/Card';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheck } from '@fortawesome/free-solid-svg-icons';
// import { Editor } from '@tinymce/tinymce-react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import api from '../utils/api';
import cookies from '../utils/cookies';
import Spinner from 'react-bootstrap/Spinner';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './UpdateArticleView.css'; // Import CSS file for styling

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { set } from 'lodash';
export default function UpdateArticleView() {
  const [editorData, setEditorData] = useState(EditorState.createEmpty());

  const defaultCategory = {
    name: '',
    description: '',
    tags: '',
    categoryId: '',
    enabled: ''
  };

  const defaultTopic = {
    name: '',
    description: '',
    tags: '',
    categoryId: '',
    author: '',
    createdDate: '',
    image: '',
    topicId: '',
    enabled: ''
  };

  const fetchTopic = (e) => {
    let id = e.target.value;
    let data = {
      "pageNo": 0,
      "pageSize": 500,
      "id": id,
      "enabled": isToggled ? "true" : "false"
    };

    api.fetchTopic(data).then(resp => resp?.data)
      .then(res1 => {
        console.log('fetchTopic', res1);

        if (res1.content.length > 0) {
          // Append new data to the existing data
          // setTopics(res1?.content);

          setList(res1.content);

        } else {
          // showToast('No topics, in this category!');
        }


      });
  };
  const [loader, setLoader] = useState(true);

  const [isToggled, setIsToggled] = useState(true);

  const handleToggle = () => {
    setIsToggled(prevState => !prevState);
  };

  const [category, setCategory] = useState(defaultCategory);

  const [topic, setTopic] = useState(defaultTopic);

  const [article, setArticle] = useState({
    title: '',
    image: '',
    content: '',
    author: '',
    createdDate: '',
    category: '' // Add category state
  });

  const handleCategorySelect = (e) => {

    let categoryId = e.target.value;
    if (!categoryId) return;
    let data = {

      "id": categoryId
    };

    api.fetchCategoryDetails(data).then(resp => resp?.data)
      .then(res1 => {
        console.log('fetchCategoryDetails', res1);
        let category = {
          id: res1.id,
          name: res1.title,
          description: res1.description,
          tags: res1.tags.join(','),
          categoryId: res1.id,
          enabled: res1.enabled
        };
        setCategory(category);
      }).catch(err => {
        // showToast(err?.response?.data?.message || 'No plan details to display');
        console.log('submit fetchCategoryDetails error', err);
      });


  };

  const handleCategoryChange = (e) => {
    setCategory({ ...category, [e.target.name]: e.target.value });
  };


  const handleTopicSelect = (e) => {
    let topicId = e.target.value;
    if (!topicId) return;
    let data = {
      "id": topicId
    };
    api.fetchTopicDetails(data).then(resp => resp?.data)
      .then(res1 => {
        console.log('fetchTopicDetails', res1);

        let date = new Date(res1.createdDate?.seconds * 1000 + res1.createdDate?.nanos / 1000000);
        let createdDate = date.toISOString().split('T')[0];

        // alert(createdDate);

        let topic = {
          name: res1.title,
          description: res1.description,
          tags: res1.tags.join(','),
          categoryId: res1.categoryId,
          topicId: res1.id,
          author: res1.authorName,
          image: res1.networkImageUrls[0],
          createdDate: createdDate,
          enabled: res1.enabled
        }
        setTopic(topic);
        // const rawContentState = convertToRaw(editorState?.getCurrentContent());
        // const html = draftToHtml(topic.description);
        // Assuming topic.description is a string containing the content you want to set
        let contentState = ContentState.createFromText(topic.description);
        let editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }).catch(err => {
        // showToast(err?.response?.data?.message || 'No plan details to display');

        console.log('submit fetchTopicDetails error', err);
      });


  };
  const handleTopicChange = (e) => {
    setTopic({ ...topic, [e.target.name]: e.target.value });
  };
  const handleChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here

    let data = {

      id: category.id,

      createdByUser: cookies.getUserID(),

      title: category.name,

      description: category.description,

      enabled: category.enabled,

      tags: category.tags.split(','),

      viewCount: 0,

      weights: 90


    };
    console.log('data', data);

    api.saveCategory(data).then(resp => resp?.data)
      .then(res1 => {
        console.log('saveCategory', res1);
        toast.dismiss();
        toast.info('Submitted successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setCategory(defaultCategory);
        fetchCategory(pageNo);
      }).catch(err => {
        console.log('submit error', err);
      });
  };

  const handleTopicSubmit = (e) => {
    e.preventDefault();

    console.log(editorState?.getCurrentContent());
    const rawContentState = convertToRaw(editorState?.getCurrentContent());
    const html = draftToHtml(rawContentState);

    let data = {
      id: topic.topicId,
      createdByUser: cookies.getUserID(),
      title: topic.name,
      description: html,
      enabled: topic.enabled,
      tags: topic.tags.split(','),
      viewCount: 0,
      weights: 90,
      categoryId: topic.categoryId,
      authorName: cookies.getUsername(),
      categoryTitle: category.name,
      networkImageUrls: [topic.image],
    };

    console.log('data', data);
    // return;

    api.saveTopic(data).then(resp => resp?.data)
      .then(res1 => {
        console.log('saveTopic', res1);
        toast.dismiss();
        toast.info('Submitted successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setTopic(defaultTopic);
        setEditorState(EditorState.createEmpty());
      }).catch(err => {
        console.log('submit error', err);
      });
  };


  const handleEditorChange = (content, editor) => {
    console.log('Content was updated:', content);
  }
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(500);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [topics, setTopics] = useState([]);
  const [list, setList] = useState([]);
  const fetchCategory = (page) => {
    // if (page < 1) return;

    setPageNo(page);
    let data = {
      "pageNo": page,
      "pageSize": pageSize,
      "enabled": isToggled ? "true" : "false"
    };

    api.fetchCategory(data).then(resp => resp?.data)
      .then(res1 => {
        console.log('fetchCategory', res1);
        setTopics(res1?.content)
      }).catch(err => {
        // showToast(err?.response?.data?.message || 'No plan details to display');

        console.log('submit contact error', err);
      });

  }
  const onEditorStateChange = (editorState) => {
    console.log('editorState', editorState);
    setEditorState(editorState);
  };
  setTimeout(() => {
    setLoader(false);
  }, 3000);

  useEffect(() => {
    // fetchData();
    fetchCategory(pageNo);
    // fetchTopic();
  }, [isToggled]);
  return (
    <>

      {loader ? <h3><br></br><br></br><br></br><br></br><br></br><br></br><br></br><Spinner animation="border" size="sm" variant="success" />  Loading form...<br></br><br></br><br></br><br></br><br></br><br></br></h3> : (


        <div style={{ width: '80%' }}>

          <br />
          <Button variant="secondary" onClick={() => {

            window.location.href = "/client/forum";


          }}>
            Go Back to FinHub
          </Button>

          <hr></hr>
          <div className="toggle-container">

          
           
            
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <h3>Displaying   <button className={`toggle-button ${isToggled ? 'active' : ''}`} onClick={handleToggle}>
              <span>{isToggled ? 'Enabled' : 'Disabled'}</span>
            </button> list.</h3>
          </div>

          <hr></hr>
          <h1>Update Category</h1>

          <form onSubmit={handleSubmit}>

            <div className="form-group">
              <label htmlFor="category">Choose Category (For Update)</label>

              <select
                className="form-control"
                id="categoryIdForUpdate"
                name="categoryId"
                value={category.categoryId}
                onChange={handleCategorySelect}
              >
                <option value="">Select a category</option>
                {topics.map(it => (
                  <option value={it.id}>{it.title}</option>
                ))}

              </select>
            </div>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={category.name}
                onChange={handleCategoryChange}
                placeholder="Enter category name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                className="form-control"
                id="description"
                name="description"
                value={category.description}
                onChange={handleCategoryChange}
                placeholder="Enter category description"
              ></textarea>
            </div>

            <div className="form-group">
              <label htmlFor="description">Tags (comma separated)</label>
              <textarea
                className="form-control"
                id="tags"
                name="tags"
                value={category.tags}
                onChange={handleCategoryChange}
                placeholder="Enter tags (comma separated)"
              ></textarea>
            </div>




            <div className="form-group">
              <label htmlFor="enabledForCategory">Enabled/Disabled?</label>

              <select
                className="form-control"
                id="enabledForCategory"
                name="enabled"
                value={category.enabled}
                onChange={handleCategoryChange}
              >

                <option value="">Choose enabled/disabled</option>
                <option value="true">Enabled</option>
                <option value="false">Disabled</option>

              </select>
            </div>
            <br />
            <button type="submit" className="btn btn-success">
              Update Category
            </button>
          </form>

          <br />
          <hr></hr>


          <h1>Update Topic</h1>

          <form onSubmit={handleTopicSubmit}>


            <div className="form-group">
              <label htmlFor="category">Choose Category  (For Update)</label>

              <select
                className="form-control"
                id="categoryId"
                name="categoryId"
                value={topic.categoryId}
                onChange={fetchTopic}
              >
                <option value="">Select a category</option>
                {topics.map(it => (
                  <option value={it.id}>{it.title}</option>
                ))}

              </select>
            </div>
            <div className="form-group">
              <label htmlFor="category">Choose Topic (for update)</label>
              <select
                className="form-control"
                id="topicIdForUpdate"
                name="topicId"
                value={topic.topicId}
                onChange={handleTopicSelect}
              ><option value="">Select a topic</option>
                {list.map(it => (
                  <option value={it.id}>{it.title}</option>
                ))}
              </select>
            </div>




            <div className="form-group">
              <label htmlFor="name">Title</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={topic.name}
                onChange={handleTopicChange}
                placeholder="Enter category name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="description">Description</label>
              {/* <textarea
                className="form-control"
                id="description"
                name="description"
                value={topic.description}
                onChange={handleTopicChange}
                placeholder="Enter category description"
              ></textarea> */}

              {/* <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={handleChange}
      /> */}
              <br></br>
              <div style={{ backgroundColor: "white" }}>

                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                />
              </div>
              <br></br>
            </div>

            <div className="form-group">
              <label htmlFor="description">Tags (comma separated)</label>
              <textarea
                className="form-control"
                id="tags"
                name="tags"
                value={topic.tags}
                onChange={handleTopicChange}
                placeholder="Enter tags (comma separated)"
              ></textarea>
            </div>
            {/* <div className="form-group">
              <label htmlFor="content">Content</label>
              <Editor
                apiKey='ntn1cp5e304n0ydpl5uxxcjqi6dr7lcqrgchbi2u0rl3vqz1'
                init={{
                  plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss',
                  toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                  tinycomments_mode: 'embedded',
                  tinycomments_author: 'Author name',
                  mergetags_list: [
                    { value: 'First.Name', title: 'First Name' },
                    { value: 'Email', title: 'Email' },
                  ],
                  ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                }}
                initialValue="Welcome to TinyMCE!"
              />
            </div> */}

            <div className="form-group">
              <label htmlFor="author">Author</label>
              <input
                type="text"
                className="form-control"
                id="author"
                name="author"
                value={topic.author}
                onChange={handleTopicChange}
                placeholder="Enter author name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="createdDate">Created Date</label>
              <input
                type="date"
                className="form-control"
                id="createdDate"
                name="createdDate"
                value={topic.createdDate}
                onChange={handleTopicChange}
                placeholder="Enter created date"
              />
            </div>

            <div className="form-group">
              <label htmlFor="createdDate">Image URL</label>
              <input
                type="text"
                className="form-control"
                id="image"
                name="image"
                value={topic.image}
                onChange={handleTopicChange}
                placeholder="Past image URL here"
              />
            </div>
            <div className="form-group">
              <label htmlFor="enabledForTopic">Enabled/Disabled?</label>

              <select
                className="form-control"
                id="enabledForTopic"
                name="enabled"
                value={topic.enabled}
                onChange={handleTopicChange}
              >
                <option value="">Choose enabled/disabled</option>
                <option value="true">Enabled</option>
                <option value="false">Disabled</option>

              </select>
            </div>
            <br />
            <button type="submit" className="btn btn-success">
              Update Topic
            </button>
          </form>
          <br />


        </div>)}



    </>
  );
}
