import { useState } from 'react';

const useErrorHandler = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const resetErrorHandler = () => {
    setLoading(false);
    setError(null);
  };

  const status = {
    error,
    loading,
  };
  return [status, setError, setLoading, resetErrorHandler];
};

export default useErrorHandler;
