/* 29 Nov 2022 - PAR-1045 - Moved utilization to manual entry from users.  Left in former code for poss. future changes */
import React, { useState, useEffect, useCallback } from "react";
import CardContainer from '../../CardContainer';
import ResultIcon from './ResultIcon';
import LabelButton from '../../LabelButton';
import { ASSESSMENT_ITEMS } from '../../../utils/constants';
// import { formatDateForDisplay, displayPercent } from '../../../utils/helpers';
import { formatDateForDisplay } from '../../../utils/helpers';

import api from "../../../utils/api";
import './Index.scss';

export default function Index({ goalMet, assessmentData, prevMonthName, rewardAmount ,paidParachuteOnTime}) {




  const buildSummary = (d) => {
    const sortOrder = ['loanOnTime', 'lowUtilization', 'billsOnTime', 'noNewDebt'];
    const parsedDivs = sortOrder.map((key, i) => {
      let message = '';
      let isGoalMet = null;
      // let breakdownA = null;
      let breakdownB = null;

      switch (key) {
        case 'loanOnTime':
          message = ASSESSMENT_ITEMS.paidParachuteOnTime;
          // isGoalMet = d[key].goalMet;
          isGoalMet = paidParachuteOnTime;
          break;
        // case 'lowUtilization':
        //   message = ASSESSMENT_ITEMS.underUtilization;
        //   isGoalMet = d[key].lastMonthsUtilization && d[key].goalMet;
        //   breakdownA = d[key].lastMonthsUtilization ? `Below ${displayPercent(d[key].threshold)}` : null;
        //   breakdownB = d[key].lastMonthsUtilization
        //     ? displayPercent(d[key].lastMonthsUtilization)
        //     : 'No utlization detected.';
        //   break;

        case 'lowUtilization':
          message = ASSESSMENT_ITEMS.underUtilization;
          isGoalMet = d['underUtilization'];
          break;
        case 'billsOnTime':
          message = ASSESSMENT_ITEMS.paidBillsOnTime;
          isGoalMet = d[key];
          break;
        case 'noNewDebt':
          message = ASSESSMENT_ITEMS.noNewDebt;
          isGoalMet = d[key];
          break;
        default:
      }
      let iconType = isGoalMet === false ? <ResultIcon fail /> : <ResultIcon pass />;
      if (isGoalMet === null || isGoalMet === undefined) iconType = <ResultIcon disabled />;

      // const utilizationInfo = (
      //   <>
      //     {isGoalMet === false && key === 'lowUtilization' && (
      //       /* Shows only if the goal is missed / failed. */
      //       <div className="missed-breakdown">
      //         <p>
      //           <strong>{'High credit utilization.'}</strong>
      //         </p>
      //         <span className="spaced">
      //           <p>{'Required credit utilization:'}</p>
      //           <p>{breakdownA}</p>
      //         </span>
      //         <span className="spaced">
      //           <p>{'Your debt utlization:'}</p>
      //           <p>{breakdownB}</p>
      //         </span>
      //       </div>
      //     )}
      //     {goalMet === 'missed' && key === 'lowUtilization' && isGoalMet === null && (
      //       /*[] TODO null utilization exception, DRY  this block
      //     Only shows when the goal is missed */
      //       <div className="missed-breakdown">
      //         <p>
      //           <strong>{'No utlization detected.'}</strong>
      //         </p>
      //       </div>
      //     )}
      //   </>
      // );

      return (
        <div key={`sumLine-${i}`} className="summaryLine">
          <div className="spaced">
            <p>{message}</p>
            {iconType}
          </div>
          {isGoalMet === false &&
            key === 'loanOnTime' &&
            /* Shows only if the goal is missed / failed. */
            assessmentData.loanOnTime.missedAndLatePayments &&
            Object.entries(assessmentData.loanOnTime.missedAndLatePayments)?.map(([dueDate, payStatus], i) => {
              const missedPayment = payStatus === 'Missed';
              const loanPaymentTitle = missedPayment ? 'Payment missed.' : 'Late Payment.';
              const loanPaymentBody = false && missedPayment ? 'Payment date' : 'Payment due date:';
              return (
                <div key={`payBreakdown-${i}`} className="missed-breakdown">
                  <p>
                    <strong>{loanPaymentTitle}</strong>
                  </p>
                  <span className="spaced">
                    <p>{loanPaymentBody}</p>
                    <p>{formatDateForDisplay(dueDate, { truncated: true })}</p>
                  </span>
                  {breakdownB && !missedPayment && (
                    /* Late payment, 
                    [] TODO should show then the payment was made 
                    */
                    <span className="spaced">
                      <p>{'Payment made on:'}</p>
                      <p>{breakdownB}</p>
                    </span>
                  )}
                </div>
              );
            })}
          {/* {utilizationInfo} */}
        </div>
      );
    });
    return parsedDivs;
  };
  return (
    <CardContainer id="assessment-summary" className="divided">
      {/* Similar to loan detail layout but with breakdown / explanations*/}
      <div className="spaced earningLine">
        <h4>{prevMonthName}</h4>
        {/* <LabelButton id="earnings" disabled className={goalMet === 'met' ? 'success' : 'default'}>
          {goalMet === 'met' ? `+$${rewardAmount} Earned` : '$0 Earned'}
        </LabelButton> */}
      </div>
      {buildSummary(assessmentData)}
    </CardContainer>
  );
}
