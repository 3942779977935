import React from 'react';
import './ModalTitleBlock.scss';

export default function ModalTitleBlock({ title, body, icon, className }) {
  const classToUse = ['modal--titleBlock'];
  className && classToUse.push(className);
  return (
    <div className={classToUse.join(' ')}>
      {icon && <img className="titleBlock--icon" src={icon} alt={icon} />}
      <h3 className="titleBlock--title">{title}</h3>
      <p className="muted titleBlock--body">{body}</p>
    </div>
  );
}
