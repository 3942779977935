/* TODO
- [ ] Add progress gradient color
- [ ] Style for Tablet view
- [ ] Style for Phone view
- [ ] Add Tool tip - awaiting content from design team
- [x] Confirm no data view
 */

import React from 'react';

// import CardInfo from '../CardInfo';
// import ProgressMessage from '../ProgressMessage';
import CardContainer from '../CardContainer';
import { TOOLTIP } from '../../utils/constants';
import CardTitleWTip from '../CardTitleWTip';
import WellnessProgressCircle from './ProgressCircle/WellnessProgressCircle';
// import ErrorNotification from '../ErrorNotification';
import CardModal from '../CardModal';
import './Index.scss';
import { formatDateForDisplay } from '../../utils/helpers';
import { wellnessExperience } from './utils/wellnessExperience';
// import averagePeerWellness from './utils/averagePeerWellness';
import ErrorNotification from '../ErrorNotification';

function WellnessScoreCard({ data }) {
  const currentScore = data?.currentScore;
  const dateCalculated = data?.dateCalculated; //CurrentScore create DateTime
  const previousScore = data?.previousScore;
  // const userAge = data?.userAge; // From user
  // const userIncome = data?.userIncome; //From user
  // const showError = !data?.info;

  const wellnessDetails = wellnessExperience(currentScore);

  const NATIONAL_AVG_SCORE = 55;

  return (
    <CardContainer id="wellnessScore">
      <CardTitleWTip tipObject={TOOLTIP.WELLNESS_SCORE}>Financial Well-Being Score</CardTitleWTip>
      <div className="v-dividers" style={{ margin: '24px 0px' }}>
        <div className="currInfo" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <WellnessProgressCircle currentScore={currentScore ?? 0} bracketDescription={wellnessDetails?.bracket} />

          <span className="avgScoreInfo" style={{ display: 'flex', maxWidth: 336 - 160 }}>
            <h3>
              National average:
              <div className="muted">{`${NATIONAL_AVG_SCORE}`}</div>
            </h3>
          </span>
        </div>

        <div className="infoLine" style={{ margin: '24px 0px' }}>
          <h4>Based on data from CFPB</h4>
          <p className="muted">
            {dateCalculated
              ? `Calculated on ${formatDateForDisplay(dateCalculated, { custom: 'MMM dd, yyyy' })}`
              : 'No data available'}
          </p>
        </div>

        <div className="infoLine">
          <h4>Previous score</h4>
          <p className="muted">{previousScore ?? 'No data available'}</p>
        </div>
      </div>

      {currentScore && (
        <CardModal id="wellness-details" buttonID="wellnessLearnMore" closeText="Close">
          <h3>Score Details</h3>
          <div className="v-dividers test">
            <h4>{`Your score: ${currentScore}`}</h4>
            <ul>
              {wellnessDetails?.['experience']?.map((xp) => {
                return <li key={xp}>{xp}</li>;
              })}
            </ul>
            <p className="disclaimer">Based on data from CFPB</p>
          </div>
        </CardModal>
      )}
      {!currentScore && <ErrorNotification>We cannot determine well-being score.</ErrorNotification>}
    </CardContainer>
  );
}

export default WellnessScoreCard;
