import React from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import './ProgressCircle.scss';
export default function UtilizationProgressCircle({ currentScore, bracketDescription }) {
  const TRICOLOR_BANDS = {
    red: '#F04438',
    orange: '#F79009',
    green: '#039855',
  };
  let colorToUse = TRICOLOR_BANDS.green;
  if (currentScore < 35) colorToUse = TRICOLOR_BANDS.green;
  if (currentScore >= 35 && currentScore <= 50) colorToUse = TRICOLOR_BANDS.orange;
  if (currentScore > 50) colorToUse = TRICOLOR_BANDS.red;

  const isNumber = (input) => {
    if (input === undefined || input === null) return null;
    if (isNaN(input)) return null;
    return true;
  };
  return (
    <div id="scoreArea">
      <CircularProgressbarWithChildren
        value={currentScore ?? 0}
        styles={{
          // Customize the root svg element
          root: {},
          // Customize the path, i.e. the "completed progress"
          path: {
            // Path color
            stroke: colorToUse, //`rgba(25, 135, 84)`,
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'round',
            // Customize transition animation
            transition: 'stroke-dashoffset 0.5s ease 0s',
          },
        }}
      >
        <div className="scoreInfo">
          <p className="score" style={{ margin: 0 }}>
            {isNumber(currentScore) ? `${currentScore}%` : 'N/A'}
          </p>
          <p className="scoreRange" style={{ margin: 0 }}>
            {currentScore ? bracketDescription : null}
          </p>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
}
