import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
// import Table from 'react-bootstrap/Table';
import api from '../../../../utils/api';
import { formatDateForDisplay } from '../../../../utils/helpers';
import { feelingEmojis, feelingReasons } from '../../../../assets/emojiSVG/emojis';
import cookies from '../../../../utils/cookies';

import { useCallback } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
export default function MoodActivity({ userToView, index }) {
  const { link } = userToView;
  const userLink = link;


  const [gridApi, setGridApi] = useState(null);
  const perPage = 10;
  const onGridReady = (params) => {
    setGridApi(params.api);
  };


  const [totalTableElements, setTotalTableElements] = useState(0);
  console.log(totalTableElements)

  const [userData, setUserData] = useState({ mood: null, inspiration: null, quiz: null, cbRecords: null });
  useEffect(() => {
    const getUserActivity = async () => {
      //Mood Responses
      const rawMood = await api.getUsersMoodAnswerForToday(userLink, cookies.getDateRangeByInput('01 Jan 2022'));
      const userMoodResponses = rawMood.data._embedded.moodAnswers;
      //[] Need Date Created in mood answers

      setUserData({
        ...userData,
        // quiz: userQuizData.sort((x, y) => x.quizID.localeCompare(y.quizID)),
        // inspiration: userInspData,
        mood: userMoodResponses,
        // cbRecords: userCBRecords,
      });
    };
    getUserActivity();
    //eslint-disable-next-line



  }, [userLink]);
  const weeklyAnalyticsCall = useCallback(async () => {
    try {

      let data = cookies.getDateRangeByInput('01 Jan 2022');
      console.log(data)
      const weeklyAnalyticsRes = await api.getUsersMoodAnswerForTodayPaginated(userLink, cookies.getDateRangeByInput('01 Jan 2022'), 0, perPage);
      console.log(weeklyAnalyticsRes.data._embedded.moodAnswers);

    } catch (e) {
      console.error(e);
    }
  }, [userLink]);

  useEffect(() => {
    weeklyAnalyticsCall();
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);

          gridApi.showLoadingOverlay();
          const page = params.endRow / perPage;
          let data = cookies.getDateRangeByInput('01 Jan 2022')

          api.getUsersMoodAnswerForTodayPaginated(userLink, data, page - 1, perPage)
            .then(resp => resp?.data)
            .then(res => {
              gridApi.hideOverlay();
              console.log(res)
              const initUsers = res._embedded.moodAnswers;
              console.log(initUsers);
              setTotalTableElements(res.page.totalElements)

              // const sortedUserInspData = initUsers.sort(function (a, b) { return a.createDateTime - b.createDateTime });
              params.successCallback(initUsers, res.page.totalElements);

            }).catch(err => {
              gridApi.hideOverlay();
              console.log(err);
              params.successCallback([], 0);
            });
        }
      }
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, weeklyAnalyticsCall, userLink]);

  const actionsFormatterCreateDateTime = ({ value, data }) => {
    if (!value) {
      return 'N/A';
    }
    return <>
      {formatDateForDisplay(value)}
    </>;
  }

  const actionsFormatterFeeling = ({ value, data }) => {
    if (!value) {
      return 'N/A';
    }
    return <>
      <span style={{ display: 'flex', gap: '12px' }}>
        <img src={feelingEmojis[value]?.['img']} alt={value} />
        <p>{value}</p>
      </span>
    </>;
  }

  const actionsFormatterWhy = ({ value, data }) => {
    if (!value) {
      return 'N/A';
    }
    return <>
      <span style={{ display: 'flex', gap: '12px' }}>
        <img src={feelingReasons[value?.toLowerCase()]?.['img']} alt={value} />
        <p>{value}</p>
      </span>
    </>;
  }
  return (
    <>
      <Accordion.Item eventKey={index}>
        <Accordion.Header>Mood Responses - {totalTableElements ?? '0'}</Accordion.Header>
        <Accordion.Body>
          {/* <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Response Date</th>
                <th>Feeling</th>
                <th>Why</th>
              </tr>
            </thead>
            <tbody>
              {userData.mood &&
                Object.values(userData.mood)
                  .sort((x, y) => y.createDateTime.localeCompare(x.createDateTime))
                  .map((q, i) => {
                    return (
                      <tr key={`userMoodRes-${i}`}>
                        <td>{formatDateForDisplay(q?.createDateTime)}</td>
                        <td>
                          <span style={{ display: 'flex', gap: '12px' }}>
                            <img src={feelingEmojis[q?.feeling]?.['img']} alt={q?.feeling} />
                            <p>{q?.feeling}</p>
                          </span>
                        </td>
                        <td>
                          <span style={{ display: 'flex', gap: '12px' }}>
                            <img src={feelingReasons[q?.why?.toLowerCase()]?.['img']} alt={q?.why} />
                            <p>{q?.why}</p>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table> */}

          {/* <hr></hr>
<p>Paginated View:</p>
<hr></hr> */}
          <div id="applicanttable1">
            <div className="App">

              <div className='container d-flex flex-column flex-grow-1 py-4'>
                <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                  <div style={{ flexGrow: '1' }}>


                    <div className="ag-theme-alpine ag-style" style={{ height: '100%', width: '100%' }}>
                      <AgGridReact
                        pagination={true}
                        rowModelType={'infinite'}
                        paginationPageSize={perPage}
                        cacheBlockSize={perPage}
                        onGridReady={onGridReady}
                        rowHeight={60}
                        sortable={true}
                        unSortIcon={true}
                        defaultColDef={{ flex: 1, sortable: true, unSortIcon: true, sortingOrder: ['ASC', 'DESC'] }}
                        overlayLoadingTemplate={
                          '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
                        }
                        overlayNoRowsTemplate={
                          '<span className="ag-overlay-loading-center">No data found to display.</span>'
                        }
                      >
                        {/* <AgGridColumn  field="userId" headerName="User ID" cellClass="vertical-middle" /> */}

                        {/* <AgGridColumn  field="firstName" headerName="First Name" cellClass="vertical-middle" /> */}
                        {/* <AgGridColumn  field="lastName" headerName="Last Name" cellClass="vertical-middle" /> */}
                        <AgGridColumn field="createDateTime" headerName="Response Date" cellClass="vertical-middle" cellRendererFramework={actionsFormatterCreateDateTime} />
                        <AgGridColumn field="feeling" headerName="Feeling" cellClass="vertical-middle" cellRendererFramework={actionsFormatterFeeling} />
                        <AgGridColumn field="why" headerName="Why" cellClass="vertical-middle" cellRendererFramework={actionsFormatterWhy} />
                        {/*  */}
                      </AgGridReact>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>


        </Accordion.Body>
      </Accordion.Item>
    </>
  );
}
