import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function GenericModal({
  modalBtn,
  header,
  body,
  primaryBtn,
  primaryAction,
  secondaryBtn,
  secondaryAction,
  children,
  style,
  variant,
  primaryVariant,
  onClose,
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    onClose();
  };
  const handleShow = () => setShow(true);

  const handlePrimary = async (e) => {
    if (primaryAction) {
      await primaryAction(e);
    }
    handleClose();
  };

  return (
    <>
      <Button size="sm" onClick={handleShow} variant={variant || 'primary'} style={style}>
        {modalBtn}
      </Button>

      <Modal show={show} onHide={handleClose} style={{ backgroundColor: 'white' }}>
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children || body}</Modal.Body>
        <Modal.Footer>
          {secondaryBtn && (
            <Button variant="secondary" onClick={handleClose}>
              {secondaryBtn}
            </Button>
          )}
          {primaryBtn && (
            <Button variant={primaryVariant || 'primary'} onClick={handlePrimary}>
              {primaryBtn}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
