import React from 'react';
import WideBtn from '../WideBtn';
import Form from 'react-bootstrap/Form';

export default function ContactForm({ handleChange, handleSubmit, isSubmitDisabled, error }) {
  return (
    <Form className="form">
      <Form.Group className="" controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" placeholder="name@example.com" onChange={handleChange} required />
      </Form.Group>

      <Form.Group className="" controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control placeholder="First, Last" onChange={handleChange} required />
      </Form.Group>

      <Form.Group className="" controlId="reason">
        <Form.Label>Reason</Form.Label>
        <Form.Control placeholder="Brief description" onChange={handleChange} required />
      </Form.Group>

      <Form.Group className="" controlId="details">
        <Form.Label>More Details</Form.Label>
        <Form.Control as="textarea" rows={3} placeholder="Tell us more" onChange={handleChange} required />
      </Form.Group>
      {error && <p className="error">{`${error}`}</p>}

      <WideBtn className={'submitBtn'} onClick={handleSubmit} disabled={isSubmitDisabled}>
        Submit
      </WideBtn>
    </Form>
  );
}
