/* Make custom error handler > dont want to verify user actually exit
errorState is only when something other than a bad request error

Message should be something like if your email is correct you should recieve an email shortly.

*/


import googlePlayIcon from "../../assets/Google-Play.png";

import appStoreIcon from "../../assets/app-store.png";
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAPI from '../../hooks/useAPI';
import CardContainer from '../CardContainer';
import Logo_Icon from '../../assets/Logo_Icon.svg';
// import arrow_right from '../../assets/icons/arrow_right.svg';
import Form from 'react-bootstrap/Form';
import WideBtn from '../WideBtn';
import './UpdateApp.scss';

export default function UpdateApp() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');

  const [platform, setPlatform] = useState(JSON.parse(localStorage.getItem('device_info')));

  const [{ data, isLoading, isError }, doFetchData] = useAPI();

  const errorMsg =
    isError && data.includes('User is not found with username') ? 'No account with that email detected.' : data;

  const handleInput = (e) => {
    e.preventDefault();
    const newValue = e.target.value;
    setUsername(newValue);
  };

  const handleSubmit = (e, user) => {
    e.preventDefault();

    if (!user) return;
    const apiCall = {
      method: 'post',
      url: '/users/requestResetEmail',
      data: {
        email: user.toLowerCase(),
      },
    };
    doFetchData(apiCall);
  };

  const resetForm = (
    <>
      <div className="titleArea">
        <h2>New Version Available!</h2>
        <p className="muted">A new version of your app is available, Please update to latest version.</p>
      </div>


    </>
  );

  return (
    <CardContainer id="forgotPassCard" style={{ margin: "5%" }}>
      <img className="loginIcon" src={Logo_Icon} alt="Parachute Cash" />
      {/* {backBtn} */}
      {data !== 'success' && resetForm}

      {/* <Button variant="outline-secondary" variant={'success'}>
        Update
      </Button> */}

      <div className="d-flex flex-column justify-content-center align-items-center py-4">


        {platform?.platform === "android" && <a className="mb-4" href="https://play.google.com/store/apps/details?id=co.myparachute.app" target="_blank" rel="noopener noreferrer">
          <img src={googlePlayIcon} alt="Download on Google Play" />
        </a>}

        {platform?.platform === "ios" &&
          <a href="https://apps.apple.com/us/app/my-parachute/id6446090531" target="_blank" rel="noopener noreferrer">
            <img src={appStoreIcon} alt="Download on the App Store" />
          </a>
        }

        {!platform &&
          <>
            <a className="mb-4" href="https://play.google.com/store/apps/details?id=co.myparachute.app" target="_blank" rel="noopener noreferrer">
              <img src={googlePlayIcon} alt="Download on Google Play" />
            </a>
            <a href="https://apps.apple.com/us/app/my-parachute/id6446090531" target="_blank" rel="noopener noreferrer">
              <img src={appStoreIcon} alt="Download on the App Store" />
            </a>
          </>}
      </div>

    </CardContainer>
  );
}
