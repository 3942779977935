/* Modal container to display onboarding slides
Business Rules
- Only moves forward; Cannot go back to previous slide
- Pagination is for reference only - NOT interactive
- [] TODO: Will need to check for Flinks integration before doing iframe (later problem)

Takes in prop UID (userID #) which is used to append onboarding in local storage (for multi-user environments)
*/
import React, { useState, useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import CardContainer from '../CardContainer';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import WelcomeVideo from './WelcomeVideo';
import VideoModal from './VideoModal';

import GoalSlide from './GoalSlide';
import ConnectSlide from './ConnectSlide';
// import FlinksSlide from './FlinksSlide';
import CashbackSlide from './CashbackSlide';
import QuizInfoSlide from './QuizSlide';
import FirstQuizSlide from './FirstQuiz';
import QuizTile from '../QuizCard/QuizTile/Index';
import SurveySlide from './SurveySlide';

import api from '../../utils/api';
import cookies from '../../utils/cookies';
import { parseIdFromLink } from '../../utils/helpers';
import './Index.scss';
import ErrorNotification from '../ErrorNotification';

export default function OnboardingModal({ UID, quiz, setOnboardingComplete }) {
  const [show, setShow] = useState(true); //[] HARD CODED TO SHOWdisplayContainer
  show && window.history.pushState({ page: 'onboarding' }, 'Onboarding', '/client/onboarding');
  !show && window.history.pushState({ page: 'client' }, 'Client', '/client');
  const [displayContainer, setDisplayContainer] = useState(true);
  const [saving, setSaving] = useState(false); //Controls indicator for patching user Object when onboarding is complete
  const [usersApplicationID, setUsersApplicationID] = useState(null);
  const userEmail = cookies.getUsername();

  useEffect(() => {
    const updateUsersApplicationID = async () => {
      try {
        const rawRes = await api.findApplicationByEmail(userEmail);
        const applicationID =
          rawRes.data._embedded.applicants[0]?._links.self.href &&
          parseIdFromLink(rawRes.data._embedded.applicants[0]?._links.self.href);

        setUsersApplicationID(applicationID);
      } catch (e) {
        console.log('Error fetching user application ID', e);
      }
    };
    updateUsersApplicationID();
  }, [userEmail]);

  const updateUserOnboarding = async (userID) => {
    setSaving(true);
    await api.userCompleteOnboarding(userID); //Changes users onboarding to true
    setSaving(false);
  };
  const hideModal = () => {
    setDisplayContainer(false);
  };

  const showModal = () => {
    setDisplayContainer(true);
  };
  const handleCloseModal = async () => {
    await updateUserOnboarding(UID);
    // Update survey questions
    //await updateSurveys(application or user id, answers)

    setOnboardingComplete(true);
    setShow(false);
  };

  const [surveyData, setSurveyData] = useState({});

  const [index, setIndex] = useState(0); //Active Slide
  const incrementIndex = () => {
    setIndex((p) => p + 1);
  };
  const SLIDES = {
    0: {
      component: <WelcomeVideo />,
    },

    1: {
      component: <GoalSlide />,
      text: 'Agree and continue',
    },
    2: {
      component: <ConnectSlide />,
      text: 'Connect account(s)',
    },
    // 3: {
    //   component: <FlinksSlide incrementIndex={incrementIndex} />,
    //   text: 'Skip',
    // },
    3: { component: <CashbackSlide /> },
    4: { component: <QuizInfoSlide /> },
    5: {
      component: <FirstQuizSlide />,
    },
    // 6 & 7 are survey questions (from pre-qual)
    6: {
      component: (
        <SurveySlide
          behaviour
          incrementIndex={incrementIndex}
          setIndex={setIndex}
          setSurveyData={setSurveyData}
          surveyData={surveyData}
          usersApplicationID={usersApplicationID}
        />
      ),
    },
    7: {
      component: (
        <SurveySlide
          financialLiteracy
          setIndex
          setSurveyData={setSurveyData}
          surveyData={surveyData}
          handleCloseModal={handleCloseModal}
          usersApplicationID={usersApplicationID}
          userEmail={userEmail}
        />
      ),
    },
  };
  const slidesExemptions = [0, 5, 6, 7]; //Slide Indexes; will disable the default button layout.
  /* !IMPORTANT, if slide indexes change, check and adjust render block, as some elements depend on active Index */

  // ===================== Pagination Logic
  let paginationItems = [];
  for (let number = 0; number <= Object.keys(SLIDES).length - 1; number++) {
    const isActive = number === index;
    const classToUse = ['paginationDot'];
    isActive && classToUse.push('active');
    paginationItems.push(<Pagination.Item key={number} active={isActive} disabled={true} />);
    /* PaginationItem is a div container styled to be a dot */
  }

  const btnAction = SLIDES[index]?.action || incrementIndex; //Increment Index unless something else is present

  const SecondaryContinueBtn = ({ children, style }) => {
    return (
      <h5 id="welcomeSlideContinue" onClick={btnAction} style={style}>
        {children}
      </h5>
    );
  };

  const videoBtns = (
    <>
      <div className="d-grid gap-2">
        <VideoModal id="onboardingBtn" showParentModal={showModal} hideParentModal={hideModal} />
      </div>
      <SecondaryContinueBtn>Continue</SecondaryContinueBtn>
    </>
  );

  return (
    <>
      <Modal
        id="onboarding"
        show={show}
        animation={false}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CardContainer
          id="onboardingContainer"
          className="cardModal-container"
          /* Logic to hide the onboard modal when the video is playing */
          style={{ display: displayContainer ? 'flex' : 'none' }}
        >
          {/* Slide is determined by the index and the content of SLIDES */}
          {SLIDES[index]?.component && SLIDES[index].component}

          {index === 5 ? (
            quiz.current.question ? (
              <div className="onboardQuizContainer" style={{ paddingBottom: '20px', width: '100%' }}>
                <QuizTile
                  id="onboardQuiz"
                  questionData={quiz.current}
                  submitAnswer={quiz.submitAnswer}
                  handleCloseModal={incrementIndex}
                />
                {quiz.current.userAnswer && (
                  <Button
                    id="onboardingBtn"
                    variant="success"
                    size="lg"
                    onClick={incrementIndex}
                    style={{ marginBottom: '24px' }}
                  >
                    {saving ? <Spinner animation="border" size="sm" /> : `Continue`}
                  </Button>
                )}
              </div>
            ) : (
              <div className="noQuiz">
                <ErrorNotification>No quiz data available</ErrorNotification>
                <SecondaryContinueBtn style={{ marginBottom: '24px' }}>Skip</SecondaryContinueBtn>
              </div>
            )
          ) : null}

          <Pagination id="onboard-pagination" size="sm">
            {paginationItems}
          </Pagination>

          {/* ============== Buttons */}
          {!slidesExemptions.includes(index) && (
            <div className="d-grid gap-2">
              <Button id="onboardingBtn" variant="success" size="lg" onClick={btnAction}>
                {`${SLIDES[index]?.text || 'Continue'}`}
              </Button>
            </div>
          )}
          {index === 0 && videoBtns}
          {/* {index === 3 && <SecondaryContinueBtn>Skip</SecondaryContinueBtn>} */}
        </CardContainer>
      </Modal>
    </>
  );
}
