/* Make custom error handler > dont want to verify user actually exit
errorState is only when something other than a bad request error

Message should be something like if your email is correct you should recieve an email shortly.

*/

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAPI from '../../hooks/useAPI';
import CardContainer from '../CardContainer';
import Logo_Icon from '../../assets/Logo_Icon.svg';
// import arrow_right from '../../assets/icons/arrow_right.svg';
import Form from 'react-bootstrap/Form';
import WideBtn from '../WideBtn';
import './ForgotPass.scss';

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');

  const [{ data, isLoading, isError }, doFetchData] = useAPI();

  const errorMsg =
    isError && data.includes('User is not found with username') ? 'No account with that email detected.' : data;

  const handleInput = (e) => {
    e.preventDefault();
    const newValue = e.target.value;
    setUsername(newValue);
  };

  const handleSubmit = (e, user) => {
    e.preventDefault();

    if (!user) return;
    const apiCall = {
      method: 'post',
      url: '/users/requestResetEmail',
      data: {
        email: user.toLowerCase(),
      },
    };
    doFetchData(apiCall);
  };

  const resetForm = (
    <>
      <div className="titleArea">
        <h2>Forgot password?</h2>
        <p className="muted">Enter your account's email address and we'll send you an email to reset the password.</p>
      </div>

      <hr />

      <Form id="resetPassForm" onSubmit={(e) => handleSubmit(e, username)}>
        <Form.Group className="mb-3" controlId="username" onSubmit={() => {}}>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="name@example.com"
            onChange={handleInput}
            value={username}
            disabled={isLoading}
            isInvalid={isError && errorMsg}
          />
          <Form.Control.Feedback type="invalid">{`${errorMsg}`}</Form.Control.Feedback>
        </Form.Group>

        <WideBtn
          disabled={username ? false : true || isLoading}
          type="submit"
          // onClick={(e) => handleSubmit(e, username)}
        >
          Send email
        </WideBtn>
      </Form>
    </>
  );

  const succesMsg = (
    <>
      <div className="titleArea">
        <h2>Password Sent</h2>
        <p className="muted">We’ve sent an email to the email address you provided to reset your password.</p>
      </div>
      <hr />
      <WideBtn type="submit" onClick={(e) => navigate('/login', { replace: true })}>
        Go to Log In
      </WideBtn>
    </>
  );

  // const backBtn = (
  //   <div className="settings-backBtn-container">
  //     <div className="settings-backBtn" onClick={(e) => navigate('/login', { replace: true })}>
  //       <img className="leftFacing" src={arrow_right} alt="Arrow" />
  //       <h3>Back</h3>
  //     </div>
  //   </div>
  // );
  return (
    <CardContainer id="forgotPassCard">
      <img className="loginIcon" src={Logo_Icon} alt="Parachute Cash" />
      {/* {backBtn} */}
      {data !== 'success' && resetForm}
      {!isLoading && !isError && data === 'success' && succesMsg}
    </CardContainer>
  );
}
