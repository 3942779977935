import React, { useState } from 'react';
import CardContainer from '../CardContainer';
import Modal from 'react-bootstrap/Modal';
import LoanInfo from '../LoanInfo';
import WideBtn from '../WideBtn';
import ModalTitleBlock from '../ModalTitleBlock';
import { displayMonth } from '../../utils/helpers';
import { ASSESSMENT_ITEMS } from '../../utils/constants';
import api from '../../utils/api';

import wrenchIcon from '../../assets/icons/wrenchIcon.svg';
import xImg from '../../assets/icons/dismissed_icon_Filled.svg';

import './DiscrepancyModal.scss';

import cookies from '../../utils/cookies';
export default function DiscrepancyModal({ data, clearClawbackFromState }) {
  //data is cbHistory of the month that has changed
  //Assumes the prior history was all green before > display the false to explain why there is a CB
  /* [] Gary is Confirming how this state is being cleared */
  const [show, setShow] = useState(false);

  const handleClose = async () => {
    try {
      //Patch notification as shown (no front end affect backend only)
      const notifyIDLink = data.notifyDetails.notificationIDLink;
      const notifyWasRead = data.notifyDetails.wasRead;

      const timeNow = cookies.getDate();
      const patchBody = { shown: true, firstShownDate: timeNow, dismissed: true, dismissedDate: timeNow };
      if (notifyWasRead === false) await api.patchNotification(notifyIDLink, patchBody);
      clearClawbackFromState();
      setShow(false);
    } catch (e) {
      setShow(false); //[] TODO will likely change if dismissal is handled on by the front end
    }
  };
  const handleShow = () => setShow(true);

  const affectedMonth = displayMonth(data.assessmentMonth, { addDays: 3 }); //[] Because of svr zulu time add extra days to the assessment month (should be 1st)
  // To ensure the display month is correct

  const failedReasons = [];
  Object.entries(data).forEach(([k, v]) => {
    if (v === false) {
      failedReasons.push(k);
    }
  });

  const parsedReasons = failedReasons.map((r, i) => {
    return (
      <LoanInfo key={`cbDisc-${i}`} className="" title={ASSESSMENT_ITEMS[r]} body={<img src={xImg} alt={xImg} />} />
    );
  });

  const CbContactSpt = (
    <div id="cbContactSpt">
      <h3>We updated your cash-back amount.</h3>
      <h3 className="linkText" onClick={handleShow}>
        Contact support
      </h3>
    </div>
  );

  return (
    <>
      {/* <WideBtn variant="outline-secondary" onClick={handleShow}>
        CHANGE TO Txt
      </WideBtn> */}
      {CbContactSpt}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CardContainer id="cbHistory-discrepancy" className="cardModal-container">
          <div className="cardModal-contents">
            <ModalTitleBlock
              title="Cash-back amount updated."
              body="Hey there, we noticed a discrepancy between your self-assessment and what our records indicated."
              icon={wrenchIcon}
            />
            <CardContainer className="discrepancy-details divided">
              <LoanInfo className="" title={`${affectedMonth}`} body={`$0 Earned`} />
              <hr />
              {/* <LoanInfo className="" title={`Pay all bills on time.`} body={<img src={xImg} alt={xImg} />} /> */}
              {parsedReasons}
            </CardContainer>

            <div>
              <p className="muted">We will update your total cash-back amount to reflect this change.</p>
              <p className="muted">
                For more information, <a href="www.google.ca">contact us</a>.
              </p>
            </div>

            <WideBtn onClick={handleClose}>Got it</WideBtn>
          </div>
        </CardContainer>
      </Modal>
    </>
  );
}
