/* Simple Modal with blurred (depending on browser) background 
Okay / close button is fixed, 
displays all children
*/
import React, { useState } from 'react';
import CardContainer from './CardContainer';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import DismissIcon from '../assets/icons/Dismiss_Icon_Blk.svg';

import './CardModal.scss';

export default function CardModal({ children, id, buttonID, btnClass, closeText, textBtn, closeBtn }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {textBtn ? (
        <p onClick={handleShow} id={buttonID} className={btnClass}>
          {textBtn}
        </p>
      ) : (
        <Button variant="outline-secondary" onClick={handleShow} id={buttonID} className={btnClass}>
          Learn More
        </Button>
      )}

      <Modal show={show} onHide={handleClose} keyboard={true} aria-labelledby="contained-modal-title-vcenter" centered>
        <CardContainer id={id} className="cardModal-container">
          <div className="cardModal-contents">
            {closeBtn && (
              <div className="closeBtnArea">
                <img className="closeBtn" src={DismissIcon} alt="Close Modal" onClick={handleClose} />
              </div>
            )}
            {children}
            <div className="d-grid gap-2 lgModalBtn">
              <Button className="modalBtn" variant="success" size="lg" onClick={handleClose}>
                {closeText || 'Okay'}
              </Button>
            </div>
          </div>
        </CardContainer>
      </Modal>
    </>
  );
}
