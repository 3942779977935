import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import api from '../../../../utils/api';
import { formatDateForDisplay, parseIdFromLink } from '../../../../utils/helpers';
import { FLINKS_INSITUTION_IDS } from '../../../../utils/constants/FLINKS_INST_IDS';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

export default function CoachingActivities({ userToView, index }) {
  const { id } = userToView;

  const [userData, setUserData] = useState(null);
  useEffect(() => {
    api.getCoachingActivities(id).then((res) => {
      if (res.data) {
        console.log('CoachingActivities', res.data);
        setUserData(res.data);
      }
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    if (userData) {
      setFormData({
        userId: userData.userId || '',
        completedOnboardingCall: userData.completedOnboardingCall ? 'Yes' : 'No',
        onboardingCallDate: formatDate(userData.onboardingCallDate),
        onboardingCallNotes: userData.onboardingCallNotes || '',
        utilizationOver50AfterLoanDisbursed: userData.utilizationOver50AfterLoanDisbursed ? 'Yes' : 'No',
        utilizationOver50Date: formatDate(userData.utilizationOver50Date),
        utilizationOver50Notes: userData.utilizationOver50Notes || '',
        referredToCoaching: userData.referredToCoaching ? 'Yes' : 'No',
        referredToCoachingDate: formatDate(userData.referredToCoachingDate),
        referredToCoachingNotes: userData.referredToCoachingNotes || '',
        receivedBudget: userData.receivedBudget ? 'Yes' : 'No',
        receivedBudgetDate: formatDate(userData.receivedBudgetDate),
        receivedBudgetNotes: userData.receivedBudgetNotes || '',
        utilizationUnder50AfterCoaching: userData.utilizationUnder50AfterCoaching ? 'Yes' : 'No',
        utilizationUnder50Date: formatDate(userData.utilizationUnder50Date),
        utilizationUnder50Notes: userData.utilizationUnder50Notes || '',
        connectedToMX: userData.connectedToMX ? 'Yes' : 'No',
        connectedToMXDate: formatDate(userData.connectedToMXDate),
        connectedToMXNotes: userData.connectedToMXNotes || '',
        completedTestimonial: userData.completedTestimonial ? 'Yes' : 'No',
        completedTestimonialDate: formatDate(userData.completedTestimonialDate),
        completedTestimonialNotes: userData.completedTestimonialNotes || '',
        optedOutOfText: userData.optedOutOfText ? 'Yes' : 'No',
        optedOutOfTextDate: formatDate(userData.optedOutOfTextDate),
        optedOutOfTextNotes: userData.optedOutOfTextNotes || '',
        optedOutOfEmails: userData.optedOutOfEmails ? 'Yes' : 'No',
        optedOutOfEmailsDate: formatDate(userData.optedOutOfEmailsDate),
        optedOutOfEmailsNotes: userData.optedOutOfEmailsNotes || ''
      });
    }
  }, [userData]);
  const initialState = {
    completedOnboardingCall: '',
    onboardingCallDate: '',
    onboardingCallNotes: '',
    utilizationOver50AfterLoanDisbursed: '',
    utilizationOver50Date: '',
    utilizationOver50Notes: '',
    referredToCoaching: '',
    referredToCoachingDate: '',
    referredToCoachingNotes: '',
    receivedBudget: '',
    receivedBudgetDate: '',
    receivedBudgetNotes: '',
    utilizationUnder50AfterCoaching: '',
    utilizationUnder50Date: '',
    utilizationUnder50Notes: '',
    connectedToMX: '',
    connectedToMXDate: '',
    connectedToMXNotes: '',
    completedTestimonial: '',
    completedTestimonialDate: '',
    completedTestimonialNotes: '',
    optedOutOfText: '',
    optedOutOfTextDate: '',
    optedOutOfTextNotes: '',
    optedOutOfEmails: '',
    optedOutOfEmailsDate: '',
    optedOutOfEmailsNotes: ''
  };

  const [formData, setFormData] = useState(initialState);
  const [finalFormData, setFinalFormData] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedData = {
      userId: id,
      ...formData,
      completedOnboardingCall: formData.completedOnboardingCall === 'Yes',
      utilizationOver50AfterLoanDisbursed: formData.utilizationOver50AfterLoanDisbursed === 'Yes',
      referredToCoaching: formData.referredToCoaching === 'Yes',
      receivedBudget: formData.receivedBudget === 'Yes',
      utilizationUnder50AfterCoaching: formData.utilizationUnder50AfterCoaching === 'Yes',
      connectedToMX: formData.connectedToMX === 'Yes',
      completedTestimonial: formData.completedTestimonial === 'Yes',
      optedOutOfText: formData.optedOutOfText === 'Yes',
      optedOutOfEmails: formData.optedOutOfEmails === 'Yes'
    };
    console.log('formattedData:', formattedData);
    setFinalFormData(formattedData);
    api.setCoachingActivities(formattedData).then((res) => {
      console.log('setCoachingActivities', res.data);
      toast.success('Coaching Activities updated successfully');
    }).catch((err) => {
      console.log(err);
      toast.error('Error updating Coaching Activities');
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Format to YYYY-MM-DD
  };
  return (
    <>
      <Accordion.Item eventKey={index}>
        <Accordion.Header>{`Coaching Activities`}</Accordion.Header>
        <Accordion.Body>

          <>
            <Container>
              {/* <h2 className="mb-4">Coaching Activities</h2> */}
              <Form onSubmit={handleSubmit}>
                {[
                  { label: "Completed onboarding call", name: "completedOnboardingCall", dateName: "onboardingCallDate", notesName: "onboardingCallNotes" },
                  { label: "Utilization over 50% after loan disbursed", name: "utilizationOver50AfterLoanDisbursed", dateName: "utilizationOver50Date", notesName: "utilizationOver50Notes" },
                  { label: "Referred to coaching", name: "referredToCoaching", dateName: "referredToCoachingDate", notesName: "referredToCoachingNotes" },
                  { label: "Received a budget", name: "receivedBudget", dateName: "receivedBudgetDate", notesName: "receivedBudgetNotes" },
                  { label: "Utilization under 50% after coaching", name: "utilizationUnder50AfterCoaching", dateName: "utilizationUnder50Date", notesName: "utilizationUnder50Notes" },
                  { label: "Connected to MX", name: "connectedToMX", dateName: "connectedToMXDate", notesName: "connectedToMXNotes" },
                  { label: "Completed a testimonial", name: "completedTestimonial", dateName: "completedTestimonialDate", notesName: "completedTestimonialNotes" },
                  { label: "Opted out of text", name: "optedOutOfText", dateName: "optedOutOfTextDate", notesName: "optedOutOfTextNotes" },
                  { label: "Opted out of emails", name: "optedOutOfEmails", dateName: "optedOutOfEmailsDate", notesName: "optedOutOfEmailsNotes" }
                ].map((item, index) => (
                  <Form.Group as={Row} key={index} className="mb-3">
                    <Form.Label column sm={4}>{item.label}</Form.Label>
                    <Col sm={8}>
                      <Form.Control as="select" name={item.name} value={formData[item.name]} onChange={handleChange} required>
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </Form.Control>
                      <Form.Control
                        type="date"
                        name={item.dateName}
                        value={formData[item.dateName]}
                        onChange={handleChange}
                        className="mt-2"
                      />
                      <Form.Control
                        type="text"
                        name={item.notesName}
                        value={formData[item.notesName]}
                        onChange={handleChange}
                        placeholder="Notes"
                        className="mt-2"
                      />
                    </Col>
                  </Form.Group>
                ))}

                
                <Button type="submit">Submit</Button>

                {/* {finalFormData && (<pre>{ JSON.stringify(finalFormData, null, 2) }</pre>)} */}
              </Form>
            </Container>

          </>

        </Accordion.Body>
      </Accordion.Item>
    </>
  );
}
