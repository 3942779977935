// import React, { useState} from 'react';
// import { auth } from "./firebase";
// import { useAuthState } from "react-firebase-hooks/auth";
import "./App.css";
import NavBar from "./NavBar";
import ChatBox from "./ChatBox";
import Welcome from "./Welcome";


import googlePlayIcon from "../../../assets/Google-Play.png";

import appStoreIcon from "../../../assets/app-store.png";
import cookies from "../../../utils/cookies";
export default function Index() {
  // const [user] = useAuthState(auth);
  let user = {
    "uid": cookies.getUserID(),
    "email": cookies.getUsername(),
    
    "role": cookies.getUserRole(),
    "emailVerified": true,
    "displayName": cookies.getUserDisplayName(),
    "isAnonymous": false,
    "photoURL": "https://lh3.googleusercontent.com/a/AAcHTtfM0vJ00b9jMVMnRrEvCGJmb1hGGTmlDN5eW-BFPy0t=s96-c",
    "providerData": [
      {
        "providerId": "google.com",
        "uid": cookies.getUserID(),
        "displayName": cookies.getUserDisplayName(),
        "email": cookies.getUsername(),
        "phoneNumber": null,
        "photoURL": "https://lh3.googleusercontent.com/a/AAcHTtfM0vJ00b9jMVMnRrEvCGJmb1hGGTmlDN5eW-BFPy0t=s96-c"
      }
    ],
    "stsTokenManager": {
      "refreshToken": "AMf-vBxJdwOtJEFSDPmkgj3eagT2Ky-pX560MEClfO5acL5i379U9CZewAk4VaDuKgZnEGrfUJs5I376m-C03l8RNkZbd1QKYSmGH5kAZapYn9vhHRirtlyjxJx47fzfiByRK6J1rEwduFFZcQ91O3uaaIYdJNqCplj3jcsBRcfvD7ZSradpGIs0vM7rM6YvjFYP3v5sLjfCWdUXOKMBKzsgcxJVqRgf8-oW0dkOz0XTRV9HjOs-EW7I6r1c4v1SwSATkK8-ACOoqIAIhBovTgq0qt4i7i-GbqG_pTU07OWiasKji6MNtChNlhztqPRi7Y3IkArGnxaa56BDvSq_RIu4QTLy-h3PRihXZbwukGXsJ2XL69_QfmGCIh3UXCeQr6BLuHxGZe_riAEzKcqqV-AkUQI4Yzi93jgs2Fz59qJvpDcSJr8cR8-dyj8MXH7jMBltJOUDmI7w",
      "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjE5MGFkMTE4YTk0MGFkYzlmMmY1Mzc2YjM1MjkyZmVkZThjMmQwZWUiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoibmFuZGFuIGQiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUFjSFR0Zk0wdkowMGI5ak1WTW5SckV2Q0dKbWIxaEdHVG1sRE41ZVctQkZQeTB0PXM5Ni1jIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL215cGFyYWNodXRlLW5vdGlmaWNhdGlvbnMiLCJhdWQiOiJteXBhcmFjaHV0ZS1ub3RpZmljYXRpb25zIiwiYXV0aF90aW1lIjoxNjk0MTcxMDIyLCJ1c2VyX2lkIjoiVURsV0JFeG5EN1k3OFA3WVU5TXlVeEFYcFN2MSIsInN1YiI6IlVEbFdCRXhuRDdZNzhQN1lVOU15VXhBWHBTdjEiLCJpYXQiOjE2OTQxNzEwMjIsImV4cCI6MTY5NDE3NDYyMiwiZW1haWwiOiJuYW5kYW5kLmNvbUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJnb29nbGUuY29tIjpbIjEwODAwNTA5NTQ0MTE4ODgzMjgzMCJdLCJlbWFpbCI6WyJuYW5kYW5kLmNvbUBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.BPYsTubcOzPYfh164Ko3pPMRR65tIKIfEcowXWTUudClwLDz-JGn2GCuUNAs5Oqn5tDN1b4hCcSkMun5-qbRT_sQzq8B9LqVaUQnrb-28X6QpWmeQazjG_MPGs3RdWfx529MvnM3dkNituG6kHxXnIEVhQ2gqpNN6AS8MiEGKNYuQfb-gDIRirm5tOlJeLCjs8J_6019u2kE8YrbO5iAjxnrgw7NNJwBlZjEn6fQ3fxEUMVyIrJzomLcbxrY_wVBo0Zujw4qLgJJHds4tekdI8MOnQCjFrT1AlPPwiFgAPInUNTjz_l_qckLLLzlchSaHX3nP9BKYXqrKZsEFPEfSg",
      "expirationTime": 1694174622656
    },
    "createdAt": "1693995393108",
    "lastLoginAt": "1694171022347",
    "apiKey": JSON.parse(localStorage.getItem('firebaseConfig')).apiKey,
    "appName": "[DEFAULT]"
  };

  // const [userToView, setUserToView] = useState(null); //{ id: userID, username: username, name: name, link: userIDLink, isOnboardComplete }
  // const [restoreUser, setRestoreUser] = useState(null);

  // useEffect(() => {
  //   if (restoreUser) {
  //     setUserToView(restoreUser);
  //     setRestoreUser(null);
  //   }
  // }, [restoreUser]);
  const savedRole = cookies.getUserRole();
  const isMobileApp = localStorage.getItem('platform').split(',')[0];



  // alert(isMobileApp);
  return (
    <>
  {/* <pre>{JSON.stringify(isMobileApp, null, 2)}</pre> */}

      {(isMobileApp=="desktop" && (savedRole === 'ROLE_USER' || savedRole === 'ROLE_FREEMIUM')) ? (
        <div className="container d-flex flex-column justify-content-center text-center chat-container">
          <h4>Sorry, chat is only available on the Parachute app. <br></br>Please download our app so you can speak directly with your Parachute coach, they're here to help!</h4>
          <div className="d-flex flex-column justify-content-center align-items-center py-4">
            <a className="mb-4" href="https://play.google.com/store/apps/details?id=co.myparachute.app" target="_blank" rel="noopener noreferrer">
              <img src={googlePlayIcon} alt="Download on Google Play" />
            </a>
            <a href="https://apps.apple.com/us/app/my-parachute/id6446090531" target="_blank" rel="noopener noreferrer">
              <img src={appStoreIcon} alt="Download on the App Store" />
            </a>
          </div>
        </div>) : (

        <div className="App">

          {/* {savedRole !== 'ROLE_SUPER_ADMIN'  && chatLink} */}
          {savedRole === 'ROLE_SUPER_ADMIN' && <NavBar />}


          {!user ? (
            <Welcome />
          ) : (
            <>
              <>
                <ChatBox />
              </>

            </>
          )}
        </div>)}
    </>
  );
}
