import React, { useState } from "react";
import Form from "react-bootstrap/Form";
// import Spinner from 'react-bootstrap/Spinner';
import WideBtn from "../WideBtn";
import { PRE_QUAL } from "../../utils/constants";
import "./WellnessSurvey.scss";
export default function SurveySlide({ incrementIndex, setSurveyData }) {
  const { WELLNESS_QUESTIONS, TIME_OPTIONS, DESCRIPTION_OPTIONS } = PRE_QUAL; //CONSTANTS for Form Options
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);

  //   useEffect(() => {
  //     surveyData && setFormData({ ...surveyData });
  //   }, [surveyData]);

  let header = `You’re almost there! Please complete the following questions.`;
  let note = `Note: This will not impact our decision on whether to fund your loan. But, this information does help us understand how we can better help you on your journey to financial well-being.`
  // let QUESTIONS = WELLNESS_QUESTIONS;
  // let ANSWER_OPTIONS = SURVEY_OPTIONS;

  const handleChange = (e) => {
    const key = e.target.id;
    let value = e.target.value;
    const questionIndex = key.slice(1);

    setFormData((p) => {
      return {
        ...p,
        [key]: { question: WELLNESS_QUESTIONS[questionIndex], answer: value },
      };
    });
  };
  const surveryQuestions = WELLNESS_QUESTIONS.map((question, questionIndex) => {
    const notLastItem = WELLNESS_QUESTIONS.length - 1 !== questionIndex;

    return (
      <div
        key={`q-${question}`}
        id={`question${questionIndex}`}
        className="surveyItem  mb-3"
      >
        <p className="muted surveryQuestion">{question}</p>
        {questionIndex <= 2 &&
          DESCRIPTION_OPTIONS.map((option, ansIndex) => {
            const type = "radio";
            return (
              <Form.Check
                className="surveyCheck"
                // reverse
                onChange={handleChange}
                name={question}
                key={`${question}-${option}`}
                id={`q${questionIndex}`} //Question
                type={type}
                label={option}
                value={option}
                checked={formData?.[`q${questionIndex}`]?.answer === option}
                required
                isInvalid={
                  validated && !formData?.[`q${questionIndex}`]?.answer
                }
                // readOnly={status.loading}
              />
            );
          })}
        {questionIndex > 2 &&
          TIME_OPTIONS.map((option, ansIndex) => {
            const type = "radio";
            return (
              <Form.Check
                className="surveyCheck"
                // reverse
                onChange={handleChange}
                name={question}
                key={`${question}-${option}`}
                id={`q${questionIndex}`} //Question
                type={type}
                label={option}
                value={option}
                checked={formData?.[`q${questionIndex}`]?.answer === option}
                required
                isInvalid={
                  validated && !formData?.[`q${questionIndex}`]?.answer
                }
                // readOnly={status.loading}
              />
            );
          })}
        {notLastItem && <hr className="mv-24" />}
      </div>
    );
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidated(true);
    //Pass Survey to Parent (will submit to API as the end)
    setSurveyData(formData);
    incrementIndex();
    //Clear Data (not sure why this is req'd b/c it should be a new component...)
    setFormData({});
    setValidated(false);
  };

  const KEYS_REQUIRED = ["q0", "q1", "q2", "q3", "q4"];
  const isSurveyComplete = KEYS_REQUIRED.every((q) => formData?.[q]);

  return (
    <div id="monthly-wellness-survey">
      <h3 className="wellness-surveryTitle">{header}</h3>
      <p className="muted surveryQuestion">{note}</p>

      <hr />

      <Form
        className="wellness-surveryForm"
        onSubmit={handleSubmit}
        validated={validated}
      >
        {surveryQuestions}
      </Form>

      <hr />

      <WideBtn disabled={!isSurveyComplete} onClick={handleSubmit}>
        Continue
      </WideBtn>
    </div>
  );
}
