import React from 'react';
import './LabelButton.scss';

function LabelButton({ children, onClick, selected, disabled, id, className }) {
  const classToUse = ['labelBubble'];
  let handleClick = onClick;
  const childType = typeof children;

  className && classToUse.push(className);

  if (disabled) {
    handleClick = null;
    classToUse.push('disabledBtn');
  }
  if (selected) {
    const paragraphValue = children.props.children[1].props.children.toString();
    const isItemSelected = selected.toString() === children.toString() || selected.toString() === paragraphValue;
    handleClick = null;
    isItemSelected ? classToUse.push('selected') : classToUse.push('noHover');
  }

  if (childType === 'string') classToUse.push('singleLabel');
  if (childType === 'array' && children.length > 1) classToUse.push('multiLabel');
  return (
    <div id={id} className={classToUse.join(' ')} onClick={handleClick}>
      {children}
    </div>
  );
}
/* Create Label Component, with short and long options, the two combined w. bubble Css === msgBubble */
export default LabelButton;
