
import React from 'react';
import { useState } from 'react';
// import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useCallback } from 'react'
import 'bootstrap-daterangepicker/daterangepicker.css';
import api from '../../../utils/api';
// import Table from 'react-bootstrap/Table';
// import ApplicantGraph from '../../GraphComponet/ApplicantGraph';


export default function Index() {

    const transactionDetails = useCallback(async () => {
        try {
         
        
          const transactionDetailsRes = await api.fetchTransactionDetails();
          
        //   console.table(transactionDetailsRes?.data);
    
          console.log("transactionDetailsRes")
          setTransactionDetails(transactionDetailsRes?.data);
          console.log(transactionDetailsRes)
         
    
    
        } catch (e) {
          console.error(e);
        }
      }, []);
      transactionDetails();
      const [transactions, setTransactionDetails] = useState(null);
      console.log(transactions)
  return (
   <div>transaction</div>
  );
}
