import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import LoanInfo from '../LoanInfo';
import Dismiss_Icon from '../../assets/icons/dismissed_icon_Filled.svg';

export default function MissedAccordion({ eventKey, month, reasons, dollarsEarned }) {
  const xImg = <img src={Dismiss_Icon} alt={'Goal not met'} />;

  const parsedReasons = reasons?.map((reason, i) => {
    return <LoanInfo key={`cb-reason-${i}`} className="reason" title={reason} body={xImg} />;
  });
  return (
    <Accordion flush>
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>
          <LoanInfo title={month} body={isNaN(dollarsEarned) ? '$0.00' : `$${dollarsEarned}`} />
        </Accordion.Header>
        <Accordion.Body>
          {/* Reason(s) */}
          {parsedReasons}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
