import React from 'react';
import goalsImg from '../../assets/onboarding/goals.svg';

export default function GoalSlide() {
  return (
    <div>
      <h3>Commit to monthly goals.</h3>
      <p className="muted">To earn cash-back on a monthly basis, you’ll need to achieve these goals.</p>
      <div>
        <img
          className="onboard-img"
          src={goalsImg}
          alt="Pay Parachute loan on time. Maintain low debt utilization. Pay all bills on time. Don’t take on new debt."
        />
      </div>
    </div>
  );
}
