import React, { useState, useEffect } from 'react';
import CardContainer from '../CardContainer';
import WideBtn from '../WideBtn';
import icon from '../../assets/Logo_Icon.svg';
import Form from 'react-bootstrap/Form';
import { PRE_QUAL } from '../../utils/constants';
import { validateAndFormat } from './validatePreQual';
import Spinner from 'react-bootstrap/Spinner';
// import InfoBubble from './InfoBubble';
import './Form0ClientInfo.scss';

// import checkbox_lightgreen from '../../assets/icons/checkbox_lightgreen.svg';
import shieldIcon from '../../assets/icons/shieldIcon.svg';
import lockIcon from '../../assets/icons/lockIcon.svg';
// import ToggleButton from 'react-bootstrap/ToggleButton';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
import RangeSlider from 'react-bootstrap-range-slider';
import api from '../../utils/api';
import { removeCommas } from '../../utils/helpers';
import { useLocation } from 'react-router-dom';
// import checkbox_grey from '../../assets/icons/Checkbox-Grey.svg';
export default function ClientDetails({
  updatePreQualState,
  stateData,
  status,
  abandondedIndex,
  navForm,
  setAbandonedIndex,
  // knockoutIndex,
}) {
  const location = useLocation();
  const searchParams1 = new URLSearchParams(location.search);
  const id = searchParams1.get("applicantKey");
  const [customerReferenceId] = useState(id);
  const [customerReferenceIdFormData, setCustomerReferenceIdFormData] = useState(null);
  const [showErrMessage, setShowErrMessage] = useState(null);

  const [isVistorUpdated, setIsVistorUpdated] = useState(false);



  const parseIdFromLink = (link, offset, strip) => {
    if (!link) return link;
    let id;
    if (strip) {
      id = link
        .split("/")
      [link.split("/").length - 1 + (offset ? offset : 0)].replace(strip, "");
    } else {
      id = link.split("/")[link.split("/").length - 1 + (offset ? offset : 0)];
    }
    if (id.includes("{?projection}")) id = id.replace("{?projection}", "");
    return id;
  };

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  }
  const goToBottom = () => {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 1000);
    // window.scrollTo(0, document.body.scrollHeight);
    // document.getElementById('eligibilityList').scrollIntoView();
    setShowModal(false);
    // window.location.href = "https://www.myparachute.co/how-it-works";
  }

  useEffect(() => {



    if (id) {
      const findByCustomerReferenceId = async (id) => {
        try {
          const rawUserRes = await api.findByCustomerReferenceId(id);
          const initUser = rawUserRes.data;
          // setUsers(initUsers);
          // alert('Fetching user details!');
          // alert(JSON.stringify(initUser));
          console.log(initUser);
          if (localStorage.getItem('applyState')) {
            localStorage.clear();
            window.location.reload();
          }
          setCustomerReferenceIdFormData(initUser);
          localStorage.setItem('customerReferenceIdData', JSON.stringify(initUser));
        } catch (e) {
          console.error(e);
        }
      };
      findByCustomerReferenceId(id);
    } else {
      try {
        setCustomerReferenceIdFormData(null);
        localStorage.removeItem('customerReferenceIdData');
      } catch (error) {
        console.error(error);
      }

    }
  }, [id]);




  const handleShow = () => setShowModal(true);

  const formatDollar = (input) => {
    const rmvCommas = removeCommas(String(input));
    const strippedNumber = Number(rmvCommas);
    if (!isNaN(strippedNumber) && strippedNumber < 2147483647) {
      return strippedNumber === 0 ? '' : strippedNumber.toLocaleString();
    }
  };
  // const [checked, setChecked] = useState(false);
  const { ELIGIBILITY_CRITEREIA, ELIGIBILITY_CRITEREIA_CHECKED, REMAINING_ELIGIBILITY } = PRE_QUAL; //Form Constants
  const [formData, setFormData] = useState(
    stateData || {
      // debtToConsolidate: '',
      firstName: '',
      lastName: '',
      email: '',
    }

  );
  const [debtToConsolidateValue, setDebtToConsolidateValue] = useState(5000);

  const [isChecks, setIsChecks] = useState(ELIGIBILITY_CRITEREIA_CHECKED);
  useEffect(() => {
    (stateData && !formData.firstName) && setFormData({ ...stateData });
    isChecks && setIsChecks({ ...isChecks });

  }, [stateData, isChecks, formData]);

  const parsedEligibility = ELIGIBILITY_CRITEREIA.map((criteria, i) => {


    if (!customerReferenceId) {
      return (
        <li key={`elig-${i}`} className="eligibility" style={{ display: 'block', margin: '0px', padding: '0px' }}>
          {/* <ToggleButton
            id={i}
            type="checkbox"
            variant={isChecks[i] ? "outline-success" : "outline-danger"}
            checked={isChecks[i]}
            value={isChecks[i]}
            onChange={(e) => updateEligibilityCritereiaChecked(e.currentTarget.checked, i)}
          >
            {!isChecks[i] ? (<img className="titleBlock--icon" style={{ margin: "0px", opacity: 0 }} src={checkbox_grey} alt={'Checkmark'} />) : (<img className="titleBlock--icon" src={checkbox_lightgreen} alt={'Checkmark'} />)}
          </ToggleButton> */}
          &nbsp;&nbsp;
          <p className="muted titleBlock--body">{criteria}</p>

          <div key={`${i}-radio`} style={{ 'padding-top': '5px' }}>
            <Form.Check
              required
              inline
              label="Yes"
              name={`${i}-radio-1`}
              type={'radio'}
              onChange={(e) => updateEligibilityCritereiaChecked(true, i)}
            />
            <Form.Check
              required
              inline
              label="No"
              name={`${i}-radio-1`}
              type={'radio'}
              onChange={(e) => updateEligibilityCritereiaChecked(false, i)}
            />
          </div>
        </li>
      );
    } else {

      if (i !== 1) {
        return (
          <li key={`elig-${i}`} className="eligibility" style={{ display: 'block', margin: '0px', padding: '0px' }}>
            {/* <ToggleButton
              id={i}
              type="checkbox"
              variant={isChecks[i] ? "outline-success" : "outline-danger"}
              checked={isChecks[i]}
              value={isChecks[i]}
              onChange={(e) => updateEligibilityCritereiaChecked(e.currentTarget.checked, i)}
            >
              {!isChecks[i] ? (<img className="titleBlock--icon" style={{ margin: "0px", opacity: 0 }} src={checkbox_grey} alt={'Checkmark'} />) : (<img className="titleBlock--icon" src={checkbox_lightgreen} alt={'Checkmark'} />)}
            </ToggleButton> */}
            &nbsp;&nbsp;
            <p className="muted titleBlock--body">{criteria}</p>

            <div key={`${i}-radio`} style={{ 'padding-top': '5px' }}>
              <Form.Check
                inline
                label="Yes"
                name={`${i}-radio-1`}
                type={'radio'}
                onChange={(e) => updateEligibilityCritereiaChecked(true, i)}
              />
              <Form.Check
                inline
                label="No"
                name={`${i}-radio-1`}
                type={'radio'}
                onChange={(e) => updateEligibilityCritereiaChecked(false, i)}
              />
            </div>
          </li>
        );
      } else {
        return ('');
      }

    }

  });

  const updateEligibilityCritereiaChecked = (val, i) => {
    isChecks[i+1] = val;
    setIsChecks([...isChecks]);
  };
  function validateEmail(email) {
    const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    return regex.test(email);
  }

  const CryptoJS = require('crypto-js');

function encodeToFixedLength(inputString, length) {
  const hash = CryptoJS.SHA256(inputString).toString();
  return hash.substring(0, length);
}

  const generateUniqueID = () => {
    const userAgent = window.navigator.userAgent;
    const uniqueID = btoa(userAgent);
    return encodeToFixedLength(uniqueID,32);
  };
  const handleChange = (e) => {
    const key = e.target.id;
    const value = e.target.value;
    const formattedValue = validateAndFormat('client', key, value);

    console.log(formattedValue);
    try {
      if (isVistorUpdated === false) {

        if (key === 'email') {


          console.log(key);
          console.log(formattedValue);
          if (validateEmail(formattedValue)) {
            console.log("Valid email");



            const updateVistorDetails = async (data) => {
              try {
                const response = await api.updateVistorDetails(data);


                console.log(response);
              } catch (e) {
                console.error(e);
              }
            };
            let data = {
              email: formattedValue,
              firstName: formData.firstName,
              lastName: formData.lastName,
              visitorId: generateUniqueID()
            }
            console.log(data);
            updateVistorDetails(data);
            // setIsVistorUpdated(true);
          }

        }

      }

    } catch (e) {
      console.error(e);
    }



    if (formattedValue === undefined || formattedValue === null) return;
    setFormData((p) => {
      const newData = { ...p };
      newData[key] = formattedValue;
      return newData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    let PREQUAL_STATE_KEY = 'client';
    formData['debtToConsolidate'] = debtToConsolidateValue;


    if (customerReferenceId && customerReferenceIdFormData) {
      updateEligibilityCritereiaChecked(true, 1);
      formData['preApproved'] = true;
      formData['firstName'] = customerReferenceIdFormData['firstName'];
      formData['lastName'] = customerReferenceIdFormData['lastName'];
      formData['email'] = customerReferenceIdFormData['email'];
      formData['debtToConsolidate'] = customerReferenceIdFormData['debtToConsolidate'] ? customerReferenceIdFormData['debtToConsolidate'] : 5000;
      setDebtToConsolidateValue(formData['debtToConsolidate']);
      const applicationLink = customerReferenceIdFormData._links.self.href;
      const appIDFrServer = parseIdFromLink(applicationLink);
      formData['appID'] = appIDFrServer;
    }




    try {
      formData['ELIGIBILITY_CRITEREIA'] = ELIGIBILITY_CRITEREIA;
      formData['ELIGIBILITY_CRITEREIA_CHECKED'] = isChecks;
      formData['eligibilityCriteria1'] = isChecks[0];
      formData['eligibilityCriteria2'] = isChecks[1];
      formData['eligibilityCriteria3'] = isChecks[2];

      formData['consumerProposal'] = isChecks[3];

      formData['highInterestCreditCardDebt'] = isChecks[4];
      formData['highInterestLoanDebt'] = isChecks[5];
      formData['consolidateDebt'] = isChecks[6];
      // formData['highInterestCreditCardAndLoanDebt'] = isChecks[5];
    } catch (error) {
      console.log(error);
    }

    if (!checkElegibilityCriteria()) {
      handleShow();
    }



    console.log(JSON.stringify(formData));

    if (!(customerReferenceId && customerReferenceIdFormData)) {
      let email = formData['email'];
      let days = 30;
      const searchByEmail = async (email, days) => {
        try {
          const rawUserRes = await api.searchByEmail(email, days);
          if (rawUserRes?.data) {

            formData['appID'] = rawUserRes?.data;
            console.log("Old user -" + formData['appID']);
          } else {
            console.log("New user, just continue");
          }


          updatePreQualState(PREQUAL_STATE_KEY, formData);
        } catch (e) {
          console.error(e);
        }
      };
      searchByEmail(email, days);

    } else {

      updatePreQualState(PREQUAL_STATE_KEY, formData);
    }

  };

  // const handleSubmitAfterModalClose = (e) => {
  //   e.preventDefault();
  //   window.scrollTo(0, 0);
  //   const PREQUAL_STATE_KEY = 'client';
  //   formData['ELIGIBILITY_CRITEREIA'] = ELIGIBILITY_CRITEREIA;
  //   formData['ELIGIBILITY_CRITEREIA_CHECKED'] = isChecks;
  //   updatePreQualState(PREQUAL_STATE_KEY, formData);
  // };

  const checkElegibilityCriteria = () => {
    let isEligible = true;
    for (let x in isChecks) {
      if (x <= 2) {
        if (isChecks[x] === false) {
          isEligible = false;
        }
      }

      // if (x > 2) {
      //   if (isChecks[x] === true) {
      //     isEligible = false;
      //   }
      // }
      if (x === 3 || x === '3') {
        if (isChecks[x] === true) {
          isEligible = false;
        }
      }


    }
    return isEligible;
  };

  const isNextDisabled = status.loading || Object.values(formData).some((x) => { return x === '' ? true : false });
  const securitySection = (
    <section className={abandondedIndex ? 'recovery-security securityInfo' : 'securityInfo'}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
        <img className="pr12" src={lockIcon} alt="Lock Icon" />
        <span>
          <h3>Encrypted and secure</h3>
          <p className="muted">We use 256-bit encryption to keep your information safe.</p>
        </span>
      </div>
      {abandondedIndex === null && <hr className="mv-24" />}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          marginTop: abandondedIndex >= 0 ? '24px' : '0px',
        }}
      >
        <img className="pr12" src={shieldIcon} alt="Shield" />
        <span>
          <h3>Data privacy</h3>
          <p className="muted">
            Your data will only be used for determining if we can provide you with a loan. We will never sell your data.
          </p>
        </span>
      </div>
    </section>
  );
  const defaultForm = (
    <CardContainer>
      <div>
        {/* <InfoBubble>
          <p>
            <strong>We are so glad you are here.</strong>
          </p>
        </InfoBubble> */}
        <div className="titleBlock">
          {icon && <img className="titleBlock--icon" src={icon} alt={icon} />}
          <h1 className="heading--title">{`Welcome to Parachute!`}</h1>
          <h1 className="titleBlock--title">{`Start your journey to a better financial future, and start earning cash-back.`}</h1>
          {/* <strong id='green-text'>{`This will not affect your credit score.`}</strong> */}


          <div className="infoBubble" style={{ minHeight: 0, marginTop: 20 }}>
            <p>
              <strong>This will NOT affect your credit score.</strong>
            </p>
          </div>
          {/*  <p className="muted titleBlock--body">
            If you have any questions, or need additional support, please text or call us at{' '}
            <a href="tel:8446702403">(844) 670-2403</a> or email us at{' '}
            <a href="email:contactus@myparachute.co">contactus@myparachute.co</a>
          </p> */}
        </div>
        <hr className="mv-24" />

        <section>

          <Form className="cardForm" onSubmit={handleSubmit}>
            <div className="inputs">

              {!customerReferenceId ? (
                <>
                  <Form.Group className="mb-3" controlId="firstName">
                    <Form.Label><span className='text-danger'>*</span> First name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Your first name"
                      onChange={handleChange}
                      value={formData.firstName}
                      required
                      readOnly={status.loading}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="lastName">
                    <Form.Label><span className='text-danger'>*</span> Last name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Your last name"
                      onChange={handleChange}
                      value={formData.lastName}
                      required
                      readOnly={status.loading}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label><span className='text-danger'>*</span> Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      onChange={handleChange}
                      value={formData.email}
                      required
                      readOnly={status.loading}
                    />
                  </Form.Group>

                  <hr className="mv-24" />
                  <h3 className="titleBlock--title">{`How much debt do you want consolidated?`}</h3>

                  <Form.Group className="mb-3" controlId="debtToConsolidate">
                    <Form.Label><span className='text-danger'>*</span> Debt amount</Form.Label>
                    <div className="inputDollar-container">
                      <span className="inputDollar-symbol">$</span>
                      <Form.Control
                        disabled={1}
                        type="text"
                        placeholder="Debt to be consolidated"
                        onChange={handleChange}

                        value={formatDollar(debtToConsolidateValue)} //[]Need for format & unformat during input...

                        readOnly
                      />
                    </div>
                    <div id="customRangeBar">
                      <RangeSlider
                        size={'lg'}
                        variant={'success'}
                        value={debtToConsolidateValue}
                        min={5000}
                        step={500}
                        max={25000}
                        onChange={changeEvent => setDebtToConsolidateValue(changeEvent.target.value)}
                      />
                    </div>

                  </Form.Group>
                  <hr className="mv-24" />

                </>

              ) : ''}




              <section>
                <h3 style={{ margin: "0px", padding: "0px" }} className="titleBlock--title">{`About me:`}</h3>
                {parsedEligibility}

                {/* <h3 className="titleBlock--title"><strong id='green'>{`Please check the above to complete the eligibility criteria!`}</strong></h3> */}

                <br />
                <p id="eligibilityLink">
                  <a href="#eligibilityList">See all eligibility requirements</a>
                </p>
              </section>
            </div>


            <Modal show={showModal} onHide={handleClose}>
              <Modal.Header>
                {/* closeButton */}
                <Modal.Title>Sorry, we’re not a match right now!</Modal.Title>
              </Modal.Header>
              <Modal.Body>Based on the information you provided,<br />we are not able to offer you a loan right now.</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                  Check your answers
                </Button>
                <Button variant="danger" onClick={goToBottom}>
                  Exit
                </Button>
              </Modal.Footer>
            </Modal>


            {(showErrMessage === false) ? (
              <div className="infoBubble" style={{ minHeight: 0, backgroundColor: "red !important;" }}>
                <p>
                  <strong>Parachute only offers debt consolidation loans. We pay directly to the financial institutions to which you owe money. We do not offer personal cash loans. We do not offer consumer proposals.  </strong>
                </p>
              </div>
            ) : ("")}

            {(showErrMessage === true) ? (
              <div className="dangerBubble" style={{ minHeight: 0, backgroundColor: "red !important;" }}>
                <p>
                  <strong>Parachute only offers debt consolidation loans. To continue your application select, YES, indicating you want a debt consolidation loan. A debt consolidation loan is not a consumer proposal. </strong>
                </p>
              </div>
            ) : ("")}


            {!customerReferenceId ? (
              <WideBtn
                disabled={isNextDisabled}
                style={{ marginTop: '8px' }}
                data-returning-login="true"
                id="submitClientInfo"
              >
                {status.loading ? <Spinner animation="border" role="status" size="sm" /> : `Next`}
              </WideBtn>
            ) : (<WideBtn
              style={{ marginTop: '8px' }}
              data-returning-login="true"
              id="submitClientInfo"
            >
              {status.loading ? <Spinner animation="border" role="status" size="sm" /> : `Next`}
            </WideBtn>)}



            {status.error && <p className="error">{status.error}</p>}
            <p className="muted submissionNote" data-returning-test="true">
              {/* <strong className='text-colour-green-parachute'>  This process does not impact your credit score</strong><br></br> */}
              <div className="infoBubble" style={{ minHeight: 0 }}>
                <p>
                  <strong>This will NOT affect your credit score.</strong>
                </p>
              </div>

              By clicking Next you agree to receive occasional marketing contacts from Parachute. You can opt out any time.
            </p>
          </Form>
        </section>
        <hr className="mv-24" />
        {securitySection}
      </div>
    </CardContainer >
  );

  const recoveryTitle = (index) => {
    if (index === 3) return 'You’re almost there.';
    if (index === 4) return 'Just one more thing.';
    return 'Keep going...';
  };
  const recovery = (
    //place the div in center
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <div className="recoveryInfo">
      <div className="recovery">
        {/* {console.log('abandondedIndex', abandondedIndex)} */}
        <h2 className="recovery--title">{recoveryTitle(abandondedIndex)}</h2>
        <p className="muted recovery--body">
          {/* You're on your way to pre-qualifying for a very special type of loan&#8212;one that actually cares about you. */}
          You almost finished the pre-qualifying application for a Parachute consolidation loan. We just need to ask you
          a few more questions (it’ll take 2 minutes).
        </p>
        <p className="muted recovery--body">
          {/* We just need to ask you a few more questions (it'll take 2 minutes). */}
          Parachute loans are designed to improve your financial well-being. Through our guided process and monthly
          goals, we coach you to making better decisions, and earning 10% cash-back on your loan!
        </p>
      </div>
      <WideBtn
        onClick={() => {
          navForm(abandondedIndex + 1);
          setAbandonedIndex(null);
        }}
        id="resumePreQual"
      >
        Pre-qualify in minutes
      </WideBtn>
      {securitySection}
    </div>
    </div>
  );

  return (
    <>
      {abandondedIndex === null && defaultForm}
      {abandondedIndex !== null && recovery}

      <article id="preQualFAQ">
        <div className="faqContent">
          <h3 className="titleBlock--title">Why borrow through Parachute?</h3>
          <p className="muted titleBlock--body">
            Many Canadians choose to borrow through Parachute as our Annual Percentage Rate (APR) is often much lower, we have no early repayment fees and accounts are easily managed online, with you in charge. But we don’t stop there. Canadians choose Parachute because we help you get financially fit by engaging with you throughout the loan process with recommendations and exciting ideas to help you improve your credit score – and then we reward you for that hard work!
          </p>
        </div>
        <div className="faqContent">
          <h3 className="titleBlock--title">About Parachute</h3>
          <p className="muted titleBlock--body">
            Parachute is subject to the provincial laws of each jurisdiction in which it offers and funds loans. On a
            federal level, Parachute’s lending operations follow the statues legislated by the Financial Transactions and Reports Analysis Centre of Canada (FINTRAC),  as well as other federal agencies.
          </p>
        </div>
        {abandondedIndex === null && (
          <div className="faqContent">
            <h3 className="titleBlock--title">What are Parachute’s loan eligibility requirements?</h3>
            <ul id="eligibilityList">
              {REMAINING_ELIGIBILITY.map((item, i) => {
                return <li key={`rem-elg-${i}`}>{item}</li>;
              })}
            </ul>
          </div>
        )}
      </article>
    </>
  );
}
