/* [ ] Can be moved to components since its a part of the client view*/

import React from 'react';
import Notification from './Notification/Index';

export default function NotificationSection({ notifications, disabled }) {
  const toDisplay =
    notifications &&
    notifications.map((notification, i) => {
      return <Notification key={`notification-${i}`} data={notification} disabled={disabled} />;
    });

  return (
    <div id="dashboardNotificaitons" className="clientNotifications">
      {toDisplay}
    </div>
  );
}
