// import axios from 'axios';
import cookies from '../../utils/cookies';
const twilioOTPLink = cookies.processenv.REACT_APP_TWILIO_LINK;

export const sendOTP = async (phoneNum) => {
  const data = new URLSearchParams();
  data.append('channel', 'sms');
  data.append('locale', 'en');
  data.append('to', `+1${phoneNum}`);
  console.log('data', data);
  try {
    const response = await fetch(`${twilioOTPLink}/start-verify`, {
      method: 'POST',
      body: data,
    });

    const json = await response.json();
    console.log('response', response);
    console.log('json send OTP', json);
    return response;
  } catch (error) {
    // console.error(error);
    // showError(`Something went wrong while sending code to ${to}.`);
    throw error;
  }
};

export const checkOTP = async function (phoneNum, OTPCode) {
  // event.preventDefault();
  // let code = document.getElementById("code");
  // showModalStatus(`Checking code ${code.value}...`);
  const data = new URLSearchParams();
  data.append('to', `+1${phoneNum}`);
  data.append('code', OTPCode);

  try {
    const response = await fetch(`${twilioOTPLink}/check-verify`, {
      method: 'POST',
      body: data,
    });

    const json = await response.json();
    console.log('json CONFIRM OTP', json);
    return json;
    // if (json.success) {
    //   //   showModalStatus("Verification success!", {color: "green"});
    //   //   code.value = "";
    //   // } else {
    //   //   showModalStatus("Incorrect token!", {color: "#a94442"});
    //   //   code.value = "";
    // }
  } catch (error) {
    console.error(error);
    // showModalStatus("Something went wrong!");
    // code.value = "";
    throw error;
  }
};

/* 
  
    async function sendOtp(event) {
      event.preventDefault();

      const locale = document.getElementById("select-language").value;
      const channel = selectedChannel();

      let statusMessage =
        channel == "call" ? "☎️ calling..." : "Sending verification code...";
      showStatus(statusMessage);

      to =
        channel == "email"
          ? document.getElementById("email").value
          : phoneInput.getNumber();

      const data = new URLSearchParams();
      data.append("channel", channel);
      data.append("locale", locale);
      data.append("to", to);

      try {
        const response = await fetch("./start-verify", {
          method: "POST",
          body: data,
        });

        const json = await response.json();

        if (response.status == 429) {
          clearStatus();
          modal.style.display = "block";
          showModalStatus(
            `You have attempted to verify '${to}' too many times. If you received a code, enter it in the form. Otherwise, please wait 10 minutes and try again.`,
            {color: "#a94442"}
          );
        } else if (response.status >= 400) {
          clearStatus();
          showError(json.error);
        } else {
          modal.style.display = "block";
          if (json.success) {
            showStatus(`Sent verification code to ${to}`);
          } else {
            showError(json.error);
          }
        }
      } catch (error) {
        console.error(error);
        showError(`Something went wrong while sending code to ${to}.`);
      }
    }

    document
      .getElementById("send-code")
      .addEventListener("submit", (event) => sendOtp(event));

    async function checkOtp(event) {
      event.preventDefault();
      let code = document.getElementById("code");
      showModalStatus(`Checking code ${code.value}...`);

      const data = new URLSearchParams();
      data.append("to", to);
      data.append("code", code.value);

      try {
        const response = await fetch("./check-verify", {
          method: "POST",
          body: data,
        });

        const json = await response.json();

        if (json.success) {
          showModalStatus("Verification success!", {color: "green"});
          code.value = "";
        } else {
          showModalStatus("Incorrect token!", {color: "#a94442"});
          code.value = "";
        }
      } catch (error) {
        console.error(error);
        showModalStatus("Something went wrong!");
        code.value = "";
      }
    }
  
  */
