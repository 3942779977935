import React, { useState, useEffect } from 'react';
// import { PRE_QUAL } from '../../../utils/constants';
import { parseIdFromLink } from '../../../utils/helpers';
import api from '../../../utils/api';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default function QuizEditor({ q, setWasEditted }) {
  const [editForm, setEditForm] = useState({
    question: q.question,
    explanation: q.explanation,
  });
  const [editMode, setEditMode] = useState(false);

  const [triggerAPI, setTriggerAPI] = useState(null);
  const [status, setStatus] = useState({ loading: false, error: null });

  useEffect(() => {
    const updateQuiz = async () => {
      setStatus({ loading: true, error: null });
      try {
        await api.editQuiz(triggerAPI.quizID, triggerAPI.body);
        setTriggerAPI(null);
        setWasEditted(true);
        setStatus({ loading: false, error: null });
      } catch (e) {
        setStatus({ loading: false, error: `${e}` });
      }
    };

    triggerAPI && updateQuiz();
    //eslint-disable-next-line
  }, [triggerAPI]);

  const editQuiz = (e) => {
    e.preventDefault();
    //Trigger Upload
    const quizID = parseIdFromLink(q._links.self.href);
    setTriggerAPI({ quizID, body: editForm });
    setEditMode(false);
  };

  return (
    <Form onSubmit={editQuiz} style={{ width: '70vw' }}>
      {q &&
        Object.entries(q).map(([k, v]) => {
          if (v === null) return null;
          if (k === '_links') {
            k = 'ID';
            v = parseIdFromLink(v.self?.href);
          }
          // return <li>{`${k} - ${v}`}</li>;
          return (
            <Form.Group controlId={k}>
              <Form.Label>{k.includes('choice') && v === q.correctAnswer ? `${k} - ANSWER:` : `${k}:`}</Form.Label>
              <Form.Control
                as="textarea"
                rows={k === 'explanation' || k === 'question' ? 3 : 1}
                disabled={(k === 'explanation' || k === 'question') && editMode ? false : true}
                onChange={(e) => {
                  setEditForm((p) => {
                    return { ...p, [k]: e.target.value };
                  });
                }}
                value={k === 'explanation' || k === 'question' ? editForm[k] : v}
              />
            </Form.Group>
          );
        })}
      <br />
      {!editMode && (
        <Button variant="outline-primary" onClick={() => setEditMode(true)}>
          Edit
        </Button>
      )}
      {editMode && (
        <>
          <Button variant="outline-primary" style={{ marginRight: '16px' }} type="submit">
            Save
          </Button>
          <Button variant="outline-secondary" onClick={() => setEditMode(false)}>
            Cancel
          </Button>
          {status.loading && <p>Loading...</p>}
          {status.error && <p className="error">{status.error}</p>}
        </>
      )}
    </Form>
  );
}
