const EXPERIENCE = {
  veryLow: [
    'Just 5% are certain they could come up with $2,000 for an emergency.',
    'Most (82%) sometimes or often experience food insecurity or food hardship.',
    'Almost all (96%) find it somewhat or very difficult to make ends meet.',
  ],
  low: [
    'Few (23%) habitually save and only some (38%) have more than $250 in liquid savings.',
    'Just 12% always stay on budget.',
    'Nearly half (45%) have experience with debt collectors.',
  ],
  mediumLow: [
    'Most (60%) have minimal savings of $250 or more, but only 30% have $2,000 or more',
    'Almost all (80%) find it somewhat or very difficult to make ends meet.',
    'Some (32%) have had a credit application rejected or are concerned about credit rejection.',
  ],
  mediumHigh: [
    'More than half (55%) have automated deposits into a savings or retirement account.',
    'A minority (32%) always pay off credit cards in full.',
    'Few (16%) sometimes or often experience food insecurity or hardship.',
  ],
  high: [
    'The vast majority (81%) are certain they could come up with $2,000 for an emergency.',
    'Just 35% always stay on budget.',
    'Very few (6%) have experienced a credit rejection or are concerned about credit rejection.',
  ],
  veryHigh: [
    'Most have high levels of savings; 80% have $10,000 or more in liquid savings.',
    'The majority (69%) make automated deposits into a savings or retirement account.',
    'Most (81%) have health insurance',
  ],
};

export const wellnessExperience = (score) => {
  if (!score) return null;
  if (score >= 68) return { bracket: 'Very High', experience: EXPERIENCE.veryHigh };
  if (score >= 58) return { bracket: 'High', experience: EXPERIENCE.high };
  if (score >= 50) return { bracket: 'Medium High', experience: EXPERIENCE.mediumHigh };
  if (score >= 38) return { bracket: 'Medium Low', experience: EXPERIENCE.mediumLow };
  if (score >= 30) return { bracket: 'Low', experience: EXPERIENCE.low };
  if (score >= 0) return { bracket: 'Very Low', experience: EXPERIENCE.veryLow };
  return null;
};
