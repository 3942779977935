// Import the functions you need from the SDKs you need
import { initializeApp,getApp  } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getAuth, initializeAuth, indexedDBLocalPersistence } from "firebase/auth"

import { getFirestore } from "firebase/firestore";
import { Capacitor } from "@capacitor/core"
export let auth;
export let db
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = JSON.parse(localStorage.getItem('firebaseConfig'));
// Initialize Firebase
if (firebaseConfig) {
  const app = initializeApp(firebaseConfig);
  // Initialize Firebase Authentication and get a reference to the service

  if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(getApp(), { persistence: indexedDBLocalPersistence })
  } else {
    auth = getAuth()
  }

  db = getFirestore(app);
}

