import React from 'react';
import { LINKS } from '../utils/constants';
import './ErrorNotification.scss';

export default function ErrorNotification({ className, id, message, noBorder, quizCardStyle, style, children }) {
  const classToUse = ['errorNotification'];
  className && classToUse.push(className);
  noBorder && classToUse.push('noBorder');
  quizCardStyle && classToUse.push('quizStyling');
  return (
    <div id={id} className={classToUse.join(' ')} style={style} role="alert">
      <h5 className="errorMsg" role="alertdialog">
        {children || message}
      </h5>
      <h5 className="supportLink">
        <a href={LINKS.support}>Contact Support</a>
      </h5>
    </div>
  );
}
