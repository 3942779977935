// import getYear from 'date-fns/getYear';
import cookies from '../utils/cookies';
export const TOOLTIP = {
  CASHBACK: {
    title: 'Total Cash-Back Earned ',
    body: 'Each month you will have a chance to earn cash back by completing four financial well-being goals. At the beginning of each month you’ll let us know whether or not you have completed your goals for the previous month – your cash-back earnings will be pending until we have validated with the credit bureau which can take up to 60 days.',
  },
  CREDIT_SCORE: {
    title: 'Credit Score',
    body: 'We update this score every month and it’s based on your FICO Score. P.S. You might see your score drop right after you join Parachute. This is completely normal and due to the hard credit inquiry we did as part of your loan approval process.',
  },
  MONTHLY_GOAL: {
    title: 'Monthly Goals ',
    body: 'These are the financial well-being goals you need to achieve each month in order to get your cash back.',
  },
  MONTHLY_FREEMIUM_GOAL: {
    title: 'Monthly Goals ',
    body: 'These are the financial well-being goals we recommend you achieve each month. They will help you improve your finances so you can become eligible to re-apply for a Parachute loan.',
  },
  WELLNESS_SCORE: {
    title: 'The CFPB Financial Well-Being Scale, is:',
    body: (
      <ul className="wellnessTip">
        <li>
          A consumer-driven measure based on a definition of financial well-being that draws on insights from both
          consumers and financial practitioners. Evaluation of scale items included cognitive interviewing and testing
          to ensure accurate comprehension of questions, as well as review by academic experts.
        </li>
        <li>
          A measurement tool developed over multiple waves of quantitative testing using both classical scale
          development methods and state-of-the-art Item Response Theory (IRT) techniques to identify appropriate items
          and how best to combine those items to produce reliable scores of the financial well-being construct.
        </li>
        <li>
          A common metric adjusted for age group and mode of administration that allows an “apples-to-apples” comparison
          of scores across consumers. It can also be used to assess a consumer’s current state of financial well-being,
          to track their progress over time, and to understand how other factors, including program interventions,
          affect financial wellbeing.
        </li>
        <li>A free and publicly available survey instrument and measurement scale.</li>
      </ul>
    ),
  },
};

export const MOOD_QUESTION = {
  //Mood Questions can be hard coded, reponses will be daily
  main: {
    question: 'How do you feel today?',
    options: null,
    // onClick: null
  },
  followUp: {
    // question: 'Why do you feel this way?',
    question: 'The top reason you feel that way is because of:',
    options: null,
    // onClick: null
  },
};

export const LINKS = {
  debug: '/debug',
  support: '/client/support',
  supportDocs: 'https://support.myparachute.co/',
  chat: '/chatViewer',
  chatrooms: '/admin/chatRoomsViewer',
  //Footer Links
  privacy: `https://www.myparachute.co/privacy`, // Temp, waiting on specific app link
  security: 'https://www.google.ca/search?q=security', //Hiding from frontend until provided
  legal: 'https://www.google.ca/search?q=legal', //Hiding from frontend until provided
  //Temp links to myParachute.co footer links
  terms: 'https://www.myparachute.co/terms-of-use', //Temp, wasn't in org design but closes to security or legal
  client_forum: '/client/forum',
  admin_forum: '/admin/forum'
};

export const STREAK_REWARD = (<><p>Complete all quizzes and receive a</p><strong>$10 reward</strong></>);
export const FREEMIUM_STREAK_REWARD = 'Complete all quizzes and get a quiz streak.';

export const DEBT_UTILIZATION_THRESHOLD = 0.5;

export const ASSESSMENT_ITEMS = {
  //Keys match API keys
  noNewDebt: 'No New Debt',
  paidBillsOnTime: 'Pay all bills on time.',
  paidParachuteOnTime: 'Pay Parachute loan on time.',
  underUtilization: 'Maintain low credit utilization.',
};

const buildOptionsRange = (x, y) => {
  const result = [];
  for (let i = x; i <= y; i++) {
    const iAsString = `${i}`;
    if (iAsString.length === 1) {
      result.push(`0${i}`);
    } else {
      result.push(`${i}`);
    }
  }
  return result;
};
export const PRE_QUAL = {
  //Form 1 Top
  // 'I have up to $25,000 debt to be consolidated',

  ELIGIBILITY_CRITEREIA: [
    'I have an income of over $30,000',
   // 'I have a credit score of 600 or over',
    'I am a Canadian Citizen or Permanent Resident of Canada',
    `Have you ever been bankrupt or filed a consumer proposal?`,
   ],
  ELIGIBILITY_CRITEREIA_CHECKED: [
    true,
    false,
    false,
    false,
    // false,
    // false,
    // false
    // false
  ],
  // Form 1 bottom eleig
  REMAINING_ELIGIBILITY: [
    'At least 18 years of age',
    'Canadian Citizen or Permanent Resident',
    'Employed with income at least $30,000',
    'Canadian Bank Account',
    'Credit Score at least 580 (FICO)',
    'No bankruptcy, Consumer Proposal or judgements ',
  ],
  //Income Form
  INCOME_TYPES: [
    '',
    'Employed',
    'Self-employed',
    'Employment Insurance',
    'Disability',
    'Retirement/Pension',
    'Student',
    'Other',
  ],
  RESIDENCY_STATUS: ['', 'Canadian Citizen', 'Permanent Resident', 'Non-Resident'],
  CREDIT_SCORES: ['', 'Under 580', '580 to 599', '600 to 619', '620 to 639', '640 to 659', '660 to 679', '680 to 699', '700 to 719', '720 to 739', '740 and over', 'Unsure'],
  CREDIT_SOURCES: ['', 'Borrowell', 'Credit Karma', 'My Bank', 'Other'],
  YES_NO_Unsure: ['', 'Yes', 'No','Unsure'],
  YES_NO: ['', 'Yes', 'No'],
  MONTHS: ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  DAYS: ['', ...buildOptionsRange(1, 31)],
  YEARS: ['', ...buildOptionsRange(1901, (cookies.getToday().year())).reverse()],
  YEARS_LESS18: ['', ...buildOptionsRange(1901, 2022 - 18).reverse()],
  //Form 4 - Residence
  RESIDENTIAL_STATUS: ['', 'Rent', 'Own', 'Live with parents or legal guardian'],
  PROVINCES: [
    '',
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
  ],
  MARITAL_STATUS: [
    'Single',
    'Common-law',
    'Married',
    'Separated',
    'Divorced',
    'Widow(er)'
  ],
  NUMBER_OF_DEPENDENTS: [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10
  ],
  WELLNESS_QUESTIONS: [
    'This statement describes me: Because of my money situation, I feel like I will never have the things I want in life.',
    'This statement describes me: I am just getting by financially.',
    'This statement describes me: I am concerned that the money I have or will save won’t last.',
    'This statement applies to me: I have money left over at the end of the month.',
    'This statement applies to me: My finances control my life.',
  ],
  DESCRIPTION_OPTIONS: ['Completely', 'Very well', 'Somewhat', 'Very little', 'Not at all'],
  TIME_OPTIONS: ['Always', 'Often', 'Sometimes', 'Rarely', 'Never'],
  BEHAVIOUR_QUESTIONS: [
    'Thinking about new plans for my life is an easy task for me.',
    'I lean on the people around me when I am faced with change.',
    'When I am faced with change, I can see things from multiple points of view.',
    'I am always keeping my eye open for new opportunities in life.',
    'I find it easy to accept and open myself up to change. ',
    'My opinions have changed over the years. ',
  ],
  FIN_LITERACY_QUESTIONS: [
    'What can affect the amount of interest that you would pay on a loan?',
    'Which of the following can hurt your credit rating?',
    'A credit report is ...',
    'If each of the following persons had the same amount of take home pay, who would need the greatest amount of life insurance?',
    'True or false? If the inflation rate is 5% and the interest rate you get on your savings account is 3%, your savings will have at least as much buying power in a year`s time.',
  ],
  FIN_LITERACY_OPTIONS:
  {
    0: ['Your credit rating',
      'How much you borrow',
      'How long you take to repay the loan',
      'All of the above',
      'Don`t know'],
    1: [
      'Making late payments on loans and debts',
      'Staying in one job too long',
      'Living in the same location too long',
      'Using your credit card frequently for purchases',
      'Don`t know'
    ],
    2: [
      'A list of your financial assets and liabilities',
      'A monthly credit card statement',
      'A loan and bill payment history',
      'A credit line with a financial institution',
      'Don`t know'
    ],
    3: [
      'A young single woman with two young children',
      'A young single woman without children',
      'An elderly retired man, with a wife who is also retired',
      'A young married man without children',
      'Don`t know'
    ],
    4: [
      'True',
      'False',
      'Don`t know'
    ]
  },
  SURVEY_OPTIONS: [
    'Strongly agree',
    'Somewhat agree',
    'Don’t agree or disagree',
    'Somewhat disagree',
    'Strongly disagree',
  ],
  TRUE_FALSE: ['True', 'False'],
  DEBT_CONSOLIDATED_OPTIONS: ['', 'Installment loan', 'Pay day loans', 'Credit card', 'Line of credit', 'Other'],
  DEBUT_NON_CONSOLIDATED_OPTIONS: [
    '',
    'Mortgage',
    'Student Loan',
    'Support (spousal and child support)',
    'Vehicle Loan',
    'Credit card or line of credit',
    'Pay day loans',
    'Installment loan',
  ],
};

export const ABBREVIATED_PROVINCES = {
  Alberta: 'AB',
  'British Columbia': 'BC',
  Manitoba: 'MB',
  'New Brunswick': 'NB',
  'Newfoundland and Labrador': 'NL',
  'Northwest Territories': 'NT',
  'Nova Scotia': 'NS',
  Nunavut: 'NU',
  Ontario: 'ON',
  'Prince Edward Island': 'PE',
  Quebec: 'QC',
  Saskatchewan: 'SK',
  Yukon: 'YT',
};

export const MONTH_NUM_TO_NAME = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};
export const USER_TYPE = {
  8: 'FREEMIUM',
  9: 'USER',
  7: 'EMPLOYEE'
};
export const PARTNERS_MAP = {
  8: 'PARACHUTE',
  9: 'IFCU',
  7: 'ALL'
};
