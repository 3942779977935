import React from 'react';
import './Footer.scss';
import { LINKS } from '../../utils/constants';
import LogoLinkFooter from '../LogoLinkFooter';
import AuthStatus from '../AuthStatus';

export default function Footer() {
  // const legal = ['Privacy', 'Security', 'Legal'];
  const legal = ['terms', 'Privacy'];
  const parsedLegalLinks = legal.map((x, i) => {
    return (
      <span key={`footLink-${i}-${x}`}>
        <a href={LINKS[x.toLowerCase()]} className='btn btn-sm small btn-link text-center text-muted'>{x === 'terms' ? 'Terms of Use' : x}</a>
      </span>
    );
  });

  // const internalLinks = ['Support', 'FAQs', 'Logout'];
  // const parsedInternalLinks = internalLinks.map((x, i) => {
  //   if (x === 'Logout')
  //     return (
  //       <li key={`footLink-${x}`}>
  //         <AuthStatus />
  //       </li>
  //     );
  //   return (
  //     <li key={`footLink-${i}-${x}`}>
  //       <h2>
  //         <a href={LINKS[x.toLowerCase()]}>{x}</a>
  //       </h2>
  //     </li>
  //   );
  // });

  return (
    <footer>
      <nav className='container'>
        <LogoLinkFooter />
        <AuthStatus />
      </nav>
      <div className="footer-legal container">
        <span className="d-block small text-center text-muted mb-0">
          © 2022 WHF Inc. All rights reserved. <a href="https://myparachute.co">Myparachute.co</a> and the{' '}
          <a href="https://myparachute.co">Myparachute.co</a> designs are trademarks of White Hat Financial Inc.
          (‘WHF’) and used here under licence.
        </span>
        <span className="d-block text-center small">{parsedLegalLinks}</span>
      </div>
    </footer>
  );
}
