/* 
Get all users
Get all quizzes for month
GET Each users quiz answers (lazyload?)
*/
import React, { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form';
// import Table from 'react-bootstrap/Table';
import api from '../../../utils/api';
import { Button } from "react-bootstrap";

import { USER_TYPE } from '../../../utils/constants';


import cookies from "../../../utils/cookies";

import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useCallback } from 'react'
import { toast } from 'react-toastify';


export default function Index() {
  const [selectedUserType, setSelectedUserType] = useState('USER');



  const [totalTableElements, setTotalTableElements] = useState(0);
  const [gridApi, setGridApi] = useState(null);
  const perPage = 10;
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  // const weeklyAnalyticsCall = useCallback(async () => {
  //   try {

  //     const weeklyAnalyticsRes = await api.findAllQuiz(perPage, 0);
  //     console.log(weeklyAnalyticsRes);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }, [selectedUserType]);
  useEffect(() => {
    // weeklyAnalyticsCall();
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);

          gridApi.showLoadingOverlay();
          const page = params.endRow / perPage;


          api.findAllQuiz(perPage, page - 1)
            .then(resp => resp?.data)
            .then(res => {
              gridApi.hideOverlay();


              const initUsers = res.content;

              console.log(initUsers);
              setTotalTableElements(res.totalElements);
              params.successCallback(initUsers, res.totalElements);
            }).catch(err => {
              gridApi.hideOverlay();
              console.log(err);
              params.successCallback([], 0);
            });


        }
      }
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, selectedUserType]);



  const dateFormatter = ({ value }) => {
    if (!value) {
      return;
    }
    let d = new Date(value).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" });
    return d;
  }
  const actionsFormatterA = ({ value, data }) => {

    return <>

      {/* <Button
        variant="outline-dark"
        size="sm"
        target='_blank'
        href={`../admin/TradeViewer/${data?.id}`}
      >
        Trade
      </Button>
      &nbsp;&nbsp;
      <Button
        variant="outline-success"
        size="sm"
        href={`../chatViewer?room=${data?.id}&username=${data?.username}&firstName=${data?.firstName}&lastName=${data?.lastName}`}
      >
        Chat
      </Button>
      &nbsp;&nbsp;
      <Button
        variant="outline-primary"
        size="sm"
        href={`./userViewer?username=${data?.username}`}
      >
        Profile
      </Button>
      &nbsp;&nbsp;
      <Button
        variant="info"
        size="sm"
        href={`./TimelineViewer?username=${data?.id}`}

      >
        Timeline
      </Button>

      &nbsp;&nbsp;
      <Button
        variant="primary"
        size="sm"
        onClick={() => enableDisableUser(data)}
      >
        {data?.enabled ? 'Disable' : 'Enable'}
      </Button> */}
    </>;
  }
  const showToast = async (msg) => {
    try {
      toast.dismiss();
      toast.info(msg, {
        position: toast.POSITION.TOP_CENTER
      });
    } catch (error) {
      console.log('error', error);
    }

  }
  const enableDisableUser = (user) => {


    try {
      let data = {
        userID: user?.id,
        flag: !user?.enabled
      }

      api.enableDisableUser(data).then((res) => {
        if (res) {
          showToast(`User ${user?.enabled ? 'Disabled' : 'Enabled'} Successfully`);
          setTimeout(() => {
            window.location.reload();
          }, 3000);

        }
      });



    } catch (e) {
      console.error(e);
    }
  };



  return (

    <>


      <div id="applicanttable">


        <div className="App">

          <div className='container d-flex flex-column flex-grow-1 py-4'>
            <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
              <div style={{ flexGrow: '1' }}>


                <div className="ag-theme-alpine ag-style" style={{ height: '100%', width: '100%' }}>
                  <AgGridReact
                    pagination={true}
                    rowModelType={'infinite'}
                    paginationPageSize={perPage}
                    cacheBlockSize={perPage}
                    onGridReady={onGridReady}
                    rowHeight={60}
                    sortable={true}
                    unSortIcon={true}
                    defaultColDef={{ flex: 1, sortable: true, unSortIcon: true, sortingOrder: ['ASC', 'DESC'] }}
                    overlayLoadingTemplate={
                      '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
                    }
                    overlayNoRowsTemplate={
                      '<span className="ag-overlay-loading-center">No data found to display.</span>'
                    }
                  >
                    <AgGridColumn minWidth={150} field="username" headerName="User Name" cellClass="vertical-middle" />

                    <AgGridColumn minWidth={150} field="firstName" headerName="First Name" cellClass="vertical-middle" />
                    <AgGridColumn minWidth={150} field="lastName" headerName="Last Name" cellClass="vertical-middle" />
                    {/* <AgGridColumn  field="lastName" headerName="Last Name" cellClass="vertical-middle" /> */}

                    <AgGridColumn minWidth={150} field="loanStartDate" headerName="Loan Start Date" cellRendererFramework={dateFormatter} cellClass="vertical-middle" />

                    <AgGridColumn minWidth={150} field="createDateTime" headerName="Created" cellRendererFramework={dateFormatter} cellClass="vertical-middle" />
                    <AgGridColumn minWidth={350} field="applicantId" headerName="Actions" cellRendererFramework={actionsFormatterA} cellClass="vertical-middle" />

                  </AgGridReact>
                </div>



              </div></div></div>

        </div>



      </div>
    </>

  );
}
