import React from 'react';
import CardModal from '../CardModal';

// import DismissIcon from '../../assets/icons/Dismiss_Icon_Blk.svg';
import FICOBadge from '../../assets/FICOBadge.svg';

import './FICOModal.scss';

export default function FICOModal() {
  const CREDIT_REPORT_LINK =
    'https://www.canada.ca/en/financial-consumer-agency/services/credit-reports-score/order-credit-report.html#toc0';
  const FICO_FAQ_CANADA_LINK = 'https://www.ficoscore.com/ficoscore/pdf/FAQs-About-FICO-Scores-Canada-EN.pdf';

  return (
    <>
      <CardModal textBtn="Click for more credit education" btnClass="creditModalBtn" id="ficoModal" closeBtn={true}>
        <h3>Parachute now offers free access to your FICO® Score!</h3>
        <hr />
        <div className="ficoModalInfo">
          <h4>What are FICO® Scores?</h4>
          <p>
            FICO® Scores are the most widely used credit scores. Each FICO® Score is a three-digit number calculated
            from the data on your credit reports at TransUnion. Your FICO® Scores predict how likely you are to pay back
            a credit obligation as agreed. Lenders use FICO® Scores to help them quickly, consistently and objectively
            evaluate potential borrowers’ credit risk.
          </p>
        </div>
        <div className="ficoModalInfo">
          <h4>Why are you providing FICO® Scores?</h4>
          <p>
            A majority of lenders in Canada, including Parachute use FICO® Scores, as the industry standard for
            determining credit worthiness. Reviewing your FICO® Scores can help you learn how lenders view your credit
            risk and allow you to better understand your financial health.
          </p>
        </div>
        <div className="ficoModalInfo">
          <h4>What goes into FICO® Scores?</h4>
          <p>
            FICO® Scores are calculated from the credit data in your credit report. This data is grouped into five
            categories; the chart below shows the relative importance of each category.
          </p>
        </div>
        <div className="list">
          <div className="ficoModalInfo">
            <h4>1. 35%--Payment History</h4>
            <p>Whether you’ve paid past credit accounts on time</p>
          </div>
          <div className="ficoModalInfo">
            <h4>2. 30%--Amount Owed</h4>
            <p>The amount of credit and loans you are using</p>
          </div>
          <div className="ficoModalInfo">
            <h4>3. 15%--Length of Credit History</h4>
            <p>How long you’ve had credit</p>
          </div>
          <div className="ficoModalInfo">
            <h4>4. 10%--New Credit</h4>
            <p>Frequency of credit inquiries and new account openings</p>
          </div>
          <div className="ficoModalInfo">
            <h4>5. 10%--Credit Max</h4>
            <p>
              The mix of your credit, retail accounts, installment loans, finance company accounts and mortgage loans
            </p>
          </div>
        </div>
        {/* <ol>
          <li>Whether you’ve paid past credit accounts on time</li>
          <li>The amount of credit and loans you are using</li>
          <li>How long you’ve had credit</li>
          <li>Frequency of credit inquiries and new account openings</li>
          <li>
            The mix of your credit, retail accounts, installment loans, finance company accounts and mortgage loans
          </li>
        </ol> */}
        <div className="ficoModalInfo">
          <h4>What are score factors?</h4>
          <p>
            Score factors are delivered with a consumer’s FICO® Score, these are the top areas that affected that
            consumer’s FICO® Scores. The order in which the score factors are listed is important. The first factor
            indicates the area that most influenced the score and the second factor is the next most significant
            influence. Addressing these factors can benefit the score.
          </p>
        </div>
        <div className="ficoModalInfo">
          <h4>Why is my FICO® Score different than other scores I’ve seen?</h4>
          <p>
            There are many different credit scores available to consumers and lenders. FICO® Scores are the credit
            scores used by a majority of lenders, and different lenders may use different versions of FICO® Scores. In
            addition, FICO® Scores are based on credit file data from a consumer reporting agency, so differences in
            your credit files may create differences in your FICO® Scores.
          </p>
        </div>
        <div className="ficoModalInfo">
          <h4>Why do FICO® Scores fluctuate/change?</h4>
          <p>
            There are many reasons why a score may change. FICO® Scores are calculated each time they are requested,
            taking into consideration the information that is in your credit file from a consumer reporting agency at
            that time. So, as the information in your credit file at that CRA changes, FICO® Scores can also change.
            Review your key score factors, which explain what factors from your credit report most affected a score.
            Comparing key score factors from the two different time periods can help identify causes for a change in a
            FICO® Score. Keep in mind that certain events such as late payments or bankruptcy can lower FICO® Scores
            quickly.
          </p>
        </div>
        <div className="ficoModalInfo">
          <h4>Will receiving my FICO® Score impact my credit?</h4>
          <p>No. The FICO® Score we provide to you will not impact your credit.</p>
        </div>
        <div className="ficoModalInfo">
          <h4>How do I check my credit report for free?</h4>
          <p>
            You may get a free copy of your credit report (also known as “credit file disclosure” or “consumer
            disclosure”) from each of the two major consumer reporting agencies annually. To learn how to request a copy
            of your credit report, please visit:{' '}
            <a className="extLink" href={CREDIT_REPORT_LINK} target="_blank" rel="noreferrer">
              {CREDIT_REPORT_LINK}
            </a>
            . Please note that your free credit report will not include your FICO® Score. Because your FICO® Score is
            based on the information in your credit report, it is important to make sure that the credit report
            information is accurate.
          </p>
        </div>
        <div className="ficoModalInfo">
          <h4>How often will I receive my FICO® Score?</h4>
          <p>
            Program participants will receive their FICO® Score from Parachute, updated on a monthly basis, when
            available.
          </p>
        </div>
        <div className="ficoModalInfo">
          <h4>Why is my FICO® Score not available?</h4>
          <ul>
            <li>You are a new account holder and your FICO® Score is not yet available</li>
            <li>Your credit history is too new</li>
            <li>You are not the primary account holder</li>
          </ul>
        </div>
        <hr />
        <div className="faqLink">
          <h4>Additional FICO® Score Information</h4>
          <a className="extLink" href={FICO_FAQ_CANADA_LINK} target="_blank" rel="noreferrer">
            Frequently Asked Questions about FICO® Scores - Canada
          </a>
        </div>
        <img src={FICOBadge} alt="FICO Score" className="ficoBadge" />
        <p className="terms">FICO is a registered trademark of Fair Isaac Corporation.</p>
        <p className="terms">
          Parachute and Fair Isaac are not credit repair organizations. Parachute and Fair Isaac do not provide”credit
          repair” services or advice or assistance regarding “rebuilding” or “improving” your credit record, credit
          history or credit rating.
        </p>
        <hr />
      </CardModal>
    </>
  );
}
