/* {
  "availableDate": "2022-04-27T04:00:00Z",
  "question": "Dogs are known to make what noise?",
  "choice1": "Bark",
  "choice2": "Meow",
  "choice3": "Moo",
  "choice4": "Cluck",
  "choice5": "",
  "choice6": "",
  "correctAnswer": "Bark",
  "explanation": "Dogs have a number of vocalizationss the most well known is barking."
} */

import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import QuizViewer from './QuizViewer';
import api from '../../../utils/api';
import { isDST } from '../../../utils/helpers';

export default function Index() {
  const FIELD_OPTIONS = ['choice1', 'choice2', 'choice3', 'choice4', 'choice5', 'choice6'];

  const [formData, setFormData] = useState({});
  const [dataToUpload, setDataToUpload] = useState(null);
  const [lastUploadSuccess, setLastUploadSuccess] = useState(false);
  const [validated, setValidated] = useState(false);

  const [error, setError] = useState(null);

  useEffect(() => {
    const uploadQuiz = async () => {
      try {
        const res = await api.createQuiz(dataToUpload);
        console.log('res', res);
        setLastUploadSuccess(true);
        setValidated(false);
        setDataToUpload(null);
        setFormData({});
      } catch (e) {
        setDataToUpload(null);
        setError(e);
      }
    };

    dataToUpload && uploadQuiz();
  }, [dataToUpload]);

  const handleChange = (e) => {
    const key = e.target.id;
    let value = e.target.value || e.target.choice;

    setLastUploadSuccess(false);
    setFormData((p) => {
      return {
        ...p,
        [key]: value,
      };
    });
  };

  const isQuizValid = (data) => {
    if (!data.correctAnswer) {
      setError('Must specify the correct answer');
      return false;
    }
    /* Only allow unique choices */
    const choices = [];
    Object.entries(data).forEach(([k, v]) => {
      if (k.includes('choice')) choices.push(v);
    });
    let unique = choices.filter((e, i) => choices.indexOf(e) === i);
    if (unique.length !== choices.length) {
      setError('Choices must be unique');
      return false;
    }

    return true; //Valid Quiz
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValidated(true);
    setError(null);

    if (!isQuizValid(formData)) {
      return; //setError
    }
    const apiData = { ...formData };

    const datesMidnight = isDST(new Date(formData.availableDate)) ? '04:00:00Z' : '05:00:00Z';

    //Convert Date to ISO String for API
    apiData.availableDate = new Date(`${formData.availableDate} ${datesMidnight}`).toISOString();
    //Build answer for API (Needs to match the choice exactly)
    const keyForCorrectAnswer = formData.correctAnswer;
    const quizAnswer = formData[keyForCorrectAnswer];
    apiData.correctAnswer = quizAnswer;
    setDataToUpload(apiData);
  };
  return (
    <>
      <div style={{ width: '70vw' }}>
        <h2>Upload Quiz</h2>
        {lastUploadSuccess && <h3>Last upload successful</h3>}
        <Form onSubmit={handleSubmit} validated={validated}>
          <Form.Group className="mb-3" controlId="availableDate">
            <Form.Label>Available Date (midnight EST)</Form.Label>
            <Form.Control
              type="date"
              placeholder=""
              onChange={handleChange}
              value={formData.availableDate || ''}
              required
              isInvalid={validated && !formData.availableDate}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="question">
            <Form.Label>Question:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              required
              onChange={handleChange}
              value={formData.question || ''}
              isInvalid={validated && !formData.question}
            />
          </Form.Group>
          {FIELD_OPTIONS.map((x, i) => {
            return (
              <Form.Group key={`field-${i}`} className="mb-3" controlId={x}>
                <Form.Label>
                  {x === formData.correctAnswer
                    ? x[0].toUpperCase() + x.slice(1) + ':  - CORRECT ANSWER'
                    : x[0].toUpperCase() + x.slice(1) + ':'}
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  required={i === 0 || i === 1}
                  onChange={handleChange}
                  value={formData[x] || ''}
                  isInvalid={validated && (i === 0 || i === 1) && !formData[x]}
                />
              </Form.Group>
            );
          })}
          <Form.Group className="mb-3" controlId="correctAnswer">
            <Form.Label>Correct Answer</Form.Label>
            <Form.Select
              aria-label="Correct Answer"
              onChange={handleChange}
              value={formData.correctAnswer || ''}
              required
              isInvalid={validated && !formData.correctAnswer}
            >
              <option>Which choice is correct?</option>
              {/* Need List choices */}
              {FIELD_OPTIONS.filter((x) => {
                return x.includes('choice') && formData[x];
              }).map((x) => {
                return <option value={x}>{x}</option>;
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="explanation">
            <Form.Label>Explanation for answer</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              required
              onChange={handleChange}
              value={formData.explanation || ''}
              isInvalid={validated && !formData.explanation}
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
          {lastUploadSuccess && <h3>Upload successful</h3>}
        </Form>
        {error && <h3>{`Error: ${error}`}</h3>}
      </div>
      <hr style={{ width: '85vw' }} />
      <QuizViewer lastUploadSuccess />
    </>
  );
}
