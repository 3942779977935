import React, { useState } from 'react';
import CardContainer from '../CardContainer';
import Modal from 'react-bootstrap/Modal';
import LoanInfo from '../LoanInfo';
import MissedAccordion from './MissedAccordion';
import WideBtn from '../WideBtn';
import { displayMonth } from '../../utils/helpers';
import { ASSESSMENT_ITEMS } from '../../utils/constants';
import addDays from 'date-fns/addDays';

import './HistoryModal.scss';
import ErrorNotification from '../ErrorNotification';

export default function CardModal({ cbHistory, totalEarned, overrideUserID }) {
  const showError = !cbHistory;
  const [show, setShow] = useState(false);
  !overrideUserID && show && window.history.pushState({ page: 'cbhistory' }, 'cbhistory', '/client/cbhistory');
  !overrideUserID && !show && window.history.pushState({ page: 'client' }, 'Client', '/client');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const parsedHistory = cbHistory?.map((assessment, i) => {
    const VALUES_REQUIRED_TO_PASS = ['noNewDebt', 'paidBillsOnTime', 'paidParachuteOnTime', 'underUtilization'];
    let allPassed = VALUES_REQUIRED_TO_PASS.every((v) => assessment?.[v] === true);

    const dateObject = addDays(new Date(assessment.assessmentMonth), 5); //Server saves as 01 0000 Z which is Prev day local, so the month renders wrong
    //Add days to keep it in the intended month
    const itemMonthYear = displayMonth(dateObject, { withYear: true });
    const dollarsEarned = assessment.cbRecordDollarsEarned?.toFixed(2);

    if (dollarsEarned > 0) {
      allPassed = true;
    }

    if (allPassed) {
      return (
        <LoanInfo
          className="cbHistory--info"
          title={itemMonthYear}
          body={isNaN(dollarsEarned) ? 'N/A' : `+$${dollarsEarned}`}
        />
      );
    }
    const failureReasons = [
      assessment.noNewDebt === false && ASSESSMENT_ITEMS.noNewDebt,
      assessment.paidBillsOnTime === false && ASSESSMENT_ITEMS.paidBillsOnTime,
      assessment.paidParachuteOnTime === false && ASSESSMENT_ITEMS.paidParachuteOnTime,
      assessment.underUtilization === false && ASSESSMENT_ITEMS.underUtilization,
    ];
    return (
      <MissedAccordion
        key={`hist-ma-${i}`}
        eventKey={1}
        month={itemMonthYear}
        reasons={failureReasons.filter((x) => x)}
        dollarsEarned={dollarsEarned}
      />
    );
  });
  return (
    <>
      <WideBtn className="wide-outlined" variant="outline-secondary" onClick={handleShow}>
        View Cash-Back History
      </WideBtn>

      <Modal show={show} onHide={handleClose} keyboard={true} aria-labelledby="contained-modal-title-vcenter" centered>
        <CardContainer id="cbHistory-container" className="cardModal-container">
          <div className="cardModal-contents">
            <h3>Cash-Back History</h3>
            <div className="cbHistory--titleBlock">
              {!showError && <h3 className="cbHistory-title">{'Total Cash-back'}</h3>}
              {!showError && <h3 className="cbHistory-body">{!isNaN(totalEarned) ? `$${totalEarned}` : 'N/A'}</h3>}
            </div>
            {!showError && <div className="history-divided">{parsedHistory}</div>}
            {showError && <ErrorNotification>We cannot determine cash-back history.</ErrorNotification>}
            <WideBtn onClick={handleClose} style={{ marginTop: '0px' }}>
              Close
            </WideBtn>
          </div>
        </CardContainer>
      </Modal>
    </>
  );
}
