import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';


import $ from 'jquery';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useCallback } from 'react'

import Container from 'react-bootstrap/Container';

// import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
// import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

export default function SelfAssessmentViewer() {

  const [totalTableElements, setTotalTableElements] = useState(0);


  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }


  /*moods*/
  const [gridApi, setGridApi] = useState(null);
  const perPage = 10;
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const weeklyAnalyticsCall = useCallback(async () => {
    try {
      let fromDate = new Date($('#fromToDate').val()?.split(' - ')[0]);
      fromDate.setUTCHours(0, 0, 0, 0);
      fromDate.setDate(fromDate.getDate() + 1).toLocaleString('en-US');

      let toDate = new Date($('#fromToDate').val()?.split(' - ')[1]);
      toDate.setUTCHours(23, 59, 59, 999);
      toDate.setDate(toDate.getDate() + 1).toLocaleString('en-US');

      let data = {
        "email": '',
        "firstName": '',
        "fromDate": isValidDate(fromDate) ? fromDate : "1991-02-15T00:00:00.000Z",
        "lastName": '',
        "leadStatus": "",
        "toDate": isValidDate(toDate) ? toDate : "2050-02-15T23:59:59.999Z",
        "utmSource": '',
      }
      const weeklyAnalyticsRes = await api.findSelfAssessmentCountInMonth(data, 0, perPage);
      console.log(weeklyAnalyticsRes?.data);
    } catch (e) {
      console.error(e);
    }
  }, []);
  useEffect(() => {
    weeklyAnalyticsCall();
    if (gridApi) {
      const dataSource = {
        getRows: (params) => {
          console.log(params);

          gridApi.showLoadingOverlay();
          const page = params?.endRow ? params?.endRow : 1 / perPage;
          let data = null;
          api.findSelfAssessmentCountInMonth(data, page - 1, perPage)
            .then(resp => resp?.data)
            .then(res => {
              gridApi.hideOverlay();


              const initUsers = res.filter((x) => x);

              console.log(initUsers);
              setTotalTableElements(res.length)
              params.successCallback(initUsers, res.length);
            }).catch(err => {
              gridApi.hideOverlay();
              console.log(err);
              params.successCallback([], 0);
            });
        }
      }
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, weeklyAnalyticsCall]);



  return (
    <>
      <>
        <Container>
          <Row>
         
            <Col xs={12}>
              <Card>
                <Card.Body>Self Assessment count in a given month (per Day)


                <Button variant="dark" className="float-end">
                    Total Records: <Badge bg="info">{totalTableElements}</Badge>
                  </Button>


                </Card.Body>
              </Card>
            </Col>
        
          </Row>

        </Container>
        <br></br>
        <div id="applicanttable">
          <div className="App">

          <div className='container d-flex flex-column flex-grow-1 py-4'> 
      <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <div style={{ flexGrow: '1' }}>
    


            <div className="ag-theme-alpine ag-style" style={{ height: '100%', width: '100%' }}>
              <AgGridReact
                pagination={true}
                rowModelType={'infinite'}
                paginationPageSize={perPage}
                cacheBlockSize={perPage}
                onGridReady={onGridReady}
                rowHeight={60}
                sortable={true}
                unSortIcon={true}
                defaultColDef={{ flex: 1, sortable: true, unSortIcon: true, sortingOrder: ['ASC', 'DESC'] }}
                overlayLoadingTemplate={
                  '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>'
                }
                overlayNoRowsTemplate={
                  '<span className="ag-overlay-loading-center">No data found to display.</span>'
                }
              >
                <AgGridColumn  field="onDate" headerName="Date" cellClass="vertical-middle" />
                <AgGridColumn  field="totalCount" headerName="Total" cellClass="vertical-middle" />
              </AgGridReact>
            </div>


            
</div></div></div>
          </div>



        </div>





      </>

    </>


  );
}
