import React, { useState } from 'react';
import closeIcon from '../../assets/onboarding/closeIcon.svg';
import playIcon from '../../assets/onboarding/playIcon.svg';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function VideoModal({ id, hideParentModal, showParentModal }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    showParentModal();
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    hideParentModal();
  };

  return (
    <>
      <div className="d-grid gap-2">
        <Button id={id} variant="success" size="lg" onClick={handleShow}>
          <img src={playIcon} alt="Play Icon" style={{ paddingRight: '8px' }} />
          {` Play Video`}
        </Button>
      </div>

      <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body>
          <div id="onboarding-video-container">
            <img src={closeIcon} alt="Close" onClick={handleClose} />
            <iframe
              id="onboarding-video-iframe"
              title="Parachute Welcome Video"
              src="https://player.vimeo.com/video/714650691?autopause=0&autoplay=1&byline=0&portrait=0&title=0" //Parachute Welcome Video
              allow="accelerometer; autoplay; encrypted-media; gyroscope; fullscreen"
              // width="801"
              // height="497"
              // width="100%"
              // height="100%"
              frameborder="0"
            />
            {/* https://embed.tube/embed-code-generator/vimeo/ */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
