import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FieldGroup from './FieldGroup';

import { ENTITIES } from '../../utils/A-Dashboard/constants';

const determineFieldsKey = (type) => {
  switch (type) {
    case 'address':
      return 'addresses';
    case 'url':
      return 'links';
    case 'email':
      return 'emails';
    case 'phone':
      return 'phones';
    default:
      break;
  }
};

export default function EntityForm({ formData, updateList, isReadOnly }) {
  const parentType = 'entity'; //Used in simple forms to specify form type
  const [form, setForm] = useState(formData);

  useEffect(() => {
    /* When form updates, triggers update @ top level state */
    updateList && updateList('ENTITY', form);
    //eslint-disable-next-line
  }, [form]);

  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    const field = e.target.id;
    setForm((p) => {
      return { ...p, [field]: value };
    });
  };

  const addField = (fieldType) => {
    //Used to add a field to the applicable array in state (ie addresses, phones, emails etc...)
    const keyToUpdate = determineFieldsKey(fieldType);
    let prevFieldData = form[keyToUpdate];
    const fieldToAdd = { [fieldType]: '', type: '' };
    keyToUpdate &&
      prevFieldData &&
      setForm((p) => {
        return { ...p, [keyToUpdate]: [...prevFieldData, fieldToAdd] };
      });
  };

  const updateField = (fieldType, index, newData) => {
    const keyToUpdate = determineFieldsKey(fieldType);
    const newFields = form[keyToUpdate];
    newFields[index] = newData;
    setForm((p) => {
      return { ...p, [keyToUpdate]: newFields };
    });
  };

  const removeField = (fieldType, index) => {
    const keyToUpdate = determineFieldsKey(fieldType);
    const oldData = form[keyToUpdate];
    oldData.splice(index, 1); //Removes from array
    const newData = oldData;
    setForm((p) => {
      return { ...p, [keyToUpdate]: newData };
    });
  };

  return (
    <>
      {/* ===========================================================ENTITY FORM */}
      <Row>
        <Form.Group as={Col} controlId="entity_name">
          <Form.Label>Legal Name</Form.Label>
          <Form.Control
            placeholder="Enter Legal Name"
            onChange={handleChange}
            value={formData.entity_name}
            disabled={isReadOnly}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="role">
          <Form.Label>Role*</Form.Label>
          <Form.Select onChange={handleChange} value={formData.role} disabled={isReadOnly} required>
            {ENTITIES.role.map((item, i) => (
              <option key={`e-role-opt-${i}`}>{item}</option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} controlId="dba">
          <Form.Label>Doing Business As</Form.Label>
          <Form.Control
            placeholder="Enter operating name"
            onChange={handleChange}
            value={formData.dba}
            disabled={isReadOnly}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="entity_type">
          <Form.Label>Entity Type</Form.Label>
          <Form.Select onChange={handleChange} value={formData.entity_type} disabled={isReadOnly}>
            {ENTITIES.entity_type.map((item, i) => (
              <option key={`e-eType-opt-${i}`}>{item}</option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} controlId="entity_number">
          <Form.Label>ID Number or Tax Number</Form.Label>
          <Form.Control
            placeholder="Enter ID number"
            onChange={handleChange}
            value={formData.entity_number}
            disabled={isReadOnly}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="naics_code">
          <Form.Label>Industry Code</Form.Label>
          <Form.Control
            placeholder="Enter Industry Code"
            onChange={handleChange}
            value={formData.naics_code}
            disabled={isReadOnly}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="established">
          <Form.Label>Established Date (YYYY-MM-DD)</Form.Label>
          <Form.Control
            placeholder="ie. 2022-03-04"
            onChange={handleChange}
            value={formData.established}
            disabled={isReadOnly}
          />
        </Form.Group>
      </Row>
      <FieldGroup
        fieldID="address"
        label="Address"
        placeholder="Full Address; 1234 Main St, Place Ontario, L3L 5L3"
        typeOptions={ENTITIES.ADDRESS_TYPES}
        parentType={parentType}
        fieldData={formData.addresses}
        addFieldToIndvForm={addField}
        updateField={updateField}
        removeField={removeField}
        isReadOnly={isReadOnly}
      />

      <FieldGroup
        fieldID="phone"
        label="Phone"
        placeholder="555 555 5555"
        typeOptions={ENTITIES.PHONE_TYPES}
        parentType={parentType}
        fieldData={formData.phones}
        addFieldToIndvForm={addField}
        updateField={updateField}
        removeField={removeField}
        isReadOnly={isReadOnly}
      />

      <FieldGroup
        fieldID="email"
        label="Email"
        placeholder="smith@example.com"
        typeOptions={ENTITIES.EMAIL_TYPES}
        parentType={parentType}
        fieldData={formData.emails}
        addFieldToIndvForm={addField}
        updateField={updateField}
        removeField={removeField}
        isReadOnly={isReadOnly}
      />

      <FieldGroup
        fieldID="url"
        label="Web URL"
        placeholder="https://example.com"
        typeOptions={ENTITIES.LINK_TYPE}
        parentType={parentType}
        fieldData={formData.links}
        addFieldToIndvForm={addField}
        updateField={updateField}
        removeField={removeField}
        isReadOnly={isReadOnly}
      />
    </>
  );
}
