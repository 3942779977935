import React, { useState } from 'react';
import CardContainer from '../CardContainer';
import CardTitleWTip from '../CardTitleWTip';
import ErrorNotification from '../ErrorNotification';
import Button from 'react-bootstrap/Button';
import ScoreArea from './ScoreArea';
import FICOModal from './FICOModal';
import './Index.scss';
import { formatDateForDisplay } from '../../utils/helpers';
import FICO_REASONS from '../../utils/constants/FICO_REASONS';

export default function Index({ data }) {
  const [showExtraInfo, setShowExtraInfo] = useState(false);

  const ReasonSection = ({ ficoReasons }) => {
    /* Takes in FICO reasons (array of strings) ["10", "04"] 
displays an explaination for those reasons
Max 2 reasons, can be 1 or 0 reasons too.*/
    return (
      <section className="scoreReasons">
        {ficoReasons?.map((reasonCode, i) => {
          return (
            <>
              <div key={`ficoReason${i}`} className="reason">
                <h4>{`${i + 1}. ${FICO_REASONS[reasonCode].statement}`}</h4>
                <p>{FICO_REASONS[reasonCode].description}</p>
                <br />
                <h4>Keep in mind:</h4>
                <p>{FICO_REASONS[reasonCode].helper}</p>
              </div>
              <hr />
            </>
          );
        })}
      </section>
    );
  };

  const limitReasons = (reasonArray, limit) => {
    if (!reasonArray) return null;
    reasonArray.length = limit;
    return reasonArray.filter((x) => x);
  };
  const limitedReasons = limitReasons([data?.reasonCode1, data?.reasonCode2], 2); //Manually enter reasons for now.

  const extraInfo = (
    <article className="extraFICOInfo">
      {limitedReasons?.length > 0 && (
        <>
          <h3>Key Factors affecting your FICO® Score</h3>
          <ReasonSection ficoReasons={limitedReasons} />
        </>
      )}

      <p className="xsMuted">
        FICO and “The score lenders use” are registered trademarks of Fair Isaac Corporation in the United States and
        other countries. ©2022 Fair Isaac Corporation. All rights reserved.
      </p>
      <p className="xsMuted">
        Fair Isaac is not a credit repair organization. Fair Isaac does not provide "credit repair" services or advice
        or assistance regarding "rebuilding" or "improving" your credit record, credit history or credit rating.
      </p>
      <FICOModal />
    </article>
  );

  const showError = !data || !data?.ficoScore;
  return (
    <CardContainer id="ficoScoreCard">
      <CardTitleWTip>Credit Score</CardTitleWTip>
      <ScoreArea score={data?.ficoScore} />
      {!showError && (
        <>
          <p className="ficoSummary">
            {data?.pulledDate &&
              `Your FICO® Score 10 based on TransUnion data is the same score that Parachute pulled on ${formatDateForDisplay(
                data.pulledDate,
                { numeric: true }
              )} and uses to manage your account.`}
          </p>
          <hr />
          {/* {//ExtraInfo} */}
          {showExtraInfo === true && extraInfo}
        </>
      )}

      {showError ? (
        <ErrorNotification>Your FICO® Score is not available at this time.</ErrorNotification>
      ) : (
        <Button variant="outline-secondary" onClick={() => setShowExtraInfo((p) => !p)}>
          {showExtraInfo ? 'Hide details' : 'Learn More'}
        </Button>
      )}
    </CardContainer>
  );
}
