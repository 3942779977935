import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

/* Simple Text input with a type drop down. */

export default function SimpleField({
  fieldID,
  label,
  placeholder,
  typeOptions,
  parentType,
  defaultSelect,
  index,
  data,
  removeField,
  updateFieldState,
  isReadOnly,
}) {
  const removeRow = () => {
    removeField(fieldID, index);
  };

  const handleChange = (e) => {
    const val = e.target.value;
    const field = e.target.id;
    const newFieldData = { ...data, [field]: val };
    updateFieldState(fieldID, index, newFieldData);
  };

  return (
    <Row>
      <Form.Group as={Col} className="mb-3" controlId={fieldID}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type="textArea"
          placeholder={placeholder}
          value={data[fieldID]}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </Form.Group>
      <Form.Group as={Col} controlId="type">
        <Form.Label>{`${label} Type`}</Form.Label>
        <div style={{ display: 'flex' }}>
          <Form.Select value={data?.type} onChange={handleChange} disabled={isReadOnly}>
            {typeOptions.map((item, i) => (
              <option key={`${parentType[0]}-${fieldID}-opt-${i}`} value={item}>
                {item}
              </option>
            ))}
          </Form.Select>
          {!isReadOnly && (
            <Button variant="outline-danger" style={{ marginLeft: '8px' }} onClick={removeRow}>
              X
            </Button>
          )}
        </div>
      </Form.Group>
    </Row>
  );
}
