import React from 'react';
import './StreakTile.scss';
import checkGreen from '../../../assets/icons/Checkbox-Green.svg';
import checkGrey from '../../../assets/icons/Checkbox-Grey.svg';
import CardContainer from '../../CardContainer';
import useAuth from '../../../hooks/useAuth';

import cookies from '../../../utils/cookies';
export default function StreakTile({ quizStats, reward, freemium_reward }) {
  let { total, completed } = quizStats;
  if (!total) total = 8;
  const iconsToShow = [];
  //Set Total Icons
  for (let i = 0; i < total; i++) {
    iconsToShow.push(<img key={`streakCheck-${i}`} src={checkGrey} alt="Quiz Incomplete" />);
  }
  //Fill in Complete
  for (let i = 0; i < completed; i++) {
    iconsToShow[i] = <img key={`streakCheck-${i}`} src={checkGreen} alt="Quiz Complete" />;
  }
  let auth = useAuth();
  const savedRole = auth?.user?.role || cookies.getUserRole();
  return (
    <CardContainer className="streakBlock divided">
      <h2 className="streakTitle">Get a Streak!</h2>
      <div className="streakInfo">
        {iconsToShow.length > 0 && <span className="streakIcons">{iconsToShow}</span>}
        <p className="streakReward">{savedRole !== 'ROLE_FREEMIUM' ? reward : freemium_reward}</p>
      </div>
    </CardContainer>
  );
}
