import React from 'react';
import CardContainer from '../CardContainer';
import WideBtn from '../WideBtn';
import icon from '../../assets/Logo_Icon.svg';
// import { LINKS } from '../../utils/constants';

export default function Finished({ goToHome }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('DONE, redirect back to home /');
    goToHome();
  };
  return (
    <>
      <CardContainer>
        <div>
          <div className="titleBlock">
            {icon && <img className="titleBlock--icon" src={icon} alt={icon} />}
            <h3 className="titleBlock--title">
              Thank you. Your application is under review, and we’ll follow up with you shortly.
            </h3>
            <p className="muted">
              Our loan specialists are reviewing the details of your application to determine if you qualify for a loan
              from Parachute. We’ll let you know once we reach a decision.
            </p>
            <p className="muted titleBlock--body">
              If you have any questions, or need additional support, please text or call us at{' '}
              <a href="tel:8446702403">(844) 670-2403</a> or email us at{' '}
              <a href="email:contactus@myparachute.co">contactus@myparachute.co</a>
            </p>
          </div>
          <WideBtn onClick={handleSubmit} id="KOGoToHome">
            Go back home
          </WideBtn>
        </div>
      </CardContainer>
    </>
  );
}
