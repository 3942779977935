import React from 'react';
import './Index.scss';
import { LINKS } from '../../utils/constants';
import LogoLink from '../LogoLink';
import AuthStatus from '../AuthStatus';
import { Link } from 'react-router-dom';

export default function Header({ debugMode }) {
  const internalLinks = []; //Removed logout from header as per design

  //if dev env
  if (debugMode) {
    internalLinks.unshift('Debug');
  }

  const parsedInternalLinks = internalLinks.map((x, i) => {
    return (
      <li key={`headLink-${i}-${x}`}>
        <Link to={LINKS[x.toLowerCase()]}>{x}</Link>
      </li>
    );
  });

  return (
    <header>
      <nav className='container'>
        <LogoLink className="headerLogo" />
        {debugMode && <ul className="headerLinks">{parsedInternalLinks}</ul>}
        {/* <ul className="headerLinks">
          {supportLink}
          {settingsLink}
        </ul> */}
        {<AuthStatus />}
      </nav>
    </header>
  );
}
