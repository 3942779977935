import React, { useState, useEffect } from 'react';
import CardContainer from '../CardContainer';
import WideBtn from '../WideBtn';
import icon from '../../assets/Logo_Icon.svg';
import Form from 'react-bootstrap/Form';
import { PRE_QUAL } from '../../utils/constants';
// import TextButton from '../TextButton';

export default function Survey({
  financialLiteracy,
  behaviour,
  wellness,
  updatePreQualState,
  stateData,
  // navForm,
  // navBackOverride,
  status,
}) {
  const {
    WELLNESS_QUESTIONS,
    FIN_LITERACY_QUESTIONS,
    FIN_LITERACY_OPTIONS,
    BEHAVIOUR_QUESTIONS,
    SURVEY_OPTIONS,
    // TRUE_FALSE,
    DESCRIPTION_OPTIONS,
    TIME_OPTIONS,
  } = PRE_QUAL; //CONSTANTS for Form Options

  const [formData, setFormData] = useState(stateData || {});
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    stateData && setFormData({ ...stateData });
  }, [stateData]);

  let currKey = '';
  let QUESTIONS = [];
  let header = '';
  let ANSWER_OPTIONS = [];
  const updateComponentVariables = () => {
    if (behaviour) {
      currKey = 'behaviourQuestions';
      QUESTIONS = BEHAVIOUR_QUESTIONS;
      ANSWER_OPTIONS = SURVEY_OPTIONS;
      header = 'You’re getting close. Let us know how the  statements below describe you:';
    }
    if (wellness) {
      currKey = 'wellnessQuestions';
      QUESTIONS = WELLNESS_QUESTIONS;
      ANSWER_OPTIONS = SURVEY_OPTIONS;
      header = 'Almost there! Let us know how the  statements below describe you:';
    }
    if (financialLiteracy) {
      currKey = 'financialLiteracyQuestions';
      QUESTIONS = FIN_LITERACY_QUESTIONS;
      ANSWER_OPTIONS = FIN_LITERACY_OPTIONS;
      header = 'Last few questions. Please answer true or false to the questions below.';
    }
  };
  updateComponentVariables();

  const handleChange = (e) => {
    const key = e.target.id;
    let value = e.target.value;
    const questionIndex = key.slice(1);

    setFormData((p) => {
      return { ...p, [key]: { question: QUESTIONS[questionIndex], answer: value } };
    });
  };
  const surveryQuestions = QUESTIONS.map((question, questionIndex) => {
    const notLastItem = QUESTIONS.length - 1 !== questionIndex;
    return (
      <div key={`q-${question}`} className="mb-3">
        <p className="muted surveryQuestion">{question}</p>

        {currKey === 'wellnessQuestions' &&
          questionIndex <= 2 &&
          DESCRIPTION_OPTIONS.map((option, ansIndex) => {
            const type = 'radio';
            return (
              <Form.Check
                className="surveyCheck"
                // reverse
                onChange={handleChange}
                name={question}
                key={`${question}-${option}`}
                id={`q${questionIndex}`} //Question
                type={type}
                label={option}
                value={option}
                checked={formData?.[`q${questionIndex}`]?.answer === option}
                required
                isInvalid={validated && !formData?.[`q${questionIndex}`]?.answer}
                readOnly={status.loading}
              />
            );
          })}
        {currKey === 'wellnessQuestions' &&
          questionIndex > 2 &&
          TIME_OPTIONS.map((option, ansIndex) => {
            const type = 'radio';
            return (
              <Form.Check
                className="surveyCheck"
                // reverse
                onChange={handleChange}
                name={question}
                key={`${question}-${option}`}
                id={`q${questionIndex}`} //Question
                type={type}
                label={option}
                value={option}
                checked={formData?.[`q${questionIndex}`]?.answer === option}
                required
                isInvalid={validated && !formData?.[`q${questionIndex}`]?.answer}
                readOnly={status.loading}
              />
            );
          })}
{currKey === 'financialLiteracyQuestions' &&
          ANSWER_OPTIONS[questionIndex].map((option, ansIndex) => {
            console.log(option)
            console.log(ansIndex)
            const type = 'radio';
            return (
              <Form.Check
                className="surveyCheck"
                // reverse
                onChange={handleChange}
                name={question}
                key={`${question}-${ansIndex}`}
                id={`q${questionIndex}`} //Question
                type={type}
                label={option}
                value={option}
                checked={formData?.[`q${questionIndex}`]?.answer === option}
                required={true}
                isInvalid={validated && !formData?.[`q${questionIndex}`]?.answer}
              />
            );
          })}
        {currKey !== 'wellnessQuestions' && currKey !== 'financialLiteracyQuestions' &&
          ANSWER_OPTIONS.map((option, ansIndex) => {
            const type = 'radio';
            return (
              <Form.Check
                className="surveyCheck"
                // reverse
                onChange={handleChange}
                name={question}
                key={`${question}-${ansIndex}`}
                id={`q${questionIndex}`} //Question
                type={type}
                label={option}
                value={option}
                checked={formData?.[`q${questionIndex}`]?.answer === option}
                required={true}
                isInvalid={validated && !formData?.[`q${questionIndex}`]?.answer}
              />
            );
          })}
        {notLastItem && <hr className="surveryDivider" />}
      </div>
    );
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setValidated(true);
    const formElement = e.target.innerHTML;
    const formContainsInvalidField = formElement.includes('is-invalid');
    if (validated && formContainsInvalidField !== true) return updatePreQualState(currKey, formData);
  };

  useEffect(() => {
    if (validated === true) {
      handleSubmit({ target: window.document.getElementsByClassName('applyForm')[0], preventDefault: () => {} });
    }
    //eslint-disable-next-line
  }, [validated]);
  return (
    <>
      <CardContainer>
        <div>
          <div className="titleBlock">
            {icon && <img className="titleBlock--icon" src={icon} alt={icon} />}
            <h3 className="titleBlock--title">{header}</h3>
            {/*   <p className="muted titleBlock--body">
              Note: This will not impact our decision on whether to fund your loan. But, this information does help us
              understand how we can better help you on your journey to financial well-being.
            </p> */}
            <p className="muted titleBlock--body">
              If you have any questions, or need additional support, please text or call us at{' '}
              <a href="tel:8446702403">(844) 670-2403</a> or email us at{' '}
              <a href="email:contactus@myparachute.co">contactus@myparachute.co</a>
            </p>
          </div>
          <hr />

          <Form className="applyForm" onSubmit={handleSubmit} noValidate validated={validated}>
            {surveryQuestions}

            <WideBtn disabled={status.loading} id={`submitKOSurvey-${currKey}`}>
              Next
            </WideBtn>
          </Form>
        </div>
      </CardContainer>
    </>
  );
}
