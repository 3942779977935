import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import startOfMonth from 'date-fns/startOfMonth';
// import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';

import checkCircleIcon from '../../../../assets/icons/blueCheckCircleIcon.svg';
import ModalTitleBlock from '../../../ModalTitleBlock';
import WideBtn from '../../../WideBtn';

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { toast } from 'react-toastify';
import { Button } from "react-bootstrap";
import api from '../../../../utils/api';
import { formatDateForDisplay, parseIdFromLink } from '../../../../utils/helpers';
import cookies from '../../../../utils/cookies';
export default function SelfAssessments({ userToView, index }) {

  const [confirmationData, setConfirmationData] = useState(null);
  const [showAssessmentModal, setshowAssessmentModal] = useState(false);
  const showToast = async (msg) => {
    try {
      toast.dismiss();
      toast.info(msg, {
        position: toast.POSITION.TOP_CENTER
      });
    } catch (error) {
      console.log('error', error);
    }

  }

  const [userAssessments, setUserAssessments] = useState(null);
  const closeAssessmentModal = () => {
    setshowAssessmentModal(false);
  };
  const fetchPlanDetails = (userID, ass_month) => {
    if (!userID) return;

    // userID = 164043;
    let today = new Date(ass_month);
    // today.setMonth(today.getMonth() - 1);
    
    let year = today.getFullYear();
    let month = (today.getMonth() + 1).toString().padStart(2, '0');
    let datestring = `${year}-${month}`;
  

    let fetchSapData = {
      "userId": userID,
      "assessmentMonth": datestring,
    };

    api.fetchSapAdmin(fetchSapData).then(resp => resp?.data)
      .then(res1 => {
        if (!res1) {
          showToast(res1?.message || 'No plan details to display');
          return;
        }
        console.log('submit success', res1);
        setshowAssessmentModal(true)
        setConfirmationData(res1);
        // setIsLoading(false);
        // setConfirmationData(res1);
        // // closeAssessment();
        // console.log('submit success', res1);

        // toast.dismiss();
        // toast.success("Your plan is set and submitted!", {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      }).catch(err => {
        showToast(err?.response?.data?.message || 'No plan details to display');
        // setIsLoading(false);
        console.log('submit contact error', err);
      });

  }
  useEffect(() => {
    const getAndSetAllUserAssessments = async () => {
      try {
        const dateRange = {
          startDate: startOfMonth(new Date(userToView.loanStartDate)),
          endDate: startOfMonth(cookies.getDate()),
        };
        const rawAssessments = await api.getUserAssessments(userToView.link, dateRange);
        const selfAssessments = rawAssessments.data._embedded.selfAssessments.sort((y, x) =>
          x.assessmentMonth.localeCompare(y.assessmentMonth)
        );

        setUserAssessments(selfAssessments);
      } catch (e) {
        console.log('e', e);
      }
    };
    userToView && getAndSetAllUserAssessments();
  }, [userToView]);

  return (
    <>
      <Accordion.Item eventKey={index}>
        <Accordion.Header>Self Assessment Reponses - {userAssessments?.length ?? '0'}</Accordion.Header>
        <Accordion.Body>
          {/* <p>Displays data from the time of the assessment</p> */}
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                {/* Secondary table header to group columns */}
                <th colSpan={2}></th>
                <th colSpan={3}>User Input:</th>
                <th colSpan={1}>Provided by server:</th>
                <th colSpan={1}></th>
              </tr>
              <tr>
                <th>Assessment ID</th>
                <th>Assessment Month</th>
                <th>Last Completed</th>
                <th>No New Debt</th>
                <th>Paid All Bills (Other)</th>
                <th>Credit Utilization</th>
                <th>Paid Parachute</th>
                <th>Plan Details</th>
                <th>User's Reason</th>
                <th>Edit (Coming soon...)</th>
              </tr>
            </thead>
            <tbody>
              {userAssessments &&
                Object.values(userAssessments)
                  // .sort((x, y) => x.availableDate.localeCompare(y.availableDate))
                  .map((q, i) => {

                    return (
                      <tr key={`userSelfAssessment-${i}`}>
                        <td>{parseIdFromLink(q?._links.self.href)}</td>
                        <td>{formatDateForDisplay(q?.assessmentMonth)}</td>
                        <td>{formatDateForDisplay(q?.createDateTime)}</td>
                        <td>{q?.noNewDebt === true ? '✔️' : '❌'}</td>
                        <td>{q?.paidBillsOnTime === true ? '✔️' : '❌'}</td>
                        <td>{q?.underUtilization === true ? '✔️' : '❌'}</td>
                        <td>{q?.paidParachuteOnTime === true ? '✔️' : '❌'}</td>
                        <td>
                          <Button
                            variant="outline-primary"
                            size="sm"

                            onClick={() => fetchPlanDetails(userToView?.id, q?.assessmentMonth)}
                          // href={`./userViewer?username=${data?.username}`}
                          >
                            Plan Details
                          </Button>
                        </td>
                        <td>{q?.reason ?? '-'}</td>
                        <td>
                          {null}
                          {/* <Button>Edit</Button> */}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
            <caption>
              <sub>*Displays completed assessments only. If an assessment was missed it will not be shown here.</sub>
              <br />
              <sub>
                As of Nov 2022 no new debt question changed so 'yes' is a pass. "Have you avoided any new debt?"
              </sub>
              <br />
              <sub>As of Dec 2022 Credit Utilization Answers now manually entered by users.</sub>
            </caption>
          </Table>
        </Accordion.Body>
      </Accordion.Item>




      {confirmationData && showAssessmentModal ? (

        <>
          <br></br>
          {/* <pre>{JSON.stringify(confirmationData, null, 2)}</pre> */}
          <Modal
            id="goalsAssessment--modal"
            show={showAssessmentModal}
            animation={false}
            // onHide={handleClose}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >




            <div className="wideCntr planForm container" style={{ textAlign: 'left', width: 'auto' }}>
              <br></br>
              <ModalTitleBlock
                title={`Plan Details`}
                body={``}
                icon={checkCircleIcon}
              />

              {/* display only if any one is true */
                confirmationData['promises']['automaticWithdrawal'] === true || confirmationData['promises']['spendLess'] === true || confirmationData['promises']['contactBiller'] === true || confirmationData['promises']['description1'] ? (
                  <>
                    <br></br>
                    <hr></hr>
                    <h5>"Pay all my bills on time"</h5>
                    <Card>
                      <Card.Header><h7>Plan</h7></Card.Header>
                      <ListGroup variant="flush">
                        {confirmationData['promises']['automaticWithdrawal'] === true && (
                          <ListGroup.Item>I will set up automatic withdrawals, so funds come directly out of my account</ListGroup.Item>
                        )}

                        {confirmationData['promises']['spendLess'] === true && (

                          <ListGroup.Item>I will spend less so I have money left to pay my bills</ListGroup.Item>
                        )}
                        {confirmationData['promises']['contactBiller'] === true && (
                          <ListGroup.Item>I will contact billers and align payment dates to my pay schedule</ListGroup.Item>
                        )}

                        {confirmationData['promises']['description1'] && (
                          <ListGroup.Item>{confirmationData['promises']['description1']}</ListGroup.Item>
                        )}
                      </ListGroup>
                    </Card>

                  </>) : (<></>)}

              {/* display only if any one is true */

                confirmationData['promises']['applySavingsToCreditCard'] === true || confirmationData['promises']['payMoreOnCreditCard'] === true || confirmationData['promises']['payOffCreditCard'] === true || confirmationData['promises']['description2'] ? (
                  <>
                    <br></br>
                    <hr></hr>
                    <h5>"Maintain credit card utilization of 50% or less"</h5>
                    <Card>
                      <Card.Header><h7>Plan</h7></Card.Header>
                      <ListGroup variant="flush">
                        {confirmationData['promises']['applySavingsToCreditCard'] === true && (
                          <ListGroup.Item>I will save up to $50 this coming month and apply the saving to my credit card</ListGroup.Item>
                        )}
                        {confirmationData['promises']['payMoreOnCreditCard'] === true && (
                          <ListGroup.Item>I will pay more than the minimum amount on my credit card</ListGroup.Item>
                        )}
                        {confirmationData['promises']['payOffCreditCard'] === true && (
                          <ListGroup.Item>I will pay off my credit card</ListGroup.Item>
                        )}
                        {confirmationData['promises']['description2'] && (
                          <ListGroup.Item>{confirmationData['promises']['description2']}</ListGroup.Item>
                        )}
                      </ListGroup>
                    </Card>
                  </>) : (<></>)}


              {/* display only if any one is true */
                confirmationData['promises']['chatBeforeNewDebt'] === true || confirmationData['promises']['noNewDebtUpcomingMonth'] === true || confirmationData['promises']['chatForDebtReduction'] === true || confirmationData['promises']['description3'] ? (
                  <>
                    <br></br>
                    <hr></hr>
                    <h5>"I will not take on new debt"</h5>
                    <Card>
                      <Card.Header><h7>Plan</h7></Card.Header>
                      <ListGroup variant="flush">
                        {confirmationData['promises']['chatBeforeNewDebt'] === true && (
                          <ListGroup.Item>I will chat to Parachute before taking on new debt</ListGroup.Item>
                        )}
                        {confirmationData['promises']['noNewDebtUpcomingMonth'] === true && (
                          <ListGroup.Item>I will not take on new debt this coming month</ListGroup.Item>
                        )}
                        {confirmationData['promises']['chatForDebtReduction'] === true && (
                          <ListGroup.Item>I will chat with Parachute abount how i can reduce the additional debt i have taken out</ListGroup.Item>
                        )}
                        {confirmationData['promises']['description3'] && (
                          <ListGroup.Item>{confirmationData['promises']['description3']}</ListGroup.Item>
                        )}
                      </ListGroup>
                    </Card>
                  </>) : (<></>)


              }



              <WideBtn onClick={closeAssessmentModal}>
                Close
              </WideBtn>

            </div>

          </Modal>


        </>

      ) : (<></>)}
    </>
  );
}
