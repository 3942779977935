import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import api from "../../../utils/api";

export default function AddManualQuiz() {
  const [formData, setFormData] = useState({});
  const [dataToUpload, setDataToUpload] = useState(null);
  const [validated, setValidated] = useState(false);
  const [lastUploadSuccess, setLastUploadSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isValidUser, setIsValidUser] = useState(false);
  const [choices, setChoices] = useState([]);
  const [availableDate, setAvailableDate] = useState(null);  // State to hold available date

  useEffect(() => {
    const uploadAnswer = async () => {
      try {
        const res = await api.submitQuizAnswer(dataToUpload);
        console.log("API Response:", res);
        setLastUploadSuccess(true);
        setValidated(false);
        setDataToUpload(null);
        setFormData({});
      } catch (e) {
        console.error("API Error:", e);
        setError(e.message || "Unknown error");
        setDataToUpload(null);
      }
    };

    if (dataToUpload) {
      uploadAnswer();
    }
  }, [dataToUpload]);

  const validateUser = async (userName) => {
    try {
      const res = await api.checkUser({ email: userName });
      console.log("res", res);
      if (res.valid) {
        setIsValidUser(true);
        setError(null);
      } else {
        setIsValidUser(false);
        setError("Invalid user. You are not allowed to proceed.");
      }
    } catch (e) {
      console.error("User validation error:", e);
      setError("Error validating user. Please try again.");
      setIsValidUser(false);
    }
  };

  const validateQuizId = async (quizId) => {
    try {
      const res = await api.validateQuiz(quizId); // Replace with actual API endpoint
      console.log("quizres", res);
      if (res.id > 0) {
        const choicesArray = [];

        // Save available date from the API response
        setAvailableDate(res.availableDate);

        for (let i = 1; i <= 6; i++) {
          if (res[`choice${i}`]) {
            choicesArray.push({
              value: res[`choice${i}`],
              label: res[`choice${i}`]
            });
          }
        }
        setChoices(choicesArray); // Update choices from the API response
      } else {
        setError("Invalid quiz ID. Please check.");
        setChoices([]); // Clear choices if invalid
      }
    } catch (e) {
      console.error("Quiz validation error:", e);
      setError("Error validating quiz ID. Please try again.");
      setChoices([]);
    }
  };

  const handleChange = async (e) => {
    const { id, value, type, checked } = e.target;

    if (type === "datetime-local") {
      const formattedDate = new Date(value).toISOString().replace("Z", "-04:00");
      setFormData((prev) => ({
        ...prev,
        [id]: formattedDate,
      }));
    } else if (type === "radio") {
      setFormData((prev) => ({
        ...prev,
        [id]: value === "True",
      }));
    } else if (type === "number") {
      setFormData((prev) => ({
        ...prev,
        [id]: value,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [id]: type === "checkbox" ? checked : value,
      }));
    }

    setLastUploadSuccess(false);
  };

  const handleBlur = (e) => {
    if (e.target.id === "userName" && formData.userName) {
      validateUser(formData.userName);
    }
  };

  const handleQuizidBlur = (e) => {
    if (e.target.id === "quizId" && formData.quizId) {
      validateQuizId(formData.quizId); // Replace with your actual validation function
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setValidated(true);
    setError(null);

    if (!formData.userName || !formData.quizId || !formData.answeredDateTime) {
      setError("All fields are required.");
      return;
    }

    if (!isValidUser) {
      setError("User validation failed. Cannot submit.");
      return;
    }

    const apiData = { ...formData };
    setDataToUpload(apiData); // Trigger API call via useEffect
  };

  return (
    <div style={{ width: "70vw" }}>
      <h2>Submit Quiz Answer</h2>
      {lastUploadSuccess && <h3>Upload Successful</h3>}
      {error && <h3 style={{ color: "red" }}>{`Error: ${error}`}</h3>}
      <Form onSubmit={handleSubmit} validated={validated.toString()}>
        <Form.Group className="mb-3" controlId="userName">
          <Form.Label>User Name (Email)</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter user email"
            value={formData.userName || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            isInvalid={validated && (!formData.userName || !isValidUser)}
          />
          <Form.Control.Feedback type="invalid">
            {validated && !isValidUser
              ? "Invalid user. Check the email."
              : "This field is required."}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="quizId">
          <Form.Label>Quiz ID</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter quiz ID"
            value={formData.quizId || ""}
            onChange={handleChange}
            onBlur={handleQuizidBlur}  // Validate when the field loses focus
            required
            isInvalid={validated && !formData.quizId}  // Show invalid only after the field is blurred
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="answeredDateTime">
          <Form.Label>Answered Date and Time</Form.Label>
          <Form.Control
            type="datetime-local"
            value={
              formData.answeredDateTime
                ? new Date(formData.answeredDateTime).toISOString().slice(0, 16)
                : ""
            }
            onChange={handleChange}
            required
            isInvalid={validated && !formData.answeredDateTime}
            min={availableDate ? new Date(availableDate).toISOString().slice(0, 16) : ""} // Set minimum date based on availableDate
          />
        </Form.Group>

        {/* Conditionally render dropdown or radio buttons based on choices */}
        <Form.Group className="mb-3" controlId="answer">
          <Form.Label>Answer</Form.Label>
          {choices.length > 0 && (
            <Form.Control
              as="select"
              value={formData.answer || ""}
              onChange={handleChange}
              required
            >
              <option value="">Select answer</option>
              {choices.map((choice, index) => (
                <option key={index} value={choice.value}>
                  {choice.label}
                </option>
              ))}
            </Form.Control>
          )}
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}
