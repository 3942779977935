import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import api from '../../../utils/api';
import format from 'date-fns/format';
import { parseIdFromLink } from '../../../utils/helpers';
import { PRE_QUAL, USER_TYPE } from '../../../utils/constants';
import FICO_REASONS from '../../../utils/constants/FICO_REASONS';
import { validateAndFormat } from '../../NewApplication/validatePreQual';
import cookies from '../../../utils/cookies';
const isTKLLive = cookies.processenv.REACT_APP_ENV !== 'api.dev'; //Dev env does NOT import TLK

export default function EditUserLoanInfo({ userDetails, setUserToActivate, parentError, newUser, setEmailToActivate }) {

  const { PROVINCES } = PRE_QUAL; //CONSTANTS for Form Options
  /* DRY VALIDATION, ie required fields, and allow save */
  // const [selectedUserType, setSelectedUserType] = useState('USER');
  const MONTHS = PRE_QUAL.MONTHS;
  const REQUIRED_FIELDS = [
    userDetails?.username,
    userDetails?.firstName,
    userDetails?.lastName,
    userDetails?.turnkeyLoanID,
    userDetails?.loanStartDate,
    userDetails?.loanPaymentFrequency,
    userDetails?.partner_id,
    userDetails?.maxCashback,
    userDetails?.monthlyPotentialCashBack,
    userDetails?.dobMonth,
    userDetails?.dobDay,
    userDetails?.dobYear,
    userDetails?.annualIncome,
    userDetails?.userType,
    userDetails?.street,
    userDetails?.city,
    userDetails?.prov,
    userDetails?.postal
  ];

  // console.log(REQUIRED_FIELDS)
  const [editMode, setEditMode] = useState(newUser || false);
  const [formData, setFormData] = useState({
    id: parseIdFromLink(userDetails?._links.self.href),

    firstName: userDetails?.firstName,
    lastName: userDetails?.lastName,
    username: userDetails?.username,
    creditLimit: userDetails?.creditLimit,
    turnkeyLoanID: userDetails?.turnkeyLoanID,

    loanStartDate: newUser ? cookies.getDate() : new Date(userDetails.loanStartDate),
    loanPaymentFrequency: userDetails?.loanPaymentFrequency,
    partner_id: userDetails?.partner_id,
    maxCashback: userDetails?.maxCashback,
    monthlyPotentialCashBack: userDetails?.monthlyPotentialCashBack,

    dobMonth: userDetails?.dobMonth,
    dobDay: userDetails?.dobDay,
    dobYear: userDetails?.dobYear,

    annualIncome: userDetails?.annualIncome,
    userType: userDetails?.userType,

    street: userDetails?.street,
    city: userDetails?.city,
    prov: userDetails?.prov,
    postal: userDetails?.postal

  });

  // console.log(formData)
  const [ficoScore, setFicoScore] = useState(null);
  const [utilization, setUtilization] = useState(null);
  const [wellnessScore, setWellnessScore] = useState(null);
  const [partners, setPartners] = useState(null);
  /* Wellness Score is a manual entry when a user is created, value is calculated and stored in hubspot, 
  this process is a to provide the user a start point. 
  Saved to its own end point /wellnessScores
  
  [x] Create, Need to enter and save a new users score
  - Can I create a record without answers (ie override score and display score only?)
  > Yes answers can be null, and score can be written directly
  - [>] Need to ensure that no other wellness records exist before saving... As create date time is used by the FE
  > Read /users/id/wellnessScores @ save? 
  > Deferrred - This section only handles wellness when a user is being created; existing users will be handled under UserViewer
  [>] Read, need to read and display a users First Wellness score
  [>] Edit, need to isolate editing to the first record for the user
  [>] Reset state after new...
  {
    id: dbRecord# or null
    score: 
  }
  */

  const [orgValues, setOrgValues] = useState(null);
  const [dataToUpload, setDataToUpload] = useState(null);
  const [allowActivation, setAllowActivation] = useState(REQUIRED_FIELDS.every((x) => x));
  const [status, setStatus] = useState({ loading: false, error: parentError || null });

  const updateWellnessScore = (e) => {
    let key = e.target.id;
    const value = e.target.value;
    if (key === 'wellnessScore') {
      if (isNaN(value) || Number(value) < 0 || Number(value) > 100) return;
      key = 'score';
    }
    if (key === 'wellnessScoreDate') key = 'scoreDate';
    setWellnessScore((p) => {
      return { ...p, [key]: value };
    });
  };

  const updateFicoScore = (e) => {
    let key = e.target.id;
    const value = e.target.value;
    if (key === 'initialFicoScore') {
      if (isNaN(value) || Number(value) > 850) return;
      key = 'score';
    }
    if (key === 'ficoPulledDate') key = 'pulledDate';
    if (key === 'ficoReason0') key = 'reason0';
    if (key === 'ficoReason1') key = 'reason1';
    setFicoScore((p) => {
      return { ...p, [key]: value };
    });
  };


  const updateUserType = (e) => {
    const value = e.target.value;
    // setSelectedUserType(value);
    formData.userType = value;
  };

  const updateUtilization = (e) => {
    const key = e.target.id;
    const value = e.target.value;
    if (isNaN(value) || value < 0) return;
    setUtilization((p) => {
      return { ...p, [key]: value };
    });
  };

  const handleEditUser = (e) => {
    // console.log(e);
    const key = e.target.id;
    let value = e.target.value;
    if (key === 'loanStartDate') {
      let newTime = new Date(value).toISOString().replace('00:00:00.000Z', '12:00:00.000Z');
      value = newTime;
    }
    if (key === 'username') {
      value = value.toLowerCase();
    }
    //Validate
    if (key === 'maxCashback' || key === 'monthlyPotentialCashBack') {
      if (isNaN(value)) return;
    }
    if (key === 'annualIncome' || key === 'creditLimit') {
      if (isNaN(value) || value < 0) return;
    }
    setFormData((p) => {
      return {
        ...p,
        [key]: value,
      };
    });

  };

  const handleDoB = (e, field) => {
    e.preventDefault();
    const value = e.target.value;
    let key = '';
    switch (field) {
      case 'month':
        key = 'dobMonth';
        break;
      case 'day':
        key = 'dobDay';
        break;
      case 'year':
        key = 'dobYear';
        break;
      default:
    }

    const formattedValue = validateAndFormat('income', key, value); //[] Borrowed from prequal, need to copy over locally to component
    if (formattedValue === undefined || formattedValue === null) return;

    key &&
      setFormData((p) => {
        return { ...p, [key]: formattedValue };
      });
  };

  const dateToISONoon = (dateString) => {
    if (!dateString) return null;
    return new Date(dateString).toISOString().replace('00:00:00.000Z', '12:00:00.000Z');
  };

  const submitUserUpdate = () => {

    console.log(formData?.loanStartDate)
    const apiFormattedDate = dateToISONoon(formData?.loanStartDate);

    //new Date(formData?.loanStartDate).toISOString().slice(0, 11) + '04:00:00.000Z';
    const upload = {
      user: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        username: formData.username,
        creditLimit: formData.creditLimit,
        turnkeyLoanID: formData.turnkeyLoanID,
        loanStartDate: apiFormattedDate,
        loanPaymentFrequency: formData.loanPaymentFrequency,
        partner_id: formData.partner_id,
        maxCashback: formData?.maxCashback,
        monthlyPotentialCashBack: formData?.monthlyPotentialCashBack,
        dobMonth: formData?.dobMonth,
        dobDay: formData?.dobDay,
        dobYear: formData?.dobYear,
        annualIncome: formData?.annualIncome,
        userType: formData?.userType,
        street: formData?.street,
        city: formData?.city,
        prov: formData?.prov,
        postal: formData?.postal
      },
    };

    if (wellnessScore)
      upload['wellnessScore'] = { score: wellnessScore.score, scoreDate: dateToISONoon(wellnessScore.scoreDate) };
    if (ficoScore)
      upload['ficoScore'] = {
        ficoScore: ficoScore.score,
        pulledDate: dateToISONoon(ficoScore.pulledDate),
        reasonCode1: ficoScore.reason0,
        reasonCode2: ficoScore.reason1,
      };
    if (utilization)
      upload['utilization'] = utilization
        ? { utilizedCredit: utilization?.initialUtilization, totalCreditAvailable: formData?.creditLimit }
        : null;
    //Validate
    //Remove empty values
    //Trim fields
    //lower case email
    Object.entries(upload.user).forEach(([k, v]) => {
      if (!v) delete upload[k];
      if (v && isNaN(v)) {
        upload[k] = v.trim();
      }
      if (k === 'username') {
        upload[k] = v.toLowerCase();
      }
    });
    setDataToUpload(upload);
  };

  useEffect(() => {
    const updateUserLoanInfo = async () => {
      setStatus({ loading: true, error: null, success: false });
      try {
        await api.userPatch(formData.id, dataToUpload.user);
        setDataToUpload(null);
        setEditMode(false);
        setOrgValues(null);
        setStatus({ loading: false, error: null, success: true });
      } catch (e) {
        setDataToUpload(null);
        setStatus({ loading: false, error: `${e}`, success: false });
      }
    };
    const createNewUser = async () => {
      setStatus({ loading: true, error: null, success: false });
      let userID = null;
      try {
        // alert(JSON.stringify(dataToUpload.user));
        const res = await api.createUserByUserType(dataToUpload.user);
        userID = parseIdFromLink(res.data._links.self.href);

        console.log(isTKLLive ? 'IMPORTING TKL...' : 'Bypassing TKL Import (dev env)');
        if (isTKLLive) {
          const tklRes = await api.userImportTKL(userID);
          if (tklRes.status !== 201) {
            throw Error(`TKL Loan Import Failed - ${tklRes.status}`);
          }
        }

        const userRes = await api.getUserData(userID);
        const userLink = userRes.data._links.self.href;

        //force showing the button - for abishek -- freemium type and employee feature
        setAllowActivation(true); //Only allow activate if this ID is present...

        if (isTKLLive) {
          console.log('VERIFY TKL...');
          const turnKeyID = userRes.data.turnkeyID;
          setAllowActivation(!!turnKeyID); //Only allow activate if this ID is present...
          if (!turnKeyID) {
            throw Error('TKL ID was not generated');
          }
        }

        console.log('Creating wellnessScore record...');
        if (dataToUpload.wellnessScore?.score) {
          //Create the wellness record
          const wellnessScoreRes = await api.saveWellnessSurvey({
            ...dataToUpload.wellnessScore,
            user: userLink,
          });
          const wellnessRecordID = parseIdFromLink(wellnessScoreRes?.data._links.self.href);
          console.log('wellnessRecord (Created)', wellnessRecordID);
        }
        console.log('Creating FICO record...');
        if (dataToUpload.ficoScore) {
          //Create the wellness record
          const ficoScoreRes = await api.saveFICOScore({
            ...dataToUpload.ficoScore,
            user: userLink,
          });
          const ficoRecordID = parseIdFromLink(ficoScoreRes?.data._links.self.href);
          console.log('ficoRecordID (Created)', ficoRecordID);
        }
        console.log('Creating Utilization record...');
        if (dataToUpload.utilization) {
          //Create the wellness record
          const utilizationRes = await api.saveFinData({
            ...dataToUpload.utilization,
            user: userLink,
          });
          console.log('utilizationRes', utilizationRes);
          const UtilizationRecordID = parseIdFromLink(utilizationRes?.data._links.self.href);
          console.log('UtilizationRecordID (Created)', UtilizationRecordID);
        }
        setEmailToActivate(userRes.data?.username);
        setDataToUpload(null);
        setEditMode(false);
        setOrgValues(null);
        setStatus({ loading: false, error: null, success: true });
        //Validate and activate Send Active Btn
      } catch (e) {
        setDataToUpload(null);
        const urlPath = e.response?.data.path;
        const userAlreadyExists = e.response?.data?.message?.[0]?.includes('already exists');
        const tklError = urlPath?.includes('TKLImport');
        const errorMsg = `${tklError ? `TKL Import Error.  ${e}` : userAlreadyExists ? 'User Already Exists' : e}`; //: userAlreadyExists ? 'User Already Exists'

        console.log('Error: cleaning up...');
        userID && (await api.deleteUser(userID));

        setStatus({
          loading: false,
          error: errorMsg,
          success: false,
        });
      }
    };
    !newUser && dataToUpload && updateUserLoanInfo();
    newUser && dataToUpload && createNewUser();
  }, [newUser, dataToUpload, formData, setEmailToActivate]);

  useEffect(() => {
    const requiredFieldsToActivate = [
      userDetails?.username,
      userDetails?.firstName,
      userDetails?.lastName,
      userDetails?.turnkeyLoanID,
    ];
    if (requiredFieldsToActivate.every((x) => x)) {
      setAllowActivation(true);
    }
  }, [userDetails]);
  const fetchAllPartners = async () => {
    try {
      const rawData = await api.fetchAllPartners();

      const partners = rawData.data.filter((x) => x);
      setPartners(partners);
      // alert(JSON.stringify(partners))
    } catch (e) {
      console.error(e);
    }
  };


  useEffect(() => {
    fetchAllPartners();
  }, []);
  const REQUIRED_TO_SAVE = [
    formData?.username,
    formData?.firstName,
    formData?.lastName,
    formData?.turnkeyLoanID,
    formData?.loanStartDate,
    formData?.loanPaymentFrequency,
    formData?.partner_id,
    formData?.maxCashback,
    formData?.monthlyPotentialCashBack,
    formData?.dobMonth,
    formData?.dobDay,
    formData?.dobYear,
    formData?.annualIncome,
    formData?.userType,
    formData?.street,
    formData?.city,
    formData?.prov,
    formData?.postal,
    // wellnessScore,
    // ficoScore,
    // utilization,
  ];
  const allowSave = REQUIRED_TO_SAVE.every((x) => x);
  const fetchPrefill = () => {

    try {
      let data = {
        "username": formData.username,
        "turnkeyLoanID": formData.turnkeyLoanID
      };
      // let data = {
      //   "username": "billafillabong@outlook.com",
      //   "turnkeyLoanID": "7525"
      // };
      api.getPrefillData(data).then((res) => {
        // console.log(res)
        if (res.status !== 200) {
          console.error('Error fetching prefill data');
          return;
        }
        if (!res?.data?.turnkeyLoanID) {
          console.error('Error fetching prefill data');
          return;
        }
        const data = res.data;
        // alert(JSON.stringify(data))
        setFormData({
          firstName: data?.firstName,
          lastName: data?.lastName,
          username: data?.username,
          creditLimit: data?.creditLimit,
          turnkeyLoanID: data?.turnkeyLoanID,
          loanStartDate: data?.loanStartDate,
          loanPaymentFrequency: data?.loanPaymentFrequency,
          partner_id: data?.partner_id,
          maxCashback: data?.maxCashback,
          monthlyPotentialCashBack: data?.monthlyPotentialCashBack,
          dobMonth: data?.dobMonth,
          dobDay: data?.dobDay,
          dobYear: data?.dobYear,
          annualIncome: data?.annualIncome,
          userType: data?.userType,
          street: data?.street,
          city: data?.city,
          prov: data?.prov,
          postal: data?.postal
        });


      }).catch((e) => {
        console.error(e);
      });


    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{newUser ? 'New User Details' : `User ${formData.id} Details`}</Accordion.Header>
          <Accordion.Body>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Form.Group className="mb-3" controlId="username">
                <Form.Label>Username (Email)</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="User's email"
                  onChange={handleEditUser}
                  value={formData.username}
                  disabled={status.loading || !editMode}
                  required
                />
                <Form.Text className="text-muted">
                  {isTKLLive
                    ? 'Must match TKL'
                    : 'DEV ENVIRONMENT: You may make up any unique email. Note: When live this must match TKL'}
                </Form.Text>
              </Form.Group>


              <Form.Group className="mb-3" controlId="turnkeyLoanID">
                <Form.Label>Turnkey Loan ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="User's Loan ID"
                  onChange={handleEditUser}
                  value={formData.turnkeyLoanID}
                  disabled={status.loading || !editMode}
                />
              </Form.Group>

              <Button variant="primary" onClick={fetchPrefill} disabled={!formData.turnkeyLoanID || !formData.username}>
                Fetch Details
              </Button>

              <Form.Group className="mb-3" controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="User's firstname"
                  onChange={handleEditUser}
                  value={formData.firstName}
                  disabled={status.loading || !editMode}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="User's lastname"
                  onChange={handleEditUser}
                  value={formData.lastName}
                  disabled={status.loading || !editMode}
                  required
                />
              </Form.Group>



              <Form.Group className="mb-3" controlId="street">
                <Form.Label>Street</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={`Street`}
                  onChange={handleEditUser}
                  value={formData.street}
                  disabled={status.loading || !editMode}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={`City`}
                  onChange={handleEditUser}
                  value={formData.city}
                  disabled={status.loading || !editMode}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="prov">
                <Form.Label>Province</Form.Label>
                <Form.Select
                  aria-label="Province"
                  placeholder={`Province`}
                  onChange={handleEditUser}
                  value={formData.prov}
                  disabled={status.loading || !editMode}
                  required
                >
                  {PROVINCES.map((i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </Form.Select>
                {/* {(formData[`${residenceType}Province`] === 'Yukon' || formData[`${residenceType}Province`] === 'Quebec' || formData[`${residenceType}Province`] === 'Northwest Territories') ? (<> <br></br>
          <Alert key={'danger'} variant={'danger'}>
            We're sorry, we are not yet able to lend in your province. Please check back often as we are working to lend across Canada.
          </Alert> </>) : ''} */}

              </Form.Group>

              <Form.Group className="mb-3" controlId="postal">
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={`Postal code`}
                  onChange={handleEditUser}
                  value={formData.postal}
                  disabled={status.loading || !editMode}
                  required
                />
              </Form.Group>


              <Form.Group className="mb-3">
                <Form.Label>Date of Birth</Form.Label>
                <span style={{ display: 'flex' }}>
                  <Form.Select
                    id="dobMonth"
                    className="mr-8"
                    aria-label="Month of Birth"
                    onChange={(e) => handleDoB(e, 'month')}
                    value={formData.dobMonth}
                    // isInvalid={validated && !formData.dobMonth}
                    required
                    readOnly={status.loading}
                    disabled={status.loading || !editMode}
                  >
                    {MONTHS.map((i) => (
                      <option key={`dobM-${i}`} value={i}>
                        {i}
                      </option>
                    ))}
                  </Form.Select>

                  <Form.Control
                    id="dobDay"
                    className="mr-8"
                    type="text"
                    aria-label="Day of Birth"
                    placeholder="DD"
                    min={1}
                    max={31}
                    onChange={(e) => handleDoB(e, 'day')}
                    value={formData.dobDay}
                    // isInvalid={validated && !formData.dobDay}
                    required
                    readOnly={status.loading}
                    disabled={status.loading || !editMode}
                  />

                  <Form.Control
                    id="dobYear"
                    type="text"
                    aria-label="Year of Birth"
                    placeholder="YYYY"
                    // max={2022 - 18}
                    // min={1901}
                    onChange={(e) => handleDoB(e, 'year')}
                    value={formData.dobYear}
                    // isInvalid={validated && (formData.dobYear.length < 4 || !formData.dobYear)}
                    required
                    readOnly={status.loading}
                    disabled={status.loading || !editMode}
                  />

                  {/* Should year have min of this year - 18 or 19, need to account for just turned 18... */}
                </span>
              </Form.Group>

              <Form.Group className="mb-3" controlId="annualIncome">
                <Form.Label>Annual Income</Form.Label>
                <Form.Control
                  type="text"
                  // min="0"
                  placeholder="User's Annual Income"
                  onChange={handleEditUser}
                  value={formData?.annualIncome}
                  disabled={status.loading || !editMode}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="creditLimit">
                <Form.Label>Credit Limit</Form.Label>
                <Form.Control
                  type="text"
                  // min="0"
                  placeholder="User's Credit Limit"
                  onChange={handleEditUser}
                  value={formData?.creditLimit}
                  disabled={status.loading || !editMode}
                />
              </Form.Group>


              <div>
                <hr />
                {/* <h3>User Cashback Settings & Loan Start Date</h3> */}


                <Form.Group className="mb-3" controlId="loanStartDate">
                  <Form.Label>Loan Start Date (EST)</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder=""
                    onChange={handleEditUser}
                    value={`${format(new Date(formData.loanStartDate), 'yyyy-MM-dd')}`}
                    disabled={status.loading || !editMode}
                  />
                  {/* <Form.Text className="text-muted">Users Parachute account must be setup first.</Form.Text> */}
                </Form.Group>

                <Form.Group className="mb-3" controlId="loanPaymentFrequency">
                  <Form.Label>Loan Payment Frequency</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Enter email"
                    onChange={handleEditUser}
                    disabled={status.loading || !editMode}
                    value={formData?.loanPaymentFrequency}
                  />
                  {/* <Form.Text className="text-muted">Users Parachute account must be setup first.</Form.Text> */}
                </Form.Group>
                {partners && (<Form.Group className="mb-3" controlId="partner_id">
                  <Form.Label>Partner</Form.Label>

                  <Form.Select
                    aria-label="Partner"
                    placeholder={`Partner`}
                    onChange={handleEditUser}
                    value={formData.partner_id}
                    disabled={status.loading || !editMode}
                    required
                  >
                    {partners.map((i) => (
                      <option key={i?.id} value={i?.id}>
                        {i?.name}
                      </option>
                    ))}
                  </Form.Select>
                   </Form.Group>)}


                <Form.Group className="mb-3" controlId="maxCashback">
                  <Form.Label>Max Cashback ($)</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleEditUser}
                    disabled={status.loading || !editMode}
                    value={formData?.maxCashback}
                  />
                  <Form.Text className="text-muted">Maximum / Total Cashback $ that the user can earn</Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="monthlyPotentialCashBack">
                  <Form.Label>Monthly Cashback (Potential $/month)</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Enter email"
                    onChange={handleEditUser}
                    disabled={status.loading || !editMode}
                    value={formData?.monthlyPotentialCashBack}
                  />
                </Form.Group>

                {newUser && (
                  /* =====================================To edit wellness score for existing users use User Viewer =============*/
                  <>
                    <hr />
                    <Form.Group className="mb-3" controlId="initialUtilization">
                      <Form.Label>Credit Utilized $</Form.Label>
                      <Form.Control
                        type="text"
                        // min="0"
                        // max={formData.creditLimit}
                        onChange={updateUtilization}
                        disabled={status.loading || !editMode}
                        value={utilization?.initialUtilization}
                      />
                      <Form.Text className="text-muted">Used with credit limit, to calc credit utilization.</Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="wellnessScore">
                      <Form.Label>Wellness Score (Initial)</Form.Label>
                      <Form.Control
                        type="text"
                        // max="100"
                        // min="0"
                        onChange={updateWellnessScore}
                        disabled={status.loading || !editMode}
                        value={wellnessScore?.score}
                      />
                      <Form.Text className="text-muted">
                        Users pre-qual wellness score (From Hubspot) - 0 to 100
                      </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="wellnessScoreDate">
                      <Form.Label>Wellness Score Date</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={updateWellnessScore}
                        disabled={status.loading || !editMode}
                        value={wellnessScore?.wellnessScoreDate}
                      />
                      <Form.Text className="text-muted">Users pre-qual wellness score (From Hubspot)</Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="initialFicoScore">
                      <Form.Label>FICO Score (Initial)</Form.Label>
                      <Form.Control
                        type="text"
                        // min="300"
                        // max="850"
                        onChange={updateFicoScore}
                        disabled={status.loading || !editMode}
                        value={ficoScore?.score}
                      />
                      <Form.Text className="text-muted">Users FICO score from TKL</Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="ficoPulledDate">
                      <Form.Label>FICO Score Pulled Date</Form.Label>
                      <Form.Control
                        type="date"
                        onChange={updateFicoScore}
                        disabled={status.loading || !editMode}
                        value={ficoScore?.ficoPulledDate}
                      />
                      <Form.Text className="text-muted">Users pre-qual wellness score (From Hubspot)</Form.Text>
                    </Form.Group>

                    <Form.Label>User Type</Form.Label>
                    <Form.Group className="mb-3" controlId="userType">
                      <Form.Select
                        aria-label="User Type"
                        disabled={status.loading || !editMode}
                        onChange={updateUserType}
                        value={formData?.userType}
                      >
                        <option value={null || undefined}></option>
                        {Object.values(USER_TYPE)?.map((i) => (
                          <option key={`userType-${i}`} value={i}>
                            {i === 'USER' ? 'CUSTOMER' : i}
                          </option>
                        ))}

                      </Form.Select>
                    </Form.Group>


                    <Form.Label>Top 2 FICO Reason codes</Form.Label>
                    <Form.Group className="mb-3" controlId="ficoReason0">
                      <Form.Select
                        aria-label="FICO Reason Codes #1"
                        disabled={status.loading || !editMode}
                        onChange={updateFicoScore}
                        value={ficoScore?.reason0}
                      >
                        <option value={null || undefined}></option>
                        {Object.values(FICO_REASONS)
                          .sort((x, y) => x.code.localeCompare(y.code))
                          .map((r, i) => {
                            return <option key={`reson1${i}`} value={r.code}>{`${r.code} - ${r.statement}`}</option>;
                          })}
                      </Form.Select>
                    </Form.Group>



                    <Form.Group className="mb-3" controlId="ficoReason1">
                      <Form.Select
                        aria-label="FICO Reason Codes #2"
                        disabled={status.loading || !editMode || !ficoScore?.reason0}
                        onChange={updateFicoScore}
                        value={ficoScore?.reason1}
                      >
                        <option value={null || undefined}></option>
                        {Object.values(FICO_REASONS)
                          .filter((x) => x.code !== ficoScore?.reason0)
                          .sort((x, y) => x.code.localeCompare(y.code))
                          .map((r, i) => {
                            return <option key={`reson1${i}`} value={r.code}>{`${r.code} - ${r.statement}`}</option>;
                          })}
                      </Form.Select>
                      <Form.Text className="text-muted">It is possible to have no reasons</Form.Text>
                    </Form.Group>
                  </>
                )}

                {!newUser && (
                  <p className="muted">
                    *Wellness Scores: To Edit an existing users wellness scores, use the 'User Viewer'
                  </p>
                )}

                <div>
                  {!newUser && !editMode && (
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={() => {
                        setEditMode(true);
                        setOrgValues(formData);
                        setStatus((p) => {
                          return { ...p, success: null };
                        });
                      }}
                    >
                      {status.loading ? 'Saving...' : 'Edit'}
                    </Button>
                  )}
                  {editMode && (
                    <>
                      {status.error && <p className="error">{status.error}</p>}
                      {status.error?.includes('User was created') && <p className="error"></p>}
                      {/* disabled={!allowSave} */}
                      <Button variant="primary" type="submit" onClick={submitUserUpdate}>
                        {status.loading ? 'Saving...' : 'Save'}
                      </Button>

                      {!newUser && (
                        <Button
                          variant="primary"
                          type="submit"
                          onClick={() => {
                            setEditMode(false);
                            setFormData(orgValues);
                            setOrgValues(null);
                          }}
                        >
                          {'Cancel'}
                        </Button>
                      )}
                    </>
                  )}
                  {status.success && <p>{newUser ? 'Success: User has been created' : 'Update Successful'}</p>}
                </div>
              </div>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Button disabled={!allowActivation} onClick={() => setUserToActivate({ email: formData?.username })}>
        Send Activation Email
      </Button>
      {parentError && <p className="error">{parentError}</p>}
    </div>
  );
}
