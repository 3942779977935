/* 
Color Bands
< 35% Red
35 - 50% Orange
> 50% Green
*/

import React from 'react';

import CardContainer from '../CardContainer';
import CardTitleWTip from '../CardTitleWTip';
import UtilizationProgressCircle from './ProgressCircle/UtilizationProgressCircle';
import './Index.scss';
import { formatDateForDisplay, formatToDollar } from '../../utils/helpers';
import ErrorNotification from '../ErrorNotification';

function UtilizationCard({ data }) {
  const progress = data?.utilizationPercent && (data?.utilizationPercent * 100).toFixed(0);
  const totalCredit = data?.totalCredit;
  const creditUsed = data?.creditUtilizedDollars;
  const recordDate = data?.createDateTime;

  const showError = !data || isNaN(data?.utilizationPercent);

  const isNumber = (input) => {
    if (input === undefined || input === null) return null;
    if (isNaN(input)) return null;
    return true;
  };
  return (
    <CardContainer id="utilizationCard">
      <CardTitleWTip tipObject={null}>Credit utilization</CardTitleWTip>
      <div className="v-dividers" style={{ margin: '24px 0px' }}>
        <div className="currInfo" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <UtilizationProgressCircle currentScore={progress} bracketDescription={null} />

          <span className="avgScoreInfo" style={{ display: 'flex', maxWidth: 336 - 160 }}>
            <h3>
              Total credit utilized:
              <div className="muted">
                {isNumber(creditUsed) && isNumber(totalCredit)
                  ? `$${formatToDollar(creditUsed, { noDecimal: true })} / $${formatToDollar(totalCredit, {
                      noDecimal: true,
                    })}`
                  : 'N/A'}
              </div>
            </h3>
          </span>
        </div>
        <div className="infoLine" style={{ margin: '24px 0px' }}>
          {/* <h4>Based on data from CFPB</h4> */}
          <p className="muted">
            Credit utilization is the amount of credit you’ve spent versus the credit you have available. A low credit
            utilization can positively impact your credit score, whereas a high credit utilization can negatively affect
            your credit score.
          </p>
        </div>
        <div className="infoLine">
          {/* <h4>Previous score</h4> */}
          <p className="muted">
            Best practice is to aim for credit utilization below 35% as much as possible. To qualify for Parachute's
            cash-back rewards you must maintain utilization of 50% or below.
          </p>
        </div>
      </div>
      {showError && <ErrorNotification>We cannot determine credit utilization.</ErrorNotification>}
      <div className="infoLine" style={{ marginTop: '24px' }}>
        {/* <h4>Previous score</h4> */}
        <p className="muted">{`Updated as of ${
          recordDate ? formatDateForDisplay(recordDate, { numeric: true }) : 'N/A'
        }`}</p>
      </div>
    </CardContainer>
  );
}

export default UtilizationCard;
