import React from 'react';
// import Button from 'react-bootstrap/Button';
import WideBtn from '../../WideBtn';
import './NextQuizTile.scss';

export default function NextQuizTile({ goToNextQuiz, availQuizCount }) {
  const quizSpelling = availQuizCount === 1 ? 'quiz' : 'quizzes';

  return (
    <div className="nextQuizBlock">
      {/* <Button className="nextQuizBtn quizBtn" variant="success" onClick={goToNextQuiz}>
        See next quiz
      </Button> */}
      <WideBtn className="nextQuizBtn" onClick={goToNextQuiz}>
        See next quiz
      </WideBtn>
      <h3 className="quizCount">{`${availQuizCount} available ${quizSpelling}`}</h3>
    </div>
  );
}
