/* 3 States, 
- not ready - Svr res ?
- ready - svr res ?
- not approved - svr res ? > Go Back Home, same as "Under Review"

*/

import React from "react";
import CardContainer from "../CardContainer";
// import WideBtn from "../WideBtn";
import icon from "../../assets/Logo_Icon.svg";
// import Form from "react-bootstrap/Form";
// import { LINKS } from '../../utils/constants';
import TextButton from "../TextButton";
import Confetti from 'react-confetti'

import useWindowSize from 'react-use/lib/useWindowSize'
// const contactTime = [
//   { value: "8:30am-12pm", label: "8:30am-12pmEST" },
//   { value: "12pm-2pmEST", label: "12pm-2pmEST" },
//   { value: "2pm-5pmEST", label: "2pm-5pmEST" },
// ];
export default function SoftResults({
  results,
  navForm,
  goToHome,
  updatePreQualState,
}) {

  const { width, height } = useWindowSize()
  const mode = results?.mode || "pending"; // approved, pending, notApproved
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (mode === "notApproved") return goToHome();
  //   navForm("next");
  // };
  // const handleInputChange = (e) => {
  //   const { value } = e.target;
  //   updatePreQualState("preferredContactTime", e.target.value);
  // };

  const Pending = (
    <>
      <Confetti
        width={width}
        height={height}
      />
      <div className="titleBlock">
        {icon && <img className="titleBlock--icon" src={icon} alt={icon} />}
        <h3 className="titleBlock--title">
          Congratulations, everything looks good so far you’ve been pre-qualified.<br></br>
        </h3>
        {/* <p className="muted">
        Your pre-qualification is based on your FICO® <br></br>Score 10, based on TransUnion Data. <br></br>This does not effect your credit score.
        </p> */}
      </div>

      <div
        style={{
          marginTop: "12px",
          marginBottom: "12px",
          backgroundColor: "#0000001F",
          height: "1px",
        }}
      />
      <div
        style={{
          padding: "12px",
          backgroundColor: "#F9FAFB",
          borderRadius: "12px",
        }}
      >
        <p className="muted">
          <strong>Let’s do this!</strong>
        </p>
        <h6 className="font-weight-bold text-justify">
       Please check your inbox to see if you've been <br></br>pre-approved.
        </h6>
        <br></br>
        <p className="muted titleBlock--body" style={{ margin: 0 }}>
          If you have any questions, or need additional support, please text or
          call us at <a href="tel:8446702403">(844) 670-2403</a> or email us at {""}
          <a href="email:contactus@myparachute.co">contactus@myparachute.co</a>
        </p>
      </div>
      <div
        style={{
          marginTop: "12px",
          marginBottom: "8px",
          backgroundColor: "#0000001F",
          height: "1px",
        }}
      />
      {/* <h3 className="titleBlock--title">Preferred time to be Contacted: </h3> */}
      {/* <Form.Group controlId="contact_time">
        <Form.Label>Preferred time to be Contacted:</Form.Label>
        <Form.Select
          onChange={(e) =>
            updatePreQualState("preferredTime", e.target.value)
          }
          value={contactTime}
        >
          {contactTime?.map((item, i) => (
            <option key={`e-role-opt-${i}`} value={item?.value}>
              {item?.label}
            </option>
          ))}
        </Form.Select>
      </Form.Group> */}
      {/* <div
        style={{
          marginTop: "12px",
          marginBottom: "8px",
          backgroundColor: "#0000001F",
          height: "1px",
        }}
      /> */}
    </>
  );

  /*  const Approved = (
    <>
      <div className="titleBlock">
        {icon && <img className="titleBlock--icon" src={icon} alt={icon} />}
        <h3 className="titleBlock--title">Fantastic! You’ve been pre-qualified for:</h3>
        <p>{`PreQUAL AMOUNT... label btn ${results?.amount}`}</p>
        <p className="muted">Pre-qualification based on information you provided.</p>
      </div>

      <div className="vertical-border">
        <h3 className="titleBlock--title">What’s next?</h3>
        <p className="muted">
          Check your inbox for an email from info@myparachute.ca (If you don’t see it, check your spam folder).
        </p>
        <p className="muted titleBlock--body">
          If you have any questions, or need additional support, please text or call us at{' '}
          <a href="tel:8446702403">(844) 670-2403</a> or email us at{' '}
          <a href="email:contactus@myparachute.co">contactus@myparachute.co</a>
        </p>
      </div>

      <h3 className="titleBlock--subtitle">
        To get your application approved faster, please click continue below, and fill in the additional information.
      </h3>
    </>
  );
 */
  /*  const NotApproved = (
    <>
      <div className="titleBlock">
        {icon && <img className="titleBlock--icon" src={icon} alt={icon} />}
        <h3 className="titleBlock--title">
          Thank you. Your application is under review, and we’ll follow up with you shortly.
        </h3>
      </div>

      <p className="muted">
        Our loan specialists are reviewing the details of your application to determine if you qualify for a loan from
        Parachute. We’ll let you know once we reach a decision.
      </p>

      <p className="muted titleBlock--body">
        If you have any questions, or need additional support, please text or call us at{' '}
        <a href="tel:8446702403">(844) 670-2403</a> or email us at{' '}
        <a href="email:contactus@myparachute.co">contactus@myparachute.co</a>
      </p>
      <hr />
    </>
  ); */
  return (
    <>
      <CardContainer>
        <div>
          {mode === "pending" && Pending}
          {/* {mode === 'approved' && Approved} */}
          {/* {mode === 'notApproved' && NotApproved} */}
          {/* <WideBtn
            onClick={handleSubmit}
            style={{ marginTop: "16px" }}
            id="resultsNext"
          >
            {mode === "notApproved"
              ? "Go back home"
              : "Continue for Faster Reply"}
          </WideBtn> */}


          {/* <WideBtn
            onClick={handleSubmit}
            style={{ marginTop: "16px" }}
            id="resultsNext"
          >
              {mode === "notApproved"
              ? "Go back home"
              : "Continue"}
          </WideBtn> */}
          <br />

          <TextButton onClick={goToHome} style={{ marginTop: 0 }}>
            Go back home
          </TextButton>
        </div>
      </CardContainer>
    </>
  );
}
