import React from 'react';
import { Link } from 'react-router-dom';

import { useState } from 'react';
import logoFull from '../assets/Logo_Full.svg';
import logoFullDark from '../assets/Logo_Full_Dark.svg';
import cookies from '../utils/cookies';

export default function LogoLinkFooter({ className, overrideLink, dark }) {

  const savedUserID = cookies.getUserID();
  
  function handleLinkClick() {
    if (overrideLink) {
      // If overrideLink is provided, open that URL
      window.location.href = overrideLink;
    } else if (savedUserID) {
      // If savedUserID exists, navigate to the root path
      window.location.href = '/';
    } else {
      // Otherwise, navigate to the external URL
      window.location.href = '/';
    }
  }
  console.log('dark', dark);
  let classToUse = ['logo'];
  if (className) classToUse.push(className);
  return (
    <>
     <a className={classToUse.join(' ')} onClick={handleLinkClick} style={{ cursor: 'pointer' }}>
        <img style={{ width: '150px' }} src={dark ? logoFullDark : logoFull} alt="Parachute.cash" />
       
      </a>

    </>


  );
}
