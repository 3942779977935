import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import api from '../../../../utils/api';
import { formatDateForDisplay, limitDecimals, parseIdFromLink } from '../../../../utils/helpers';

export default function UserActivities({ userToView, index }) {
  const { id } = userToView;

  const [userData, setUserData] = useState(null);
  const [editFields, setEditFields] = useState([]); //Array of field Indexes that are in edit mode

  // const [cashbackSummary, setCashbackSummary] = useState(null);
  // const [cashbackRecords, setCashbackRecords] = useState(null);
  // const getCashbackRecords = async () => {
  //   //Mood Responses
  //   api.getCashbackRecords(id).then((res) => {
  //     if (res?.data) {
  //       setCashbackSummary(res.data);
  //       setCashbackRecords(res.data?.cashbackRecordPage?.content);
  //     }
  //   }).catch((e) => {
  //     console.log(e);
  //   });

  // };
  useEffect(() => {
    const getUserActivity = async () => {
      //Mood Responses
      const rawCBRecords = await api.getUsersCBRecords(id);
      const userCBRecords = rawCBRecords.data._embedded.cashbackRecords.map((x) => {
        const changeISOStringDate = (ISODateString) => {
          const arr = ISODateString.split('');
          arr[8] = '1';
          arr[9] = '5';
          return arr.join('');
        };
        const midMonthDate = changeISOStringDate(x.appliedDate);

        /* 
          Server CB Records puts the appliedDate the 00:00Z of the first date, this causes an error with date objects / timezones
          IE 2022-08-01T00:00Z is 2022-07-31T20:00 EST, so it treats the date as the prev month
          Above changes the date to the 15th IOT ensure the applied month is displayed correctly
          */
        return {
          id: parseIdFromLink(x?._links.self.href),
          cashbackAmount: x.cashbackAmount,
          appliedDate: midMonthDate ?? 'N/A',
          reason: x.reason ? x.reason : null
        };
      });
      console.log(userCBRecords);
      setUserData(userCBRecords?.sort((y, x) => x.appliedDate.localeCompare(y.appliedDate)));
    };
    getUserActivity();
    //eslint-disable-next-line

    // getCashbackRecords();
  }, []);

  const handleChange = (e) => {
    const recordToEdit = e.target.id;
    const value = e.target.value;
    const oldState = userData;

    if (isNaN(value)) return;
    /* State needs to be a string (limited to #s, then convert to Number on validation and save?) */
    //Find index of record to edit
    const indexToEdit = oldState.findIndex((x) => x.id === recordToEdit);
    oldState[indexToEdit] = {
      id: recordToEdit,
      appliedDate: oldState[indexToEdit]['appliedDate'],
      cashbackAmount: limitDecimals(value),
      reason: oldState[indexToEdit]['reason'] ? oldState[indexToEdit]['reason'] : ''
    };
    //Update state
    setUserData([...oldState]);
  };

  // const handleGoalCashBackAmountChange = (e) => {

  //   const recordToEdit = e.target.id;
  //   let value = parseFloat(e.target.value);
  //   //convert value to number or decimal value not a string

  //   const oldState = cashbackRecords;

  //   if (isNaN(value)) {
  //     value=0;
  //   };

  //   const indexToEdit = oldState.findIndex((x) => x.id.toString() === recordToEdit);
  //   oldState[indexToEdit] = {
  //     id: recordToEdit,
  //     appliedDate: oldState[indexToEdit]['appliedDate'],
  //     goalCashBackAmount: parseFloat(value || 0),
  //     quizCashBackAmount: parseFloat(oldState[indexToEdit]['quizCashBackAmount'] || 0),
  //     cashbackAmount: parseFloat(oldState[indexToEdit]['cashbackAmount'] || 0) + parseFloat(value || 0) + parseFloat(oldState[indexToEdit]['quizCashBackAmount'] || 0),
  //     reason: oldState[indexToEdit]['reason'] ? oldState[indexToEdit]['reason'] : ''
  //   };
  //   console.log(oldState);
  //   setCashbackRecords([...oldState]);
  // };

  // const handleQuizCashBackAmountChange = (e) => {

  //   const recordToEdit = e.target.id;
  //   let value = parseFloat(e.target.value);
  //   const oldState = cashbackRecords;

  //   if (isNaN(value)) {
  //     value=0;
  //   };

  //   const indexToEdit = oldState.findIndex((x) => x.id.toString() === recordToEdit);
  //   oldState[indexToEdit] = {
  //     id: recordToEdit,
  //     appliedDate: oldState[indexToEdit]['appliedDate'],

  //     goalCashBackAmount: parseFloat(oldState[indexToEdit]['goalCashBackAmount'] || 0),
  //     quizCashBackAmount: parseFloat(value || 0),
  //     cashbackAmount: parseFloat(oldState[indexToEdit]['cashbackAmount'] || 0) + parseFloat(oldState[indexToEdit]['goalCashBackAmount'] || 0) + parseFloat(value || 0),

  //     reason: oldState[indexToEdit]['reason'] ? oldState[indexToEdit]['reason'] : ''
  //   };

  //   console.log(oldState);
  //   setCashbackRecords([...oldState]);
  // };

  const handleReasonChange = (e) => {
    const recordToEdit = e.target.id;
    const value = e.target.value;
    const oldState = userData;
    //Find index of record to edit
    const indexToEdit = oldState.findIndex((x) => x.id === recordToEdit);
    oldState[indexToEdit] = {
      id: recordToEdit,
      appliedDate: oldState[indexToEdit]['appliedDate'],
      cashbackAmount: oldState[indexToEdit]['cashbackAmount'],
      reason: value
    };
    //Update state
    setUserData([...oldState]);
  };
  /* Should only update after save of state / api... */
  const totalCBEarned = userData
    ?.map((x) => Number(x.cashbackAmount))
    .reduce((a, b) => a + b, 0)
    .toFixed(2);

  return (
    <>
      <Accordion.Item eventKey={index}>
        <Accordion.Header>Cashback Records - {userData?.length ?? '0'}</Accordion.Header>
        <Accordion.Body>
          <Table striped bordered hover size="sm">
            <caption>
              *Cashback Records are only created after an assessment is completed.
              <br />
              If there is a 'gap' because a user missed a month(s); it will be filled in the next time a user completes
              a self assessment.
            </caption>
            <thead>
              <tr>
                <th>Record ID</th>
                <th>Applicable Month</th>
                <th>{`Cashback amount - Total $${totalCBEarned}`}</th>
                <th>{`Goal cash back`}</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {userData?.map((q, i) => {
                return (
                  <tr key={`userInspResponse-${i}`}>
                    <td>{q?.id}</td>
                    <td>{formatDateForDisplay(q?.appliedDate, { monthYear: true })}</td>
                    <td>
                      {/* <input>{`$${q?.cashbackAmount}`}</input> */}
                      <Form.Group className="mb-3" controlId={q.id} onChange={handleChange}>
                        <Form.Control type="text" disabled={!editFields.includes(i)} value={q.cashbackAmount} />
                      </Form.Group>
                    </td>
                    <td>
                     
                    {/* <pre>{JSON.stringify(q, null, 2)}</pre> */}
                      <Form.Group className="mb-3" controlId={q.id} onChange={handleReasonChange}>
                      <Form.Control type="text" disabled={!editFields.includes(i)} value={q.reason} />
                      </Form.Group>
                    </td>
                    <td>
                      {editFields.includes(i) ? (
                        <>
 {/* <pre>{JSON.stringify(q, null, 2)}</pre> */}
                          <Button
                            onClick={async () => {
                              try {
                                if (isNaN(q.cashbackAmount)) return; // should throw error..., also should be useEffect
                                if (!q?.reason) return;
                                await api.updateCashbackRecord(q?.id, { cashbackAmount: q.cashbackAmount ,reason:q?.reason});
                              } catch (e) {
                                console.log(e);
                              }
                              //Remove that index from EditFields rather than everything
                              const newFields = [...editFields].filter((x) => x !== i);
                              setEditFields(newFields);
                            }}
                          >
                            Save
                          </Button>
                          <Button
                            variant="outline-primary"
                            onClick={(x) => {
                              //Remove that index from EditFields rather than everything
                              const newFields = [...editFields].filter((x) => x !== i);
                              setEditFields(newFields);
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => {
                            setEditFields([...editFields, i]);
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
}
