import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// import Card from 'react-bootstrap/Card';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheck } from '@fortawesome/free-solid-svg-icons';
// import { Editor } from '@tinymce/tinymce-react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import api from '../utils/api';
import cookies from '../utils/cookies';
import Spinner from 'react-bootstrap/Spinner';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { set } from 'lodash';
export default function CreateArticleView() {
  const [editorData, setEditorData] = useState(EditorState.createEmpty());

  const defaultCategory = {
    name: '',
    description: '',
    tags: ''
  };
  const defaultTopic = {
    name: '',
    description: '',
    tags: '',
    categoryId: '',
    author: '',
    createdDate: '',
    image: '',
  };
  const [loader, setLoader] = useState(true);
  const [category, setCategory] = useState(defaultCategory);
  const [topic, setTopic] = useState(defaultTopic);

  const [article, setArticle] = useState({
    title: '',
    image: '',
    content: '',
    author: '',
    createdDate: '',
    category: '' // Add category state
  });

  const handleCategoryChange = (e) => {
    setCategory({ ...category, [e.target.name]: e.target.value });
  };
  const handleTopicChange = (e) => {
    setTopic({ ...topic, [e.target.name]: e.target.value });

  };
  const handleChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here

    //Random Alphanumeric with length 20
    var randomId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    let data = {

      id: randomId,

      createdByUser: cookies.getUserID(),

      title: category.name,

      description: category.description,

      enabled: true,

      tags: category.tags.split(','),

      viewCount: 0,

      weights: 90


    };


    api.saveCategory(data).then(resp => resp?.data)
      .then(res1 => {
        console.log('saveCategory', res1);
        toast.dismiss();
        toast.info('Submitted successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setCategory(defaultCategory);
      }).catch(err => {
        console.log('submit error', err);
      });
  };
  const handleTopicSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here

    //Random Alphanumeric with length 20
    var randomId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
     console.log( editorState?.getCurrentContent());
     const rawContentState = convertToRaw(editorState?.getCurrentContent());
     const html = draftToHtml(rawContentState);
    let data = {

      id: randomId,

      createdByUser: cookies.getUserID(),

      title: topic.name,

      description: html,

      enabled: true,

      tags: topic.tags.split(','),

      viewCount: 0,

      weights: 90,
      categoryId: topic.categoryId,

      authorName: cookies.getUsername(),
      categoryTitle: category.name,
      // createdDate: topic.createdDate,
      networkImageUrls: [topic.image],
    };
    console.log('data', data);
// return;

    api.saveTopic(data).then(resp => resp?.data)
      .then(res1 => {
        console.log('saveTopic', res1);
        toast.dismiss();
        toast.info('Submitted successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setTopic(defaultTopic);
        setEditorState(EditorState.createEmpty());
      }).catch(err => {
        console.log('submit error', err);
      });
  };


  const handleEditorChange = (content, editor) => {
    console.log('Content was updated:', content);
  }
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(12);
const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [topics, setTopics] = useState([]);
  const fetchCategory = (page) => {
    // if (page < 1) return;

    setPageNo(page);
    let data = {
      "pageNo": page,
      "pageSize": pageSize,
      "enabled": "true"
    };

    api.fetchCategory(data).then(resp => resp?.data)
      .then(res1 => {
        console.log('fetchCategory', res1);
        setTopics(res1?.content)
      }).catch(err => {
        // showToast(err?.response?.data?.message || 'No plan details to display');

        console.log('submit contact error', err);
      });

  }
  const onEditorStateChange = (editorState) => {
    console.log('editorState', editorState);
    setEditorState(editorState);
  };
  setTimeout(() => {
    setLoader(false);
  }, 3000);

  useEffect(() => {
    // fetchData();
    fetchCategory(pageNo);
  }, []);
  return (
    <>

      {loader ? <h3><br></br><br></br><br></br><br></br><br></br><br></br><br></br><Spinner animation="border" size="sm" variant="success" />  Loading form...<br></br><br></br><br></br><br></br><br></br><br></br></h3> : (


        <div style={{ width: '80%' }}>

          <br />
          <Button variant="secondary" onClick={() => {

            window.location.href = "/client/forum";


          }}>
            Go Back to FinHub
          </Button>
          <hr></hr>
          <h1>Create Category</h1>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={category.name}
                onChange={handleCategoryChange}
                placeholder="Enter category name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                className="form-control"
                id="description"
                name="description"
                value={category.description}
                onChange={handleCategoryChange}
                placeholder="Enter category description"
              ></textarea>
            </div>

            <div className="form-group">
              <label htmlFor="description">Tags (comma separated)</label>
              <textarea
                className="form-control"
                id="tags"
                name="tags"
                value={category.tags}
                onChange={handleCategoryChange}
                placeholder="Enter tags (comma separated)"
              ></textarea>
            </div>

            <br />
            <button type="submit" className="btn btn-success">
              Create Category
            </button>
          </form>

          <br />
          <hr></hr>


          <h1>Create Topic</h1>

          <form onSubmit={handleTopicSubmit}>

            <div className="form-group">
              <label htmlFor="category">Choose Category</label>

              <select
                className="form-control"
                id="categoryId"
                name="categoryId"
                value={topic.categoryId}
                onChange={handleTopicChange}
              >
                <option value="">Select a category</option>
                {topics.map(it => (
                  <option value={it.id}>{it.title}</option>
                ))}

              </select>
            </div>

            <div className="form-group">
              <label htmlFor="name">Title</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={topic.name}
                onChange={handleTopicChange}
                placeholder="Enter category name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="description">Description</label>
              {/* <textarea
                className="form-control"
                id="description"
                name="description"
                value={topic.description}
                onChange={handleTopicChange}
                placeholder="Enter category description"
              ></textarea> */}

{/* <CKEditor
        editor={ClassicEditor}
        data={editorData}
        onChange={handleChange}
      /> */}
      <br></br>
      <div style={{backgroundColor:"white"}}>

      <Editor
  editorState={editorState}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={onEditorStateChange}
/>
      </div>
      <br></br>  
            </div>

            <div className="form-group">
              <label htmlFor="description">Tags (comma separated)</label>
              <textarea
                className="form-control"
                id="tags"
                name="tags"
                value={topic.tags}
                onChange={handleTopicChange}
                placeholder="Enter tags (comma separated)"
              ></textarea>
            </div>
            {/* <div className="form-group">
              <label htmlFor="content">Content</label>
              <Editor
                apiKey='ntn1cp5e304n0ydpl5uxxcjqi6dr7lcqrgchbi2u0rl3vqz1'
                init={{
                  plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss',
                  toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                  tinycomments_mode: 'embedded',
                  tinycomments_author: 'Author name',
                  mergetags_list: [
                    { value: 'First.Name', title: 'First Name' },
                    { value: 'Email', title: 'Email' },
                  ],
                  ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                }}
                initialValue="Welcome to TinyMCE!"
              />
            </div> */}

            <div className="form-group">
              <label htmlFor="author">Author</label>
              <input
                type="text"
                className="form-control"
                id="author"
                name="author"
                value={topic.author}
                onChange={handleTopicChange}
                placeholder="Enter author name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="createdDate">Created Date</label>
              <input
                type="date"
                className="form-control"
                id="createdDate"
                name="createdDate"
                value={topic.createdDate}
                onChange={handleTopicChange}
                placeholder="Enter created date"
              />
            </div>

            <div className="form-group">
              <label htmlFor="createdDate">Image URL</label>
              <input
                type="text"
                className="form-control"
                id="image"
                name="image"
                value={topic.image}
                onChange={handleTopicChange}
                placeholder="Past image URL here"
              />
            </div>

            <br />
            <button type="submit" className="btn btn-success">
              Create Topic
            </button>
          </form>
          <br />


        </div>)}



    </>
  );
}
