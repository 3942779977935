import React from 'react';
import ModalTitleBlock from '../ModalTitleBlock';
import WideBtn from '../WideBtn';

import achievementIcon from '../../assets/icons/achievementIcon.svg';

export default function Start({ incrementIndex, prevMonth }) {
  return (
    <>
      <ModalTitleBlock
        title="Goals Self-Assessment"
        body="This is your opportunity to reflect on your financial habits  and let us know if you reached your goals for:"
        icon={achievementIcon}
      />
      <h1>{prevMonth}</h1>
      <hr />
      <ModalTitleBlock
        title="Earn Cash-back!"
        body="When you meet all your goals, you’ll earn cash-back which will be provided to you at the end of your loan."
      />
      <WideBtn onClick={incrementIndex}>Get Started</WideBtn>
    </>
  );
}
