import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ClientInfo from "./Form0ClientInfo";
import OTP from "./Form1OTP";
import IncomeDetails from "./Form2IncomeDetails";
import Residence from "./Form3Residence";
import WellnessQuestions from "./Form4WellnessQuestions";
import Terms from "./Form5Terms";
import TellYourStory from "./Form4TellYourStory";
import SoftResults from "./Form6SoftResults";
import DebtToConsolidate from "./Form7DebtToConsolidate";
import NonConsolidatedDebt from "./Form8NonConsolidatedDebt";
// import Finished from "./Form9Finished";
import ApplicationSurvey from "./ApplicationSurvey";
import UnderReview from "./UnderReview";

// import { useParams } from 'react-router-dom';
import api from "../../utils/api";
import { parseIdFromLink } from "../../utils/helpers";
import { formatDataForAPI } from "./preQualDataHandler";
import differenceInYears from "date-fns/differenceInYears";
import cookies from '../../utils/cookies';
const PROGRESS = {
  0: 0, //First Form
  1: 10, //OTP
  2: 30, // Income and other details
  3: 50, // Residence
  4: 70, // Survey
  5: 90, // Terms
  6: 100, //Soft Results
  7: null, //Debt to Consolidate
  8: null, //Non consolidated debt
  9: null, //DONE
  //Knockout progress
  101: 65,
  102: 80,
  103: 95,
  104: 100,
};


export default function Index({ setFormProgress }) {
  const location = useLocation();
  const searchParams1 = new URLSearchParams(location.search);
  const id = searchParams1.get("applicantKey");
  const [customerReferenceId] = useState(id);
  let customerReferenceIdData = null;

  if (customerReferenceId) {
    try {
      customerReferenceIdData = JSON.parse(localStorage.getItem('customerReferenceIdData'));
    } catch (error) {
    }
  }

  const debugMode =
    cookies.processenv.REACT_APP_ENV === "api.dev" ||
    cookies.processenv.REACT_APP_ENV === "api.qa";
  const searchParams = useLocation().search;

  const initial_utm_source =
    new URLSearchParams(searchParams).get("initial_utm_source") ||
    new URLSearchParams(searchParams).get("utm_source") || customerReferenceIdData?.initial_utm_source;
  const initial_utm_campaign =
    new URLSearchParams(searchParams).get("initial_utm_campaign") ||
    new URLSearchParams(searchParams).get("utm_campaign") || customerReferenceIdData?.initial_utm_campaign;
  const initial_utm_medium =
    new URLSearchParams(searchParams).get("initial_utm_medium") ||
    new URLSearchParams(searchParams).get("utm_medium") || customerReferenceIdData?.initial_utm_medium;
  const click_id =
    new URLSearchParams(searchParams).get("click_id") || customerReferenceIdData?.click_id;
  const hubspotIDParam =
    new URLSearchParams(searchParams).get("hubspotID") ||
    new URLSearchParams(searchParams).get("hubspotid");

  //  alert(initial_utm_campaign);
  const [index, setIndex] = useState(0);
  const [abandondedIndex, setAbandonedIndex] = useState(null);
  // const [knockoutIndex, setKnockoutIndex] = useState(null); //Where the user was knocked out from (dont need anymore b/c no back from knockout) was Should use a useRef
  const [knockout, setKnockout] = useState(false);
  useEffect(() => {
    /* Updates progress bar in parent (apply view), based on current index */
    setFormProgress(abandondedIndex ? null : PROGRESS[index]);
  }, [index, setFormProgress, abandondedIndex]);

  const [preQualData, setPreQualData] = useState({
    initial_utm_source: initial_utm_source,
    initial_utm_campaign: initial_utm_campaign,
    initial_utm_medium: initial_utm_medium,
    click_id: click_id,
    hubspotID: hubspotIDParam,
  });


  // alert(JSON.stringify(preQualData))
  //Collection of all forms data (updates when next is clicked on each form), submitted to API @ end.
  // const [results, setResults] = useState({}); //Application Results from API (pending/ notApproved, in future may have approved) // Currently not implemented
  const [applicationID, setApplicationID] = useState(null); //Application ID from API, used to patch

  const [dataToUpload, setDataToUpload] = useState(null); //Triggers UseEffect to Upload Data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const checkElegibilityCriteria = (isChecks) => {
    let isEligible = true;
    for (let x in isChecks) {
      if (x <= 2) {
        if (isChecks[x] === false) {
          isEligible = false;
        }
      }

      if (x === 3 || x === '3') {
        if (isChecks[x] === true) {
          isEligible = false;
        }
      }


    }
    return isEligible;
  };

  const isKnockout = (dataToEvaluate) => {
    if (dataToEvaluate.client?.ELIGIBILITY_CRITEREIA_CHECKED) {
      if (!checkElegibilityCriteria(dataToEvaluate.client?.ELIGIBILITY_CRITEREIA_CHECKED)) {
        return true;
      }
    }

    if (
      dataToEvaluate.income?.primaryIncomeType !== undefined &&
      dataToEvaluate.income?.primaryIncomeType !== "Employed"
    ) {
      return true;
    }
    if (dataToEvaluate.income?.residencyStatus === "Non-Resident") return true;
    if (dataToEvaluate.income?.creditScore === "Under 580") {
      return true;
    }
    const age = differenceInYears(
      cookies.getDate(),
      new Date(
        `${dataToEvaluate.income?.dobDay} ${dataToEvaluate.income?.dobMonth} ${dataToEvaluate.income?.dobYear}`
      )
    ); 
    if (age < 18) return true;
    if (
      dataToEvaluate.residence &&
      dataToEvaluate.residence?.primaryProvince === "Yukon"
    )
      return true;
    if (
      dataToEvaluate.residence &&
      dataToEvaluate.residence?.primaryProvince === "Quebec"
    )
      return true;

    if (
      dataToEvaluate.residence &&
      dataToEvaluate.residence?.primaryProvince === "Northwest Territories"
    )
      return true;

    return false;
  };

  useEffect(() => {
    /* Recover abandonded forms from local storage */
    const savedData = localStorage.getItem("applyState");
    const parsedSavedData = JSON.parse(savedData);
    /* Is it possible to recover questions from savedData */
    //If what form was last completed




    const determineLastFormCompleted = (data) => {
      if (data.knockedOut) {
        if (
          Object.values(data.wellnessQuestions)?.every((x) => x === undefined)
        )
          return 100;
        if (
          Object.values(data.behaviourQuestions)?.every((x) => x === undefined)
        )
          return 101;
        if (
          Object.values(data.financialLiteracyQuestions)?.every(
            (x) => x === undefined
          )
        )
          return 102;
      }

      /* Reverse Order of forms, once it hits last completed return the index # */
      if (
        data.wellnessQuestions &&
        Object.values(data.wellnessQuestions)?.every((x) => x)
      )
        return 4; //Wellness index
      if (data.residence) return 3; //res index
      if (data.income) return 2; //income index
      if (data.clientPhone) return 1; //phone? index //Verified phone should goto 2.
      if (data.client && Object.values(data.client).every((x) => x)) return 0; //Client index
    };
    if (parsedSavedData) {
      const lastCompletedForm = determineLastFormCompleted(parsedSavedData);

      //If there is saved data update preQualState
      setAbandonedIndex(lastCompletedForm);

      //Add search params to this state
      parsedSavedData.hubspotID = hubspotIDParam;
      parsedSavedData.initial_utm_source = initial_utm_source;
      parsedSavedData.initial_utm_campaign = initial_utm_campaign;
      parsedSavedData.initial_utm_medium = initial_utm_medium;
      parsedSavedData.click_id = click_id;
      console.log('click_id 1 : ' + click_id);
      setPreQualData(parsedSavedData);
      setApplicationID(parsedSavedData.applicationID);
    }


    if (customerReferenceId && !parsedSavedData) {
      try {
        let tempDate = { ...preQualData };
        tempDate.initial_utm_source = customerReferenceIdData?.initial_utm_source;
        tempDate.initial_utm_campaign = customerReferenceIdData?.initial_utm_campaign;
        tempDate.initial_utm_medium = customerReferenceIdData?.initial_utm_medium;
        tempDate.click_id = customerReferenceIdData?.click_id;
        console.log('click_id 2 : ' + click_id);
        setPreQualData(tempDate);

      } catch (error) {
      }
    }



    //eslint-disable-next-line
  }, []);
  const navForm = (input) => {
    window.scrollTo(0, 0);
    if (isNaN(input)) {
      let nextIndex = index;
      if (input === "next") {
        nextIndex = nextIndex + 1;
      }
      if (input === "back") {
        nextIndex = nextIndex - 1;
      }
      setIndex(nextIndex);
    } else {
      setIndex(input); //Direct override with index #
    }
  };

  const submitToAPI = (latestData) => {
    //Validate @ each form prior to updating state
    //Format/ parse into upload data
    const formattedData = formatDataForAPI(latestData);
    //Trigger API to upload
    //Set upload state
    setDataToUpload(formattedData); //<<< Make this formatted data
    //After upload (useEffect),setResults, go to next form
    // navForm('next');
  };

  const updatePreQualState = (formKey, formData, options) => {



    if (formKey === "client" && !debugMode) {
      try {
        window.heap.addUserProperties({
          preQualEmail: formData.email?.toLowerCase(),
        });
      } catch (error) {

      }

    }
    /* Trim Whitespace from strings */
    //Cant edit clientPhone (read-only) so it is exempt
    formKey !== "clientPhone" && formKey !== 'preferredTime' &&
      Object.entries(formData).forEach(([k, v]) => {
        if (typeof v === "string") {
          formData[k] = v.trim();
        }
      });

    const newData = { ...preQualData };

    if (formData?.appID) {
      newData.appID = formData.appID;
    }
    //Add new info to data...
    if (formKey === "nonConsolidatedDebt") {
      //Append to debt
      newData.debt = [...preQualData.debt, ...formData];
    } else {
      //Default action and consolidated debt, replace
      //when consolidated debt changes it will erase any nonConsolidated debt (this is by design)
      newData[formKey] = formData;
    }
    console.log(formData)
    setPreQualData(newData);
    if (formKey === "client" || formKey === "income" || formKey === "residence") {
      if (isKnockout(newData)) {
        //add knockedOut to state and pass to API
        newData["knockedOut"] = true;
        setKnockout(true); //Used to nav to knockout during saving useEffect then is immedietly turned off.
        //[]Cfm below is still needed after 02 Aug changes...
        //clear old knockout survey data..., uneccessary b/c you cant go back from knockout.
        newData["wellnessQuestions"] = {};
        newData["behaviourQuestions"] = {};
        newData["financialLiteracyQuestions"] = {};
      } else {
        newData["knockedOut"] = false;
        setKnockout(false);
      }
    }

    submitToAPI(newData); //Need to feed 'new' data b/c submit maynot grab latest state yet...
    // if (options?.noNext) return;
    // navForm('next');
  };
  useEffect(() => {

    /* Handles uploading data to API (@ click next of each form) */
    let updateAPI = applicationID
      ? api.updateApplication
      : api.submitApplication; //Action to update the api, depends if the ID is available or not.

    if (dataToUpload?.appID) {
      updateAPI = api.updateApplication;
    }

    const sendFormToAPI = async () => {
      setLoading(true);
      setError(null);

      try {
        index <= 4 &&
          (await localStorage.setItem(
            "applyState",
            JSON.stringify(preQualData)
          )); // Only save state for initial forms
        index >= 101 &&
          index <= 103 &&
          (await localStorage.setItem(
            "applyState",
            JSON.stringify(preQualData)
          )); // Only save state for initial forms
        if (dataToUpload?.appID) {
          updateAPI = api.updateApplication;
        }
        const res = await updateAPI(dataToUpload, applicationID);
        if (index === 5 || index === 103) {
          /* Clear Local storage after terms are accepted, knockout is complete, // after debt entered (8) (OLD Debt is not saved locally (no recovery)) */
          localStorage.clear();
        }
        const applicationLink = res?.data._links.self.href;
        const appIDFrServer = parseIdFromLink(applicationLink);
        setApplicationID(appIDFrServer);
        setPreQualData((p) => {
          return {
            ...p,
            applicationID: appIDFrServer,
          };
        });
        setDataToUpload(null);


        if (knockout === true && index === 0) {
          //do nothing because we are showing popup/modal in frontend
        } else {
          if (knockout === true) {
            navForm(101);
          } else {
            navForm("next");
          }
        }

        setLoading(false);
        setKnockout(false);
      } catch (e) {
        const statusCode = e.response?.data?.status;
        const errorType = e.response?.data?.error;
        let errorToDisplay = `${e}`;
        if (errorType) {
          errorToDisplay = `${e} - ${errorType}`;
        }
        if (statusCode >= 500 && statusCode <= 599) {
          errorToDisplay = `Unable to reach server (${statusCode})`;
        }
        setDataToUpload(null);
        setError(`${errorToDisplay}` || `${e}`);
        setLoading(false);
      }
    };
    !!dataToUpload && sendFormToAPI();
    // eslint-disable-next-line
  }, [dataToUpload]);

  const goToHome = () => {
    localStorage.clear(); //Erases local storage when click Go To Home
    // window.location = "/apply/#eligibilityList";
    window.location = 'https://myparachute.co';
  };

  return (
    !customerReferenceId ? (
      <>
        <main>
          {debugMode && (
            <div>
              <button onClick={() => navForm("back")}>PREV FORM</button>
              <button id="nextForm" onClick={() => navForm("next")}>
                NEXT FORM
              </button>
              <button
                onClick={() => {
                  localStorage.clear();
                  window.location = "/apply";
                }}
              >
                DEBUG - Clear saved form data & Refresh
              </button>
            </div>
          )}
          {index === 0 && (
            <ClientInfo
              updatePreQualState={updatePreQualState}
              stateData={preQualData.client}
              status={{ error, loading }}
              abandondedIndex={abandondedIndex}
              navForm={navForm}
              setAbandonedIndex={setAbandonedIndex}
            // knockoutIndex={knockoutIndex}
            />
          )}
          {index === 1 && (
            <OTP
              navForm={navForm}
              updatePreQualState={updatePreQualState}
              debugMode={debugMode}
            />
          )}
          {index === 2 && (
            <IncomeDetails
              navForm={navForm}
              updatePreQualState={updatePreQualState}
              stateData={preQualData.income}
              status={{ error, loading }}
            />
          )}
          {index === 3 && (
            <Residence
              navForm={navForm}
              updatePreQualState={updatePreQualState}
              stateData={preQualData.residence}
              status={{ error, loading }}
            />
          )}
          {index === 4 && (

            <DebtToConsolidate
              amountRequested={preQualData.client?.debtToConsolidate}
              navForm={navForm}
              updatePreQualState={updatePreQualState}
              numOfDebts={preQualData.debt?.length}
              stateData={preQualData.debt?.filter(
                (x) => x.needsConsolidate === true
              )}
              status={{ error, loading }}
            />


          )}
          {index === 5 && (

            <NonConsolidatedDebt
              navForm={navForm}
              updatePreQualState={updatePreQualState}
              numOfDebts={preQualData.debt?.length}
              stateData={preQualData.debt?.filter(
                (x) => x.needsConsolidate === false
              )}
              status={{ error, loading }}
            />


          )}



          {index === 6 && (
            <WellnessQuestions
              navForm={navForm}
              updatePreQualState={updatePreQualState}
              stateData={preQualData.wellnessQuestions}
              status={{ error, loading }}
            />
          )}


          {index === 7 && (
            <TellYourStory
              navForm={navForm}
              updatePreQualState={updatePreQualState}
              submitToAPI={submitToAPI}
              stateData={preQualData.freeFormQuestion}
              status={{ error, loading }}
            />

          )}

          {index === 8 && (
            <Terms
              navForm={navForm}
              updatePreQualState={updatePreQualState}
              submitToAPI={submitToAPI}
              stateData={preQualData.termsAccepted}
              status={{ error, loading }}
            />

          )}


          {index === 9 && (
            <SoftResults
              navForm={navForm}
              goToHome={goToHome}
              updatePreQualState={updatePreQualState}
            /* results={results} */
            />
          )}
          {/* {index === 9 && <Finished goToHome={goToHome} />} */}

          {/* KnockoutFlow */}
          {index === 101 && (
            <ApplicationSurvey
              wellness
              updatePreQualState={updatePreQualState}
              stateData={preQualData.wellnessQuestions}
              // navForm={navForm}
              // navBackOverride={knockoutIndex} //No going back
              status={{ error, loading }}
            />
          )}
          {index === 102 && (
            <ApplicationSurvey
              behaviour
              updatePreQualState={updatePreQualState}
              stateData={preQualData.behaviourQuestions}
              // navForm={navForm}
              // navBackOverride={'back'}
              status={{ error, loading }}
            />
          )}
          {index === 103 && (
            <ApplicationSurvey
              financialLiteracy
              updatePreQualState={updatePreQualState}
              stateData={preQualData.financialLiteracyQuestions}
              // navForm={navForm}
              // navBackOverride={'back'}
              status={{ error, loading }}
            />
          )}
          {index === 104 && <UnderReview goToHome={goToHome} />}
        </main>

      </>
    ) : (

      <>

        <main>
          {debugMode && (
            <div>
              <button onClick={() => navForm("back")}>PREV FORM</button>
              <button id="nextForm" onClick={() => navForm("next")}>
                NEXT FORM
              </button>
              <button
                onClick={() => {
                  localStorage.clear();
                  window.location = "/apply";
                }}
              >
                DEBUG - Clear saved form data & Refresh
              </button>
            </div>
          )}
          {index === 0 && (
            <ClientInfo
              updatePreQualState={updatePreQualState}
              stateData={preQualData.client}
              status={{ error, loading }}
              abandondedIndex={abandondedIndex}
              navForm={navForm}
              setAbandonedIndex={setAbandonedIndex}
            // knockoutIndex={knockoutIndex}
            />
          )}

          {index === 1 && (
            <IncomeDetails
              navForm={navForm}
              updatePreQualState={updatePreQualState}
              stateData={preQualData.income}
              status={{ error, loading }}
            />
          )}
          {index === 2 && (
            <Residence
              navForm={navForm}
              updatePreQualState={updatePreQualState}
              stateData={preQualData.residence}
              status={{ error, loading }}
            />
          )}
          {index === 3 && (

            <DebtToConsolidate
              amountRequested={preQualData.client?.debtToConsolidate}
              navForm={navForm}
              updatePreQualState={updatePreQualState}
              numOfDebts={preQualData.debt?.length}
              stateData={preQualData.debt?.filter(
                (x) => x.needsConsolidate === true
              )}
              status={{ error, loading }}
            />


          )}
          {index === 4 && (

            <NonConsolidatedDebt
              navForm={navForm}
              updatePreQualState={updatePreQualState}
              numOfDebts={preQualData.debt?.length}
              stateData={preQualData.debt?.filter(
                (x) => x.needsConsolidate === false
              )}
              status={{ error, loading }}
            />


          )}



          {index === 5 && (
            <WellnessQuestions
              navForm={navForm}
              updatePreQualState={updatePreQualState}
              stateData={preQualData.wellnessQuestions}
              status={{ error, loading }}
            />


          )}

          {index === 6 && (
            <OTP
              navForm={navForm}
              updatePreQualState={updatePreQualState}
              debugMode={debugMode}
            />
          )}

          {index === 7 && (
            <Terms
              navForm={navForm}
              updatePreQualState={updatePreQualState}
              submitToAPI={submitToAPI}
              stateData={preQualData.termsAccepted}
              status={{ error, loading }}
            />

          )}
          {index === 8 && (
            <SoftResults
              navForm={navForm}
              goToHome={goToHome}
              updatePreQualState={updatePreQualState}
            /* results={results} */
            />
          )}



          {/* {index === 9 && <Finished goToHome={goToHome} />} */}

          {/* KnockoutFlow */}
          {index === 101 && (
            <ApplicationSurvey
              wellness
              updatePreQualState={updatePreQualState}
              stateData={preQualData.wellnessQuestions}
              // navForm={navForm}
              // navBackOverride={knockoutIndex} //No going back
              status={{ error, loading }}
            />
          )}
          {index === 102 && (
            <ApplicationSurvey
              behaviour
              updatePreQualState={updatePreQualState}
              stateData={preQualData.behaviourQuestions}
              // navForm={navForm}
              // navBackOverride={'back'}
              status={{ error, loading }}
            />
          )}
          {index === 103 && (
            <ApplicationSurvey
              financialLiteracy
              updatePreQualState={updatePreQualState}
              stateData={preQualData.financialLiteracyQuestions}
              // navForm={navForm}
              // navBackOverride={'back'}
              status={{ error, loading }}
            />
          )}
          {index === 104 && <UnderReview goToHome={goToHome} />}
        </main>


      </>

    )
  );
}
