/* This route is accessed by the forgot password email 
Requuired a token parameter to access, else will re route to login
 */

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import api from '../utils/api';
import { isValidPassword } from '../utils/helpers';
import cookies from '../utils/cookies';
import Spinner from 'react-bootstrap/Spinner';

import CardContainer from '../components/CardContainer';
import Logo_Icon from '../assets/Logo_Icon.svg';

// import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
// import Alert from 'react-bootstrap/Alert';
import WideBtn from '../components/WideBtn';
import './ChangePasswordView.scss';

function ChangePasswordView({ newAccount }) {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const name = query.get('name');
  const username = query.get('username');

  const [newPass, setNewPass] = useState('');
  const [reEnteredPass, setReEnteredPass] = useState('');
  const [optIn, setOptIn] = useState(false);
  const [passToSubmit, setPassToSubmit] = useState(null);

  const [changeError, setChangeError] = useState(null);
  const [changed, setChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleTextChange = (e) => {
    const newValue = e.target.value;
    const field = e.target.id;
    field === 'newPass' ? setNewPass(newValue) : setReEnteredPass(newValue);
  };

  const handleOptInChange = (e) => {
    const newValue = e.target.checked;
    setOptIn(newValue);
  };

  const validatePassword = (pass1, pass2) => {
    if (pass1 !== pass2) return setChangeError('Password needs to match');
    if (!isValidPassword(pass1)) return setChangeError("Password doesn't meet requirements");

    return true;
  };
  const handleChangePass = (e) => {
    setChangeError(null);
    e.preventDefault();
    //validate
    if (!validatePassword(newPass, reEnteredPass)) return;
    //[] TODO if newAccount, need to note 'marketingOptIn'
    // trigger API useEffect
    setPassToSubmit({ password: newPass, token: token });
  };
  useEffect(() => {
    /* Use Effect to API - triggered by passToSubmit */
    let mounted = true;

    cookies.removeJWTAndUserID();
    if (!passToSubmit) return;
    const submitNewPass = async () => {
      setChangeError(null);
      setIsLoading(true);
      try {
        if (!Object.values(passToSubmit).every((x) => x)) throw new Error('Missing data');
        await api.changePassword(passToSubmit);
        if (newAccount && optIn === true) {
          // Update users Marketing optin flag
          //authenticate using username and latest password
          if (!username || !passToSubmit) return;
          const credentials = await api.authenticate(username, passToSubmit.password);
          const userID = credentials.data.userID;
          const authJWT = credentials.data.token;
          //update cookiesJWT to allow patch call
          cookies.setJWT(authJWT);
          //then with userID and auth JWT, patch user
          await api.userPatch(userID, { subscribeMarketing: true });
          //remove cookieJWT
          cookies.removeJWTAndUserID();
          //then update state to direct to login (usualy process)
        }
        if (mounted) {
          setChanged(true);
          setPassToSubmit(null);
          setIsLoading(false);
        }
      } catch (e) {
        // const errorPath = e?.response.data.path;
        const errorMsg =
          e?.response && `Error: Request Failed ${e?.response.data.status} - ${e?.response.data.message}`;
        //[] 403 from errorPatch.includes("changePassword") = bad token
        setChangeError(errorMsg || e);
        setPassToSubmit(null);
        setIsLoading(false);
      }
    };
    submitNewPass();
    return () => {
      mounted = false;
    };
    //eslint-disable-next-line
  }, [passToSubmit]);

  useEffect(() => {
    /* Clear Error is fields are empty */
    if (!newPass && !reEnteredPass) setChangeError(null);
  }, [newPass, reEnteredPass]);

  if (!token) {
    /* Redirects to Login if no change token */
    return <Navigate to="/login" />;
  }

  const marketingOptIn = (
    <div id="marketingOptIn-section">
      <input type="checkbox" name="marketingOptIn" id="marketingOptIn" onChange={handleOptInChange} value={optIn} />
      <div>
        <h3>Opt-in to marketing emails.</h3>
        <p>You can unsubscribe anytime.</p>
      </div>
    </div>
  );

  const changePassForm = (
    <Form id="updatePass-form">
      <Form.Group className="mb-3" controlId="newPass">
        <Form.Label>New Password</Form.Label>
        <Form.Control type="password" placeholder="Your new password" value={newPass} onChange={handleTextChange} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="reEnteredPass">
        <Form.Label>Confirm Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Confirm your new password"
          value={reEnteredPass}
          onChange={handleTextChange}
          isInvalid={changeError}
        />
        <Form.Control.Feedback type="invalid">{`${changeError}`}</Form.Control.Feedback>
      </Form.Group>
      {newAccount && marketingOptIn}
      <WideBtn
        type="submit"
        onClick={handleChangePass}
        disabled={isLoading || (newPass && reEnteredPass) ? false : true}
      >
        {!isLoading && newAccount && 'Create account'}
        {!isLoading && !newAccount && 'Update password'}
        {isLoading && <Spinner animation="border" role="status" size="sm" />}
      </WideBtn>
    </Form>
  );

  const changePassArea = (
    <>
      <div className="titleBlock">
        <h2>{name ? `Hey ${name}, please create a password.` : 'Please create a password.'}</h2>
        <p>Password must be 8-50 characters, 1 uppercase, 1 lowercase, 1 digit, 1 special character.</p>
      </div>
      <hr />
      {changePassForm}
    </>
  );

  const newAccountSuccess = (
    <>
      <div className="titleBlock">
        <h2>{newAccount ? `Password created.` : 'Password changed'}</h2>
        <p className="muted">{newAccount ? `Start your journey.` : 'Your password has been updated, login now'}</p>
      </div>
      <hr />
      <WideBtn type="submit" onClick={(e) => navigate('/login')}>
        Log In
      </WideBtn>
    </>
  );

  return (
    <div style={{ display: 'grid', placeItems: 'center' }}>
      <CardContainer id="changePassCard">
        <img className="loginIcon" src={Logo_Icon} alt="Parachute Cash" />
        {!changed && changePassArea}
        {changed && newAccountSuccess}
      </CardContainer>
    </div>
  );
}

export default ChangePasswordView;
