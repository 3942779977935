import React from 'react';
import CardContainer from '../CardContainer';
import CardModal from '../CardModal';
import calendarIcon from '../../assets/icons/calendarIcon.svg';
import useAuth from '../../hooks/useAuth';
import cookies from '../../utils/cookies';

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
export default function TileBills({planDetails}) {
  let auth = useAuth();
  const savedRole = auth?.user?.role || cookies.getUserRole();
  return (
    <CardContainer className="goalTile">
      <div className="tileContent">
        <img className="tileIcon" src={calendarIcon} alt="Calendar Icon" />
        <div className="tileInfo">
          <div>
            <h3>Pay all bills on time.</h3>
            <p className="infoTitle">Paying all your bills on time can positively impact your credit score.</p>


          </div>
          <CardModal id="monthly-billsInfo">
            <img className="tileIcon" src={calendarIcon} alt="Calendar Icon" />
            <h3>Pay all bills on time.</h3>
            <p className="muted">
              Everyone has bills, we get it. And it can be hard to get them all paid on time, but it’s super important
              for managing your finances, improving your credit score and setting you on a path to success.
            </p>
            <p className="muted">
              At the end of the month we’re going to ask you to confirm with us if you’ve paid all your bills on time
              (ie. credit cards, utilities, internet, mobile, etc.). We hope you’ll tell us the truth, and reflect on
              how the month went for you financially. 
              
              {savedRole !== 'ROLE_FREEMIUM' ? (<> If you did pay all your bills on time, you’ll have achieved one of
              the four monthly goals to receiving cashback. If you didn’t, that’s OK, we get it, and we’re here to help.</>) : (<>If you did pay all your bills on time, you’ll have achieved one of the four monthly goals. </>)}
              
             


            </p>
          </CardModal>

      

        </div>
      </div>

{planDetails?( planDetails['promises']['automaticWithdrawal'] === true || planDetails['promises']['spendLess'] === true || planDetails['promises']['contactBiller'] === true || planDetails['promises']['description1'] ? (
          <>
            <div className="wideCntr planForm container" style={{ textAlign: 'left', width: 'auto' }}>
            <Card>
              <Card.Header><h7>Plan</h7></Card.Header>
              <ListGroup variant="flush">
                {planDetails['promises']['automaticWithdrawal'] === true && (
                  <ListGroup.Item>I will set up automatic withdrawals, so funds come directly out of my account</ListGroup.Item>
                )}

                {planDetails['promises']['spendLess'] === true && (

                  <ListGroup.Item>I will spend less so I have money left to pay my bills</ListGroup.Item>
                )}
                {planDetails['promises']['contactBiller'] === true && (
                  <ListGroup.Item>I will contact billers and align payment dates to my pay schedule</ListGroup.Item>
                )}

                {planDetails['promises']['description1'] && (
                  <ListGroup.Item>{planDetails['promises']['description1']}</ListGroup.Item>
                )}
              </ListGroup>
            </Card>
            </div>
          </>) : (<></>)):(<></>)}
      
          
    </CardContainer>
    
  );
}
