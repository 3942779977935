import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';


import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Spinner from 'react-bootstrap/Spinner';
import $ from 'jquery';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useCallback } from 'react'

import Container from 'react-bootstrap/Container';

import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

import cookies from '../../../utils/cookies';
export default function ReportsViewer() {


  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const typesArr = ['BB', 'RPA'];
  const [selectedType, setSelectedType] = useState('BB');

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };






  useEffect(() => {

  }, []);



  const handleDownloadCashFlow = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      let data = {
        "subType": selectedType
      };
      api.downloadCashFlowReportByType(data).then(res => {
        setIsLoading(false);
      }).catch(err => {
        setIsLoading(false);
      });

    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };
  const handleDownloadWLU = async () => {
    if (isLoading1) {
      return;
    }
    try {
      setIsLoading1(true);
      let data = {
      };
      api.downloadWLUReport(data).then(res => {
        setIsLoading1(false);
      }).catch(err => {
        setIsLoading1(false);
      });

    } catch (e) {
      setIsLoading1(false);
      console.error(e);
    }
  };

  const handleDownloadTGI = async () => {
    if (isLoading2) {
      return;
    }
    try {
      setIsLoading2(true);
      let data = {
      };
      api.downloadTGIReport(data).then(res => {
        setIsLoading2(false);
      }).catch(err => {
        setIsLoading2(false);
      });

    } catch (e) {
      setIsLoading2(false);
      console.error(e);
    }
  };

  const handleDownloadServiceReport = async () => {
    if (isLoading3) {
      return; // Prevent the function from executing if already loading
    }

    try {
      setIsLoading3(true); // Set loading state to true when the API starts

      let data = {}; // Add the necessary data for the API request
      await api.downloadserviceReport(data)
        .then(res => {
          setIsLoading3(false); // Set loading state to false when the API call is complete
          // Handle success if needed (e.g., show a success message or trigger the download)
        })
        .catch(err => {
          setIsLoading3(false); // Set loading state to false if there's an error
          console.error(err); // Log the error for debugging
        });

    } catch (e) {
      setIsLoading3(false); // Set loading state to false if an exception occurs
      console.error(e); // Log the error
    }
  };


  return (
    <>
      <div>
        <div>
          <br></br>
          <h1>{`Reports`}</h1>
          <hr>
          </hr>
          <h2>Cash Flow</h2>
          <h2>Select type to download</h2>
          <div>
            <Form.Select
              id="quizMonth"
              className="mr-8"
              aria-label="Type to download"
              onChange={handleTypeChange}
              value={selectedType}
            >
              {Object.values(typesArr)?.map((i) => (
                <option key={`${i}`} value={i}>
                  {i}
                </option>
              ))}
            </Form.Select>
            <br></br>
            <Button variant="dark" onClick={handleDownloadCashFlow}>Download  {isLoading && (
              <>
                {/* <p>Submitting plan details, please wait...</p> */}
                <Spinner
                  className="flinksSpinner"
                  animation="border"
                  size="sm"
                />
              </>
            )}</Button>
          </div>



          <hr></hr>
          <h2>WLU</h2>
          {/* <h2>Select type to download</h2> */}
          <div>
            {/* <Form.Select
              id="quizMonth"
              className="mr-8"
              aria-label="Type to download"
              onChange={handleTypeChange}
              value={selectedType}
            >
              {Object.values(typesArr)?.map((i) => (
                <option key={`${i}`} value={i}>
                  {i}
                </option>
              ))}
            </Form.Select>
            <br></br> */}
            <Button variant="dark" onClick={handleDownloadWLU}>Download  {isLoading1 && (
              <>
                {/* <p>Submitting plan details, please wait...</p> */}
                <Spinner
                  className="flinksSpinner"
                  animation="border"
                  size="sm"
                />
              </>
            )}</Button>
          </div>
          <hr></hr>
          <h2>TGI</h2>
          <div>

            <Button variant="dark" onClick={handleDownloadTGI}>Download  {isLoading2 && (
              <>
                {/* <p>Submitting plan details, please wait...</p> */}
                <Spinner
                  className="flinksSpinner"
                  animation="border"
                  size="sm"
                />
              </>
            )}</Button>
          </div>
          <hr></hr>
          <h2>Download Spartan Report</h2>
          <div>

            <Button
              variant={isLoading3 ? 'danger' : 'dark'} // Change button color to red when loading
              onClick={handleDownloadServiceReport}
              disabled={isLoading3} // Disable button while loading
            >
              Download
              {isLoading3 && (
                <Spinner
                  className="flinksSpinner"
                  animation="border"
                  size="sm"
                  style={{ marginLeft: '10px' }}
                />
              )}
            </Button>

          </div>
          <hr />
        </div>
      </div>
    </>


  );
}
