import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { REPORT } from '../../utils/A-Dashboard/constants';
import Badge from 'react-bootstrap/Badge';

export default function FlagSection({ flagsState, addFlag, removeFlag, isReadOnly }) {
  const flagList =
    flagsState &&
    flagsState.map((flag, i) => {
      return (
        <Badge key={`flag-${i}`} pill bg="warning" onClick={isReadOnly ? null : removeFlag}>
          {flag}
        </Badge>
      );
    });

  const availableFlags = REPORT.flags.filter((x) => {
    if (!flagsState) return true;
    const isFlagIsAvail = !flagsState.includes(x);
    return isFlagIsAvail;
  });
  return (
    <div>
      {/* Flags, Drop down to add item to list,  */}
      <h5>Flags</h5>
      {!isReadOnly && (
        <span style={{ display: 'flex' }}>
          <Dropdown>
            <Dropdown.Toggle variant="outline-success" id="flag">
              Add Flag
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {availableFlags.map((item, i) => (
                <Dropdown.Item key={`flag-opt-d-${i}`} onClick={addFlag}>
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <p style={{ marginLeft: '16px' }}>{`Click flag to remove (${flagList?.length || 0})`}</p>
        </span>
      )}
      <div>{flagList}</div>
    </div>
  );
}
