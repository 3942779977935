import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
// import { PRE_QUAL } from '../../../utils/constants';
import { parseIdFromLink, formatDateForDisplay } from '../../../utils/helpers';
import api from '../../../utils/api';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import GenericModal from '../GenericModal';

import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import isValid from 'date-fns/isValid';
// import getYear from 'date-fns/getYear';

import cookies from '../../../utils/cookies';
export default function InspirationViewer({ successUpload }) {
  const monthNumTo3Ltr = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };
  const today = cookies.getToday();
  const [selectedMonth, setSelectedMonth] = useState(monthNumTo3Ltr[today.month() + 1]);
  const [selectedYear, setSelectedYear] = useState(today.year());
  const [forceRefresh, setForceRefresh] = useState(0);
  const [lastFetch, setLastFetch] = useState(null);

  const [quizzesForMonth, setQuizzesForMonth] = useState(null);
  const [status, setStatus] = useState({ loading: false, error: null });
  const selectedYearString = `${selectedYear}`;
  console.log('selectedYear, typeof selectedYear', selectedYearString.length, typeof selectedYearString);
  useEffect(() => {
    const getQuizzesForMonth = async (dateRange) => {
      try {
        setStatus({ loading: true, error: null });
        setQuizzesForMonth([]);
        console.log('dateRange A', dateRange);
        const rawRes = await api.getMonthsInspiration(dateRange);
        const monthsQuizzes = rawRes.data._embedded.inspirations.sort((x, y) =>
          x.availableDate.localeCompare(y.availableDate)
        );
        console.log('monthsQuizzes', monthsQuizzes);
        setQuizzesForMonth(monthsQuizzes);
        setLastFetch(cookies.getDate());
        setStatus({ loading: false, error: null });
      } catch (e) {
        const errorMsg = `Error: ${e?.response.data.status} - ${e?.response.data.error} - ${e?.response.data.message}`;
        setStatus({ loading: false, error: `${errorMsg || e}` });
      }
    };
    /* Update date range to view */
    const dateString = `15 ${selectedMonth} ${selectedYear}`;
    const date = new Date(dateString); //15th is arbitrary, time is midnight EST
    let currDateRange = null;
    if (selectedYearString.length === 4 && isValid(date)) {
      currDateRange = {
        startDate: startOfMonth(date).toISOString(),
        endDate: endOfMonth(date).toISOString(),
      };
    }
    currDateRange && getQuizzesForMonth(currDateRange);
    if (successUpload === true) {
      console.log('UPLOADED< FETCHING');
      getQuizzesForMonth(currDateRange);
    }
  }, [selectedMonth, selectedYear, forceRefresh, selectedYearString, successUpload]);
  const handleViewMonth = (e) => {
    const value = e.target.value;
    console.log('value', value);
    setSelectedMonth(value);
  };
  const handleYear = (e) => {
    const value = e.target.value;
    if (value.length > 4) return;
    setSelectedYear(value);
  };

  const deleteItem = async (quizID) => {
    setStatus({ loading: true, error: null });
    console.log('clickDelete', quizID);
    try {
      await api.deleteInspiration(quizID);
      //Remove that quiz from state
      const prevState = quizzesForMonth;
      const newState = prevState.filter((x) => !x._links.self.href.includes(quizID));
      console.log('newState', newState);
      setQuizzesForMonth(newState);
      setStatus({ loading: false, error: null });
    } catch (e) {
      let errorMsg = `Error: ${e?.response.data.status} - ${e?.response.data.error} - ${e?.response.data.message}`;
      if (errorMsg.includes('is still referenced from table "inspiration_answer"'))
        errorMsg = `Unable to delete, there are community answers linked to this inspiration (${quizID})`;
      setStatus({ loading: false, error: `${errorMsg}` });
    }
  };

  useEffect(() => {
    status.error && alert(status.error);
  }, [status.error]);

  return (
    <section>
      <div>
        <h1>{`QuizViewer - Showing: ${selectedMonth}, ${
          selectedYearString.length === 4 ? selectedYearString : '___'
        }`}</h1>
        {status.error && <p className="error">{status.error}</p>}
        <h2>Select Month to view</h2>
        <div>
          <Form.Select
            id="dobMonth"
            className="mr-8"
            aria-label="Month of Birth"
            onChange={handleViewMonth}
            value={selectedMonth}
            // isInvalid={validated && !incomeDetails.dobMonth}
          >
            {Object.values(monthNumTo3Ltr)?.map((i) => (
              <option key={`dobM-${i}`} value={i}>
                {i}
              </option>
            ))}
          </Form.Select>
          <Form.Control value={selectedYear} type="number" onChange={handleYear} min={2019} />
        </div>
      </div>
      <span>
        <Button variant="info" size="sm" style={{ margin: '24px 0px' }} onClick={() => setForceRefresh((p) => p + 1)}>
          REFRESH
        </Button>
        <p>{`As of: ${lastFetch}`}</p>
      </span>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>
              {'Available Date'}
              <br />
              {'Midnight EST'}
            </th>
            <th>Image</th>
          </tr>
        </thead>
        <tbody>
          {quizzesForMonth?.map((q, i) => {
            return (
              <tr key={`questItem-${i}`}>
                <td>{parseIdFromLink(q._links.self.href)}</td>
                <td style={{ textAlign: 'center' }}>{formatDateForDisplay(q.availableDate, { truncated: true })}</td>
                <td>
                  <img src={q.url} alt="Not Available" style={{ height: '200px', aspectRatio: '4:3' }} />
                </td>
                <td>
                  <GenericModal
                    modalBtn="Delete"
                    variant="danger"
                    header="Do you want to this inspiration?"
                    primaryBtn={'DELETE'}
                    style={{ width: '100%' }}
                    primaryAction={() => deleteItem(parseIdFromLink(q._links?.self?.href))}
                    primaryVariant="danger"
                    secondaryBtn="Cancel"
                  >
                    <>
                      {/* <h4>Do you want to this quiz question?</h4> */}
                      <p className="muted">This cannot be undone</p>
                      {/* <p>{`Question: ${q.url}`}</p> */}
                      <p>{`Available: ${q.availableDate}`}</p>
                    </>
                  </GenericModal>
                  {/*  <Button variant="danger" size="sm" onClick={cfmDeleteItem} style={{ width: '100%' }}>
                    Delete
                  </Button> */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </section>
  );
}
