import React from 'react';
import Form from 'react-bootstrap/Form';
import { PRE_QUAL } from '../../utils/constants';

export default function DebtForm({ consolidated, handleChange, formData, index, status }) {
  console.log('debt form', formData);
  const { DEBT_CONSOLIDATED_OPTIONS, DEBUT_NON_CONSOLIDATED_OPTIONS } = PRE_QUAL; // CONSTANTS for Form Options

  const handleSubFormChange = (e) => {
    e.preventDefault();
    handleChange(e, index);
  };

  // List of types that require the Monthly Payments field
  const showMonthlyPayments = ["Leasing", "Financing"].includes(formData.type);

  return (
    <>
      <div style={{ marginTop: '24px', marginBottom: '24px', backgroundColor: '#0000001F', height: '1px' }} />

      <Form.Group className="mb-3" controlId={`type`}>
        <Form.Label>Debt Type</Form.Label>
        <Form.Select
          aria-label="Debt Type"
          onChange={handleSubFormChange}
          value={formData[`type`]}
          readOnly={status.loading}
        >
          {consolidated
            ? DEBT_CONSOLIDATED_OPTIONS.map((i) => (
                <option key={`cons-debtType-${i}`} value={i}>
                  {i}
                </option>
              ))
            : DEBUT_NON_CONSOLIDATED_OPTIONS.map((i) => (
                <option key={`noncons-debtType-${i}`} value={i}>
                  {i}
                </option>
              ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3" controlId={`amount`}>
        <Form.Label>{consolidated ? 'Debt Amount (Balance)' : 'Debt Amount'}</Form.Label>
        <div className="inputDollar-container">
          <span className="inputDollar-symbol">$</span>
          <Form.Control
            type="text"
            placeholder="How much you owe"
            onChange={handleSubFormChange}
            value={formData[`amount`]}
            readOnly={status.loading}
          />
        </div>
      </Form.Group>

      {showMonthlyPayments && (
        <Form.Group className="mb-3" controlId={`monthlyPayments`}>
          <Form.Label>Monthly Payments</Form.Label>
          <div className="inputDollar-container">
            <span className="inputDollar-symbol">$</span>
            <Form.Control
              type="text"
              placeholder="How much you pay monthly"
              onChange={handleSubFormChange}
              value={formData[`monthlyPayments`]}
              readOnly={status.loading}
            />
          </div>
        </Form.Group>
      )}

      <Form.Group className="mb-3" controlId={`creditor`}>
        <Form.Label>Company/Financial Institution</Form.Label>
        <Form.Control
          type="text"
          placeholder="Who do you owe this money to?"
          onChange={handleSubFormChange}
          value={formData[`creditor`]}
          readOnly={status.loading}
        />
      </Form.Group>
    </>
  );
}
