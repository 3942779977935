/* Optional Slide, to say why the goals were not met
Can submit reason
    -After saving Shows thanks with an btn that will close the modal
Can Cancel
    - Return to results

- Saving function and data will be passed in from Index
- Reason will also be stored @ higher level and drilled down.

*/
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import { preMonthName, prevMonthDate, parseIdFromLink } from '../../utils/helpers';
import React, { useState, useEffect } from 'react';
import ModalTitleBlock from '../ModalTitleBlock';
import WideBtn from '../WideBtn';
import missedGoal from '../../assets/icons/missedGoal.svg';
import achievementIcon from '../../assets/icons/achievementIcon.svg';
import Spinner from 'react-bootstrap/Spinner';
import { Button, ProgressBar } from 'react-bootstrap';

import useAuth from '../../hooks/useAuth';
import Form from 'react-bootstrap/Form';
import TextButton from '../TextButton';
import cookies from '../../utils/cookies';
import { toast } from 'react-toastify';
import './TellMore.scss';
import api from '../../utils/api';
import checkCircleIcon from '../../assets/icons/blueCheckCircleIcon.svg';
import { last, set } from 'lodash';
export default function SetAPlan({ goalsStatus, submitReason, closeAssessment, backToSummary, skipReason, status, reasonPatched }) {
  const [mode, setMode] = useState(skipReason ? 'thanks' : 'feedback');
  const [form, setForm] = useState('');
  const [billsOnTimeTextArea, setBillsOnTimeTextArea] = useState('');
  const [formData, setFormData] = useState(null);
  const [confirmationData, setConfirmationData] = useState(null);
  const { loading, error } = status;

  const [disableContinue, setDisableContinue] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const [userJson, setUserJson] = useState({
    billsOnTime: {},
    lowUtilization: {},
    noNewDebt: {}
  });
  //date in yyyy-mm format
  let today = new Date();
  today.setMonth(today.getMonth() - 1);

  let year = today.getFullYear();
  let month = (today.getMonth() + 1).toString().padStart(2, '0');
  let datestring = `${year}-${month}`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    submitReason(form);
  };
  const handleChange = (e) => {
    const v = e.target.value;
    if (v.length > 100) {
      return;
    }
    setForm(v);
  };
  const handleChangeBillsOnTimeTextArea = (e) => {
    const v = e.target.value;
    if (v.length > 100) {
      return;
    }
    setBillsOnTimeTextArea(v);
  };

  const [lowUtilizationTextArea, setLowUtilizationTextArea] = useState('');
  const handleChangeLowUtilizationTextArea = (e) => {
    const v = e.target.value;
    if (v.length > 100) {
      return;
    }
    setLowUtilizationTextArea(v);
  };

  const [noNewDebtTextArea, setNoNewDebtTextArea] = useState('');

  const handleChangeNoNewDebtTextArea = (e) => {
    const v = e.target.value;
    if (v.length > 100) {
      return;
    }
    setNoNewDebtTextArea(v);
  };



  useEffect(() => {
    reasonPatched && setMode('thanks');
  }, [reasonPatched]);

  const onChangeHandler = (event) => {
    console.log('event', event.target.checked);

    //check if checked or not
    let isChecked = event.target.checked;


    //change bg of checkbox selected
    if (isChecked) {
      //add extra css class name 
      event.target.parentElement.parentElement.classList.remove('not-selected-plan-checkbox');
      event.target.parentElement.parentElement.classList.add('selected-plan-checkbox');
    } else {
      //change css class name 
      event.target.parentElement.parentElement.classList.remove('selected-plan-checkbox');
      event.target.parentElement.parentElement.classList.add('not-selected-plan-checkbox');
    }
  };



  const isFormFilledTrue = (event) => {
    console.log('event', event.target);
    let currentStep = step;
    let isFormFilled = true;


    //get event parent form data
    const data = new FormData(event.target.form);
    console.log(data)
    if (!data) {
      setDisableContinue(true);
      return;
    }
    let user = {};
    for (let entry of data.entries()) {
      user[entry[0]] = entry[1];
    }
    console.log('user', user);
    let userJsonData = userJson;
    if (currentStep === 1) {
      if (user?.['billsOnTime-0'] === 'on' || user?.['billsOnTime-1'] === 'on' || user?.['billsOnTime-2'] === 'on' || billsOnTimeTextArea?.length > 0) {
        isFormFilled = false;
        userJsonData['billsOnTime'] = user;
        setUserJson(userJsonData);
      }
      if (goalsStatus?.billsOnTime === false) {
        // alert("skip")
      }
    }
    if (currentStep === 2) {
      if (user?.['lowUtilization-0'] === 'on' || user?.['lowUtilization-1'] === 'on' || user?.['lowUtilization-2'] === 'on' || lowUtilizationTextArea?.length > 0) {
        isFormFilled = false;
        userJsonData['lowUtilization'] = user;
        setUserJson(userJsonData);
      }
      if (goalsStatus?.lowUtilization === false) {
        // alert("skip")
      }
    }
    if (currentStep === 3) {
      if (user?.['noNewDebt-0'] === 'on' || user?.['noNewDebt-1'] === 'on' || user?.['noNewDebt-2'] === 'on' || noNewDebtTextArea?.length > 0) {
        isFormFilled = false;
        userJsonData['noNewDebt'] = user;
        setUserJson(userJsonData);
      }
      if (goalsStatus?.noNewDebt === false) {
        // alert("skip")
      }
    }
    console.log(userJsonData);
    setDisableContinue(isFormFilled);
    console.log(disableContinue)

  }


  const handleSubmitForm = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    setIsLoading(true);
    // let user = {};
    // for (let entry of data.entries()) {
    //   user[entry[0]] = entry[1];
    // }
    //create user list using userJson
    let user = {};
    user['billsOnTime-0'] = userJson?.['billsOnTime']?.['billsOnTime-0'];
    user['billsOnTime-1'] = userJson?.['billsOnTime']?.['billsOnTime-1'];
    user['billsOnTime-2'] = userJson?.['billsOnTime']?.['billsOnTime-2'];
    user['lowUtilization-0'] = userJson?.['lowUtilization']?.['lowUtilization-0'];
    user['lowUtilization-1'] = userJson?.['lowUtilization']?.['lowUtilization-1'];
    user['lowUtilization-2'] = userJson?.['lowUtilization']?.['lowUtilization-2'];
    user['noNewDebt-0'] = userJson?.['noNewDebt']?.['noNewDebt-0'];
    user['noNewDebt-1'] = userJson?.['noNewDebt']?.['noNewDebt-1'];
    user['noNewDebt-2'] = userJson?.['noNewDebt']?.['noNewDebt-2'];

    setFormData(user);
    setTimeout(() => {
      if (user) {
        try {
          console.log('user', user);

          let data = {
            "userId": userID,
            "assessmentMonth": datestring,
            "promises": {
              "automaticWithdrawal": user?.['billsOnTime-0'] === 'on' ? true : false,
              "spendLess": user?.['billsOnTime-1'] === 'on' ? true : false,
              "contactBiller": user?.['billsOnTime-2'] === 'on' ? true : false,
              "applySavingsToCreditCard": user?.['lowUtilization-0'] === 'on' ? true : false,
              "payMoreOnCreditCard": user?.['lowUtilization-1'] === 'on' ? true : false,
              "payOffCreditCard": user?.['lowUtilization-2'] === 'on' ? true : false,
              "chatBeforeNewDebt": user?.['noNewDebt-0'] === 'on' ? true : false,
              "noNewDebtUpcomingMonth": user?.['noNewDebt-1'] === 'on' ? true : false,
              "chatForDebtReduction": user?.['noNewDebt-2'] === 'on' ? true : false,
              "description1": billsOnTimeTextArea,
              "description2": lowUtilizationTextArea,
              "description3": noNewDebtTextArea
            }
          };
          api.sap(data).then(resp => resp?.data)
            .then(res => {

              let fetchSapData = {
                "userId": userID,
                "assessmentMonth": datestring,
              };
              api.fetchSap(fetchSapData).then(resp => resp?.data)
                .then(res1 => {
                  setIsLoading(false);
                  setConfirmationData(res1);
                  // closeAssessment();
                  console.log('submit success', res1);

                  // toast.dismiss();
                  // toast.success("Your plan is set and submitted!", {
                  //   position: toast.POSITION.TOP_CENTER,
                  // });
                });

            }).catch(err => {
              setIsLoading(false);
              console.log('submit contact error', err);
            });

        } catch (e) {
          setIsLoading(false);
          console.log('submit contact error', e);
        }
      }
    }, 2000);


  };
  const auth = useAuth();
  const userID = auth?.user?.id;

  const [firstStep, setFirstStep] = useState(getFirstStep());
  const [lastStep, setLastStep] = useState(getLastStep());
  const [totalSteps, setTotalSteps] = useState(getTotalSteps());


  function getFirstStep() {
    let firstStep = 1;
    /* see if can be deleted */

    if (goalsStatus?.billsOnTime === false) {
      firstStep = 1;
    } else if (goalsStatus?.lowUtilization === false) {
      firstStep = 2;
    } else if (goalsStatus?.noNewDebt === false) {
      firstStep = 3;
    }
    /* see if can be deleted */

    return firstStep;
  }
  function getLastStep() {
    let lastStep = 3;
    /* see if can be deleted */

    if (goalsStatus?.billsOnTime === false) {
      lastStep = 1;
    }


    if (goalsStatus?.lowUtilization === false) {
      lastStep = 2;
    }


    if (goalsStatus?.noNewDebt === false) {
      lastStep = 3;
    }
    /* see if can be deleted */

    return lastStep;
  }

  function getTotalSteps() {
    let totalSteps = 0;

    /* see if can be deleted */

    if (goalsStatus?.billsOnTime === false) {
      totalSteps = totalSteps + 1;
    }


    if (goalsStatus?.lowUtilization === false) {
      totalSteps = totalSteps + 1;
    }


    if (goalsStatus?.noNewDebt === false) {
      totalSteps = totalSteps + 1;
    }

    /* see if can be deleted */

    return totalSteps;
  }
  const [step, setStep] = useState(getLandingStep(1));


  function getLandingStep(step) {
    let latestStep = step;

    /* see if can be deleted */
    if (latestStep === 1) {
      if (goalsStatus?.billsOnTime !== false) {
        latestStep = latestStep + 1;
      }
    }

    if (latestStep === 2) {
      if (goalsStatus?.lowUtilization !== false) {
        latestStep = latestStep + 1;
      }
    }

    if (latestStep === 3) {
      if (goalsStatus?.noNewDebt !== false) {
        // latestStep = latestStep + 1;
        //no room to go next
      }
    }
    /* see if can be deleted */

    return latestStep;

  }


  console.log('landing step', step);
  console.log('current step', step);
  console.log('first step', firstStep);
  console.log('last step', lastStep);
  console.log('total step', totalSteps);

  const handleNext = () => {

    let latestStep = step + 1;

    /* see if can be deleted */
    if (latestStep === 1) {
      if (goalsStatus?.billsOnTime !== false) {
        latestStep = latestStep + 1;
      }
    }

    if (latestStep === 2) {
      if (goalsStatus?.lowUtilization !== false) {
        latestStep = latestStep + 1;
      }
    }

    if (latestStep === 3) {
      if (goalsStatus?.noNewDebt !== false) {
        // latestStep = latestStep + 1;
        //no room to go next
      }
    }
    /* see if can be deleted */

    setStep(latestStep);



    setDisableContinue(true);
  };
  const decideToShowSubmitBtn = () => {
    let show = false;
    if (step === 3) {
      show = true;
    }

    if (totalSteps === 1) {
      show = true;
    }


    if (step === lastStep) {
      show = true;
    }

    if (totalSteps === lastStep && lastStep <= totalSteps && step === lastStep) {
      show = true;
    }
    return show;
  };


  const decideToShowBackBtn = () => {
    let show = false;
    if (step > 1 && totalSteps > 1 && step !== firstStep) {
      show = true;
    }

    return show;
  };


  const handlePrevious = () => {

    let latestStep = step - 1;
    /* see if can be deleted */
    if (latestStep === 1) {
      if (goalsStatus?.billsOnTime !== false) {
        //latestStep = latestStep - 1;
        //no more room to go previous
      }
    }

    if (latestStep === 2) {
      if (goalsStatus?.lowUtilization !== false) {
        latestStep = latestStep - 1;
      }
    }

    if (latestStep === 3) {
      if (goalsStatus?.noNewDebt !== false) {
        latestStep = latestStep - 1;
      }
    }
    /* see if can be deleted */
    setStep(latestStep);

    setDisableContinue(true);
  };

  const feedback = (
    <>


      {confirmationData ? (<> </>) : (<>
        {/* <pre>{JSON.stringify(goalsStatus, null, 2)}</pre> */}
        <Form className="planForm" onSubmit={handleSubmitForm}

          onChange={isFormFilledTrue}
        >



          {step === 1 && (
            <Form.Group controlId="formStep1">
              <div id="Step1" >

                {goalsStatus?.billsOnTime === false ? (
                  <>
                    <div className="wideCntr d-grid gap-2" style={{ textAlign: 'left' }}>


                      {/* <pre>{JSON.stringify(userJson?.['billsOnTime'], null, 2)}</pre> */}

                      <ModalTitleBlock
                        title={`Set a plan for:`}
                        body={`"Pay all my bills on time"`}
                        icon={checkCircleIcon}
                      />

                      <br></br>
                      <div className="blueBubble toastbox-grey">
                        <p>
                          Setting a plan will help you reach your goal, create better financial habits and earn cash-back.
                        </p>
                      </div>

                      <hr></hr>
                      <p class="text-center">Select all that apply:</p>


                      <div key={`billsOnTime-0`} className="mb-3 form-control d-flex" >
                        <Form.Check // prettier-ignore
                          defaultChecked={userJson?.['billsOnTime']?.['billsOnTime-0']}
                          type={'checkbox'}
                          name={`billsOnTime-0`}
                          onClick={onChangeHandler}
                          label={``}
                        />
                        <p>I will set up automatic withdrawals, so funds come directly out of my account</p>
                      </div>

                      <div key={`billsOnTime-1`} className="mb-3 form-control d-flex">
                        <Form.Check // prettier-ignore
                          defaultChecked={userJson?.['billsOnTime']?.['billsOnTime-1']}
                          type={'checkbox'}
                          name={`billsOnTime-1`}

                          onClick={onChangeHandler}
                          label={``}
                        />
                        <p>I will spend less so I have money left to pay my bills</p>
                      </div>
                      <div key={`billsOnTime-2`} className="mb-3 form-control d-flex">
                        <Form.Check // prettier-ignore
                          defaultChecked={userJson?.['billsOnTime']?.['billsOnTime-2']}
                          type={'checkbox'}
                          name={`billsOnTime-2`}

                          onClick={onChangeHandler}
                          label={``}
                        />
                        <p>I will contact billers and align payment dates to my pay schedule</p>
                      </div>



                      <Form.Group className="" controlId="billsOnTimeTextArea">
                        <Form.Label>

                          Other, Please describe



                        </Form.Label>
                        <Form.Control style={{ backgroundColor: `#fff` }} as="textarea" value={billsOnTimeTextArea} rows={12} placeholder="Please describe" onChange={handleChangeBillsOnTimeTextArea} />
                        {/* {(formData?.freeFormQuestion?.length ===0 || !formData?.freeFormQuestion?.length) && <p className="error">*Required</p>} */}
                        <Form.Label>Total characters -

                          {billsOnTimeTextArea?.length ? billsOnTimeTextArea?.length : 0}

                          /100</Form.Label>

                      </Form.Group>

                      {/* <WideBtn disabled={1}>
              Continue
            </WideBtn>
            <TextButton onClick={closeAssessment} style={{ marginTop: 0 }}>
              Go back
            </TextButton> */}
                    </div>
                    <br></br>
                  </>
                ) : (<>



                  <br></br>

                  <br></br>
                  <br></br>
                  <article class="">
                    <div class="spaced earningLine">
                      {/* <h4>Awesome!!!</h4> */}
                      <br></br>
                    </div>

                    <div class="summaryLine">
                      <div class="spaced">
                        {/* <p>Pay Parachute loan on time. ✅</p> */}
                        <br></br>
                      </div>
                    </div>
                    <br></br>
                    <p>Please click continue/submit to proceed</p>
                  </article>   <br></br>

                  <br></br>
                  <br></br>



                </>)}

              </div>

            </Form.Group>
          )}
          {step === 2 && (
            <Form.Group controlId="formStep2">

              <div id="Step2">


                {goalsStatus?.lowUtilization === false ? (
                  <>
                    <div className="wideCntr d-grid gap-2" style={{ textAlign: 'left' }}>


                      <ModalTitleBlock
                        title={`Set a plan for:`}
                        body={`"Maintain credit card utilization of 50% or less"`}
                        icon={checkCircleIcon}
                      />

                      <br></br>
                      <div className="blueBubble toastbox-grey">
                        <p>
                          Setting a plan will help you reach your goal, create better financial habits and earn cash-back.
                        </p>
                      </div>

                      <hr></hr>
                      <p class="text-center">Select all that apply:</p>


                      <div key={`lowUtilization-0`} className="mb-3 form-control d-flex" >
                        <Form.Check // prettier-ignore
                          defaultChecked={userJson?.['lowUtilization']?.['lowUtilization-0']}
                          type={'checkbox'}
                          name={`lowUtilization-0`}

                          onClick={onChangeHandler}
                          label={``}
                        />
                        <p>I will save up to $50 this coming month and apply the saving to my credit card</p>
                      </div>

                      <div key={`lowUtilization-1`} className="mb-3 form-control d-flex">
                        <Form.Check // prettier-ignore
                          defaultChecked={userJson?.['lowUtilization']?.['lowUtilization-1']}
                          type={'checkbox'}
                          name={`lowUtilization-1`}

                          onClick={onChangeHandler}
                          label={``}
                        />
                        <p>I will pay more than the minimum amount on my credit card</p>
                      </div>
                      <div key={`lowUtilization-2`} className="mb-3 form-control d-flex">
                        <Form.Check // prettier-ignore
                          defaultChecked={userJson?.['lowUtilization']?.['lowUtilization-2']}
                          type={'checkbox'}
                          name={`lowUtilization-2`}

                          onClick={onChangeHandler}
                          label={``}
                        />
                        <p>I will pay off my credit card</p>
                      </div>



                      <Form.Group className="" controlId="lowUtilizationTextArea">
                        <Form.Label>

                          Other, Please describe



                        </Form.Label>
                        <Form.Control style={{ backgroundColor: `#fff` }} as="textarea" value={lowUtilizationTextArea} rows={12} placeholder="Please describe" onChange={handleChangeLowUtilizationTextArea} />
                        {/* {(formData?.freeFormQuestion?.length ===0 || !formData?.freeFormQuestion?.length) && <p className="error">*Required</p>} */}
                        <Form.Label>Total characters -

                          {lowUtilizationTextArea?.length ? lowUtilizationTextArea?.length : 0}

                          /100</Form.Label>

                      </Form.Group>


                      {/* <WideBtn disabled={1}>
              Continue
            </WideBtn>
            <TextButton onClick={closeAssessment} style={{ marginTop: 0 }}>
              Go back
            </TextButton> */}
                    </div>
                    <br></br>
                  </>
                ) : (<>



                  <br></br>

                  <br></br>
                  <br></br>
                  <article class="">
                    <div class="spaced earningLine">
                      {/* <h4>Awesome!!!</h4> */}
                      <br></br>
                    </div>

                    <div class="summaryLine">
                      <div class="spaced">
                        {/* <p>Maintain low credit utilization.  ✅</p> */}
                        <br></br>
                      </div>
                    </div>
                    <br></br>
                    <p>Please click continue/submit to proceed</p>
                  </article>   <br></br>

                  <br></br>
                  <br></br>
                </>)}

              </div>
            </Form.Group>
          )}
          {step === 3 && (
            <Form.Group controlId="formStep3">



              <div id="Step3">

                {goalsStatus?.noNewDebt === false ? (
                  <>
                    <div className="wideCntr d-grid gap-2" style={{ textAlign: 'left' }}>


                      <ModalTitleBlock
                        title={`Set a plan for:`}
                        body={`"I will not take on new debt"`}
                        icon={checkCircleIcon}
                      />

                      <br></br>
                      <div className="blueBubble toastbox-grey">
                        <p>
                          Setting a plan will help you reach your goal, create better financial habits and earn cash-back.
                        </p>
                      </div>

                      <hr></hr>
                      <p class="text-center">Select all that apply:</p>


                      <div key={`noNewDebt-0`} className="mb-3 form-control d-flex" >
                        <Form.Check // prettier-ignore
                          defaultChecked={userJson?.['noNewDebt']?.['noNewDebt-0']}
                          type={'checkbox'}
                          name={`noNewDebt-0`}

                          onClick={onChangeHandler}
                          label={``}
                        />
                        <p>I will chat to Parachute before taking on new debt</p>
                      </div>

                      <div key={`noNewDebt-1`} className="mb-3 form-control d-flex">
                        <Form.Check // prettier-ignore
                          defaultChecked={userJson?.['noNewDebt']?.['noNewDebt-1']}
                          type={'checkbox'}
                          name={`noNewDebt-1`}

                          onClick={onChangeHandler}
                          label={``}
                        />
                        <p>I will not take on new debt this coming month</p>
                      </div>
                      <div key={`noNewDebt-2`} className="mb-3 form-control d-flex">
                        <Form.Check // prettier-ignore
                          defaultChecked={userJson?.['noNewDebt']?.['noNewDebt-2']}
                          type={'checkbox'}
                          name={`noNewDebt-2`}

                          onClick={onChangeHandler}
                          label={``}
                        />
                        <p>I will chat with Parachute abount how i can reduce the additional debt i have taken out</p>
                      </div>




                      <Form.Group className="" controlId="noNewDebtTextArea">
                        <Form.Label>

                          Other, Please describe



                        </Form.Label>
                        <Form.Control style={{ backgroundColor: `#fff` }} as="textarea" value={noNewDebtTextArea} rows={12} placeholder="Please describe" onChange={handleChangeNoNewDebtTextArea} />
                        {/* {(formData?.freeFormQuestion?.length ===0 || !formData?.freeFormQuestion?.length) && <p className="error">*Required</p>} */}
                        <Form.Label>Total characters -

                          {noNewDebtTextArea?.length ? noNewDebtTextArea?.length : 0}

                          /100</Form.Label>

                      </Form.Group>
                      {/* <WideBtn disabled={1}>
              Continue
            </WideBtn>
            <TextButton onClick={closeAssessment} style={{ marginTop: 0 }}>
              Go back
            </TextButton> */}
                    </div>

                  </>
                ) : (<>


                  <br></br>

                  <br></br>
                  <br></br>
                  <article class="">
                    <div class="spaced earningLine">
                      {/* <h4>Awesome!!!</h4> */}
                      <br></br>
                    </div>

                    <div class="summaryLine">
                      <div class="spaced">
                        {/* <p>No New Debt.  ✅</p> */}
                        <br></br>
                      </div>
                    </div>
                    <br></br>
                    <p>Please click continue/submit to proceed</p>
                  </article>   <br></br>

                  <br></br>
                  <br></br>



                </>)}

              </div>
            </Form.Group>
          )}


          {/* <ProgressBar now={(step / 3) * 100} />
      <br></br> */}








          {(

            decideToShowSubmitBtn(
            ) === true

          ) && (
              <>
                {/* disabled={disableContinue} */}
                <WideBtn >
                  SUBMIT   {isLoading && (
                    <>
                      {/* <p>Submitting plan details, please wait...</p> */}
                      <Spinner
                        className="flinksSpinner"
                        animation="border"
                        size="md"
                      />
                    </>
                  )}
                </WideBtn>
              </>


            )}
        </Form>
        {/* <TextButton onClick={closeAssessment} style={{ marginTop: 0 }}>
          Go back
        </TextButton> */}
        <div className="justify-content-between">
          {/* disabled={disableContinue} */}
          {(

            decideToShowSubmitBtn() === false
          ) ? (
            <WideBtn variant="success" onClick={handleNext} >
              Continue
            </WideBtn>
          ) : (
            <><p></p></>
          )}

          {step === 1 && (
            // <Button variant="secondary" >
            //   Previous 
            // </Button>
            <><p></p></>
          )}
          {(
            decideToShowBackBtn() === true

          ) && (
              // <Button variant="secondary" onClick={handlePrevious}>
              //   Previous
              // </Button>

              <TextButton onClick={handlePrevious} style={{ marginTop: 0 }}>
                Go back
              </TextButton>
            )}
        </div>

      </>
      )}






      {confirmationData ? (

        <span className='planForm'>
          {/* <pre>{JSON.stringify(confirmationData, null, 2)}</pre> */}
          <div className="wideCntr d-grid gap-2" style={{ textAlign: 'left' }}>



            <ModalTitleBlock
              title={``}
              body={`Your plan has been set.`}
              icon={checkCircleIcon}
            />

            {/* display only if any one is true */
              confirmationData['promises']['automaticWithdrawal'] === true || confirmationData['promises']['spendLess'] === true || confirmationData['promises']['contactBiller'] === true || confirmationData['promises']['description1'] ? (
                <>
                  <br></br>
                  <hr></hr>
                  <h5>"Pay all my bills on time"</h5>
                  <Card>
                    <Card.Header><h7>Plan</h7></Card.Header>
                    <ListGroup variant="flush">
                      {confirmationData['promises']['automaticWithdrawal'] === true && (
                        <ListGroup.Item>I will set up automatic withdrawals, so funds come directly out of my account</ListGroup.Item>
                      )}

                      {confirmationData['promises']['spendLess'] === true && (

                        <ListGroup.Item>I will spend less so I have money left to pay my bills</ListGroup.Item>
                      )}
                      {confirmationData['promises']['contactBiller'] === true && (
                        <ListGroup.Item>I will contact billers and align payment dates to my pay schedule</ListGroup.Item>
                      )}

                      {confirmationData['promises']['description1'] && (
                        <ListGroup.Item>{confirmationData['promises']['description1']}</ListGroup.Item>
                      )}
                    </ListGroup>
                  </Card>

                </>) : (<></>)}

            {/* display only if any one is true */

              confirmationData['promises']['applySavingsToCreditCard'] === true || confirmationData['promises']['payMoreOnCreditCard'] === true || confirmationData['promises']['payOffCreditCard'] === true || confirmationData['promises']['description2'] ? (
                <>
                  <br></br>
                  <hr></hr>
                  <h5>"Maintain credit card utilization of 50% or less"</h5>
                  <Card>
                    <Card.Header><h7>Plan</h7></Card.Header>
                    <ListGroup variant="flush">
                      {confirmationData['promises']['applySavingsToCreditCard'] === true && (
                        <ListGroup.Item>I will save up to $50 this coming month and apply the saving to my credit card</ListGroup.Item>
                      )}
                      {confirmationData['promises']['payMoreOnCreditCard'] === true && (
                        <ListGroup.Item>I will pay more than the minimum amount on my credit card</ListGroup.Item>
                      )}
                      {confirmationData['promises']['payOffCreditCard'] === true && (
                        <ListGroup.Item>I will pay off my credit card</ListGroup.Item>
                      )}
                      {confirmationData['promises']['description2'] && (
                        <ListGroup.Item>{confirmationData['promises']['description2']}</ListGroup.Item>
                      )}
                    </ListGroup>
                  </Card>
                </>) : (<></>)}


            {/* display only if any one is true */
              confirmationData['promises']['chatBeforeNewDebt'] === true || confirmationData['promises']['noNewDebtUpcomingMonth'] === true || confirmationData['promises']['chatForDebtReduction'] === true || confirmationData['promises']['description3'] ? (
                <>
                  <br></br>
                  <hr></hr>
                  <h5>"I will not take on new debt"</h5>
                  <Card>
                    <Card.Header><h7>Plan</h7></Card.Header>
                    <ListGroup variant="flush">
                      {confirmationData['promises']['chatBeforeNewDebt'] === true && (
                        <ListGroup.Item>I will chat to Parachute before taking on new debt</ListGroup.Item>
                      )}
                      {confirmationData['promises']['noNewDebtUpcomingMonth'] === true && (
                        <ListGroup.Item>I will not take on new debt this coming month</ListGroup.Item>
                      )}
                      {confirmationData['promises']['chatForDebtReduction'] === true && (
                        <ListGroup.Item>I will chat with Parachute abount how i can reduce the additional debt i have taken out</ListGroup.Item>
                      )}
                      {confirmationData['promises']['description3'] && (
                        <ListGroup.Item>{confirmationData['promises']['description3']}</ListGroup.Item>
                      )}
                    </ListGroup>
                  </Card>
                </>) : (<></>)


            }

          </div>


          <WideBtn onClick={closeAssessment}>
            Confirm
          </WideBtn>
        </span>

      ) : (<></>)}









    </>




  );

  const thanks = (
    <>
      <ModalTitleBlock title="Thanks for the feedback!" body="We’ll get back to you soon." icon={achievementIcon} />
      <hr />
      <ModalTitleBlock
        className="thanks"
        title="Get on track next month."
        body="Try again to reach the goal for next month and you can earn cash-back! The first step is to commit."
      />
      <WideBtn onClick={closeAssessment}>Stay on track next month</WideBtn>
    </>
  );
  return (
    <div id="tellMore">
      {mode === 'feedback' && feedback}
      {mode === 'thanks' && thanks}
    </div>
  );
}
