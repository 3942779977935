import React, { useState, useEffect } from 'react';
import ClientView from '../../../views/ClientView';
// import FreemiumView from '../../../views/FreemiumView';
import UserSearch from './UserSearch';
import UserActivities from './UserActivities/Index';
import Button from 'react-bootstrap/Button';
import { formatDateForDisplay } from '../../../utils/helpers';
import FreemiumView from '../../../views/FreemiumView';
import EmployeeView from '../../../views/EmployeeView';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import cookies from '../../../utils/cookies';

import Form from 'react-bootstrap/Form';
import api from '../../../utils/api';
import { toast } from 'react-toastify';
export default function Index() {
  const [userToView, setUserToView] = useState(null); //{ id: userID, username: username, name: name, link: userIDLink, isOnboardComplete }
  const [restoreUser, setRestoreUser] = useState(null);
  const [mxDetails, setMxDetails] = useState(null);
  const [partner_id, setPartnerID] = useState(null);


  const [partners, setPartners] = useState(null);

  const fetchAllPartners = async () => {
    try {
      const rawData = await api.fetchAllPartners();

      const partners = rawData.data.filter((x) => x);
      setPartners(partners);
      // alert(JSON.stringify(partners))
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    fetchAllPartners();
  }, []);

  const handlePartnerSelection = (e) => {
    setPartnerID(e.target.value);
  };
  const submitPartner = () => {
    if (!partner_id) {
      showToast(`Please select a partner`);
      return;
    };
    try {
      let data = {
        "userId": userToView?.id,
        "partnerId": partner_id
      };
      // alert(JSON.stringify(data));
      api.submitPartner(data).then((res) => {
        if (res) {
          showToast(`Set partner successful`);
          setTimeout(() => {
            window.location.href = `./userViewer?username=${userToView?.username}`;
          }, 3000);
        }
      }).catch((e) => {
        console.error(e);
      });


    } catch (e) {
      console.log('e', e);
      // const errorResponse = `${e?.response.data.status} - ${e?.response.data.error} - ${e?.response.data.message}`;
      showToast(`Failed to send set partner`);
      // console.log('errorResponse', errorResponse);
    }
  };
  const refreshUserViewer = () => {
    const currView = userToView;
    setUserToView(null);
    setRestoreUser(currView);
  };
  /* Send User Activate Email */
  const activateUser = async (data) => {
    try {
      await api.activateUser(data);
      showToast(`User activation email sent successfully`);
    } catch (e) {
      console.log('e', e);
      const errorResponse = `${e?.response.data.status} - ${e?.response.data.error} - ${e?.response.data.message}`;
      showToast(`Failed to send user activation email`);
      console.log('errorResponse', errorResponse);
    }
  };
  const showToast = async (msg) => {
    try {
      toast.dismiss();
      toast.info(msg, {
        position: toast.POSITION.TOP_CENTER
      });
    } catch (error) {
      console.log('error', error);
    }

  }
  const copyToClipboard = () => {
    showToast('Copied to clipboard!');
  };

  useEffect(() => {
    try {
      if (userToView?.id) {
        api.mxDetails(userToView?.id)
          .then(resp => resp?.data)
          .then(res => {

            setMxDetails(res);


          }).catch(err => {
            console.log(err);

          });
      }



    } catch (error) {
      console.log(error);
    }
  }, [userToView]);

  useEffect(() => {

    if (restoreUser) {
      setUserToView(restoreUser);
      setRestoreUser(null);


    }
  }, [restoreUser]);

  const enableDisableUser = (user) => {


    try {
      let data = {
        userID: user?.id,
        flag: !user?.enabled
      }

      api.enableDisableUser(data).then((res) => {
        if (res) {
          showToast(`User ${user?.enabled ? 'Disabled' : 'Enabled'} Successfully`);
          setTimeout(() => {
            window.location.href = `./userViewer?username=${userToView?.username}`;
          }, 3000);

        }
      });



    } catch (e) {
      console.error(e);
    }
  };

  const getPartnerName = () => {
    if (partners) {
      console.log('partners', partners);
      const partner = partners.find((x) => x.id.toString() === (partner_id));
      return partner?.name;
    }
    return 'N/A';
  };

  return (
    <div>
      <UserSearch setUserToView={setUserToView} userToView={userToView} />
      <hr />

      {/* <pre>{JSON.stringify(mxDetails, null, 2)}</pre> */}

      {userToView && (
        <>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3>{`Viewing: ${userToView?.name} - ${userToView?.username} - User ID: ${userToView?.id} (READ ONLY)`}</h3>
              <p>{`Loan Start: ${formatDateForDisplay(userToView.loanStartDate)}`}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>{`Onboarding Status: ${userToView.isOnboardComplete ? 'Complete' : 'N/A'} `}</p>
              <p>{`TKL Loan ID: ${userToView.turnkeyLoanID}`}</p>
              <p>{`Curr Credit Limit: ${userToView.creditLimit}`}</p>
            </div>


            {mxDetails?.guid ? (<>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p><u>{`MX guid:`}</u> {`${mxDetails?.guid ? mxDetails?.guid : 'N/A'} `}  <CopyToClipboard text={userToView?.member_guid ? userToView.member_guid : 'N/A'}>
                  <Button onClick={copyToClipboard} className="modalBtn" variant="success" size="sm">Copy</Button>
                </CopyToClipboard></p>
                <p>{`MX disabled?: ${mxDetails?.is_disabled} `} </p>
              </div>
            </>) : (<></>)}



          </div>
          <div className="viewerActions" style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* <pre>{JSON.stringify(userToView, null, 2)}</pre> */}

            <Button onClick={refreshUserViewer}>Refresh User View</Button>
            {userToView &&
              <>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => enableDisableUser(userToView)}
                >
                  {userToView?.enabled ? 'Disable' : 'Enable'} User
                </Button>

                <Button size="sm" onClick={() => activateUser({ email: userToView?.username })}>
                  Send Activation Email
                </Button>

                <Button
                  variant="dark"
                  size="sm"
                  target='_blank'
                  href={`../admin/TradeViewer/${userToView?.id}`}
                >
                  Trade
                </Button>
              </>


            }
            <span>
              <a href="#clientViewer">Client View</a> <a href="#userActivities">Activities</a>
            </span>




          </div>

          {partners && (

            <>
              <br />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>



                <Form.Group className="mb-3" controlId="partner_id">
                  <Form.Label>Choose Partner</Form.Label>

                  <Form.Select
                    aria-label="Partner"
                    placeholder={`Partner`}
                    onChange={handlePartnerSelection}
                    value={partner_id}
                  >
                    <option value={null}>
                      Select
                    </option>
                    {partners.map((i) => (
                      <option key={i?.id} value={i?.id}>
                        {i?.name}
                      </option>
                    ))}
                  </Form.Select>

                  <Form.Text className="text-muted">Set New Partner: {getPartnerName()}</Form.Text>
                  <br />
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={submitPartner}
                  >
                    Submit
                  </Button>
                </Form.Group>

                {userToView?.partner?.name && <>


                  <span style={{ gap: '12px' }}>
                  
                    <p>{`Current Partner:`} {userToView?.partner?.name}</p>
                    <img style={{width:'150px'}} src={userToView?.partner?.cdn} alt={userToView?.partner?.name} />
                  </span>
                </>}


              </div>
            </>
          )}



          <br />
          <div
            id="clientViewer"
            style={{
              border: '2px dashed orange',
            }}
          >

            {userToView && userToView?.role === 'ROLE_USER' && <ClientView overrideUserID={userToView?.id} />}
            {userToView && userToView?.role === 'ROLE_FREEMIUM' && <FreemiumView overrideUserID={userToView?.id} />}
            {userToView && userToView?.role === 'ROLE_EMPLOYEE' && <EmployeeView overrideUserID={userToView?.id} />}

          </div>
          <UserActivities userToView={userToView} />
        </>
      )}

      {/* {userToView && (
        <>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3>{`Viewing: ${userToView?.name} - ${userToView?.username} - User ID: ${userToView?.id} (READ ONLY)`}</h3>
              <p>{`Loan Start: ${formatDateForDisplay(userToView.loanStartDate)}`}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>{`Onboarding Status: ${userToView.isOnboardComplete ? 'Complete' : 'N/A'} `}</p>
              <p>{`TKL Loan ID: ${userToView.turnkeyLoanID}`}</p>
              <p>{`Curr Credit Limit: ${userToView.creditLimit}`}</p>
            </div>
          </div>
          <div className="viewerActions" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={refreshUserViewer}>Refresh User View</Button>
            <span>
              <a href="#freemiumViewer">Client View</a> <a href="#userActivities">Activities</a>
            </span>
          </div>
          <div
            id="freemiumViewer"
            style={{
              border: '2px dashed orange',
            }}
          >
            {userToView && <FreemiumView overrideUserID={userToView?.id} />}
          </div>
          <UserActivities userToView={userToView} />
        </>
      )} */}
    </div>
  );
}
