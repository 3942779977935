import React, { useState, useEffect } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

export default function ErrorToast({ clearError, error }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // if (error) setShow(true);
    error ? setShow(true) : setShow(false);
  }, [error]);

  const errorMsg = error?.response?.data?.message;
  const errorID = error?.response?.data?.id;

  const handleClose = () => {
    clearError();
    setShow(false); //Close toast
  };
  return (
    <ToastContainer className="p-3" position={'middle-center'}>
      <Toast show={show} onClose={handleClose} bg="warning">
        <Toast.Header closeButton={true}>
          {/* <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" /> */}
          <strong className="me-auto">{`Error ${errorID}`}</strong>
          {/* <small>11 mins ago</small> */}
        </Toast.Header>
        <Toast.Body>{`${errorMsg || error}`}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
