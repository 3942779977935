import React, { useState, useEffect } from 'react';
import CardContainer from '../CardContainer';
import CardTitleWTip from '../CardTitleWTip';
import useAuth from '../../hooks/useAuth';
import LoanTile from './TileLoan';
import UtilizationTile from './TileUtilization';
import BillTile from './TileBills';
import DebtTile from './TileDebt';
import cookies from '../../utils/cookies';
import { displayMonth, findDaysFromToday, createDueDateMsg } from '../../utils/helpers';
// import isThisMonth from 'date-fns/isThisMonth';
import { TOOLTIP } from '../../utils/constants';
import './Index.scss';
import api from '../../utils/api';
import { MONTH_NUM_TO_NAME, USER_TYPE } from '../../utils/constants';
export default function MonthlyGoalCard({overrideUserID, data }) {
  let auth = useAuth();
  const today = cookies.getToday();
  const savedUserID = overrideUserID || cookies.getUserID();
  const PAYMENT = {
    //[] Convert dueMessage to use THIS
    isCurrMonthPaid: data?.loan?.isCurrMonthPaid,
    // data?.loan?.nextPaymentDate && isThisMonth(new Date(data?.loan?.nextPaymentDate))
    //   ? false
    //   : data?.loan?.isCurrMonthPaid,
    nextDueDate: data?.loan?.nextPaymentDate, // as per tlLoan (could be this month or next month...) used to display modal date
    nextPaymentAmount: data?.loan?.nextPaymentAmount,
    dueInXDays: data?.loan?.currMonthDueDate && findDaysFromToday(new Date(data.loan?.currMonthDueDate)),
    thisDueMsg: function () {
      return createDueDateMsg(this.dueInXDays, this.isCurrMonthPaid);
    },
    // dueMessage:
    // data?.loan?.nextPaymentDate &&
    // createDueDateMsg(findDaysFromToday(new Date(data.loan?.nextPaymentDate)), data?.loan?.isCurrMonthPaid),
  };
  useEffect(() => {
    fetchPlanDetails(savedUserID);
  }, []);
  const DEBT = {
    // [] API TBC
    utilization: data?.debt?.utilizedAmount,
    totalDebt: data?.debt?.total,
    utilizationPercent:
      data?.debt?.utilizationPercent && Number.parseFloat(data?.debt?.utilizationPercent * 100).toFixed(0),

    // function () {
    //   const percent = (this.utilization / this.totalDebt) * 100;
    //   const x = Number.parseFloat(percent).toFixed(0);
    //   return isNaN(x) ? null : x;
    // },
  };
  const currentMonth = displayMonth(cookies.getDate());
  
  const savedRole = auth?.user?.role || cookies.getUserRole();
  
  const [planDetails, setPlanDetails] = useState(null);
  const fetchPlanDetails = (userID) => {
    if (!userID) return;

    console.log(userID)

    let today = new Date();
    today.setMonth(today.getMonth() - 1);
  
    let year = today.getFullYear();
    let month = (today.getMonth() + 1).toString().padStart(2, '0');
    let datestring = `${year}-${month}`;
  

    let fetchSapData = {
      "userId": userID,
      "assessmentMonth": datestring,
    };
    
    api.fetchSapAdmin(fetchSapData).then(resp => resp?.data)
      .then(res1 => {
        if (!res1) {
          // showToast(res1?.message || 'No plan details to display');
          return;
        }
        setPlanDetails(res1);
        console.log('submit success', res1);


      }).catch(err => {
        // showToast(err?.response?.data?.message || 'No plan details to display');

        console.log('submit contact error', err);
      });

  }

  return (
    <CardContainer id="monthlyGoalCard" className="divided">
      <div className="goalHeader">

        {savedRole === 'ROLE_FREEMIUM' ? (<CardTitleWTip tipObject={TOOLTIP.MONTHLY_FREEMIUM_GOAL}>Monthly Goal</CardTitleWTip>) : (<CardTitleWTip tipObject={TOOLTIP.MONTHLY_GOAL}>Monthly Goal</CardTitleWTip>)}


        <h1>{currentMonth}</h1>

      </div>

      <div className="goalTiles">


        {savedRole !== 'ROLE_FREEMIUM' && <LoanTile PAYMENT={PAYMENT} />}



        <UtilizationTile planDetails={planDetails} DEBT={DEBT} />
        <BillTile planDetails={planDetails}/>
        <DebtTile planDetails={planDetails}/>



      </div>
    </CardContainer>
  );
}
