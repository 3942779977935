import React, { useState, useEffect } from 'react';
import LogoLink from '../components/LogoLink';
import NewApplication from '../components/NewApplication/Index';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { LINKS } from '../utils/constants';
import SupportLink from '../components/NewApplication/SupportLink';
import './ApplyView.scss';

export default function ApplyView() {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleIsMobile = () => setIsMobileView(window.document.body.clientWidth < 1250);
    window.addEventListener('resize', handleIsMobile);
    handleIsMobile();
    return () => {
      window.removeEventListener('resize', handleIsMobile);
    };
  }, [setIsMobileView]);

  const legal = ['terms', 'Privacy'];
  const parsedLegalLinks = legal.map((x, i) => {
    return (
      <li key={`footLink-${i}-${x}`}>
        <h3>
          <a href={LINKS[x.toLowerCase()]}>{x === 'terms' ? 'Terms of Use' : x}</a>
        </h3>
      </li>
    );
  });
  const [formProgress, setFormProgress] = useState(0);

  return (
    <div id="preQualAppView">
      <header id="applyHeader">
        <LogoLink overrideLink="" />
        {!isMobileView && (
          <>
            {formProgress !== null && (
              <span className="progressSpan">
                <ProgressBar now={formProgress} />
                <p className="progressPercent">{`${formProgress}%`}</p>
              </span>
            )}
            <SupportLink />
          </>
        )}

        {isMobileView && (
          <>
            <div id="mobileHeaderDivider" />
            <SupportLink noIcon />
            {formProgress !== null && (
              <span className="progressSpan">
                <ProgressBar now={formProgress} />
                <p className="progressPercent">{`${formProgress}%`}</p>
              </span>
            )}
          </>
        )}
      </header>

      <NewApplication setFormProgress={setFormProgress} />

      <footer id="applyFooter">
        <div id="applyFootContent">
          <span>
            <LogoLink dark />
          </span>
          <div className="footerContent">
            <div className="legal">
              <p className="xSmall muted">
                © 2022 WHF Inc. All rights reserved. <a href="https://myparachute.co">Myparachute.co</a> and the{' '}
                <a href="https://myparachute.co">Myparachute.co</a> designs are trademarks of White Hat Financial Inc.
                (‘WHF’) and used here under licence. *
              </p>
            </div>
            <ul className="legalLinks links">{parsedLegalLinks}</ul>
          </div>
        </div>
      </footer>
    </div>
  );
}
