import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import api from '../../../../utils/api';
import { formatDateForDisplay, parseIdFromLink } from '../../../../utils/helpers';

export default function UserWellnessScores({ userToView, index }) {
  const { id: userID, link: userLink } = userToView;

  const [userData, setUserData] = useState(null);
  const [status, setStatus] = useState({ loading: false, error: null });

  useEffect(() => {
    const getUserActivity = async () => {
      const rawRes = await api.getUsersWellnessScores(userID);
      const userWellnessScores = rawRes.data._embedded.wellnessScores;
      setUserData(userWellnessScores);
    };
    getUserActivity();
    //eslint-disable-next-line
  }, []);

  const validateScore = (inputScore) => {
    if (!inputScore) return 'Must enter a score';
    if (isNaN(inputScore)) return 'Score must be a valid number between 0 to 100';
    if (inputScore < 0 || inputScore > 100) return 'Score must be a number between 0 and 100';
    return true;
  };
  const submitWellnessScore = async (e) => {
    e.preventDefault();
    const score = e.target[0].value;
    //Convert Time to ISO and set time to 0400hr
    const scoreDate =
      e.target[1].value && new Date(e.target[1].value).toISOString().replace('00:00:00.000Z', '12:00:00.000Z');
    //Upload wellness core
    try {
      setStatus({ loading: true, error: null });
      //Validate Input #'s only max 100
      if (validateScore(score) !== true) {
        throw new Error(validateScore(score));
      }
      const res = await api.saveWellnessSurvey({ user: userLink, score, scoreDate: scoreDate });
      //Re-render component /update state (scores)
      setUserData([res.data]);
      setStatus({ loading: false, error: null });
    } catch (e) {
      setStatus({ loading: false, error: `${e}` });
    }
  };
  console.log('userData', userData);
  return (
    <>
      <Accordion.Item eventKey={index}>
        <Accordion.Header>Wellness Scores - {userData?.length ?? '0'}</Accordion.Header>
        <Accordion.Body>
          {userData?.length > 0 && (
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Record ID</th>
                  <th>Date Scored</th>
                  <th>Score</th>
                  <th>Responses</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(userData)
                  .sort(
                    (x, y) =>
                      y.scoreDate?.localeCompare(x.scoreDate) || y._links.self.href?.localeCompare(x._links.self.href)
                  )
                  .map((q, i) => {
                    return (
                      <tr key={`userWellness-${i}`}>
                        <td>{parseIdFromLink(q?._links.self.href)}</td>
                        <td>{formatDateForDisplay(q?.scoreDate)}</td>
                        <td>{q?.score}</td>
                        <td>
                          {q?.a0 ? (
                            <OverlayTrigger
                              trigger="hover"
                              placement="right"
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Header as="h3">
                                    {`${formatDateForDisplay(q?.scoreDate)} - ${parseIdFromLink(q?._links.self.href)}`}
                                  </Popover.Header>
                                  <Popover.Body>
                                    <ol>
                                      <li>{`${q?.a0}`}</li>
                                      <li>{`${q?.a1}`}</li>
                                      <li>{`${q?.a2}`}</li>
                                      <li>{`${q?.a3}`}</li>
                                      <li>{`${q?.a4}`}</li>
                                    </ol>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <Button variant="secondary">Details</Button>
                            </OverlayTrigger>
                          ) : (
                            'Nil'
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              <caption className="muted">
                *Max Score 100 <br /> User is presented with wellness questions during monthly self assessment
              </caption>
            </Table>
          )}
          {userData?.length === 0 && (
            <Form onSubmit={submitWellnessScore}>
              <Form.Text className="text-muted">No wellness score results for this user</Form.Text>

              <Form.Group className="mb-3" controlId="initialWellnessScore">
                <Form.Label>Wellness Score (Initial)</Form.Label>
                <Form.Control disabled={status.loading} placeholder="Enter score between 0 and 100" />
                <Form.Text className="text-muted">Users pre-qual wellness score (From Hubspot)</Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="initialScoreDate">
                <Form.Label>Date of Score</Form.Label>
                <Form.Control disabled={status.loading} type="date" />
              </Form.Group>
              <Button type="submit" disabled={status.loading}>
                {status.loading ? 'Saving...' : 'Add Wellness Score'}
              </Button>
              {status.error && <p className="error">{status.error}</p>}
            </Form>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
}
