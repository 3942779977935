import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';

// import Button from 'react-bootstrap/Button';
// import SimpleField from './SimpleField';
import FieldGroup from './FieldGroup';
import { INDIVIDUALS } from '../../utils/A-Dashboard/constants';

const determineFieldsKey = (type) => {
  switch (type) {
    case 'address':
      return 'addresses';
    case 'url':
      return 'links';
    case 'email':
      return 'emails';
    case 'phone':
      return 'phones';
    default:
      break;
  }
};

export default function IndividualForm({ formData, updateList, isReadOnly }) {
  const parentType = 'indv'; //Used in simple forms to specify form type
  const [individualForm, setIndividualForm] = useState(formData);
  useEffect(() => {
    /* When form updates, triggers update @ top level state */
    updateList && updateList('INDIVIDUAL', individualForm);
    //eslint-disable-next-line
  }, [individualForm]);

  const handleChange = (e, subField) => {
    e.preventDefault();
    const value = e.target.value;
    const field = e.target.id;
    if (subField) {
      return setIndividualForm((p) => {
        return {
          ...p,
          [subField]: {
            ...p[subField],
            [field]: value,
          },
        };
      });
    }
    setIndividualForm((p) => {
      return { ...p, [field]: value };
    });
  };

  const addField = (fieldType) => {
    //Used to add a field to the applicable array in state (ie addresses, phones, emails etc...)
    const keyToUpdate = determineFieldsKey(fieldType);
    let prevFieldData = individualForm[keyToUpdate];
    const fieldToAdd = { [fieldType]: '', type: '' };
    keyToUpdate &&
      prevFieldData &&
      setIndividualForm((p) => {
        return { ...p, [keyToUpdate]: [...prevFieldData, fieldToAdd] };
      });
  };

  const updateField = (fieldType, index, newData) => {
    const keyToUpdate = determineFieldsKey(fieldType);
    const newFields = individualForm[keyToUpdate];
    newFields[index] = newData;
    setIndividualForm((p) => {
      return { ...p, [keyToUpdate]: newFields };
    });
  };

  const removeField = (fieldType, index) => {
    const keyToUpdate = determineFieldsKey(fieldType);
    const oldData = individualForm[keyToUpdate];
    oldData.splice(index, 1); //Removes from array
    const newData = oldData;
    setIndividualForm((p) => {
      return { ...p, [keyToUpdate]: newData };
    });
  };

  const showSection = (dataObject, readOnly) => {
    const isDataPresent = dataObject && Object.values(dataObject).some((x) => x);
    if (readOnly && !isDataPresent) return false;
    return true;
  };

  return (
    <>
      {/* ===========================================================Invidual FORM */}
      <Row>
        <Form.Group as={Col} controlId="legal_name">
          <Form.Label>Legal Name</Form.Label>
          <Form.Control
            placeholder="Enter Legal Name"
            onChange={handleChange}
            value={formData.legal_name}
            disabled={isReadOnly}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="role">
          <Form.Label>Role*</Form.Label>
          <Form.Select defaultValue="" onChange={handleChange} value={formData.role} disabled={isReadOnly} required>
            {INDIVIDUALS.role.map((item, i) => (
              <option key={`i-role-opt-${i}`}>{item}</option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>

      <Row>
        <Form.Group as={Col} controlId="sin">
          <Form.Label>SIN</Form.Label>
          <Form.Control placeholder="Enter SIN" onChange={handleChange} value={formData.sin} disabled={isReadOnly} />
        </Form.Group>
        <Form.Group as={Col} controlId="dob">
          <Form.Label>DoB (YYY-MM-DD)</Form.Label>
          <Form.Control placeholder="2020-12-30" onChange={handleChange} value={formData.dob} disabled={isReadOnly} />
        </Form.Group>
      </Row>

      <FieldGroup
        fieldID="address"
        label="Address"
        placeholder="Full Address; 1234 Main St, Place Ontario, L3L 5L3"
        typeOptions={INDIVIDUALS.ADDRESS_TYPES}
        parentType={parentType}
        fieldData={formData.addresses}
        addFieldToIndvForm={addField}
        updateField={updateField}
        removeField={removeField}
        isReadOnly={isReadOnly}
      />

      <FieldGroup
        fieldID="phone"
        label="Phone"
        placeholder="555 555 5555"
        typeOptions={INDIVIDUALS.PHONE_TYPES}
        parentType={parentType}
        fieldData={formData.phones}
        addFieldToIndvForm={addField}
        updateField={updateField}
        removeField={removeField}
        isReadOnly={isReadOnly}
      />

      <FieldGroup
        fieldID="email"
        label="Email"
        placeholder="smith@example.com"
        typeOptions={INDIVIDUALS.EMAIL_TYPES}
        parentType={parentType}
        fieldData={formData.emails}
        addFieldToIndvForm={addField}
        updateField={updateField}
        removeField={removeField}
        isReadOnly={isReadOnly}
      />
      <FieldGroup
        fieldID="url"
        label="Web URL"
        placeholder="https://example.com"
        typeOptions={INDIVIDUALS.LINK_TYPE}
        parentType={parentType}
        fieldData={formData.links}
        addFieldToIndvForm={addField}
        updateField={updateField}
        removeField={removeField}
        isReadOnly={isReadOnly}
      />

      {showSection(formData.drivers_license, isReadOnly) && (
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Drivers Licence</Accordion.Header>
            <Accordion.Body>
              {/* <h5>Drivers Licence</h5> */}
              <Row>
                <Form.Group as={Col} className="mb-3" controlId="number">
                  <Form.Label>Number</Form.Label>
                  <Form.Control
                    placeholder="Enter DL number"
                    value={formData.drivers_license?.number}
                    disabled={isReadOnly}
                    onChange={(e) => handleChange(e, 'drivers_license')}
                  />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="expired">
                  <Form.Label>Expiry (YYYY-MM-DD)</Form.Label>
                  <Form.Control
                    placeholder="Enter DL Expiry"
                    value={formData.drivers_license?.expired}
                    disabled={isReadOnly}
                    onChange={(e) => handleChange(e, 'drivers_license')}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className="mb-3" controlId="province">
                  <Form.Label>Issuing Province (2 Ltr Abrv)</Form.Label>
                  <Form.Control
                    placeholder="Enter Prov ie ON"
                    value={formData.drivers_license?.province}
                    disabled={isReadOnly}
                    onChange={(e) => handleChange(e, 'drivers_license')}
                  />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="country">
                  <Form.Label>Issuing Country (Abrv)</Form.Label>
                  <Form.Control
                    placeholder="Default is CA"
                    value={formData.drivers_license?.country}
                    disabled={isReadOnly}
                    onChange={(e) => handleChange(e, 'drivers_license')}
                  />
                </Form.Group>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}

      {showSection(formData.passport, isReadOnly) && (
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Passport</Accordion.Header>
            <Accordion.Body>
              <Row>
                <Form.Group as={Col} className="mb-3" controlId="number">
                  <Form.Label>Number</Form.Label>
                  <Form.Control
                    placeholder="Enter Passport number"
                    value={formData.passport?.number}
                    disabled={isReadOnly}
                    onChange={(e) => handleChange(e, 'passport')}
                  />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="issued">
                  <Form.Label>Issued</Form.Label>
                  <Form.Control
                    placeholder="Enter Date ie 2022-04-25"
                    value={formData.passport?.issued}
                    disabled={isReadOnly}
                    onChange={(e) => handleChange(e, 'passport')}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className="mb-3" controlId="expired">
                  <Form.Label>Expiry</Form.Label>
                  <Form.Control
                    placeholder="Enter Date ie 2022-04-25"
                    value={formData.passport?.expired}
                    disabled={isReadOnly}
                    onChange={(e) => handleChange(e, 'passport')}
                  />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="country">
                  <Form.Label>Issuing Country (Abr)</Form.Label>
                  <Form.Control
                    placeholder="Default is CA"
                    value={formData.passport?.country}
                    disabled={isReadOnly}
                    onChange={(e) => handleChange(e, 'passport')}
                  />
                </Form.Group>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}

      {showSection(formData.omvic_registration_certificate, isReadOnly) && (
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>OMVIC Info</Accordion.Header>
            <Accordion.Body>
              {/* <h5>OMVIC</h5> */}
              <Row>
                <Form.Group as={Col} className="mb-3" controlId="registration_number">
                  <Form.Label>Registration or Licence Number</Form.Label>
                  <Form.Control
                    placeholder="Enter number"
                    value={formData.omvic_registration_certificate?.registration_number}
                    disabled={isReadOnly}
                    onChange={(e) => handleChange(e, 'omvic_registration_certificate')}
                  />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="name">
                  <Form.Label>Name on Certificate</Form.Label>
                  <Form.Control
                    placeholder="Individual as printed on the certificate."
                    value={formData.omvic_registration_certificate?.name}
                    disabled={isReadOnly}
                    onChange={(e) => handleChange(e, 'omvic_registration_certificate')}
                  />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="expired">
                  <Form.Label>Cert Expiry</Form.Label>
                  <Form.Control
                    placeholder="Enter Date ie 2022-04-25"
                    value={formData.omvic_registration_certificate?.expired}
                    disabled={isReadOnly}
                    onChange={(e) => handleChange(e, 'omvic_registration_certificate')}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} className="mb-3" controlId="dealer_registration_number">
                  <Form.Label>Dealer Reg Number</Form.Label>
                  <Form.Control
                    placeholder="Registration or license number of the dealership."
                    value={formData.omvic_registration_certificate?.dealer_registration_number}
                    disabled={isReadOnly}
                    onChange={(e) => handleChange(e, 'omvic_registration_certificate')}
                  />
                </Form.Group>
                <Form.Group as={Col} className="mb-3" controlId="dealer_name">
                  <Form.Label>Dealer Name</Form.Label>
                  <Form.Control
                    placeholder="Dealership as printed on the certificate."
                    value={formData.omvic_registration_certificate?.dealer_name}
                    disabled={isReadOnly}
                    onChange={(e) => handleChange(e, 'omvic_registration_certificate')}
                  />
                </Form.Group>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
}
