import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import api from '../../../utils/api';
import { parseIdFromLink } from '../../../utils/helpers';

export default function UserSearch({ setUserToView, userToView }) {
  const [usersEmail, setUsersEmail] = useState('');
  const [dataToAPI, setDataToAPI] = useState(null);
  const [status, setStatus] = useState({ loading: false, error: null });
  const [urlEmail, setUrlEmail] = useState('');

  useEffect(() => {
    const urlParams = new URL(window.location);
    setUrlEmail(urlParams?.searchParams.get('username'));
    setDataToAPI(urlParams?.searchParams.get('username'));
  }, [urlEmail]);

  useEffect(() => {
    //Set window param of userToview
    if (!userToView?.username) return;
    const newURL = new URL(window.location);
    console.table(newURL)
    newURL.searchParams.set('viewing', userToView?.username);
  }, [userToView?.username]);

  const handleChange = (e) => {
    const value = e.target.value;
    setUsersEmail(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //Validate Email
    //Trigger API
    setDataToAPI(usersEmail);
  };
  useEffect(() => {
    const findUserID = async () => {
      setUserToView(null);
      setStatus({ loading: true, error: null });
      try {
        // alert("hi")
        const res = await api.getUserByEmail(dataToAPI);
        const searchResults = res.data;
   
        if (searchResults) {
          const userIDLink = searchResults?.url;
          const userID = parseIdFromLink(userIDLink);

          const rawUserDetails = await api.getUserData(userID);
          const userDetails = rawUserDetails.data;

          const name = `${searchResults?.firstName} ${searchResults?.lastName}`;
          const username = searchResults?.username;
          // alert(JSON.stringify(userDetails?.partner))
          setUserToView({
            id: userID,
            username: username,
            name: name,
            link: userIDLink,
            isOnboardComplete: userDetails.completeOnBoard,
            creditLimit: userDetails.creditLimit,
            turnkeyLoanID: userDetails.turnkeyLoanID,
            loanStartDate: userDetails.loanStartDate,
            role: searchResults?.roleName,
            enabled: userDetails?.enabled,
            partner: userDetails?.partner
          });
          setStatus({ loading: false, error: null });
        } else {
          setStatus({ loading: false, error: 'User was not found' });
        }
        setDataToAPI(null);
        setUsersEmail('');
      } catch (e) {
        setUserToView(null);
        setStatus({ loading: false, error: `${e}` });
      }
    };

    dataToAPI && findUserID(dataToAPI);
  }, [dataToAPI, setUserToView]);

  return (
    <div>
      <Form>
        <Form.Group className="mb-3" controlId="usersEmail" onSubmit={handleSubmit}>
          <Form.Label>Users Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter user's email" onChange={handleChange} value={usersEmail} />
        </Form.Group>

        <Button variant="primary" type="submit" disabled={!usersEmail} onClick={handleSubmit}>
          Search
        </Button>
        {status.error && <p className="error">{status.error}</p>}
      </Form>
    </div>
  );
}
