import React from 'react';

export default function FirstQuiz() {
  return (
    <div className="sptText">
      <h3>Improve your financial knowledge.</h3>
      <p className="muted">Every few days we’ll give you a new quiz to quickly improve your financial know-how.</p>
      <p className="muted">Try your first quiz now.</p>
      <hr />
    </div>
  );
}
