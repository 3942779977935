import React from 'react';
import TitleBlock from '../ModalTitleBlock';
import WideBtn from '../WideBtn';
import Spinner from 'react-bootstrap/Spinner';
import './SeeResults.scss';

import achievementIcon from '../../assets/icons/achievementIcon.svg';

export default function SeeResults({ uploadAssessment, status }) {
  const indicator = <Spinner animation="border" size="sm" />;
  const { loading, error } = status;
  return (
    <div id="seeResults">
      <TitleBlock
        title="Thank you for your self-assessment."
        body="We use this information to find ways to improve your financial behaviour—but also to reward you for staying on track."
        icon={achievementIcon}
      />
      <WideBtn onClick={uploadAssessment} disabled={loading}>
        {loading ? indicator : 'See results'}
      </WideBtn>
      {error && (
        <p style={{ textAlign: 'center' }} className="error">
          {error}
        </p>
      )}
      <hr />
      <p className="muted">
        We’ll provide you with a cash-back reward based on your self-assessment but subject to review.
      </p>
    </div>
  );
}
