import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
const DISABLED_BTN_STYLE = { backgroundColor: '#EAECF0', border: '1px solid #EAECF0' };
function LoginForm({ handleSubmit, savedLogin, isLoading, errors }) {
  const [formData, setFormData] = useState({ username: `${savedLogin ? savedLogin : ''}`, password: '' });
  const handleInput = (e) => {
    e.preventDefault();
    const field = e.target.id;
    const newValue = e.target.value;
    setFormData((prev) => {
      return { ...prev, [field]: newValue };
    });
  };

  return (
    <Form id="login-form" onSubmit={(e) => handleSubmit(e, formData)}>
      <Form.Group className="mb-3" controlId="username">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="name@example.com"
          onChange={handleInput}
          value={formData.username}
          disabled={isLoading}
          isInvalid={errors?.username}
        />
        <Form.Control.Feedback type="invalid">{errors?.username}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="password"
          onChange={handleInput}
          value={formData.password}
          disabled={isLoading}
          isInvalid={errors?.password}
        />
        <Form.Control.Feedback type="invalid">{errors?.password}</Form.Control.Feedback>
      </Form.Group>
      <div className="d-grid gap-2" id="loginBtn">
        <Button
          variant="success"
          style={formData.username && formData.password ? null : DISABLED_BTN_STYLE}
          type="submit"
          disabled={formData.username && formData.password ? false : true || isLoading}
        >
          Sign In
        </Button>
      </div>
    </Form>
  );
}

export default LoginForm;
